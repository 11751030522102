/* eslint-disable max-classes-per-file */
type FetchParams = Parameters<typeof window.fetch>;

export type TypedResponse<JsonBodyType, StatusCode extends number = 200> = {
  json: () => Promise<JsonBodyType>;
  status: StatusCode;
} & Response;

export type EmptyResponse<StatusCode extends number = 200> = TypedResponse<never, StatusCode>;

abstract class ResponseError<TResponse extends Response> extends Error {
  response: TResponse;

  protected constructor(message: string, response: TResponse) {
    super(message);
    this.response = response;
  }
}

export class UnauthenticatedError<TResponse extends Response> extends ResponseError<TResponse> {
  constructor(response: TResponse) {
    super('User is not authenticated.', response);
  }
}
export class NotAuthorizedError<TResponse extends Response> extends ResponseError<TResponse> {
  constructor(response: TResponse) {
    super('User is not authorized to perform this action.', response);
  }
}

export const fetch = async <T extends Response = Response>(...params: FetchParams) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const response = await (window.fetch(...params) as Promise<T>);

  if (response.status === 401) {
    throw new UnauthenticatedError(response);
  } else if (response.status === 403) {
    throw new NotAuthorizedError(response);
  }

  return response;
};
