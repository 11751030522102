import styled from 'styled-components';
import { getInitials } from '@lower-financial/toolbox';
import { Heading, HeadingColors } from '../heading';

export const InitialsBadge = ({ name }: { name: string }) => {
  const initials = getInitials(name);
  return (
    <InitialsBadgeContainer
      data-testid={'initials-badge'}
    >
      <Heading
        size={'xxs'}
        color={HeadingColors.Primary}
        marginTop={'2px'} // centers the all-caps text in the circle
      >
        {initials}
      </Heading>
    </InitialsBadgeContainer>
  );
};

export const InitialsBadgeContainer = styled.div`
    align-items: center;
    background-color: var(--primary-light);
    border-radius: 3rem;
    display: flex;
    height: 3rem;
    justify-content: center;
    min-height: 3rem;
    min-width: 3rem;
    position: relative;
    width: 3rem;
`;
