import React from 'react';
import { CardRadioGroup } from '@lower-financial/core-components/src/components/card-radio-group/card-radio-group';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

export const ApplicationOptionsNonPurchaseForm = () => {
  const {
    updateMortgageApplication, mortgageApplication, clearCoBorrower,
  } = useMortgageApplication();

  const handleChange = () => {
    // delete all coborrower fields if this was checked previously
    if (mortgageApplication.hasCoBorrower) {
      clearCoBorrower();
    }
    updateMortgageApplication('hasCoBorrower', !mortgageApplication.hasCoBorrower);
  };

  const items = [
    {
      icon: '🙌',
      label: 'Yep—we’re both ready to go.',
      value: true,
    },
    {
      icon: '🤘',
      label: 'Nope—I’m going solo here.',
      value: false,
    },
  ];

  return (
    <CardRadioGroup
      label={'Are you applying with someone?'}
      items={items}
      value={mortgageApplication.hasCoBorrower}
      onChange={handleChange}
    />
  );
};

