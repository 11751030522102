import styled from 'styled-components';
import { HTMLAttributes } from 'react';
import {
  Heading,
  responsiveStyleFromTheme,
} from '@lower-financial/core-components';

export function SplitSection(props: HTMLAttributes<HTMLDivElement>) {
  return <Container {...props} />;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 80px;
  flex-grow: 1;
`;

SplitSection.Content = styled.main`
  display: flex;
  flex: 6;
  flex-direction: column;
  margin: 0 auto;
`;

SplitSection.Heading = styled(Heading)`
  padding-top: var(--spacing-7);

  ${responsiveStyleFromTheme({
    desktop: {
      maxWidth: '580px',
      paddingTop: '0',
    },
  })}
`;

SplitSection.Sidebar = styled.aside`
  display: flex;
  order: 2;
  flex: 4;
  flex-direction: column;
  margin: 0 auto;

  button {
    max-width: 240px !important;
  }
`;

SplitSection.LegalFooter = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
`;
