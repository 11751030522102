import {
  DateInput,
  Explainer,
  Form,
  FormRow,
  Heading,
  Label,
  OptionalElement,
  Paragraph,
  SSNInput,
  ValidationError,
} from '@lower-financial/core-components';
import styled from 'styled-components';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

interface IdentityFormProps {
  errors: Map<string, string[]>|null;
  displayExplanation?: boolean;
}

export function IdentityForm({ errors, displayExplanation = false }: IdentityFormProps) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  return (
    <>
      <Form>
        <div>
          <Heading
            element={'h2'}
            size={'sm'}
          >Soft Credit Check
          </Heading>
          {displayExplanation && (
            <Explanation variant={'smallLight'}>
              To talk about rates, we need to verify your info, but it won’t impact your credit. Scout’s honor. 👌
            </Explanation>
          )}
        </div>
        <FormRow>
          <Label flex={['0 0 100%', 1]}>
            Date of Birth*
            <DateInput
              name={'borrower-date-of-birth'}
              value={mortgageApplication.borrowerDateOfBirth}
              onChange={(e) => updateMortgageApplication('borrowerDateOfBirth', e.target.value)}
            />
            <ValidationError
              errors={errors}
              path={'borrowerDateOfBirth'}
            />
          </Label>
          <Label flex={['0 0 100%', 1]}>
            Social Security Number*
            <SSNInput
              name={'ssn'}
              placeholder={'XXX-XX-XXXX'}
              value={mortgageApplication.borrowerSocialSecurityNumber}
              onChange={(e) => updateMortgageApplication('borrowerSocialSecurityNumber', e.target.value)}
            />
            <ValidationError
              errors={errors}
              path={'borrowerSocialSecurityNumber'}
            />
          </Label>
        </FormRow>
      </Form>
      <Explainer
        label={'Why We Ask'}
        headerElement={'h2'}
      >
        <Paragraph variant={'smallLight'}>
          When doing anything related to borrowing a large sum of money (like quoting a rate) we need a
          bit of info about you to do the math. We’re looking at things like debt-to-income ratio and
          credit score when we run your soft credit check.
        </Paragraph>
      </Explainer>
      <OptionalElement show={mortgageApplication.hasCoBorrower}>
        <Form>
          <Heading
            element={'h2'}
            size={'sm'}
            isSensitive
          >{mortgageApplication.coBorrowerFirstName}’s Soft Credit Check
          </Heading>
          <FormRow>
            <Label flex={['0 0 100%', 1]}>
              Date of Birth*
              <DateInput
                name={'coBorrower-date-of-birth'}
                value={mortgageApplication.coBorrowerDateOfBirth}
                onChange={(e) => updateMortgageApplication('coBorrowerDateOfBirth', e.target.value)}
              />
              <ValidationError
                errors={errors}
                path={'coBorrowerDateOfBirth'}
              />
            </Label>
            <Label flex={['0 0 100%', 1]}>
              Social Security Number*
              <SSNInput
                placeholder={'XXX-XX-XXXX'}
                value={mortgageApplication.coBorrowerSocialSecurityNumber}
                onChange={(e) => updateMortgageApplication('coBorrowerSocialSecurityNumber', e.target.value)}
              />
              <ValidationError
                errors={errors}
                path={'coBorrowerSocialSecurityNumber'}
              />
            </Label>
          </FormRow>
        </Form>
      </OptionalElement>
    </>
  );
}

const Explanation = styled(Paragraph)`
  padding-top: var(--spacing-2);
`;
