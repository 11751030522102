import styled from 'styled-components';
import { Link, Paragraph } from '@lower-financial/core-components';
import { EqualHousingLender } from '@lower-financial/icons/';
import { usePartnerLogos } from '@lightspeed/hooks/usePartnerStyles/usePartnerLogos';

export const SiteFooter = () => {
  const { logos } = usePartnerLogos();
  return (

    <SiteFooterContainer>
      <FooterGradient />
      <FooterContent>
        <FooterSection>
          <a
            href={'https://lower.com'}
            aria-current={'page'}
            style={
              {
                display: 'inline-block',
                maxWidth: '100%',
              }
            }
          >
            <img
              src={logos.logo}
              alt={'Logo'}
            />
          </a>
        </FooterSection>
        <FooterSection>
          <FooterCategoryContainer>
            <FooterCategory>
              <CategoryHeader>Products</CategoryHeader>
              <FooterLink
                href={'https://lower.com/finance'}
              >Finance.
              </FooterLink>
              <FooterLink
                href={'https://lower.com/refinance'}
              >Refinance.
              </FooterLink>
              <FooterLink
                href={'https://lower.com/real-estate'}
              >Buy. Sell.
              </FooterLink>
              <FooterLink
                href={'https://lower.com/save'}
              >Save.
              </FooterLink>
              <FooterLink
                href={'https://lower.com/insurance'}
              >Insure.
              </FooterLink>
            </FooterCategory>
            <FooterCategory>
              <CategoryHeader>About</CategoryHeader>
              <FooterLink
                href={'https://lower.com/about-us'}
              >Company
              </FooterLink>
              <FooterLink
                rel={'noopener'}
                href={'https://lower.com/dream'}
              >Careers
              </FooterLink>
              <FooterLink
                href={'https://lower.com/news'}
              >News
              </FooterLink>
              <FooterLink
                href={'https://lower.com/learn'}
              >Learn
              </FooterLink>
            </FooterCategory>
            <FooterCategory>
              <CategoryHeader>Tools</CategoryHeader>
              <FooterLink
                href={'https://lower.com/app-landing'}
              >Lower App
              </FooterLink>
              <FooterLink
                href={'https://quiz.lower.com/'}
                target={'_blank'}
                rel={'noreferrer'}
              >Home Readiness Quiz
              </FooterLink>
              <FooterLink
                href={'https://hi.lower.com/refinance-calculator'}
              >Refi Calculator
              </FooterLink>
              <FooterLink
                href={'https://hi.lower.com/heloc'}
              >HELOC Calculator
              </FooterLink>
              <FooterLink
                href={'https://hi.lower.com/va-calculator'}
              >VA Calculator
              </FooterLink>
              <FooterLink
                href={'http://upload.lower.com'}
              >Secure Upload
              </FooterLink>
              <FooterLink
                href={'https://lower.secureconduit.net/'}
                target={'_blank'}
                rel={'noreferrer'}
              >Make a Payment
              </FooterLink>
            </FooterCategory>
            <FooterCategory>
              <CategoryHeader>Support</CategoryHeader>
              <FooterLink
                href={'https://lower.com/care'}
              >Care
              </FooterLink>
              <FooterLink
                href={'tel:1(833)920-2273'}
              >1 (833) 920-2273
              </FooterLink>
              <FooterLink
                href={'mailto:Care@lower.com'}
              >
                Care@lower.com
              </FooterLink>
            </FooterCategory>
            <FooterCategory>
              <CategoryHeader>Connect</CategoryHeader>
              <FooterLink
                rel={'noopener noreferrer'}
                href={'https://www.facebook.com/lowerdotcom'}
                target={'_blank'}
              >Facebook
              </FooterLink>
              <FooterLink
                rel={'noopener noreferrer'}
                href={'https://www.instagram.com/lowerdotcom/'}
                target={'_blank'}
              >Instagram
              </FooterLink>
              <FooterLink
                rel={'noopener noreferrer'}
                href={'https://www.linkedin.com/company/lower/'}
                target={'_blank'}
              >LinkedIn
              </FooterLink>
              <FooterLink
                rel={'noopener noreferrer'}
                href={'https://www.glassdoor.com/Overview/Working-at-Lower-EI_IE2297520.11,16.htm'}
                target={'_blank'}
              >Glassdoor
              </FooterLink>
              <FooterLink
                rel={'noopener noreferrer'}
                href={'https://www.zillow.com/lender-profile/lower/'}
                target={'_blank'}
              >Zillow
              </FooterLink>
            </FooterCategory>
          </FooterCategoryContainer>
        </FooterSection>
        <FooterSection
          style={
            {
              paddingBottom: 10,
            }
          }
        >
          <Paragraph variant={'smallLight'}>
            Lower is a family of companies. Lower® and its DBAs (Homeside Financial, AMSW Lending, Fairfax Mortgage,
            Key Mortgage Group, Lakeside Mortgage, Oz Lending, Q Home Loans, and True Lend) provide home loans;
            Lower Realty, LLC provides real estate services; Homeside Insurance Services, LLC facilitates shopping
            experience for homeowner&quot;s insurance policies. All rights reserved. Conducts business as
            “Lower.com,LLC”
            in Kentucky, Minnesota, and Washington in lieu of Lower, LLC. Conducts business as “Lowerdotcom,LLC” in
            Montana in lieu of Lower, LLC. Conducts business as “Lower Mortgage,LLC” in Oklahama in lieu of Lower, LLC.
          </Paragraph>
          <Paragraph
            variant={'smallLight'}
            style={{
              marginTop: '24px',
            }}
          >
            Home lending products offered by Lower | 8621 Robert Fulton Drive, Suite 150, Columbia, Maryland 21046
          </Paragraph>
        </FooterSection>
        <FooterSection>
          <Paragraph variant={'smallLight'}>
            <img
              src={EqualHousingLender}
              alt={'Equal Housing Lender Icon'}
              style={{
                display: 'inline',
                marginRight: '10px',
                marginTop: '-4px',
              }}
            />
            <span
              style={{
                fontWeight: 600,
              }}
            >EQUAL HOUSING LENDER
            </span>
            <span
              style={{
                display: 'inline',
              }}
            >&nbsp;&nbsp;|&nbsp;&nbsp;© {new Date().getFullYear()} Lower Holding Company.
              All rights reserved.&nbsp;&nbsp;|&nbsp;&nbsp;
              NMLS ID #1124061&nbsp;&nbsp;|&nbsp;&nbsp;
              <CustomLink
                href={'https://nmlsconsumeraccess.org/'}
                target={'_blank'}
                rel={'noreferrer'}
              >nmlsconsumeraccess.org
              </CustomLink>
            </span>
          </Paragraph>
        </FooterSection>
      </FooterContent>
    </SiteFooterContainer>
  );
};

const SiteFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(241,241,247,.5)),color-stop(35%,#fff));
  background-image: linear-gradient(180deg,rgba(241,241,247,.5),#fff 35%);
  color: red;
  padding: 3rem;
`;

const FooterGradient = styled.div`
  position: absolute;
  top: -100%;
  z-index: -10;
  display: block;
  width: 100%;
  height: 200%;
  background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#f8f8fb));
  background-image: linear-gradient(180deg,#fff,#f8f8fb);
`;

const FooterContent = styled.div`
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 901px;
  gap: 3rem;
`;

const FooterCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  ${(props) => props.theme.media.desktop} {
    align-items: stretch;
    align-content: space-between;
  }
`;

const FooterCategory = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 24px;
`;

const CategoryHeader = styled.h5`
  font: var(--font-footer-header);
  text-align: center;
  color: var(--headline);
`;

const FooterSection = styled.div`
  display: block;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
`;

const FooterLink = styled(Link)`
  display: inline-block;
  padding: 10px 20px 10px 0;
  color: var(--body);
  font: var(--font-footer-link);
`;

const CustomLink = styled.a`
  display: inline-block;
  color: var(--body);
  font: var(--font-paragraph-1);
`;
