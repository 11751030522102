export const SecureCallWithoutLogo = ({ ...props }) => (
  <svg
    width={'102'}
    height={'91'}
    viewBox={'0 0 102 91'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>Secure call</title>
    <rect
      x={'16'}
      y={'12.124'}
      width={'69.8761'}
      height={'69.8761'}
      rx={'17.8407'}
      fill={'#001CDB'}
    />
    <rect
      x={'13.0265'}
      y={'9.15057'}
      width={'75.823'}
      height={'75.823'}
      rx={'20.8142'}
      stroke={'#001CDB'}
      strokeOpacity={'0.14'}
      strokeWidth={'5.9469'}
    />
    <path
      d={'M62.8875 52.3192C61.1096 52.3192 59.3896 52.0301 57.7851 51.5098C57.2792 51.3363 56.7155 51.4664 56.3253 51.8567C54.9966 53.5238 52.6618 54.0891 50.9326 52.8423C49.0848 51.5099 47.389 49.8493 46.0147 47.9916C44.7176 46.2382 45.2548 43.8462 46.9155 42.4325C47.3058 42.0278 47.4214 41.464 47.2624 40.9581C46.7276 39.3537 46.453 37.6337 46.453 35.8558C46.453 35.0752 45.8026 34.4248 45.022 34.4248H40.0208C39.2403 34.4248 38.3008 34.7717 38.3008 35.8558C38.3008 49.2838 49.4739 60.4425 62.8875 60.4425C63.9138 60.4425 64.3185 59.5319 64.3185 58.7369V53.7502C64.3185 52.9696 63.668 52.3192 62.8875 52.3192Z'}
      fill={'white'}
    />
    <circle
      cx={'81.3894'}
      cy={'15.6106'}
      r={'15.6106'}
      fill={'#02CA02'}
    />
    <mask
      id={'mask0_4260_6253'}
      maskUnits={'userSpaceOnUse'}
      x={'74'}
      y={'8'}
      width={'15'}
      height={'15'}
    >
      <rect
        x={'74.2788'}
        y={'8.5'}
        width={'14.2209'}
        height={'14.2209'}
        fill={'#D9D9D9'}
      />
    </mask>
    <g mask={'url(#mask0_4260_6253)'}>
      <path
        d={'M77.834 21.535C77.5081 21.535 77.2291 21.419 76.997 21.1869C76.765 20.9549 76.6489 20.6759 76.6489 20.35V14.4246C76.6489 14.0987 76.765 13.8197 76.997 13.5877C77.2291 13.3556 77.5081 13.2395 77.834 13.2395H78.4265V12.0545C78.4265 11.2348 78.7154 10.5361 79.2931 9.95838C79.8708 9.38066 80.5695 9.0918 81.3892 9.0918C82.2089 9.0918 82.9076 9.38066 83.4853 9.95838C84.063 10.5361 84.3519 11.2348 84.3519 12.0545V13.2395H84.9444C85.2703 13.2395 85.5493 13.3556 85.7814 13.5877C86.0135 13.8197 86.1295 14.0987 86.1295 14.4246V20.35C86.1295 20.6759 86.0135 20.9549 85.7814 21.1869C85.5493 21.419 85.2703 21.535 84.9444 21.535H77.834ZM79.6116 13.2395H83.1668V12.0545C83.1668 11.5607 82.994 11.141 82.6483 10.7953C82.3027 10.4497 81.883 10.2769 81.3892 10.2769C80.8954 10.2769 80.4757 10.4497 80.1301 10.7953C79.7844 11.141 79.6116 11.5607 79.6116 12.0545V13.2395Z'}
        fill={'white'}
      />
    </g>
  </svg>
);
