export const CloseXIcon = ({ color = '#FFF', ...props }) => (
  <svg
    width={'13'}
    height={'13'}
    viewBox={'0 0 13 13'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <path
      d={'M1 12L12 1'}
      stroke={color}
      strokeWidth={'2'}
      strokeLinecap={'round'}
    />
    <path
      d={'M1 1L12 12'}
      stroke={color}
      strokeWidth={'2'}
      strokeLinecap={'round'}
    />
  </svg>
);
