import cx from 'classnames';
import styled from 'styled-components';
import { ReactNode, ComponentProps } from 'react';
import { ThemedProps } from '../../styles/utils/theme-utils';

interface ErrorComponentProps {
  isHidden?: boolean;
  className?: string;
  children?: ReactNode;
}

export const Error = styled.p.attrs(
  ({
    isHidden,
    className,
    ...props
  }: ErrorComponentProps) => ({
    className: cx(
      className,
      'Error',
      {
        'is-hidden': isHidden,
      },
    ),
    ...props,
  }),
)<ErrorComponentProps>`
  opacity: 1;
  margin: 0;
  ${ (props: ThemedProps) => props.theme.Label.book };
  color: var(--error);
  transition: opacity ${(props: ThemedProps) => props.theme.transitions.prop};

  &.is-hidden {
    opacity: 0;
  }
`;

export type ErrorProps = ComponentProps<typeof Error>;
