import { useCallback } from 'react';
import { getCalendlyTimezoneFromState } from './calendly-timezones';

export type CalendlyBorrower = {
  state: string | null,
  firstName: string | null,
  lastName: string | null,
  email: string | null,
  phoneNumber: string | null
};

export function useCalendlyUrl(
  prefillUrl: string,
  activeLeadId: string | null,
  borrower: CalendlyBorrower,
  isDuringBusinessHours: boolean,
  utmSource: string,
) {
  const buildCalendlyUrl = useCallback(() => {
    const calendlyPrefillBaseUrl = `${prefillUrl}lower-com-loan-consultation-`;

    const borrowerStateToUser = !borrower.state
      ? 'CA'
      : borrower.state;

    const timeZoneSuffix = getCalendlyTimezoneFromState(borrowerStateToUser);
    const calendlyUrl = new URL(`${calendlyPrefillBaseUrl}${timeZoneSuffix}`);

    if (borrower.firstName) {
      calendlyUrl.searchParams.append('first_name', borrower.firstName);
    }
    if (borrower.lastName) {
      calendlyUrl.searchParams.append('last_name', borrower.lastName);
    }
    if (borrower.email) {
      calendlyUrl.searchParams.append('email', borrower.email);
    }
    if (borrower.phoneNumber) {
      const USCountryCode = '1';
      calendlyUrl.searchParams.append('a1', `${USCountryCode}${borrower.phoneNumber}`);
    }
    calendlyUrl.searchParams.append('utm_medium', 'web');
    calendlyUrl.searchParams.append('utm_source', utmSource);
    calendlyUrl.searchParams.append('utm_campaign', isDuringBusinessHours
      ? 'businesshours'
      : 'afterhours');
    calendlyUrl.searchParams.append('utm_content', 'scheduleforlater');
    if (activeLeadId) {
      calendlyUrl.searchParams.append('sfid', activeLeadId);
    }
    return calendlyUrl;
  }, [activeLeadId,
    borrower.email,
    borrower.firstName,
    borrower.lastName,
    borrower.phoneNumber,
    borrower.state,
    isDuringBusinessHours,
    prefillUrl,
    utmSource]);

  return {
    buildCalendlyUrl,
  };
}

