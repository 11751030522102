import React, { ComponentProps } from 'react';
import { PatternFormat } from 'react-number-format';
import { TextInput } from '..';
import { TextInputProps } from '../text-input/text-input';

type PhoneInputComponentProps = {
  value: string;
  onChange: (value: string) => void;
} & Omit<TextInputProps, 'onChange'>;

export function PhoneInput({
  value,
  name,
  onChange,
  $hasError,
  placeholder = '844-645-6937',
  disabled = false,
  onFocus,
  rightIcon,
}: PhoneInputComponentProps) {
  return (
    <PatternFormat
      onFocus={onFocus}
      name={name}
      mask={'_'}
      format={'###-###-####'}
      customInput={TextInput}
      value={value}
      onValueChange={(values) => {
        onChange(values.formattedValue);
      }}
      placeholder={placeholder}
      disabled={disabled}
      $hasError={$hasError}
      inputMode={'numeric'}
      rightIcon={rightIcon}
    />
  );
}

export default PhoneInput;

export type PhoneInputProps = ComponentProps<typeof PhoneInput>;
