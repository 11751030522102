import styled from 'styled-components';
import { ComponentProps } from 'react';
import { responsiveStyleFromTheme } from '../../styles/utils/theme-utils';
import { CardToggle } from '../card-toggle/card-toggle';

type CardToggleProps = ComponentProps<typeof CardToggle>;

export type CardToggleItem = Omit<CardToggleProps, 'variant' | 'role' | 'tabIndex'>;

export function CardCheckboxGroup({
  items, tabIndex = 0, role = 'checkbox',
}: {
  items: CardToggleItem[],
  tabIndex?: number,
  role?: 'checkbox' | 'radio' | 'button'
}) {
  return (
    <Container>
      {items.map((item, index) => (
        <CardToggle
          {...item}
          variant={'checkbox'}
          key={`card-toggle-${index}`}
          tabIndex={tabIndex}
          role={role}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  
  > div {
    width: 100%;
  }
  
  ${responsiveStyleFromTheme({
    desktop: {
      gap: '1.5rem',
    },
  })}
`;
