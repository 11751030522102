import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { Market } from '@lower-financial/lending-web-api/generated/models';
import { useErrorHandling } from '@lower-financial/core-components';
import getMarket from '@lightspeed/api/services/backend/market/get-market';

export interface MarketsContextData {
  markets: Market[]|null;
  setMarkets: React.Dispatch<React.SetStateAction<Market[]|null>>;
  isLoading: boolean;
}

export const marketsContextDefaultValue = {
  isLoading: false,
  markets: null,
  setMarkets: () => { /* do nothing */ },
};
export const MarketsContext = createContext<MarketsContextData>(marketsContextDefaultValue);

export function MarketContextProvider({
  children,
  overrides,
}: {
  children?: ReactNode | ReactNode[],
  overrides: null | Market[]
}) {
  const [markets, setMarkets] = useState<Market[]|null>(overrides || null);
  const [isMarketLoading, setIsMarketLoading] = useState(false);
  const context = useMemo(() => ({
    isLoading: isMarketLoading,
    markets,
    setMarkets,
  }), [isMarketLoading, markets]);
  const handleError = useErrorHandling();

  useEffect(() => {
    (async () => {
      if (markets !== null || isMarketLoading) return;
      await handleError({
        onRetryableError: () => {
          setIsMarketLoading(false);
        },
        tryFn: async () => {
          setIsMarketLoading(true);
          const response = await getMarket();
          if (response.status === 404) {
            const data = await response.json();
            throw new Error(`404: Couldn't find Markets: ${JSON.stringify(data.message)}`);
          } else if  (response.status === 200) {
            const data = await response.json();
            setMarkets(data);
            setIsMarketLoading(false);
          } else {
            // @ts-expect-error this should never happen unless we have a bug on the backend
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error(`Unknown status when fetching markets: ${response.status}`);
          }
        },
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MarketsContext.Provider
      value={context}
    >
      {children}
    </MarketsContext.Provider>
  );
}
