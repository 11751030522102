import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';

// doing a generic here so that we can use the MortgageApplicationStore type here
const createAllowedPrefillFields = <TKey extends keyof MortgageApplicationStore>(...items: TKey[]): Readonly<TKey[]> =>
  items;

export const coborrowerPrefillFields = [
  'coBorrowerFirstName',
  'coBorrowerLastName',
  'coBorrowerEmail',
  'coBorrowerPhoneNumber',
  'coBorrowerStreetAddress',
  'coBorrowerCity',
  'coBorrowerState',
  'coBorrowerZipCode',
  'coBorrowerEmployer',
  'coBorrowerAmountInSavings',
  'coBorrowerEmploymentStatus',
  'coBorrowerOtherIncomePerYear',
  'coBorrowerYearsAtCompany',
  'coBorrowerTitle',
] as const;

export const allowedPrefillFields = createAllowedPrefillFields(
  'borrowerFirstName',
  'borrowerLastName',
  'borrowerEmail',
  'borrowerPhoneNumber',
  'borrowerStreetAddress',
  'borrowerCity',
  'borrowerState',
  'borrowerZipCode',
  'borrowerEmployer',
  'borrowerAmountInSavings',
  'borrowerFirstTimeHomeBuyer',
  'borrowerYearsAtAddress',
  'borrowerEmploymentStatus',
  'borrowerOtherIncomePerYear',
  'borrowerYearsAtCompany',
  'borrowerTitle',
  'propertyState',
  ...coborrowerPrefillFields,
);

export type PrefillKey = typeof allowedPrefillFields[number];

export type PrefillData = Partial<Record<PrefillKey, string>>;
