import buyAHomeImageSrc from '@lightspeed/assets/images/buy-a-home.png';

export const BuyAHomeImage = ({ ...props }) => (
  <img
    src={buyAHomeImageSrc}
    alt={'Buy a home'}
    height={'87px'}
    width={'126px'}
    {...props}
  />
);
