import { ComponentProps } from 'react';
import styled from 'styled-components';
import { compose, LayoutProps, size, SizeProps, layout, variant } from 'styled-system';
import cx from 'classnames';
import { SymbolAsset, Symbols } from '../../symbols';

export type SymbolComponentProps = LayoutProps & SizeProps & {
  type: SymbolAsset;
  className?: string;
};

/**
 * Renders a svg element based off attribute {type}.
 *
 */
export const Symbol = styled.div.attrs(
  ({
    type,
    className,
    ...props
  }: SymbolComponentProps) => ({
    as: Symbols[type],
    className: cx('Symbol', `Symbol-${type}`, type, className),
    type,
    ...props,
  }),
)<SymbolComponentProps>(
  compose(
    variant({
      prop: 'type',
      variants: {
        Info: {
          height: '12px',
          marginLeft: '3px',
          verticalAlign: 'top',
          width: '12px',
        },
      },
    }),
    size,
    layout,
  ),
);

export type SymbolProps = ComponentProps<typeof Symbol>;
