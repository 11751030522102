export const BoltIcon = ({ color = '#FFB82F', ...props }) => (
  <svg
    width={'12'}
    height={'14'}
    viewBox={'0 0 12 14'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <path
      d={'M6.20078 9.33323L5.73005 12.9645C5.6592 13.5111 6.39531 13.7534 6.66298 13.2716L10.9484 5.55794C11.1705 5.15803 10.8814 4.66656 10.4239 4.66656H6.20078L6.69512 0.853038C6.76498 0.314139 6.04616 0.066373 5.76913 0.533861L1.09151 8.42734C0.854492 8.82731 1.14277 9.33323 1.60768 9.33323H6.20078Z'}
      fill={color}
    />
  </svg>
);
