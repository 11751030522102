import { CurrencyRangeDto } from '@lower-financial/lending-web-api/generated';
import { fetch, TypedResponse } from '@lower-financial/toolbox';
import { ENV } from '@lightspeed/environment';

export function getCurrencyRanges() {
  return fetch<GetCurrencyRangesResponse>(
    `${ENV.BACKEND_API_HOST}/api/lending/currency_ranges`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  );
}

export type CurrencyRange = CurrencyRangeDto;
export type GetCurrencyRangesResponse = TypedResponse<CurrencyRange[], 200>;
