import { QuotingApplication, QuotingApplicationCreated, ValidationErrorResponse } from '@lower-financial/lending-web-api/generated';
import { fetch, TypedResponse } from '@lower-financial/toolbox';
import { ENV } from '@lightspeed/environment';

export function createQuotingApplication(quotingApplication: QuotingApplication) {
  return fetch<CreateQuotingApplicationResponse>(
    `${ENV.BACKEND_API_HOST}/api/lending/quoting_applications`,
    {
      body: JSON.stringify(quotingApplication),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
}

type ValidationErrorResponseInterface = TypedResponse<ValidationErrorResponse, 422>;
type QuotingApplicationCreatedInterface = TypedResponse<QuotingApplicationCreated, 201>;

export type CreateQuotingApplicationResponse = ValidationErrorResponseInterface | QuotingApplicationCreatedInterface;
