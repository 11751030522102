import { HelpIcon, Paragraph } from '@lower-financial/core-components/src';
import styled from 'styled-components';

export const PersonBanner = ({ text }: { text: string }) => (
  <PersonBannerContainer>
    <HelpIcon showOnlineDot={false} />
    <StyledParagraph variant={'regular'}>{text}</StyledParagraph>
  </PersonBannerContainer>
);

const PersonBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.75rem;
`;

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  color: var(--ash);
`;

const StyledPersonCircle = styled.img`
  height: 2.5rem;
  width: 2.5rem;
`;
