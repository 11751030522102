import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useReferralData } from '@lightspeed/contexts/referral-context/referral-context';
import { initializePartnerConfiguration, PartnerConfiguration, defaultPartnerConfiguration, ClearableField, removeCookie } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-helpers';

const partnerConfigurationContextDefaultValue
  = {
    clearField: (fieldName: ClearableField) => {
      // do nothing
    },
    partnerConfiguration: defaultPartnerConfiguration,
  };
const PartnerConfigurationContext = createContext<{
  partnerConfiguration: PartnerConfiguration,
  clearField(fieldName: ClearableField): void
}>(
    partnerConfigurationContextDefaultValue);

export function PartnerConfigurationContextProvider({ children }: {
  children?: ReactNode | ReactNode[]
}) {
  const { updateReferralData } = useReferralData();
  const [partnerConfiguration, setPartnerConfiguration] = useState<PartnerConfiguration>(
    () => initializePartnerConfiguration(),
  );

  const clearField = useCallback((fieldName: ClearableField) => {
    removeCookie(fieldName);

    setPartnerConfiguration((prevPartnerConfiguration) => ({
      ...prevPartnerConfiguration,
      [fieldName]: defaultPartnerConfiguration[fieldName],
    }));
  }, []);

  useEffect(() => {
    partnerConfiguration.affinityPartnerName && updateReferralData?.({
      affinityPartnerName: partnerConfiguration.affinityPartnerName,
    });
  }, [partnerConfiguration.affinityPartnerName, updateReferralData]);

  const context = useMemo(() => (
    {
      clearField,
      partnerConfiguration,
    }
  ), [clearField, partnerConfiguration]);

  return (
    <PartnerConfigurationContext.Provider
      value={context}
    >
      {children}
    </PartnerConfigurationContext.Provider>
  );
}

export const usePartnerConfiguration = () => useContext(PartnerConfigurationContext);

