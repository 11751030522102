import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { theme } from '@lower-financial/core-components/src/styles/primary/theme';
import styled from 'styled-components';
import { DesktopPageLayout } from '@lightspeed/components/shared/page-layout/desktop-page-layout';
import { MobilePageLayout } from '@lightspeed/components/shared/page-layout/mobile-page-layout';
import { BasePageTemplate } from '@lightspeed/components/ui/templates/base-page-template';
import { Header } from '@lightspeed/components/ui/molecules/header';

export type PageLayoutProps = {
  hideBackButton?: boolean
  title: ReactNode | ReactNode[],
  subtitle?: ReactNode | ReactNode[]
  children: ReactNode | ReactNode[],
  mobileBanner?: ReactNode | ReactNode[],
  button?: ReactNode | ReactNode[],
  buttonDisclaimer?: ReactNode | ReactNode[],
  disclaimer?: ReactNode | ReactNode[],
  consentCheckbox?: ReactNode | ReactNode[],
  desktopSidebar?: ReactNode | ReactNode[],
};

export function PageLayout({
  hideBackButton = false,
  title,
  subtitle,
  children,
  button,
  buttonDisclaimer,
  consentCheckbox,
  mobileBanner,
  disclaimer,
  desktopSidebar,
  ...props
}: PageLayoutProps) {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });
  return (
    <PageLayoutContainer {...props}>
      <Header hideBackButton={hideBackButton} />
      <BasePageTemplate>
        {isDesktop
          ? (
            <DesktopPageLayout
              title={title}
              subtitle={subtitle}
              button={button}
              buttonDisclaimer={buttonDisclaimer}
              disclaimer={disclaimer}
              consentCheckbox={consentCheckbox}
              sidebar={desktopSidebar}
            >
              {children}
            </DesktopPageLayout>
          )
          : (
            <MobilePageLayout
              title={title}
              subtitle={subtitle}
              banner={mobileBanner}
              button={button}
              buttonDisclaimer={buttonDisclaimer}
              disclaimer={disclaimer}
              consentCheckbox={consentCheckbox}
            >
              {children}
            </MobilePageLayout>
          )
        }
      </BasePageTemplate>
    </PageLayoutContainer>
  );
}

const PageLayoutContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
