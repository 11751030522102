import { useErrorHandling } from '@lower-financial/core-components';
import { useCallback } from 'react';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { submitQuotingApplication } from '@lightspeed/api/services/backend/lending/submitQuotingApplication';

export function useSubmitQuotingApplication() {
  const handleError = useErrorHandling();
  const { updateMortgageApplication } = useMortgageApplication();

  return useCallback((quotingApplicationId: string) => handleError({
    tryFn: async () => {
      const response = await submitQuotingApplication(quotingApplicationId);

      if (response.status === 202) {
        const data = await response.json();
        updateMortgageApplication('quotingApplicationSubmissionId', data.quotingApplicationSubmissionId);
        return data.quotingApplicationSubmissionId;
      }
      if (response.status === 404) {
        throw new Error(`Couldn't find application during submission for lead id ${quotingApplicationId}`);
      } else if (response.status === 422) {
        const data = await response.json();
        throw new Error(`Error validating application during submission for lead id ${quotingApplicationId}: ${JSON.stringify(data.errors)}`);
      } else if (response.status === 409) {
        const data = await response.json();
        throw new Error(`Application not updatable error during submission for lead id ${quotingApplicationId}: ${JSON.stringify(data.message)}`);
      }
      throw new Error(`Could not submit application for lead id ${quotingApplicationId}`);
    },
  }), [handleError, updateMortgageApplication]);
}
