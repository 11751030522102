import { Heading, OptionalElement, Paragraph, responsiveStyleFromTheme } from '@lower-financial/core-components/src';
import { ReactNode } from 'react';
import styled from 'styled-components';

export function ContentHeading({
  title,
  titleSize = 'xl',
  subtitle,
}: {
  title: ReactNode | ReactNode[],
  titleSize?: 'xl' | 'lg',
  subtitle?: ReactNode | ReactNode[]
}) {
  return (
    <Container>
      <ContentHeadingTitle
        element={'h1'}
        size={titleSize}
      >
        {title}
      </ContentHeadingTitle>
      <OptionalElement show={subtitle !== undefined}>
        <div>
          <Paragraph variant={'light'}>
            {subtitle}
          </Paragraph>
        </div>
      </OptionalElement>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ContentHeadingTitle = styled(Heading)`
  ${responsiveStyleFromTheme({
    desktop: {
      maxWidth: '580px',
      paddingTop: '0',
    },
  })}
`;
