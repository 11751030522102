import { ComponentProps, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { ErrorModal } from './error-modal';
import { StandardModal } from './standard-modal';
import { LoadingModal } from './loading-modal';

export enum ModalTypes {
  Standard,
  Error,
  Loading,
}

interface BaseModalOptions {
  type: ModalTypes;
}

type CommonModalProps = Pick<ComponentProps<typeof StandardModal>, 'isOpen' | 'onCloseModal'>;

type StandardModalOptions = BaseModalOptions & Omit<ComponentProps<typeof StandardModal>, keyof CommonModalProps> & {
  type: ModalTypes.Standard;
};

type ErrorModalOptions = BaseModalOptions & Omit<ComponentProps<typeof ErrorModal>, keyof CommonModalProps> & {
  type: ModalTypes.Error;
};

type LoadingModalOptions = BaseModalOptions & Omit<ComponentProps<typeof LoadingModal>, keyof CommonModalProps> & {
  type: ModalTypes.Loading;
};

type ShowModalOptions = StandardModalOptions | ErrorModalOptions | LoadingModalOptions;

export const useStandardModal = () => {
  const [currentOptions, setCurrentOptions] = useState<ShowModalOptions | null>(null);
  const showModal = (options: ShowModalOptions) => {
    setCurrentOptions(options);
  };

  const showErrorModal = (options: Omit<ErrorModalOptions, 'type'>) => {
    showModal({
      ...options,
      type: ModalTypes.Error,
    });
  };

  const showStandardModal = (options: Omit<StandardModalOptions, 'type'>) => {
    showModal({
      ...options,
      type: ModalTypes.Standard,
    });
  };

  const showLoadingModal = (options: Omit<StandardModalOptions, 'type'>) => {
    showModal({
      ...options,
      type: ModalTypes.Loading,
    });
  };

  const closeModal = () => setCurrentOptions(null);

  const Modal = useMemo(() => {
    if (!currentOptions) {
      return null;
    }
    const commonProps: CommonModalProps = {
      isOpen: true,
      onCloseModal: closeModal,
    };

    let builtComponent: JSX.Element;
    switch (currentOptions.type) {
    case ModalTypes.Standard:
      builtComponent = (
        <StandardModal
          {...currentOptions}
          {...commonProps}
        />
      );
      break;
    case ModalTypes.Error:
      builtComponent = (
        <ErrorModal
          {...currentOptions}
          {...commonProps}
        />
      );
      break;
    case ModalTypes.Loading:
      builtComponent = (
        <LoadingModal
          {...currentOptions}
          {...commonProps}
        />
      );
      break;
    default:
      throw new Error('Invalid modal type');
    }
    return createPortal(builtComponent, document.body);
  }, [currentOptions]);

  return {
    Modal,
    closeModal,
    showErrorModal,
    showLoadingModal,
    showModal,
    showStandardModal,
  } as const;
};
