export const OpenHouseIcon = ({ color = '#001CDB', ...props }) => (
  <svg
    width={'16'}
    height={'17'}
    viewBox={'0 0 16 17'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <path
      d={'M0.31543 7.27995C0.31543 6.4315 0.31543 6.00727 0.49641 5.64104C0.677391 5.27481 1.01438 5.01711 1.68835 4.50172L5.87499 1.30018C6.89667 0.518888 7.40751 0.128245 7.99952 0.128245C8.59152 0.128245 9.10236 0.518889 10.124 1.30018L14.3107 4.50172C14.9847 5.01711 15.3216 5.27481 15.5026 5.64104C15.6836 6.00727 15.6836 6.4315 15.6836 7.27995V14.5757C15.6836 15.4 15.6836 15.8122 15.4275 16.0683C15.1714 16.3244 14.7592 16.3244 13.9349 16.3244H11.6693C10.8449 16.3244 10.4327 16.3244 10.1766 16.0683C9.92054 15.8122 9.92054 15.4 9.92054 14.5757V12.1971C9.92054 11.3727 9.92054 10.9605 9.66444 10.7044C9.40835 10.4483 8.99617 10.4483 8.17181 10.4483H7.82722C7.00286 10.4483 6.59068 10.4483 6.33459 10.7044C6.07849 10.9605 6.07849 11.3727 6.07849 12.1971V14.5757C6.07849 15.4 6.07849 15.8122 5.8224 16.0683C5.5663 16.3244 5.15413 16.3244 4.32977 16.3244H2.06416C1.2398 16.3244 0.82762 16.3244 0.571525 16.0683C0.31543 15.8122 0.31543 15.4 0.31543 14.5757V7.27995Z'}
      fill={color}
    />
  </svg>

);
