import {
  Form,
  FormRow,
  Heading,
  Label,
  PhoneInput,
  TextInput,
  ValidationError,
} from '@lower-financial/core-components';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

interface BasicInfoFormProps {
  errors: Map<string, string[]>|null;
}

export const BasicInfoCoborrowerForm = ({ errors }: BasicInfoFormProps) => {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();

  return (
    <Form>
      <Heading
        element={'h4'}
      >
        Co-borrower Info
      </Heading>
      <FormRow>
        <Label flex={1}>
          First Name*
          <TextInput
            name={'coBorrowerFirstName'}
            value={mortgageApplication.coBorrowerFirstName}
            onChange={(e) => {
              updateMortgageApplication('coBorrowerFirstName', e.target.value);
            }}
          />
          <ValidationError
            path={'coBorrowerFirstName'}
            errors={errors}
          />
        </Label>
        <Label flex={1}>
          Last Name*
          <TextInput
            name={'coBorrowerLastName'}
            value={mortgageApplication.coBorrowerLastName}
            onChange={(e) => {
              updateMortgageApplication('coBorrowerLastName', e.target.value);
            }}
          />
          <ValidationError
            path={'coBorrowerLastName'}
            errors={errors}
          />
        </Label>
      </FormRow>
      <FormRow>
        <Label flex={['0 0 100%', 1]}>
          Email*
          <TextInput
            inputMode={'email'}
            name={'coBorrowerEmail'}
            value={mortgageApplication.coBorrowerEmail}
            onChange={(e) => {
              updateMortgageApplication('coBorrowerEmail', e.target.value);
            }}
            placeholder={'me@example.com'}
          />
          <ValidationError
            path={'coBorrowerEmail'}
            errors={errors}
          />
        </Label>
        <Label flex={['0 0 100%', 1]}>
          Phone*
          <PhoneInput
            name={'coborrower-phone-number'}
            value={mortgageApplication.coBorrowerPhoneNumber}
            onChange={(v) => updateMortgageApplication('coBorrowerPhoneNumber', v)}
          />
          <ValidationError
            path={'coBorrowerPhoneNumber'}
            errors={errors}
          />
        </Label>
      </FormRow>
    </Form>
  );
};
