import styled from 'styled-components';
import { isBusinessHours } from '@lower-financial/toolbox';
import { DateTime } from 'luxon';
import { OptionalElement } from '@lower-financial/core-components';
import personImageSrc from './chelsea.png';

const PersonImage = ({ ...props }) => (
  <img
    src={personImageSrc}
    alt={'help person'}
    {...props}
  />
);

export const HelpIcon = ({
  onClick,
  showOnlineDot = true,
}: { onClick?: () => void, showOnlineDot?: boolean }) => (
  <HelpIconContainer
    role={onClick
      ? 'button'
      : ''}
    aria-label={'help chat icon'}
    clickable={onClick !== undefined}
    onClick={onClick}
  >
    <StyledPersonImage />
    <OptionalElement show={showOnlineDot && isBusinessHours(DateTime.now())}>
      <OnlineDot aria-label={'online dot'} />
    </OptionalElement>
  </HelpIconContainer>
);

const HelpIconContainer = styled.div<{ clickable: boolean }>`
  position: relative;
  display: flex;
  cursor: ${(props) => (props.clickable
    ? 'pointer'
    : 'auto')};
`;

const StyledPersonImage = styled(PersonImage)`
  height: 3.375rem;
  width: 3.375rem;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: -2px 4px 20px 0px rgba(0, 0, 0, 0.08), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
`;

const OnlineDot = styled.div`
  border-radius: 50%;
  background-color: var(--success);
  width: 0.6875rem;
  height: 0.6875rem;
  position: absolute;
  top: 3px;
  right: 3px;
`;
