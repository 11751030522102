import { ComponentProps } from 'react';
import { Heading, IconCircle, responsiveStyleFromTheme } from '@lower-financial/core-components';
import styled from 'styled-components';

interface ListProps {
  iconItems: IconItem[];
  headerType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

interface IconItem {
  icon: React.ReactNode;
  descriptionText: string;
  headerText: string;
}
export const IconList = ({ iconItems, headerType }: ListProps) => (
  <IconListContainer>
    {iconItems.map((iconItem, index) => (
      <Perk key={index}>
        <PerkIconContainer>
          <IconCircle
            background={'var(--primary-light)'}
            icon={iconItem.icon}
          />
        </PerkIconContainer>
        <PerkText>
          <Heading
            element={headerType}
            size={'xs'}
          >
            {iconItem.headerText}
          </Heading>
          <DescriptionText>
            {iconItem.descriptionText}
          </DescriptionText>
        </PerkText>
      </Perk>
    ))}

  </IconListContainer>
);

const IconListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  padding-bottom: var(--spacing-10);
`;

const Perk = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-8);
`;

const PerkIconContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
`;

const PerkText = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
`;

const DescriptionText = styled.div`
  color: var(--ash);

  ${responsiveStyleFromTheme({
    desktop: {
      font: 'var(--font-icon-list-description)',
    },
    mobile: {
      font: 'var(--font-icon-list-description-mobile)',
    },
  })}
`;

export type IconListProps = ComponentProps<typeof IconList>;
