export const MoonStarIcon = ({ color = '#FFB82F', ...props }) => (
  <svg
    width={'20'}
    height={'19'}
    viewBox={'0 0 20 19'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>moon and star icon</title>
    <g clipPath={'url(#clip0_15_7962)'}>
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={'M8.06572 19.0003C3.73056 18.8648 0.246955 15.4006 0.111482 11.1041C-0.0239918 6.78833 3.24673 3.13054 7.56253 2.72412C5.68525 5.24006 5.9562 8.76237 8.18184 10.988C10.4075 13.2136 13.9491 13.4652 16.4844 11.6073C16.078 15.9038 12.4009 19.1358 8.06572 19.0003Z'}
        fill={color}
      />
      <path
        d={'M14.4353 1.24201C14.6869 1.97744 15.0933 2.6161 15.6546 3.17735C16.2158 3.73859 16.8932 4.16437 17.6867 4.49338C16.8545 4.84174 16.1771 5.28686 15.6546 5.79005C15.132 6.31259 14.745 6.95125 14.4547 7.70603C14.1063 6.89319 13.6805 6.21583 13.158 5.67393C12.6355 5.15139 11.9774 4.74497 11.2227 4.45467C12.0936 4.14502 12.7903 3.71924 13.3128 3.1967C13.8354 2.67416 14.2031 2.01614 14.4353 1.22266V1.24201Z'}
        fill={color}
      />
    </g>
    <defs>
      <clipPath id={'clip0_15_7962'}>
        <rect
          width={'18.9276'}
          height={'18.8889'}
          fill={color}
          transform={'translate(0.111328 0.111328)'}
        />
      </clipPath>
    </defs>
  </svg>
);
