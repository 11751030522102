import styled from 'styled-components';
import { Heading, Banner, Paragraph, OptionalElement, Button } from '@lower-financial/core-components';
import { LoadingIcon } from '@lower-financial/core-components/src/components/loading-icon';
import { useAnalytics } from '@lower-financial/analytics';
import { useState } from 'react';
import { TerminalScreenLayout } from '@lightspeed/components/shared/terminal-screen-layout/terminal-screen-layout';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { EmailSentIcon } from '@lightspeed/components/ui/icons/email-sent-icon';
import { resendJumpstartEmail } from '@lightspeed/api/services/backend/lending/resendJumpstartEmail';

export const VerifyEmailSent = () => {
  const fireAnalyticsEvent = useAnalytics('verify_email_sent');
  const { mortgageApplication } = useMortgageApplication();
  const [loading, setLoading] = useState(false);
  const [resendText, setResendText] = useState('Resend verification');
  const [textContent, setTextContent] = useState({
    heading: 'Verification sent! Check your inbox.',
    subtext: 'We just sent you a link to authenticate and verify your Lower loan profile. One click to confirm.',
  });

  const handleDone = () => {
    (async () => {
      if (loading) {
        return;
      }

      setLoading(true);
      setResendText('Sending verification...');
      await resendJumpstartEmail(mortgageApplication.quotingApplicationSubmissionId);

      setTimeout(() => {
        setLoading(false);
        setResendText('Email verification sent!');
        fireAnalyticsEvent('email_resent');

        setTextContent({
          heading: 'Another one. Check your inbox.',
          subtext: 'We just sent you another link to authenticate and verify your Lower loan profile. One click to confirm.',
        });

        // Reset text after 10 seconds
        setTimeout(() => setResendText('Resend verification'), 8000);
      }, 4000);
    })();
  };

  return (
    <TerminalScreenLayout
      heading={textContent.heading}
      subtext={textContent.subtext}
      iconComponent={(
        <EmailIconWrapper>
          <EmailSentIcon />
        </EmailIconWrapper>
      )}
      animations={false}
      fireAnalyticsEvent={fireAnalyticsEvent}
      hideCloseButton
      callToAction={(
        <ResendBanner
          emailAddress={mortgageApplication.borrowerEmail}
          onClickHandler={handleDone}
          isLoading={loading}
          actionText={resendText}
        />
      )}
    />
  );
};

const EmailIconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ResendBanner = ({
  emailAddress, onClickHandler, isLoading, actionText,
}: { emailAddress: string, onClickHandler: () => void, isLoading: boolean, actionText: string }) => (
  <BannerContainer>
    <Banner>
      <BannerHeading size={'xs'}>
        Didn't get an email?
      </BannerHeading>

      <Paragraph variant={'small'}>
        We emailed you at <span data-dd-privacy={'mask'}>{emailAddress}</span>. If you don't receive anything soon, try resending.
      </Paragraph>

      <ResendLink onClick={onClickHandler}>
        <OptionalElement show={isLoading}>
          <LoadingIconWrapper>
            <LoadingIcon
              spinnerHeight={'18px'}
            />
          </LoadingIconWrapper>
        </OptionalElement>
        <Button variant={'link'}>
          {actionText}
        </Button>
      </ResendLink>
    </Banner>
  </BannerContainer>
);

const BannerContainer = styled.div`
  max-width: 25rem;
  margin: auto 0 0 0;
`;

const BannerHeading = styled(Heading)({
  marginBottom: 'var(--spacing-2)',
});

const LoadingIconWrapper = styled.div`
  height: 18px;
  margin-right: var(--spacing-2);
`;

const ResendLink = styled.div`
  cursor: pointer;
  color: var(--primary);
  margin-top: var(--spacing-4);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
