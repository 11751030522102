import { ComponentProps, Fragment, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { CloseXIcon } from '@lower-financial/icons';
import { useModal } from '../../hooks/useModal';

interface ExplainerComponentProps {
  headerElement?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | null;
  label: string;
  disabled?: boolean;
  children?: ReactNode;
}

const Container = styled.div`
  width: 100%;
`;

const Thumbnail = styled.button`
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
  padding: 0;
`;

const Indicator = styled.div<{ open?: boolean }>`
  ${({ open }) => `
    position: relative;
    ${open
    ? 'visibility: visible;'
    : 'visibility: hidden;'}
  `}
`;

const Panel = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
    margin-top: 12px;
    ${open && 'max-height: 100rem;' || ''};
  `}
`;

const PanelContent = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    opacity: 0;
    transform: translateY(-0.5rem);
    transition-timing-function: ease-in-out;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    ${open
    ? `
      opacity: 1;
      transform: translateY(0);
    `
    : 'visibility: hidden;'};
  `}
`;

const ThumbnailLabel = styled.span(() => css`
  font: var(--font-label-bold-1);
  color: var(--primary);
  margin: 0;
  padding: 0;
`);

export const DropdownArrowImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
`;

const CloseIcon = styled(CloseXIcon)`
  height: var(--spacing-2);
  width: var(--spacing-2);
`;

export const Explainer = ({
  headerElement = null,
  label,
  disabled,
  children,
  ...props
}: ExplainerComponentProps) => {
  const [active, , , toggle] = useModal();

  const idPrefix = label.replace(/\s/g, '-');
  const panelId = `${idPrefix}-sect`;
  const buttonId = `${idPrefix}-button`;
  const HeaderElement = headerElement ?? Fragment;

  return (
    <Container>
      <HeaderElement>
        <Thumbnail
          onClick={toggle}
          id={buttonId}
          aria-label={label}
          aria-expanded={active}
          aria-controls={panelId}
          type={'button'}
        >
          <ThumbnailLabel>{label}</ThumbnailLabel>
          <Indicator open={active}>
            <CloseIcon color={'var(--primary)'} />
          </Indicator>
        </Thumbnail>
      </HeaderElement>
      <Panel
        open={active}
        id={panelId}
        role={'region'}
        aria-labelledby={buttonId}
      >
        <PanelContent open={active}>
          {children && children}
        </PanelContent>
      </Panel>
    </Container>
  );
};

export type ExplainerProps = ComponentProps<typeof Explainer>;
