import styled from 'styled-components';
import { ComponentProps } from 'react';
import { CardButton } from '../card-button';

interface CardButtonItem {
  title: ComponentProps<typeof CardButton>['title'];
  subtitle: ComponentProps<typeof CardButton>['subtitle'];
  icon: ComponentProps<typeof CardButton>['icon'];
  onClick: ComponentProps<typeof CardButton>['onClick'];
}

export const CardButtonGroup = ({ items, ...props }: { items: CardButtonItem[] }) => (
  <CardButtonGroupContainer {...props}>
    {items.map((item, index) => (
      <CardButton
        title={item.title}
        subtitle={item.subtitle}
        icon={item.icon}
        onClick={item.onClick}
        key={`card-${index}`}
      />
    ))}
  </CardButtonGroupContainer>
);

const CardButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
