import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { OptionalElement } from '../optional-element';

interface ModalOverlayComponentProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const ModalOverlay = ({
  isOpen = false,
  onClose,
  children,
}: ModalOverlayComponentProps) => (
  <OptionalElement show={isOpen}>
    <ModalOverlayContainer
      onClick={onClose}
      data-testid={'modal-overlay'}
    >
      {children}
    </ModalOverlayContainer>
  </OptionalElement>
);

const ModalOverlayContainer = styled.div({
  alignItems: 'center',
  background: 'var(--overlay)',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 'var(--index-modal)',
});
