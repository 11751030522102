import styled from 'styled-components';
import { ReactNode } from 'react';

export interface BannerProps {
  backgroundColor?: string;
  padding?: string;
  children: ReactNode | ReactNode[]
}

export const Banner = ({
  backgroundColor = 'var(--info-banner-background)',
  padding = 'var(--spacing-4) var(--spacing-6)',
  children,
  ...props
}: BannerProps) => (
  <Container
    $backgroundColor={backgroundColor}
    $padding={padding}
    {...props}
  >
    {children}
  </Container>
);

const Container = styled.div<{ $backgroundColor: string, $padding: string,  }>(({ $backgroundColor, $padding }) => ({
  background: $backgroundColor,
  borderRadius: 10,
  padding: $padding,
}));
