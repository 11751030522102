import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  iframe#react-refresh-overlay,
  iframe#webpack-dev-server-client-overlay {
    display:none;
  }

  html,
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // Disable Chrome Android swipe refresh
    overscroll-behavior: contain;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    background-repeat: no-repeat;
  }

  *:focus {
    outline: none;
  }

  * h1,
  * h2,
  * h3,
  * h4,
  * h5,
  * h6 {
    margin: 0;
  }

  a,
  area,
  button,
  input,
  label,
  select,
  textarea,
  [tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
  }
`;
