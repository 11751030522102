import type { AnalyticsMetadataKeys } from '@lower-financial/analytics';

export class Analytics {
  static event(eventName: string, eventMetadata: Record<AnalyticsMetadataKeys, unknown> | Record<string, never> = {}) {
    window.dataLayer.push({
      event: eventName,
      ...eventMetadata,
    });
  }

  static pageView(pageTitle: string, pageLocation: string, pagePath: string) {
    window.dataLayer.push({
      event: 'page_view',
      page_location: pageLocation,
      page_path: pagePath,
      page_title: pageTitle,
    });
  }
}
