import styled from 'styled-components';

import { ReviewStarIcon } from '@lower-financial/icons/src';

import {
  Heading as _Heading,
  Paragraph,
} from '@lower-financial/core-components';

import { ElementType as HeaderElement } from '@lower-financial/core-components/src/components/heading/heading';

export type TestimonialProps = {
  title: string;
  content: string;
  author: string;
  date: string;
  headingElement?: HeaderElement;
};

export const Testimonial = ({
  title, content, author, date, headingElement = 'h2',
}: TestimonialProps) => (
  <>
    <StyledReviewStarIcon />

    <Heading
      element={headingElement}
      size={'xs'}
    >{title}
    </Heading>

    <TestimonialParagraph>
      {content}
    </TestimonialParagraph>

    <AttributionContainer>
      <Initials>{initials(author)}</Initials>
      <div>
        {author}<br />
        <Timestamp dateTime={date}>{formattedDate(date)}</Timestamp>
      </div>
    </AttributionContainer>
  </>
);

const initials = (author: string) => author.split(' ').map((name) => name[0]).join('');

const formattedDate = (date: string) => {
  const [y, m, d] = date.split('-').map(Number);
  const dateObj = new Date(y, m - 1, d);

  const month = dateObj.toLocaleString('default', {
    month: 'short',
  });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
};

const StyledReviewStarIcon = styled(ReviewStarIcon)`
  margin-bottom: var(--spacing-4);
`;

const Heading = styled(_Heading)`
  margin-bottom: var(--spacing-2);
`;

const TestimonialParagraph = styled(Paragraph)`
  color: var(--ash);
  font: var(--font-testimonial-paragraph);
  margin-bottom: var(--spacing-4);
`;

const AttributionContainer = styled.div`
  align-items: center;
  display: flex;
  font: var(--font-testimonial-attribution);
  gap: var(--spacing-3);
`;

const Initials = styled.div`
  background-color: var(--chalk);
  border-radius: var(--radius-full);
  color: var(--tertiary-1);
  display: inline-block;
  font: var(--font-testimonial-initials);
  height: var(--spacing-8);
  text-align: center;
  width: var(--spacing-8);
`;

const Timestamp = styled.time`
  color: var(--ash);
  font: var(--font-testimonial-timestamp);
`;
