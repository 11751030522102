import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useErrorHandling } from '@lower-financial/core-components';
import { CurrencyRange } from '@lightspeed/api/services/backend/lending/getCurrencyRanges';
import { resilientGetCurrencyRanges } from '@lightspeed/utils/resilient-get-currency-ranges';

export interface CurrencyRangesContextData {
  currencyRanges: CurrencyRange[]|null;
  isLoading: boolean;
}

export const currencyRangesContextDefaultValue = {
  currencyRanges: null,
  isLoading: false,
};
export const CurrencyRangesContext = createContext<CurrencyRangesContextData>(currencyRangesContextDefaultValue);

export function CurrencyRangesContextProvider({
  children,
  overrides,
}: {
  children?: ReactNode | ReactNode[],
  overrides: null | CurrencyRange[]
}) {
  const [currencyRanges, setCurrencyRanges] = useState<CurrencyRange[]|null>(overrides || null);
  const [isLoading, setIsLoading] = useState(true);
  const handleError = useErrorHandling();

  const context = useMemo(() => ({
    currencyRanges,
    isLoading,
  }), [currencyRanges, isLoading]);

  useEffect(() => {
    (async () => {
      if (currencyRanges !== null) return;
      await handleError({
        onRetryableError: () => {
          setIsLoading(false);
        },
        tryFn: async () => {
          const ranges = await resilientGetCurrencyRanges();
          setIsLoading(false);
          setCurrencyRanges(ranges);
        },
      });
    })();
  }, [currencyRanges, handleError]);

  return (
    <CurrencyRangesContext.Provider
      value={context}
    >
      {children}
    </CurrencyRangesContext.Provider>
  );
}

export const useCurrencyRanges = () => useContext(CurrencyRangesContext);
