import { EventObject } from 'xstate';

export enum ROUTING_EVENTS {
  NEXT = 'NEXT',
  DEFER_LINK_ASSETS = 'DEFER_LINK_ASSETS',
}

export interface RoutingEvent extends EventObject {
  type: ROUTING_EVENTS,
}
