import React from 'react';
import styled from 'styled-components';
import { IconCircle } from '@lower-financial/core-components';
import { BoltIcon, CheckIcon } from '@lower-financial/icons';
import { responsiveStyleFromTheme } from '@lower-financial/core-components/src/styles/utils/theme-utils';
import { TrustPilotStarIcon } from '@lightspeed/components/ui/icons/trust-pilot-star-icon';

export const PurchaseRefinanceSidebarBlurb = () => (
  <div>
    <BlurbSection>
      <Blurb>
        <BlurbIconContainer>
          <IconCircle
            background={'var(--tertiary-1-light)'}
            icon={<CheckIcon color={'var(--tertiary-1)'} />}
          />
        </BlurbIconContainer>
        <BlurbText>
          All-Digital Experience
        </BlurbText>
      </Blurb>
      <Blurb>
        <BlurbIconContainer>
          <IconCircle
            background={'var(--tertiary-5-light)'}
            icon={<BoltIcon color={'var(--tertiary-5)'} />}
          />
        </BlurbIconContainer>
        <BlurbText>
          Quick Close
        </BlurbText>
      </Blurb>
      <Blurb>
        <BlurbIconContainer>
          <IconCircle
            background={'var(--tertiary-3-light)'}
            icon={<TrustPilotStarIcon color={'var(--tertiary-3)'} />}
          />
        </BlurbIconContainer>
        <BlurbText>
          4.9 Trustpilot Rating
        </BlurbText>
      </Blurb>
    </BlurbSection>
  </div>
);

const BlurbIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BlurbSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 36px;
`;

const BlurbText = styled.h5`
  ${(props) => responsiveStyleFromTheme(props.theme.Headers.xs)};
  margin-left: 20px;
`;

const Blurb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
