import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { PARTNER_QUERY_PARAMS } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-helpers';

export const PartnerQueryStringCleaner = () => {
  const { partnerConfiguration: { isPartnerExperience } } = usePartnerConfiguration();
  const [searchParams, setSearchParams] = useSearchParams();
  const hasCleanedQueryString = useRef(false);

  useEffect(() => {
    if (isPartnerExperience && !hasCleanedQueryString.current) {
      hasCleanedQueryString.current = true;

      PARTNER_QUERY_PARAMS.forEach((param) => {
        searchParams.delete(param);
      });

      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, [isPartnerExperience, searchParams, setSearchParams]);

  return null;
};
