import { ErrorResponse } from '@lower-financial/lending-web-api/generated';
import { EmptyResponse, fetch, TypedResponse } from '@lower-financial/toolbox';
import { ENV } from '@lightspeed/environment';

export function resendJumpstartEmail(id: string) {
  return fetch<ResendJumpstartEmailResponse>(
    `${ENV.BACKEND_API_HOST}/api/lending/quoting_application_submissions/${id}/resend_jumpstart_email`,
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
}

type NotFoundErrorResponseInterface = TypedResponse<ErrorResponse, 404>;
type SuccessfulResendJumpstartEmailResponse = EmptyResponse<200>;

export type ResendJumpstartEmailResponse =
  | SuccessfulResendJumpstartEmailResponse
  | NotFoundErrorResponseInterface;
