import { PARTNER_NAME } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-helpers';
import '@lower-financial/core-components/src/assets/css/font-faces.css';

export const importDefaultStylesheet = () => {
  import('@lower-financial/core-components/src/assets/css/lower-theme.css');
};

export const importStylesheets = (partner: string) => {
  // this switch case is necessary because webpack can't
  // determine import paths at compile time unless they're static
  switch (partner) {
  case PARTNER_NAME.Opendoor:
    import('@lightspeed/assets/partners/opendoor.css');
    break;
  case PARTNER_NAME.Gravy:
    import('@lightspeed/assets/partners/gravy.css');
    break;
  case PARTNER_NAME.Prevu:
    import('@lightspeed/assets/partners/prevu.css');
    break;
  case PARTNER_NAME.Mashvisor:
    import('@lightspeed/assets/partners/mashvisor.css');
    break;
  case PARTNER_NAME.LowerTest:
    import('@lightspeed/assets/partners/lower-test.css');
    break;
  default:
  }
};

interface LogoMapping {
  logo: string;
  mobileLogo: string;
  loadingLogo: string;
}

const lowerLogoMapping: LogoMapping = {
  loadingLogo: '/static/assets/partners/lower/logo.svg',
  logo: '/static/assets/partners/lower/logo.svg',
  mobileLogo: '/static/assets/partners/lower/logo.svg',
};

const opendoorLogoMapping: LogoMapping = {
  loadingLogo: '/static/assets/partners/opendoor/loadingLogo.svg',
  logo: '/static/assets/partners/opendoor/logo.svg',
  mobileLogo: '/static/assets/partners/opendoor/logo.svg',
};

const gravyLogoMapping: LogoMapping = {
  loadingLogo: '/static/assets/partners/gravy/logo.svg',
  logo: '/static/assets/partners/gravy/logo.svg',
  mobileLogo: '/static/assets/partners/gravy/logo.svg',
};

const prevuLogoMapping: LogoMapping = {
  loadingLogo: '/static/assets/partners/prevu/loadingLogo.svg',
  logo: '/static/assets/partners/prevu/logo.svg',
  mobileLogo: '/static/assets/partners/prevu/logo.svg',
};

const mashvisorLogoMapping: LogoMapping = {
  loadingLogo: '/static/assets/partners/mashvisor/loadingLogo.svg',
  logo: '/static/assets/partners/mashvisor/logo.svg',
  mobileLogo: '/static/assets/partners/mashvisor/logo.svg',
};

const lowerTestLogoMapping: LogoMapping = {
  loadingLogo: '/static/assets/partners/lower-test/logo.svg',
  logo: '/static/assets/partners/lower-test/logo.svg',
  mobileLogo: '/static/assets/partners/lower-test/logo.svg',
};

export const getPartnerLogos: (partner: string) => LogoMapping = (partner) => {
  switch (partner) {
  case PARTNER_NAME.Opendoor:
    return opendoorLogoMapping;
  case PARTNER_NAME.Gravy:
    return gravyLogoMapping;
  case PARTNER_NAME.Prevu:
    return prevuLogoMapping;
  case PARTNER_NAME.LowerTest:
    return lowerTestLogoMapping;
  case PARTNER_NAME.Mashvisor:
    return mashvisorLogoMapping;
  default:
    return lowerLogoMapping;
  }
};
