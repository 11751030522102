import React, { useState } from 'react';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import {
  AddressAutocomplete,
  AddressAutocompleteOnChange,
  AddressAutocompleteWrapper,
  doesContainUndefinedFeatures,
  Form,
  formatAddressResult,
  OptionalElement,
  ValidationError,
} from '@lower-financial/core-components';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { FullAddressForm, addressFieldNames } from '@lightspeed/components/ui/pages/property-purchase/full-address-form';
import { ENV } from '@lightspeed/environment';

const addressLabel = 'Property Address';

interface AddressFormProps {
  fireAnalyticsEvent: (event: string) => void;
  errors: Map<string, string[]>;
  isLoading: boolean,
}

const anyAddressFieldsPresent = (
  mortgageApplication: MortgageApplicationStore,
) =>
  Object.values(addressFieldNames('property')).some((field) => !!mortgageApplication[field]);

const PropertyAddressAutocompleteWrapper = ({ children, id }: { children: React.ReactNode; id: string; }) => (
  <AddressAutocompleteWrapper
    id={id}
    label={addressLabel}
  >
    {children}
  </AddressAutocompleteWrapper>
);

export function PropertyAddressForm({
  errors,
  fireAnalyticsEvent,
  isLoading,
}: AddressFormProps) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const initialShowFallback = mortgageApplication.propertyAutocompleteValue === '' && anyAddressFieldsPresent(mortgageApplication);
  const [showFallback, setShowFallback] = useState(initialShowFallback);

  const handleAutocompleteOnChange: AddressAutocompleteOnChange = (argument) => {
    if (argument && doesContainUndefinedFeatures(argument)) {
      setShowFallback((show) => !show);
    }

    const addressResult = formatAddressResult(argument);
    updateMortgageApplication({
      propertyAutocompleteValue: addressResult.addressAutocompleteValue,
      propertyCity: addressResult.city,
      propertyCounty: addressResult.county,
      propertyState: addressResult.state,
      propertyStreetAddress: addressResult.streetAddress,
      propertyZipCode: addressResult.zipCode,
    });
  };

  return (
    <Form>
      <AddressAutocomplete
        fireAnalyticsEvent={fireAnalyticsEvent}
        id={'autocomplete-property'}
        setShowFallback={setShowFallback}
        showFallback={showFallback}
        initialSearchText={mortgageApplication.propertyAutocompleteValue}
        onChange={handleAutocompleteOnChange}
        WrappingElement={PropertyAddressAutocompleteWrapper}
        placeholder={'123 Apple Creek Ln. Columbus, OH 43004'}
        disabled={isLoading}
        apiKey={ENV.GOOGLE_MAPS_API_KEY}
        appEnv={ENV.ENVIRONMENT}
        fallbackUi={(
          <FullAddressForm
            addressLabel={addressLabel}
            addressType={'property'}
            mortgageApplication={mortgageApplication}
            updateMortgageApplication={updateMortgageApplication}
            errors={errors || new Map()}
            disabled={isLoading}
            displayRequiredSymbols={false}
          />
        )}
      />
      <OptionalElement show={!!mortgageApplication.propertyAutocompleteValue}>
        <ValidationError
          path={'propertyState'}
          errors={errors}
        />
      </OptionalElement>
    </Form>
  );
}
