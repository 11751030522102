import { MutableRefObject, RefObject } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export const isKeyof = <O extends object>(key: string | number | symbol, obj: O): key is keyof O => key in obj;

export const refIsUndefined = <T>(ref: MutableRefObject<T | null>): ref is RefObject<T> => !ref.current;

// eslint-disable-next-line @typescript-eslint/ban-types
export const allPropertiesExist = <T extends object>(obj: T): obj is Required<T> =>
  !Object.keys(obj)
    .some((key) => obj[key] === undefined);

export const isStringIndexable = (obj: unknown): obj is Record<string, unknown> => typeof obj === 'object' && obj !== null;

export const isRecordOfString = (value: unknown): value is Record<string, string> => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  return Object.keys(value).every((key) =>
    typeof key === 'string'
    && isStringIndexable(value)
    && typeof value[key] === 'string');
};
