import { responsiveStyleFromTheme } from '@lower-financial/core-components/src';
import { ShieldIcon, StarIcon } from '@lower-financial/icons/src';
import styled from 'styled-components';

export const ImagePanel = ({
  reviewText, reviewAuthor, backgroundImagePath,
}: {
  reviewText: string,
  reviewAuthor: string,
  backgroundImagePath: string,
}) => (
  <ImageContainer $backgroundImagePath={backgroundImagePath}>
    <ImageOverlayContainer>
      <ImageParagraph>
        {reviewText}
      </ImageParagraph>
      <ReviewTextContainer>
        <ReviewAuthorContainer>
          <div>{reviewAuthor}</div>
          <ReviewSubText><StyledShieldIcon color={'var(--white-60)'} /> Verified Zillow Review</ReviewSubText>
        </ReviewAuthorContainer>
        <ReviewsDataContainer>
          <ReviewStarsContainer>5.0<StyledStarIcon color={'var(--white)'} /></ReviewStarsContainer>
          <ReviewSubText>30,000+ Reviews</ReviewSubText>
        </ReviewsDataContainer>
      </ReviewTextContainer>
    </ImageOverlayContainer>
  </ImageContainer>
);

const ImageContainer = styled.div<{ $backgroundImagePath: string }>`
  flex: 1;
  background-position-x: 50%;
  background-position-y: 50%;
  
  background-image:url("${(props) => props.$backgroundImagePath}");
  background-size: cover;
`;

const ImageOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--spacing-6);
  font: var(--font-heading-4);
  color: var(--white);
  min-height: 400px;

  ${responsiveStyleFromTheme({
    desktop: {
      font: 'var(--font-heading-4)',
      minHeight: '100%',
      padding: '0 80px 80px 68px',
    },
    mobile: {
      font: 'var(--font-heading-5)',
      padding: '0 32px 24px 32px',
    },
  })}
`;

const ImageParagraph = styled.p`
  margin: 0;
`;

const ReviewTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ReviewAuthorContainer = styled.div`
  border-right: 0.2px solid var(--white-35);

  ${responsiveStyleFromTheme({
    desktop: {
      paddingRight: 'var(--spacing-10)',
    },
    mobile: {
      paddingRight: 'var(--spacing-5)',
    },
  })}
`;

const ReviewsDataContainer = styled.div`
  ${responsiveStyleFromTheme({
    desktop: {
      paddingLeft: 'var(--spacing-10)',
    },
    mobile: {
      paddingLeft: 'var(--spacing-5)',
    },
  })}
`;

const ReviewSubText = styled.div`
  color: var(--white-60);
  display: flex;
  align-items: center;

  ${responsiveStyleFromTheme({
    desktop: {
      font: 'var(--font-label-3)',
    },
    mobile: {
      font: 'var(--font-label-1)',
    },
  })}
`;

const StyledShieldIcon = styled(ShieldIcon)`
  position: relative;

  ${responsiveStyleFromTheme({
    desktop: {
      height: '0.875rem',
    },
    mobile: {
      height: '0.75rem',
    },
  })}
`;

const ReviewStarsContainer = styled.div`
  display: flex;
`;

const StyledStarIcon = styled(StarIcon)`
  align-self: flex-start;
  margin-top: var(--spacing-1);
`;
