import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';

export const calculateAssetsTotal = (mortgageApplication: MortgageApplicationStore) => {
  // convert strings to num and default to zero if empty
  const borrowerAmountInSavingsNum = mortgageApplication.borrowerAmountInSavings
    ? parseInt(mortgageApplication.borrowerAmountInSavings, 10)
    : 0;
  const borrowerAmountInMutualFundsNum = mortgageApplication.borrowerAmountInMutualFunds
    ? parseInt(mortgageApplication.borrowerAmountInMutualFunds, 10)
    : 0;
  const coBorrowerAmountInSavingsNum = mortgageApplication.coBorrowerAmountInSavings
    ? parseInt(mortgageApplication.coBorrowerAmountInSavings, 10)
    : 0;
  const coBorrowerAmountInMutualFundsNum = mortgageApplication.coBorrowerAmountInMutualFunds
    ? parseInt(mortgageApplication.coBorrowerAmountInMutualFunds, 10)
    : 0;
  return coBorrowerAmountInSavingsNum
    + coBorrowerAmountInMutualFundsNum
    + borrowerAmountInSavingsNum
    + borrowerAmountInMutualFundsNum;
};
