import { useFeatureFlagVariantKey as _useFeatureFlagVariantKey } from 'posthog-js/react';
import { useMemo } from 'react';
import {
  FeatureFlagDefaultValues,
  FeatureFlagKeys,
  FeatureFlagVariant,
  isValidFlagValue,
} from '@lower-financial/feature-flags/src/feature-flags';

export function useFeatureFlagVariantKey<TKey extends FeatureFlagKeys>(flagName: TKey): FeatureFlagVariant<TKey> {
  const flagValue = _useFeatureFlagVariantKey(flagName);

  return useMemo(() => (flagValue !== undefined && isValidFlagValue(flagName, flagValue)
    ? flagValue
    : FeatureFlagDefaultValues[flagName]), [flagName, flagValue]);
}
