import { css, CSSObject } from 'styled-components';
import { ArrayProperties } from '../../types/PropertiesOfType';
import { theme, ThemeType } from '../primary/theme';

export interface ThemedProps {
  theme: ThemeType;
}

export interface ResponsiveComponentStyles {
  mobile?: CSSObject;
  tablet?: CSSObject;
  desktop?: CSSObject;
}

export const responsiveStyleFromTheme = ({ mobile = {}, desktop = {} }: ResponsiveComponentStyles) => css`
  ${ mobile };

  ${ theme.media.desktop } {
    ${ desktop };
  }
`;

/**
 * Takes in an object with multiple variation names and returns
 * css styling for each variation.
 *
 * Class name pattern should follow the pattern --${variation}
 */
export const withVariations = (obj: CSSObject) =>
  obj && Object.keys(obj).map(
    (key) => css`
      &.--${ key } {
        ${ obj[key] }
      }
    `,
  );

const getPropFromThemeArray = (propName: ArrayProperties<ThemeType>) => (props: ThemedProps) => props.theme[propName];

const createFindPropIndexFn = (number: number, prop: ArrayProperties<ThemeType>) => css`
  ${ (props) => `${getPropFromThemeArray(prop)(props)[number]}px` };
`;

export const spacing = (number: number) => createFindPropIndexFn(number, 'space');

