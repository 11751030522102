import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models';
import { useEffect } from 'react';
import { useAnalytics } from '@lower-financial/analytics/src/useAnalytics';
import { DidYouKnow, HighlightedText } from '@lightspeed/components/shared/banners/did-you-know';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { CardToggleLayout } from '@lightspeed/components/shared/card-toggle-screen-layout/card-toggle-layout';
import { TestimonialSidebar } from '@lightspeed/components/shared/sidebars/testimonial-sidebar';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';

const propertyTypes = (isPurchase: boolean) => [
  {
    icon: '🏡',
    label: isPurchase
      ? 'This is where I will live.'
      : 'This is where I live.',
    value: 'Primary Residence',
  },
  {
    icon: '🌴',
    label: 'It’s a second home.',
    value: 'Secondary Residence',
  },
  {
    icon: '💵',
    label: 'It’s an investment property.',
    value: 'Investment',
  },
] as const;

const PropertyTypeDidYouKnow = () => (
  <DidYouKnow>
    Specific loans are built for situations like rental homes,
    so <HighlightedText>how you use the home</HighlightedText> is important.
  </DidYouKnow>
);

const PropertyTypePurchaseTestimonial = () => (
  <Testimonial
    title={'Had a great experience with Lower!'}
    content={'Lower was able to get me approved and in my new home as a first time homebuyer with hardly any issues. My loan advisor answered all my questions, and kept me informed throughout the process. Highly recommended!'}
    author={'Thien D'}
  />
);

const PropertyTypeHelocTestimonial = () => (
  <Testimonial
    title={'Great HELOC Experience'}
    content={'The process was easy and painless. Lower was able to give me a great rate + a larger money amount than any other lender! If I ever need a refi, etc. I will be definitely returning to them.'}
    author={'Jordan Reilly'}
  />
);

export const PropertyType = ({ loanPurpose }: { loanPurpose: LoanPurpose }) => {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const pageTitle = `${loanPurpose}_property_type`;

  const { partnerConfiguration: { partnerName } } = usePartnerConfiguration();
  const fireAnalyticsEvent = useAnalytics(pageTitle);

  useEffect(() => {
    updateMortgageApplication('loanPurpose', loanPurpose);
    fireAnalyticsEvent('loan_purpose_updated', {
      loanPurpose,
      partnerName,
    });
  }, [fireAnalyticsEvent, loanPurpose, partnerName, updateMortgageApplication]);

  return (
    <CardToggleLayout
      title={mortgageApplication.loanPurpose === LoanPurpose.Purchase
        ? 'Will you live in the home you’re buying?'
        : 'Do you live in the home we’re talking about?'}
      hideBackButton
      subtitle={<>Home is where your Amazon boxes arrive.&nbsp;📦</>}
      mortgageApplicationField={'propertyResidenceType'}
      fireAnalyticsEvent={fireAnalyticsEvent}
      cardOptions={propertyTypes(mortgageApplication.loanPurpose === LoanPurpose.Purchase)}
      mobileBanner={<PropertyTypeDidYouKnow />}
      desktopSidebar={(
        <TestimonialSidebar
          testimonial={mortgageApplication.loanPurpose === LoanPurpose.Purchase
            ? <PropertyTypePurchaseTestimonial />
            : <PropertyTypeHelocTestimonial />
          }
          banner={<PropertyTypeDidYouKnow />}
        />
      )}
    />
  );
};
