import { IconCircle } from '@lower-financial/core-components';
import { MapPinIcon } from '@lower-financial/icons';
import { useAnalytics } from '@lower-financial/analytics';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { TerminalScreenLayout } from '@lightspeed/components/shared/terminal-screen-layout/terminal-screen-layout';

export function UnsupportedState() {
  const { mortgageApplication } = useMortgageApplication();
  const fireAnalyticsEvent = useAnalytics('unsupported_state');

  return (
    <TerminalScreenLayout
      heading={'It looks like we’re not in your area yet.'}
      subtext={`We’re still getting set up in ${mortgageApplication.propertyState}. We’ll send you an email to let you know when we’re in your neighborhood.`}
      animations={false}
      iconComponent={(
        <IconCircle
          background={'var(--tertiary-1-light)'}
          icon={(
            <MapPinIcon
              color={'var(--tertiary-1)'}
            />
          )}
          circleSize={100}
          altText={'Map Icon'}
        />
      )}
      showWhileYoureWaitingCards
      fireAnalyticsEvent={fireAnalyticsEvent}
    />
  );
}
