import { poll } from '@lower-financial/toolbox';
import { useAnalytics } from '@lower-financial/analytics';
import { JumpstartEligibilityStatus } from '@lower-financial/lending-web-api/generated';
import { getJumpstartEligibility } from '@lightspeed/api/services/backend/lending/getJumpstartEligibility';

const checkEligibility = async (quotingApplicationSubmissionId: string) => {
  const response = await getJumpstartEligibility(quotingApplicationSubmissionId);
  let errorMessage = null;
  let result = null;

  if (response.status === 200) {
    result = await response.json();
  } else {
    const data = await response.json();
    errorMessage = data.message ?? null;
  }

  return {
    decision: result?.status ?? null,
    errorMessage,
    isBorrowerCreditFrozen: result?.isBorrowerCreditFrozen ?? null,
    status: response.status,
  };
};
const checkForPollingComplete = (info: {
  decision: string | null,
  errorMessage: string | null
}) => info.decision !== null && info.decision !== JumpstartEligibilityStatus.InProgress;
export const pollForJumpstartEligibility = async (
  quotingApplicationSubmissionId: string,
  fireAnalyticsEvent: ReturnType<typeof useAnalytics>,
  ms = 2000,
  timeout = 15000,
) => {
  const getEligibility = () => checkEligibility(quotingApplicationSubmissionId);

  const pollResult = await poll(
    getEligibility,
    checkForPollingComplete,
    ms,
    timeout,
    () => fireAnalyticsEvent('polling_for_jumpstart_eligibility_timeout'),
  );

  if (pollResult.status === 200) {
    return {
      decision: pollResult.decision,
      isBorrowerCreditFrozen: pollResult.isBorrowerCreditFrozen,
    };
  }
  if (pollResult.status === 404) {
    throw new Error(`Not found error for submission id ${quotingApplicationSubmissionId}: ${JSON.stringify(pollResult.errorMessage)}`);
  } else {
    throw new Error(`Unexpected response status found while checking for jumpstart eligibility for submission id ${quotingApplicationSubmissionId}.`);
  }
};
