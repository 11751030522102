export const currencyFormatter = (value: number, fractionDigits = 0) => new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: fractionDigits,
  minimumFractionDigits: fractionDigits,
  style: 'currency',
}).format(value);

export const shortHandCurrencyFormatter = (value: number) => new Intl.NumberFormat('en-US', {
  currency: 'USD',
  notation: 'compact',
  style: 'currency',
}).format(value);
