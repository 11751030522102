import styled from 'styled-components';

export const OverlayOnHover = styled.div<{ $disabled?: boolean, $opacity: string, $color: string }>`
  height: 100%;
  width: 100%;
  
  &:before {
    background-color: ${(props) => props.$color};
    opacity: 0;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.1s opacity ease-out;
  }
  
  &:hover:not(:active) {
    &:before {
      opacity: ${(props) => (props.$disabled
    ? 0
    :  props.$opacity)};
    }
  }
`;
