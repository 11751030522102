import styled from 'styled-components';

export enum LoadingIconColor {
  Primary = 'primary',
  White = 'white',
}

export const LoadingIcon = (
  {
    spinnerHeight, color = LoadingIconColor.Primary, ...props
  }: { spinnerHeight: string, color?: LoadingIconColor },
) => (
  <LoadingContainer
    {...props}
    $spinnerHeight={spinnerHeight}
    data-testid={'loading-icon'}
    aria-label={'Loading'}
  >
    <InnerSpinner $color={color} />
  </LoadingContainer>
);

const LoadingContainer = styled.span<{ $spinnerHeight: string }>(({ $spinnerHeight }) => `
  display: inline-block;
  height: ${$spinnerHeight};
  width: ${$spinnerHeight};
  animation: spin 4s linear infinite;
`);

const InnerSpinner = styled.span<{ $color: LoadingIconColor  }>(({ $color }) => `
  margin: auto;
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: var(--radius-full);
  
  ${borderStyle($color)}

  animation: spin 1s cubic-bezier(.5,.1,.3,.9) infinite;
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`);

const borderColorMap = {
  [LoadingIconColor.Primary]: 'border-color: var(--satin); border-top-color: var(--primary);',
  [LoadingIconColor.White]: 'border-color: var(--white-35); border-top-color: var(--white);',
};

const borderStyle = (color: LoadingIconColor) => `
  border: 3px solid;
  ${borderColorMap[color]}
`;
