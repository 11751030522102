export const STATE_OPTIONS = [
  {
    label: 'Alaska',
    value: 'Alaska',
  },
  {
    label: 'Alabama',
    value: 'Alabama',
  },
  {
    label: 'Arkansas',
    value: 'Arkansas',
  },
  {
    label: 'Arizona',
    value: 'Arizona',
  },
  {
    label: 'California',
    value: 'California',
  },
  {
    label: 'Colorado',
    value: 'Colorado',
  },
  {
    label: 'Connecticut',
    value: 'Connecticut',
  },
  {
    label: 'District Of Columbia',
    value: 'District Of Columbia',
  },
  {
    label: 'Delaware',
    value: 'Delaware',
  },
  {
    label: 'Florida',
    value: 'Florida',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Hawaii',
    value: 'Hawaii',
  },
  {
    label: 'Iowa',
    value: 'Iowa',
  },
  {
    label: 'Idaho',
    value: 'Idaho',
  },
  {
    label: 'Illinois',
    value: 'Illinois',
  },
  {
    label: 'Indiana',
    value: 'Indiana',
  },
  {
    label: 'Kansas',
    value: 'Kansas',
  },
  {
    label: 'Kentucky',
    value: 'Kentucky',
  },
  {
    label: 'Louisiana',
    value: 'Louisiana',
  },
  {
    label: 'Massachusetts',
    value: 'Massachusetts',
  },
  {
    label: 'Maryland',
    value: 'Maryland',
  },
  {
    label: 'Maine',
    value: 'Maine',
  },
  {
    label: 'Michigan',
    value: 'Michigan',
  },
  {
    label: 'Minnesota',
    value: 'Minnesota',
  },
  {
    label: 'Missouri',
    value: 'Missouri',
  },
  {
    label: 'Mississippi',
    value: 'Mississippi',
  },
  {
    label: 'Montana',
    value: 'Montana',
  },
  {
    label: 'North Carolina',
    value: 'North Carolina',
  },
  {
    label: 'North Dakota',
    value: 'North Dakota',
  },
  {
    label: 'Nebraska',
    value: 'Nebraska',
  },
  {
    label: 'New Hampshire',
    value: 'New Hampshire',
  },
  {
    label: 'New Jersey',
    value: 'New Jersey',
  },
  {
    label: 'New Mexico',
    value: 'New Mexico',
  },
  {
    label: 'Nevada',
    value: 'Nevada',
  },
  {
    label: 'New York',
    value: 'New York',

  },
  {
    label: 'Ohio',
    value: 'Ohio',
  },
  {
    label: 'Oklahoma',
    value: 'Oklahoma',
  },
  {
    label: 'Oregon',
    value: 'Oregon',
  },
  {
    label: 'Pennsylvania',
    value: 'Pennsylvania',
  },
  {
    label: 'Rhode Island',
    value: 'Rhode Island',

  },
  {
    label: 'South Carolina',
    value: 'South Carolina',
  },
  {
    label: 'South Dakota',
    value: 'South Dakota',
  },
  {
    label: 'Tennessee',
    value: 'Tennessee',
  },
  {
    label: 'Texas',
    value: 'Texas',
  },
  {
    label: 'Utah',
    value: 'Utah',
  },
  {
    label: 'Virginia',
    value: 'Virginia',
  },
  {
    label: 'Vermont',
    value: 'Vermont',
  },
  {
    label: 'Washington',
    value: 'Washington',
  },
  {
    label: 'Wisconsin',
    value: 'Wisconsin',
  },
  {
    label: 'West Virginia',
    value: 'West Virginia',
  },
  {
    label: 'Wyoming',
    value: 'Wyoming',
  },
];

export enum Timezone {
  AKST = 'AKST',
  CST = 'CST',
  EST = 'EST',
  HST = 'HST',
  MST = 'MST',
  PST = 'PST',
}

export const STATE_TO_TIMEZONE = new Map<string, Timezone>([
  // EST
  ['CT', Timezone.EST],
  ['DE', Timezone.EST],
  ['DC', Timezone.EST],
  ['GA', Timezone.EST],
  ['ME', Timezone.EST],
  ['MA', Timezone.EST],
  ['MD', Timezone.EST],
  ['NH', Timezone.EST],
  ['NJ', Timezone.EST],
  ['NY', Timezone.EST],
  ['NC', Timezone.EST],
  ['OH', Timezone.EST],
  ['PA', Timezone.EST],
  ['RI', Timezone.EST],
  ['SC', Timezone.EST],
  ['VT', Timezone.EST],
  ['VA', Timezone.EST],
  ['WV', Timezone.EST],

  // Hawaii
  ['HI', Timezone.HST],

  // Alaska
  ['AK', Timezone.AKST],

  // CST
  ['AL', Timezone.CST],
  ['AR', Timezone.CST],
  ['FL', Timezone.CST],
  ['IL', Timezone.CST],
  ['IA', Timezone.CST],
  ['IN', Timezone.CST],
  ['LA', Timezone.CST],
  ['KY', Timezone.CST],
  ['MI', Timezone.CST],
  ['MN', Timezone.CST],
  ['MO', Timezone.CST],
  ['MS', Timezone.CST],
  ['OK', Timezone.CST],
  ['TN', Timezone.CST],
  ['WI', Timezone.CST],

  // MST
  ['CO', Timezone.MST],
  ['KS', Timezone.MST],
  ['MT', Timezone.MST],
  ['NE', Timezone.MST],
  ['ND', Timezone.MST],
  ['NM', Timezone.MST],
  ['SD', Timezone.MST],
  ['TX', Timezone.MST],
  ['UT', Timezone.MST],
  ['WY', Timezone.MST],

  // PST
  ['AZ', Timezone.PST],
  ['CA', Timezone.PST],
  ['ID', Timezone.PST],
  ['NV', Timezone.PST],
  ['OR', Timezone.PST],
  ['WA', Timezone.PST],
]);

export const STATE_ABBREVIATION_TO_NAME = new Map<string, string>([
  ['AK', 'Alaska'],
  ['AL', 'Alabama'],
  ['AR', 'Arkansas'],
  ['AZ', 'Arizona'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DC', 'District Of Columbia'],
  ['DE', 'Delaware'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['HI', 'Hawaii'],
  ['IA', 'Iowa'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['MA', 'Massachusetts'],
  ['MD', 'Maryland'],
  ['ME', 'Maine'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MO', 'Missouri'],
  ['MS', 'Mississippi'],
  ['MT', 'Montana'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['NE', 'Nebraska'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NV', 'Nevada'],
  ['NY', 'New York'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PA', 'Pennsylvania'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VA', 'Virginia'],
  ['VT', 'Vermont'],
  ['WA', 'Washington'],
  ['WI', 'Wisconsin'],
  ['WV', 'West Virginia'],
  ['WY', 'Wyoming'],
]);

export const STATE_NAME_TO_ABBREVIATION = new Map<string, string>([
  ['Alaska', 'AK'],
  ['Alabama', 'AL'],
  ['Arkansas', 'AR'],
  ['Arizona', 'AZ'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['District Of Columbia', 'DC'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Iowa', 'IA'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Massachusetts', 'MA'],
  ['Maryland', 'MD'],
  ['Maine', 'ME'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Missouri', 'MO'],
  ['Mississippi', 'MS'],
  ['Montana', 'MT'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Nebraska', 'NE'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['Nevada', 'NV'],
  ['New York', 'NY'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Virginia', 'VA'],
  ['Vermont', 'VT'],
  ['Washington', 'WA'],
  ['Wisconsin', 'WI'],
  ['West Virginia', 'WV'],
  ['Wyoming', 'WY'],
]);

export type StateFullNames =
  | 'Alabama'
  | 'Alaska'
  | 'Arkansas'
  | 'Arizona'
  | 'California'
  | 'Colorado'
  | 'Connecticut'
  | 'District Of Columbia'
  | 'Delaware'
  | 'Florida'
  | 'Georgia'
  | 'Hawaii'
  | 'Iowa'
  | 'Idaho'
  | 'Illinois'
  | 'Indiana'
  | 'Kansas'
  | 'Kentucky'
  | 'Louisiana'
  | 'Massachusetts'
  | 'Maryland'
  | 'Maine'
  | 'Michigan'
  | 'Minnesota'
  | 'Missouri'
  | 'Mississippi'
  | 'Montana'
  | 'North Carolina'
  | 'North Dakota'
  | 'Nebraska'
  | 'New Hampshire'
  | 'New Jersey'
  | 'New Mexico'
  | 'Nevada'
  | 'New York'
  | 'Ohio'
  | 'Oklahoma'
  | 'Oregon'
  | 'Pennsylvania'
  | 'Rhode Island'
  | 'South Carolina'
  | 'South Dakota'
  | 'Tennessee'
  | 'Texas'
  | 'Utah'
  | 'Virginia'
  | 'Vermont'
  | 'Washington'
  | 'Wisconsin'
  | 'West Virginia'
  | 'Wyoming';
