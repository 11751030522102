import {
  PrefillData,
  forEachPrefillValue,
  allowedPrefillFields,
  coborrowerPrefillFields,
  PrefillKey,
} from '@lower-financial/mortgage-utils';
import { SchemaLike } from 'yup/lib/types';
import { ValidationError } from 'yup';
import { sendException } from '@lower-financial/toolbox';
import { mortgageApplicationSchema } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';

export type ValidPrefillFields = Partial<PrefillData> & { hasCoBorrower: boolean };
export const filterInvalidPrefillFields = (
  prefillData: Partial<PrefillData>,
  baseSchema = mortgageApplicationSchema,
): ValidPrefillFields | Record<string, never> => {
  const errorFields: Set<string> = new Set();

  const supportedPrefillFields = allowedPrefillFields.map((field) => field.toString());
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const prefillSchema = baseSchema.pick(supportedPrefillFields) as SchemaLike;

  // eslint-disable-next-line rulesdir/no-try-catch
  try {
    prefillSchema.validateSync(
      prefillData,
      {
        abortEarly: false,
        context: {
          // We still return valid fields even if some fields fail validation, so we just always
          // pass in true for hasCoBorrower and hasDifferentCoBorrowerAddress since there isn't
          // a downside to running all of the coborrower validations all of the time.
          hasCoBorrower: true,
          hasDifferentCoBorrowerAddress: true,
        },
      },
    );
  } catch (e) {
    if (e instanceof ValidationError) {
      let invalidPathFound = false;

      e.inner.forEach((err) => {
        const fieldName = err.params?.path;
        if (typeof fieldName !== 'string') {
          invalidPathFound = true;
        } else {
          errorFields.add(fieldName);
        }
      });

      if (invalidPathFound) {
        sendException(new Error('Non-string found for prefill field name'));
        return {};
      }
    } else {
      if (e instanceof Error) {
        sendException(e);
      }

      return {};
    }
  }

  const filteredPrefillData: Partial<PrefillData> = {};
  const coborrowerFieldSet = new Set<PrefillKey>(coborrowerPrefillFields);
  let hasCoBorrower = false;

  forEachPrefillValue(prefillData, ([propertyName, value]) => {
    if (!errorFields.has(propertyName)) {
      filteredPrefillData[propertyName] = value;

      if (coborrowerFieldSet.has(propertyName)) {
        hasCoBorrower = true;
      }
    }
  });

  return {
    ...filteredPrefillData,
    hasCoBorrower,
  };
};
