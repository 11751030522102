import {
  type ContractStatus,
  JumpstartEligibilityStatus,
  LoanPurpose,
} from '@lower-financial/lending-web-api/generated';

export const PropertyResidenceType = {
  Investment: 'Investment',
  PrimaryResidence: 'Primary Residence',
  SecondaryResidence: 'Secondary Residence',
} as const;
export type PropertyResidenceType = typeof PropertyResidenceType[keyof typeof PropertyResidenceType] | '';

export interface MortgageApplicationStore {
  borrowerAddressAutocompleteValue: string;
  borrowerAmountInMutualFunds: string;
  borrowerAmountInSavings: string;
  borrowerCity: string;
  borrowerContractStatus: ContractStatus | '';
  borrowerCounty: string;
  borrowerCreditAuthDateTime: string;
  borrowerDateOfBirth: string;
  borrowerEmail: string;
  borrowerEmployer: string;
  borrowerEmploymentStatus: string;
  borrowerFirstName: string;
  borrowerFirstTimeHomeBuyer: string;
  borrowerLastName: string;
  borrowerOtherIncomePerYear: string;
  borrowerPhoneNumber: string;
  borrowerSmsConsentedAt: string | null;
  borrowerSocialSecurityNumber: string;
  borrowerState: string;
  borrowerStreetAddress: string;
  borrowerTitle: string;
  borrowerYearlyIncomeRangeId: string | null;
  borrowerYearlySalary: string;
  borrowerYearsAtAddress: string;
  borrowerYearsAtCompany: string;
  borrowerZipCode: string;
  coBorrowerAddressAutocompleteValue: string;
  coBorrowerAmountInMutualFunds: string;
  coBorrowerAmountInSavings: string;
  coBorrowerCity: string;
  coBorrowerCounty: string;
  coBorrowerCreditAuthDateTime: string;
  coBorrowerDateOfBirth: string;
  coBorrowerEmail: string;
  coBorrowerEmployer: string;
  coBorrowerEmploymentStatus: string;
  coBorrowerFirstName: string;
  coBorrowerLastName: string;
  coBorrowerOtherIncomePerYear: string;
  coBorrowerPhoneNumber: string;
  coBorrowerSocialSecurityNumber: string;
  coBorrowerState: string;
  coBorrowerStreetAddress: string;
  coBorrowerTitle: string;
  coBorrowerYearlySalary: string;
  coBorrowerYearsAtCompany: string;
  coBorrowerZipCode: string;
  consentSharePreapprovalDenialReasons: boolean | null;
  discountPoints: string;
  hasCoBorrower: boolean;
  hasRealEstateAgent: boolean | null;
  isBorrowerCreditFrozen: boolean | null;
  jumpstartEligibilityDecision: JumpstartEligibilityStatus | null;
  loanPurpose: LoanPurpose | null;
  loanTerm: 'twenty' | 'thirty' | 'fifteen';
  preapprovalEligibilityId: string;
  propertyAutocompleteValue: string;
  propertyCashOut: string;
  propertyCity: string;
  propertyCounty: string;
  propertyDownPayment: string;
  propertyDownPaymentPartiallyGift: string;
  propertyHomeValue: string;
  propertyHomeValueRangeId: string | null;
  propertyMortgageBalance: string;
  propertyPurchasePrice: string;
  propertyPurchasePriceRangeId: string | null;
  propertyResidenceType: PropertyResidenceType;
  propertyState: string;
  propertyStreetAddress: string;
  propertyType: string;
  propertyZipCode: string;
  quoteChosen: boolean;
  quotingApplicationId: string;
  quotingApplicationSubmissionId: string;
}
