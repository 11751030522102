import { DateTime } from 'luxon';

export const isBusinessHours = (now: DateTime) => {
  const monday = 1;
  const friday = 5;

  const rezoned = now.setZone('America/New_York');
  const { weekday, hour } = rezoned;

  const businessStartHour = 9;
  const businessEndHour = weekday === friday
    ? 16
    : 18;

  // Monday-Thursday 9-6pm and Friday 9-4pm EST
  return weekday >= monday && weekday <= friday && hour >= businessStartHour && hour <= businessEndHour;
};
