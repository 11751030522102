import styled from 'styled-components';
import { CheckIcon } from './check-icon';

interface CheckIconCircleProps {
  $circleColor?: string;
  $size?: string;
}

export const CheckIconCircle = styled(CheckIcon).attrs<CheckIconCircleProps>((
  {
    $circleColor = 'var(--success)',
    $size = '1.6875rem',
    ...props
  },
) => ({
  ...props,
  $circleColor,
  $size,
}))`
  background-color: ${(props: CheckIconCircleProps) => props.$circleColor};
  border-radius: var(--radius-full);
  height: ${(props: CheckIconCircleProps) => props.$size};
  width: ${(props: CheckIconCircleProps) => props.$size};
  padding: calc(${(props: CheckIconCircleProps) => props.$size} / 4);
`;
