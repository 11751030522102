import styled from 'styled-components';
import { ProgressBar } from './progress-bar';

export const StepProgressBar = ({
  currentStepNumber = 1, totalSteps = 1, ...props
}) => {
  const stepBars = Array.from({
    length: totalSteps,
  }, (_, index) => {
    const stepNumber = index + 1;
    let progressPercentage = 0;
    if (stepNumber === currentStepNumber) progressPercentage = StepProgressBar.InProgressPercentage;
    else if (stepNumber < currentStepNumber) progressPercentage = 100;
    const key = `step ${stepNumber} progress bar`;
    return (
      <StyledProgressBar
        progress={progressPercentage}
        // only animate the current in progress step and the step that preceded it
        isAnimated={stepNumber === currentStepNumber || stepNumber === (currentStepNumber - 1)}
        // current in progress step should have an animation delay
        animationDelayMs={
          stepNumber === currentStepNumber
            ? 1.25 * ProgressBar.AnimationDurationMs
            : 0
        }
        key={key}
        label={key}
      />
    );
  });
  return (
    <StepProgressBarContainer {...props}>
      {stepBars}
    </StepProgressBarContainer>
  );
};

StepProgressBar.InProgressPercentage = 14;

const StepProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledProgressBar = styled(ProgressBar)`
  background-color: var(--silk);
  border-radius: 1.875rem;
`;
