import accessEquityImageSrc from '@lightspeed/assets/images/access-equity.png';

export const AccessEquityImage = ({ ...props }) => (
  <img
    src={accessEquityImageSrc}
    alt={'Access equity'}
    height={'87px'}
    width={'126px'}
    {...props}
  />
);

