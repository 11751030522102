import { ReactNode, ComponentProps } from 'react';
import styled from 'styled-components';
import { compose, layout, LayoutProps, shadow, ShadowProps, space, SpaceProps } from 'styled-system';
import { Pill } from '..';
import { PillProps } from '../pill/pill';
import { BoxProps } from '../box/box';
import { Heading } from '../heading';
import { HeadingProps } from '../heading/heading';
import { ThemedProps } from '../../styles/utils/theme-utils';

type CardButtonProps = {
  $active?: boolean;
  $variant?: 'variant1' | 'variant2' | 'variant3' | 'variant4';
} & LayoutProps & SpaceProps & ShadowProps;

export interface CardComponentProps extends BoxProps {
  children?: ReactNode;
  className?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  variant?: 'variant1' | 'variant2' | 'variant3' | 'variant4';
  label?: string;
}

export const Card = ({
  active,
  disabled,
  children,
  className,
  onClick,
  variant,
  label,
  ...props
}: CardComponentProps) => (
  <CardButton
    aria-label={label}
    type={'button'}
    p={2}
    $active={active}
    $variant={variant}
    onClick={disabled
      ? undefined
      : onClick}
    disabled={disabled}
    {...props}
  >
    {children && children}
  </CardButton>
);

export type CardProps = ComponentProps<typeof Card>;

const CardButton = styled.button<CardButtonProps>`
  all: unset;
  flex: 1;
  position: relative;
  background-color: var(--background);
  border: 1px solid;
  border-color: var(--silk);
  border-radius: 12px;
  transition:
    transform 400ms ${ (props: ThemedProps) => props.theme.easings.easeOutCubic },
    box-shadow 100ms ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(0.95) translateY(-5px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  ${({
    $active, $variant, theme,
  }: ThemedProps & CardButtonProps) => $active && `
    border-radius: 12px;
    box-shadow: 0 24px 48px var(--box-shadow);
    transition:
      opacity ${theme.transitions.prop },
      transform 400ms ${theme.easings.easeOutCubic },
      box-shadow 100ms ease-in-out;
    border: 1px solid;
    border-color: ${$variant
    ? theme.card.variations[$variant].borderColor
    : 'var(--primary)' };
    &:after {
      opacity: 1;
    }
  `}

  ${ compose(layout, space, shadow) };
`;

Card.Pill = (props: PillProps) => (
  <Pill
    data-test-id={'cardPill'}
    marginBottom={2}
    {...props}
  />
);

Card.Text = (props: HeadingProps) => (
  <Heading
    element={'h5'}
    {...props}
  />
);
