import styled from 'styled-components';
import { useAnalytics } from '@lower-financial/analytics';
import { Heading } from '@lower-financial/core-components';
import { useErrorBoundary } from 'react-error-boundary';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { BasePageTemplate   } from '@lightspeed/components/ui/templates';

export function Error() {
  const { resetBoundary } = useErrorBoundary();
  useAnalytics('v2/error');

  return (
    <Container>
      <Header beforeNavigatingBack={() => resetBoundary()} />
      <PageWrapper>
        <Heading
          style={{
            textAlign: 'center',
          }}
        >
          Something went wrong!
        </Heading>
        <p
          style={{
            textAlign: 'center',
          }}
        >
          Our engineering team has been notified and will take a look.
        </p>
      </PageWrapper>
      <div />{/* keep this here for spacing */}
    </Container>
  );
}

const Container = styled(BasePageTemplate)`
  padding: 0;
  margin: 0 auto;
`;

const PageWrapper = styled.div`
  width: 100%;
`;
