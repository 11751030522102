export const Confetti = ({ ...props }) => (

  <svg
    width={'354'}
    height={'125'}
    viewBox={'0 0 354 125'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>confetti</title>
    <g clipPath={'url(#clip0_387_4345)'}>
      <rect
        x={'200.673'}
        y={'-0.391357'}
        width={'6.45917'}
        height={'10.4962'}
        rx={'2'}
        transform={'rotate(-24.0719 200.673 -0.391357)'}
        fill={'#0064FF'}
      />
      <rect
        x={'254.758'}
        y={'83.4014'}
        width={'6.45917'}
        height={'10.4962'}
        rx={'2'}
        transform={'rotate(33.3216 254.758 83.4014)'}
        fill={'#02CA02'}
      />
      <rect
        x={'125.164'}
        y={'46.0195'}
        width={'6.45917'}
        height={'10.4962'}
        rx={'2'}
        transform={'rotate(163.925 125.164 46.0195)'}
        fill={'#1B1B25'}
      />
      <rect
        x={'31.2771'}
        y={'8.90918'}
        width={'6.45917'}
        height={'10.4962'}
        rx={'2'}
        transform={'rotate(19.6247 31.2771 8.90918)'}
        fill={'#02CA02'}
      />
      <rect
        x={'169.61'}
        y={'18.5867'}
        width={'6.45917'}
        height={'10.4962'}
        rx={'2'}
        transform={'rotate(-110.007 169.61 18.5867)'}
        fill={'#02CA02'}
      />
      <rect
        x={'326.778'}
        y={'52.3794'}
        width={'6.45917'}
        height={'10.4962'}
        rx={'2'}
        transform={'rotate(-137.785 326.778 52.3794)'}
        fill={'#1B1B25'}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={'M59.716 61.7126C60.3188 58.9487 62.6326 56.9991 65.3032 56.7331C66.4023 56.6236 67.4827 55.9746 67.7181 54.8954L68.3573 51.9643C68.5927 50.8851 67.9068 49.803 66.8047 49.7298C60.3111 49.2986 54.3053 53.6707 52.8768 60.2211C52.3817 62.4912 52.4967 64.7581 53.1121 66.8438C53.4247 67.9032 54.6307 68.3341 55.6317 67.8672L58.3505 66.599C59.3515 66.1321 59.7472 64.9355 59.6139 63.839C59.5299 63.1491 59.5589 62.4331 59.716 61.7126Z'}
        fill={'#0064FF'}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={'M288.366 20.3657C288.366 17.5368 286.52 15.1389 283.968 14.31C282.917 13.9688 282 13.1046 282 12V9C282 7.89543 282.901 6.98427 283.993 7.1476C290.429 8.10991 295.366 13.6613 295.366 20.3657C295.366 22.6892 294.77 24.8795 293.725 26.7862C293.193 27.7547 291.923 27.9187 291.045 27.2492L288.659 25.4308C287.78 24.7613 287.648 23.5079 288.012 22.465C288.241 21.8089 288.366 21.1031 288.366 20.3657Z'}
        fill={'black'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_387_4345'}>
        <rect
          width={'354'}
          height={'125'}
          fill={'white'}
        />
      </clipPath>
    </defs>
  </svg>

);
