import styled from 'styled-components';
import { OptionalElement } from '@lower-financial/core-components/src';
import { ContentHeading } from '@lightspeed/components/shared/content-heading/content-heading';
import { MobileButtonLayout } from '@lightspeed/components/shared/button-layout/mobile-button-layout';
import { PageLayoutProps } from '@lightspeed/components/shared/page-layout/page-layout';

export function MobilePageLayout({
  title,
  subtitle,
  children,
  button,
  buttonDisclaimer,
  consentCheckbox,
  banner,
  disclaimer,
}: {
  title: PageLayoutProps['title'],
  subtitle?: PageLayoutProps['subtitle']
  children: PageLayoutProps['children'],
  button?: PageLayoutProps['button'],
  buttonDisclaimer?: PageLayoutProps['buttonDisclaimer'],
  consentCheckbox?: PageLayoutProps['consentCheckbox'],
  banner?: PageLayoutProps['mobileBanner'],
  disclaimer?: PageLayoutProps['disclaimer'],
}) {
  return (
    <>
      <MobileContentContainer>
        <MobileTopContentContainer>
          <HeadingAndContentContainer>
            <div>
              <ContentHeading
                title={title}
                titleSize={'lg'}
                subtitle={subtitle}
              />
            </div>
            {children}
          </HeadingAndContentContainer>
          <OptionalElement show={button !== undefined}>
            <MobileButtonLayout
              button={button}
              disclaimer={buttonDisclaimer}
            />
          </OptionalElement>
        </MobileTopContentContainer>
        <div>
          <OptionalElement show={banner !== undefined}>
            <MobileBannerContainer>
              {banner}
            </MobileBannerContainer>
          </OptionalElement>
          <OptionalElement show={consentCheckbox !== undefined}>
            <MobileConsentContainer>
              {consentCheckbox}
            </MobileConsentContainer>
          </OptionalElement>
        </div>
      </MobileContentContainer>
      <OptionalElement show={disclaimer !== undefined}>
        <MobileDisclaimerContainer>
          {disclaimer}
        </MobileDisclaimerContainer>
      </OptionalElement>
    </>
  );
}

const MobileContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MobileTopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const HeadingAndContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const MobileBannerContainer = styled.div`
  margin-top: var(--spacing-8);
`;

const MobileConsentContainer = styled.div`
  margin-top: 1rem;
`;

const MobileDisclaimerContainer = styled.div`
  padding-top: 2rem;
`;
