import { ErrorResponse, JumpstartEligibility } from '@lower-financial/lending-web-api/generated';
import { fetch, TypedResponse } from '@lower-financial/toolbox';
import { ENV } from '@lightspeed/environment';

export function getJumpstartEligibility(id: string) {
  return fetch<JumpstartEligibilityResponse>(
    `${ENV.BACKEND_API_HOST}/api/lending/quoting_application_submissions/${id}/jumpstart_eligibility`,
    {
      credentials: 'include',
      method: 'GET',
    },
  );
}

export type JumpstartEligibilityResponse =
  | TypedResponse<JumpstartEligibility, 200>
  | TypedResponse<ErrorResponse, 404>;
