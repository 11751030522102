import styled from 'styled-components';
import { responsiveStyleFromTheme } from '@lower-financial/core-components/src/styles/utils/theme-utils';

export const BasePageTemplate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--mobile-page-padding);
  max-width: 1200px;
  margin: 0 auto;
  flex-grow: 1;

  ${responsiveStyleFromTheme({
    desktop: {
      flexWrap: 'wrap',
      marginTop: '1.5rem',
      padding: 'var(--desktop-page-padding)',
    },
  })}
`;
