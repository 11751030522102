import styled from 'styled-components';
import LoadingDots from '../../lottie-animations/loading-dots.json';
import { LottieSource } from '..';

export function LoadingOverlay() {
  return (
    <OverlayModal
      data-testid={'loading-modal'}
      role={'dialog'}
      aria-label={'Loading modal'}
    >
      <LottieSource
        ariaLabel={'Loading animation'}
        lottieJSONFile={LoadingDots}
        style={{
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          width: '10vmax',
        }}
      />
    </OverlayModal>
  );
}

const OverlayModal = styled.div`
  position: fixed;
  z-index: var(--index-modal);
  background: var(--overlay);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
