import styled from 'styled-components';
import { CheckBox, Paragraph, useErrorHandling, useValidation } from '@lower-financial/core-components';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { objectContainsFalsyPropsForKeys } from '@lower-financial/toolbox';
import { SecureLock } from '@lower-financial/icons';
import { ENV } from '@lightspeed/environment';
import { IdentityForm } from '@lightspeed/components/ui/pages/credit-check/identity-form';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { NextStepButton } from '@lightspeed/components/shared/buttons/next-step-button';
import { PageLayout } from '@lightspeed/components/shared/page-layout/page-layout';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { useMortgageAnalytics } from '@lightspeed/hooks/useMortgageAnalytics/use-mortgage-analytics';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { useSubmitQuotingApplication } from '@lightspeed/hooks/useSubmitQuotingApplication/useSubmitQuotingApplication';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';

const buttonDisclaimer = (
  <>
    <Paragraph variant={'smallLight'}>I agree to a soft credit check.</Paragraph>
    <Paragraph variant={'smallLight'}>(No hard credit check until you’re ready.)</Paragraph>
  </>
);

const CreditCheckPurchaseTestimonial = () => (
  <Testimonial
    title={'Guess who’s a new homeowner! We are!!!!'}
    content={'What made my experience great was that my loan advisor was always reliable and there when we needed him most! First time home buying can be stressful, but he was so very helpful, and I highly recommend him and the Lower team for your next or newest home purchase! Thank you!'}
    author={'Turquoise Dixon'}
  />
);

const CreditCheckHelocTestimonial = () => (
  <Testimonial
    title={'Solid HELOC Experience'}
    content={'Overall I had a great experience with Lower closing my HELOC. Our advisor was a great resource and very helpful throughout the entire process.'}
    author={'Conor McFarland'}
  />
);

export function CreditCheck() {
  const borrowerKeys: MortgageApplicationStoreKeys[] = [
    'borrowerSocialSecurityNumber',
    'borrowerDateOfBirth',
  ];

  const { goToNextRoute } = useNextRoute();
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const {
    partnerConfiguration: {
      isPartnerExperience, consentRequired, displayName,
    },
  } = usePartnerConfiguration();
  const { upsertApplication } = useUpsertApplication();
  const handleError = useErrorHandling();

  const fireAnalyticsEvent = useMortgageAnalytics('credit_check');
  const navigate = useNavigate();
  const submitQuotingApplication = useSubmitQuotingApplication();

  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [consented, setConsented] = useState(
    mortgageApplication.consentSharePreapprovalDenialReasons
    ?? (isPartnerExperience && consentRequired),
  );
  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: borrowerKeys,
  });

  const submissionExecutedRef = useRef(false);

  const disableNextButton = isLoading || objectContainsFalsyPropsForKeys(
    mortgageApplication,
    borrowerKeys,
  );

  const submitForm = useCallback(
    () => {
      if (validate(mortgageApplication).success) {
        setIsLoading(true);
        const currentDateTime = (new Date()).toISOString();
        updateMortgageApplication('borrowerCreditAuthDateTime', currentDateTime);

        updateMortgageApplication('consentSharePreapprovalDenialReasons', consented);
        setFormSubmitted(true);
      }
    },
    [consented, mortgageApplication, updateMortgageApplication, validate],
  );

  const denialConsentCheckbox = useMemo(
    () => {
      if (!isPartnerExperience || !consentRequired) { return undefined; }
      return (
        <CheckBox
          id={'consentSharePreapprovalDenialReasons'}
          label={(
            <>
              Send {displayName} updates. I agree to let Lower, LLC (Lower)
              share info to {displayName} on the final result of my application.&nbsp;
              <SeeDetailsLink
                href={`${ENV.PARTNER_DENIAL_CONSENT_DETAILS_URL}?partner=${encodeURIComponent('displayName')}`}
                target={'_blank'}
                onClick={(e) => e.stopPropagation()}
              >
                See the details.
              </SeeDetailsLink>
            </>
          )}
          checked={consented}
          onChange={(newVal) => setConsented(newVal)}
        />
      );
    },
    [consentRequired, consented, displayName, isPartnerExperience],
  );

  useEffect(() => {
    // when upsertApplication creates a new application, it will update the mortgage application
    // context with the ID it receives from the response. This will cause upsertApplication to
    // change since it depends on mortgageApplication, which will cause this useEffect to run a second time
    if (formSubmitted && !submissionExecutedRef.current) {
      submissionExecutedRef.current = true;
      (async () => {
        setIsLoading(true);
        await handleError({
          onRetryableError: () => {
            setIsLoading(false);
          },
          tryFn: async () => {
            const quotingApplicationId = await upsertApplication();
            await submitQuotingApplication(quotingApplicationId);
            fireAnalyticsEvent('next_button_success');
            goToNextRoute();
          },
        });
      })();
    }
  }, [
    fireAnalyticsEvent,
    formSubmitted,
    goToNextRoute,
    handleError,
    navigate,
    submitQuotingApplication,
    upsertApplication,
  ]);

  const submitButton = useMemo(
    () => (
      <NextStepButton
        isLoading={isLoading}
        disabled={disableNextButton}
        onClick={submitForm}
        label={'Submit'}
        ariaLabel={'I agree to a soft credit check and submit'}
      />
    ),
    [disableNextButton, isLoading, submitForm],
  );

  return (
    <Layout>
      <PageLayout
        title={<>Last step. Secure, <NoWrap>no-impact</NoWrap> credit check.</>}
        subtitle={'To start building your loan profile, we need to verify your info. This won’t impact your credit.'}
        button={submitButton}
        buttonDisclaimer={buttonDisclaimer}
        consentCheckbox={denialConsentCheckbox}
        desktopSidebar={mortgageApplication.loanPurpose === LoanPurpose.Purchase
          ? <CreditCheckPurchaseTestimonial />
          : <CreditCheckHelocTestimonial />
        }
      >
        <IdentityForm
          errors={errors}
          disabled={isLoading}
        />
        <FormNoteContainer>
          <div>
            <SecureLock />
          </div>
          <Paragraph variant={'smallLight'}>
            It’s safe with us. Your data is encrypted and secure. We’ll never sell or share your info with anyone.
          </Paragraph>
        </FormNoteContainer>
      </PageLayout>
    </Layout>
  );
}

const SeeDetailsLink = styled.a`
  color: var(--body);
  font-weight: bold;
`;

const FormNoteContainer = styled.div`
  display: flex;
  gap: var(--spacing-2);
  margin: var(--spacing-4) 0;
  align-items: center;
`;

const NoWrap = styled.span`
  text-wrap: nowrap;
`;
