export const DropDownArrowIcon = ({ color = '#001CDB', ...props }) => (
  <svg
    width={'10'}
    height={'7'}
    viewBox={'0 0 10 7'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <path
      d={'M1.9502 1.5L5.4502 5.5C6.81703 3.9379 7.58336 3.0621 8.9502 1.5'}
      stroke={color}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>
);
