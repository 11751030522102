import { ComponentProps, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { TextArrow } from './text-arrow';
import { CardButtonContent } from './card-button-content';

export const CardButton = ({
  title,
  subtitle,
  icon,
  tabIndex = 0,
  onClick,
}: {
  title: ComponentProps<typeof CardButtonContent>['title'],
  subtitle: ComponentProps<typeof CardButtonContent>['subtitle'],
  icon: ComponentProps<typeof CardButtonContent>['icon'],
  tabIndex?: number,
  onClick: () => void;
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Space') {
      onClick();
    }
  };

  return (
    <CardButtonContainer
      aria-label={title}
      role={'button'}
      tabIndex={tabIndex}
      onKeyDown={handleKeyDown}
      onClick={onClick}
    >
      <CardButtonContent
        title={title}
        subtitle={subtitle}
        icon={icon}
      />
      <TextArrow text={'Apply'} />
    </CardButtonContainer>
  );
};

const CardButtonContainer = styled.div`
  cursor: pointer;
  border: 1px solid var(--border);
  font: var(--font-card-toggle);
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--radius-default);
  transition: box-shadow 0.2s ease-out;

  &:focus, &:active, &:hover {
    box-shadow: 0 1.5rem 3rem rgba(23, 23, 47, 0.06);
  }
`;
