import { FormattedGoogleAddress } from './formatted-google-address';

export function getFormattedAddressFromPlaceResult(result: google.maps.places.PlaceResult) {
  const address: FormattedGoogleAddress = {};
  result?.address_components?.forEach((element) => {
    if (element.types.includes('street_number')) {
      address.streetNumber = element.short_name;
    }
    if (element.types.includes('route')) {
      address.streetName = element.long_name;
    }
    if (element.types.includes('locality')) {
      address.city = element.short_name;
    }
    if (element.types.includes('administrative_area_level_1')) {
      address.state = element.long_name;
      address.stateAbbreviation = element.short_name;
    }
    if (element.types.includes('administrative_area_level_2')) {
      address.county = element.short_name.replace(' County', '');
    }
    if (element.types.includes('postal_code')) {
      address.zipcode = element.short_name;
    }
  });
  return address;
}

export function formatFeatureToString(predictions: FormattedGoogleAddress) {
  if (
    predictions.streetNumber
    && predictions.streetName
    && predictions.city
    && predictions.stateAbbreviation
    && predictions.zipcode
  ) {
    return `${predictions.streetNumber} ${predictions.streetName} ${predictions.city}, ${predictions.stateAbbreviation} ${predictions.zipcode}`;
  }
}

export const formatAddressResult = (address: FormattedGoogleAddress | null) => (
  address
    ? {
      addressAutocompleteValue: formatFeatureToString(address) ?? '',
      city: address.city ?? '',
      county: address.county ?? '',
      state: address.state ?? '',
      stateAbbreviation: address.stateAbbreviation ?? '',
      streetAddress: [address.streetNumber, address.streetName].filter((val) => val).join(' '),
      zipCode: address.zipcode ?? '',
    }
    : {
      addressAutocompleteValue: '',
      city: '',
      county: '',
      state: '',
      stateAbbreviation: '',
      streetAddress: '',
      zipCode: '',
    });
