export const ShieldIcon = ({ color = '#6236FF', ...props }) => (
  <svg
    width={'19'}
    height={'23'}
    viewBox={'0 0 19 23'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>Shield</title>
    <path
      d={'M0.740021 5.52878C0.740021 4.83778 0.740021 4.49228 0.911945 4.22085C0.928303 4.19503 0.945722 4.16989 0.964159 4.14551C1.15794 3.88923 1.48144 3.76791 2.12844 3.52529L7.26794 1.59798C8.42196 1.16522 8.99897 0.948841 9.60156 0.948841C10.2041 0.948841 10.7812 1.16522 11.9352 1.59798L17.0747 3.52529C17.7217 3.76791 18.0452 3.88923 18.239 4.14551C18.2574 4.16989 18.2748 4.19503 18.2912 4.22085C18.4631 4.49228 18.4631 4.83778 18.4631 5.52878V7.27368C18.4631 10.869 18.4631 12.6667 17.948 14.2962C17.5505 15.5535 16.9318 16.7298 16.1209 17.7695C15.0699 19.1172 13.5886 20.1357 10.6259 22.1725C10.2441 22.435 10.0532 22.5662 9.84491 22.6131C9.68468 22.6492 9.51843 22.6492 9.35821 22.6131C9.14994 22.5662 8.95905 22.435 8.57726 22.1725C5.61456 20.1357 4.13322 19.1172 3.0822 17.7695C2.2713 16.7298 1.65257 15.5535 1.25514 14.2962C0.740021 12.6667 0.740021 10.869 0.740021 7.27368L0.740021 5.52878Z'}
      fill={color}
    />
  </svg>
);
