import React, { useState } from 'react';
import {
  Button,
  Heading,
  Label,
  LoadingOverlay,
  OptionalElement,
  useErrorHandling,
  useValidation,
} from '@lower-financial/core-components';
import { useAnalytics } from '@lower-financial/analytics';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models';
import { objectContainsFalsyPropsForKeys } from '@lower-financial/toolbox';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import { PurchaseRefinanceSidebarBlurb } from '@lightspeed/components/shared/sidebars/purchase-refinance-sidebar-blurb';
import { RateDisclaimer } from '@lightspeed/components/shared/legal-disclaimers/rate-disclaimer';
import { HelocSidebarBlurb } from '@lightspeed/components/shared/sidebars/heloc-sidebar-blurb';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import { BasicInfoCoborrowerForm } from '@lightspeed/components/ui/pages/basic-info/basic-info-coborrower-form';
import { TodaysRates } from './todays-rates';

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerFirstName',
  'coBorrowerLastName',
  'coBorrowerEmail',
  'coBorrowerPhoneNumber',
];

export function BasicInfoCoborrower() {
  const fireAnalyticsEvent = useAnalytics('coborrower_info');
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const [loading, setLoading] = useState(false);
  const { upsertApplication } = useUpsertApplication();
  const handleError = useErrorHandling();

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: coBorrowerKeys,
    yupValidationContext: {
      hasCoBorrower: mortgageApplication.hasCoBorrower,
    },
  });

  const disableNextButton = objectContainsFalsyPropsForKeys(
    mortgageApplication,
    coBorrowerKeys,
  );

  const onNextClick = () => {
    (async () => {
      if (validate(mortgageApplication).success) {
        setLoading(true);

        await handleError({
          onRetryableError: () => {
            setLoading(false);
          },
          tryFn: async () => {
            await upsertApplication();
            setLoading(false);
            fireAnalyticsEvent('next_success');
            goToNextRoute();
          },
        });
      }
    })();
  };

  return (
    <Layout>
      <OptionalElement show={loading}>
        <LoadingOverlay />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading
                    element={'h1'}
                    marginBottom={'24px'}
                  >
                    The basics.
                  </Heading>
                  <BasicInfoCoborrowerForm errors={errors} />
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <OptionalElement show={mortgageApplication.loanPurpose !== LoanPurpose.Heloc}>
                    <TodaysRates
                      mortgageApplication={mortgageApplication}
                    />
                    <Label
                      marginBottom={'8px'}
                    >
                      Loan Term
                    </Label>
                    <TermSelect
                      value={mortgageApplication.loanTerm}
                      onChange={(v) => {
                        updateMortgageApplication('loanTerm', v);
                      }}
                    />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.loanPurpose !== LoanPurpose.Heloc}>
                    <PurchaseRefinanceSidebarBlurb />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.loanPurpose === LoanPurpose.Heloc}>
                    <HelocSidebarBlurb />
                  </OptionalElement>
                  <Button
                    marginY={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                  <RateDisclaimer />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <div>
                <Heading
                  element={'h1'}
                  marginBottom={'32px'}
                >
                  The basics.
                </Heading>
                <BasicInfoCoborrowerForm
                  errors={errors}
                />
              </div>
              <Button
                marginTop={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </Layout>
  );
}
