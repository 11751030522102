import {
  Form,
  FormRow,
  Label,
  SSNInput,
  DateInput,
  ValidationError,
} from '@lower-financial/core-components';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

interface IdentityFormProps {
  errors: Map<string, string[]>|null;
  disabled: boolean;
}

export const IdentityForm = ({ errors, disabled }: IdentityFormProps) => {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();

  return (
    <Form>
      <FormRow>
        <Label flex={['0 0 100%', 1]}>
          Date of Birth
          <DateInput
            name={'borrower-date-of-birth'}
            value={mortgageApplication.borrowerDateOfBirth}
            onChange={(e) => updateMortgageApplication('borrowerDateOfBirth', e.target.value)}
            disabled={disabled}
          />
          <ValidationError
            errors={errors}
            path={'borrowerDateOfBirth'}
          />
        </Label>
        <Label flex={['0 0 100%', 1]}>
          Social Security Number
          <SSNInput
            name={'ssn'}
            placeholder={'XXX-XX-XXXX'}
            value={mortgageApplication.borrowerSocialSecurityNumber}
            onChange={(e) => updateMortgageApplication('borrowerSocialSecurityNumber', e.target.value)}
            disabled={disabled}
          />
          <ValidationError
            errors={errors}
            path={'borrowerSocialSecurityNumber'}
          />
        </Label>
      </FormRow>
    </Form>
  );
};
