import { createContext, ReactNode, useContext } from 'react';
import { useQuotesState, QuotesState, QuotesStore } from './use-quotes-state';

const QuotesContext = createContext<QuotesState>({
  quotesOptions: {
    passedFilters: false,
    quotes: [],
  },
  updateQuotes: () => {
    throw new Error('updateQuotes cannot be used before it is set');
  },
});

export function QuotesContextProvider({
  children,
  overrides,
}: {
  children?: ReactNode | ReactNode[],
  overrides: Partial<QuotesStore>
}) {
  return (
    <QuotesContext.Provider value={useQuotesState(overrides)}>
      {children}
    </QuotesContext.Provider>
  );
}

export function useQuotes() {
  return useContext(QuotesContext);
}
