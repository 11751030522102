import { Label, TextInput, ValidationError } from '@lower-financial/core-components';

interface ZipcodeInputProps {
  onChange: (newVal: string) => void;
  value: string | number | readonly string[] | undefined;
  displayRequiredSymbol?: boolean;
  name: string;
  errors?: Map<string, string[]> | null;
  errorPath?: string;
  flex?: number|number[];
  disabled?: boolean,
}

export function ZipcodeInput({
  errors,
  errorPath,
  flex = 1,
  name,
  onChange,
  value,
  displayRequiredSymbol = false,
  disabled = false,
}: ZipcodeInputProps) {
  if (errors && !errorPath) {
    throw new Error('You need to pass in errorPath');
  } else if (errorPath && errors === undefined) {
    throw new Error('You need to pass in errors');
  }
  return (
    <Label flex={flex}>
      Zip Code{displayRequiredSymbol
        ? '*'
        : undefined}
      <TextInput
        onChange={(e) => onChange(e.target.value)}
        value={value}
        maxLength={5}
        name={name}
        inputMode={'numeric'}
        placeholder={'43210'}
        disabled={disabled}
      />
      {errors && errorPath && (
        <ValidationError
          path={errorPath}
          errors={errors}
        />
      )}
    </Label>
  );
}
