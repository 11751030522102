import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAnalytics } from '@lower-financial/analytics';
import {
  Button,
  CheckBox,
  Heading,
  IconCircle,
  LoadingOverlay,
  OptionalElement,
  Paragraph,
  useErrorHandling,
  useValidation,
} from '@lower-financial/core-components';
import { ShieldIcon } from '@lower-financial/icons';
import { responsiveStyleFromTheme } from '@lower-financial/core-components/src/styles/utils/theme-utils';
import { objectContainsFalsyPropsForKeys } from '@lower-financial/toolbox';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { useSubmitQuotingApplication } from '@lightspeed/hooks/useSubmitQuotingApplication/useSubmitQuotingApplication';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { ENV } from '@lightspeed/environment';
import { IdentityForm } from './identity-form';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerSocialSecurityNumber',
  'borrowerDateOfBirth',
];

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerSocialSecurityNumber',
  'coBorrowerDateOfBirth',
];

export function VerifyIdentity() {
  const { updateMortgageApplication, mortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const fireAnalyticsEvent = useAnalytics('v2/identity');
  const handleError = useErrorHandling();
  const { partnerConfiguration } = usePartnerConfiguration();
  const {
    isPartnerExperience, consentRequired, displayName,
  } = partnerConfiguration;
  const { hasCoBorrower } = mortgageApplication;
  const { upsertApplication } = useUpsertApplication();
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const navigate = useNavigate();
  const submitQuotingApplication = useSubmitQuotingApplication();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const mortgageApplicationSubmittedRef = useRef(false);
  const [consented, setConsented] = useState(
    mortgageApplication.consentSharePreapprovalDenialReasons
      ?? (isPartnerExperience && consentRequired),
  );

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoBorrower
      ? [...borrowerKeys, ...coBorrowerKeys]
      : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower,
    },
  });

  const disableNextButton = objectContainsFalsyPropsForKeys(
    mortgageApplication,
    hasCoBorrower
      ? [...borrowerKeys, ...coBorrowerKeys]
      : borrowerKeys,
  );

  useEffect(() => {
    if (formSubmitted && !mortgageApplicationSubmittedRef.current) {
      mortgageApplicationSubmittedRef.current = true;

      (async () => {
        await handleError({
          onRetryableError: () => {
            setShowLoadingModal(false);
          },
          tryFn: async () => {
            const quotingApplicationId = await upsertApplication();
            await submitQuotingApplication(quotingApplicationId);
            setShowLoadingModal(false);
            fireAnalyticsEvent('next_button_success');
            goToNextRoute();
          },
        });
      })();
    }
  }, [
    formSubmitted,
    upsertApplication,
    submitQuotingApplication,
    navigate,
    fireAnalyticsEvent,
    goToNextRoute,
    handleError,
  ]);

  const onClick = () => {
    if (validate(mortgageApplication).success) {
      setShowLoadingModal(true);

      const currentDateTime = (new Date()).toISOString();
      updateMortgageApplication('borrowerCreditAuthDateTime', currentDateTime);
      if (hasCoBorrower) {
        updateMortgageApplication('coBorrowerCreditAuthDateTime', currentDateTime);
      }

      updateMortgageApplication('consentSharePreapprovalDenialReasons', consented);
      setFormSubmitted(true);
    }
  };

  const denialConsentCheckbox = useMemo(() => {
    if (!isPartnerExperience || !consentRequired) { return null; }
    return (
      <CheckBox
        id={'consentSharePreapprovalDenialReasons'}
        label={(
          <>
            Send {displayName} updates. I agree to let Lower, LLC (Lower)
            share info to {displayName} on the final result of my application.&nbsp;
            <SeeDetailsLink
              href={`${ENV.PARTNER_DENIAL_CONSENT_DETAILS_URL}?partner=${encodeURIComponent(displayName)}`}
              target={'_blank'}
              onClick={(e) => e.stopPropagation()}
            >
              See the details.
            </SeeDetailsLink>
          </>
        )}
        checked={consented}
        onChange={(newVal) => setConsented(newVal)}
      />
    );
  }, [consentRequired, consented, displayName, isPartnerExperience]);

  return (
    <Layout>
      <OptionalElement show={showLoadingModal}>
        <LoadingOverlay />
      </OptionalElement>

      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <DesktopContentContainer>
                    <div>
                      <Heading
                        element={'h1'}
                      >
                        Verify identity.
                      </Heading>
                      <IdentityForm
                        errors={errors}
                      />
                    </div>
                    {denialConsentCheckbox}
                  </DesktopContentContainer>
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <SidebarContainer>
                    <SidebarIconContainer>
                      <IconCircle
                        background={'var(--primary-light)'}
                        icon={<ShieldIcon color={'var(--primary)'} />}
                      />
                    </SidebarIconContainer>
                    <Heading
                      marginTop={'20px'}
                      marginBottom={'8px'}
                      element={'h2'}
                      size={'sm'}
                    >{hasCoBorrower
                        ? 'Both'
                        : 'I'} agree to a soft credit check.<br />(No hard credit check until you’re ready.)
                    </Heading>
                    <Paragraph variant={'light'}>
                      To talk about rates, we need to verify your info, but it won’t
                      impact your credit. Scout’s honor.&nbsp;👌
                    </Paragraph>
                  </SidebarContainer>
                  <Button
                    aria-label={`${hasCoBorrower
                      ? 'Both'
                      : 'I'} agree to a soft credit check and submit`}
                    disabled={disableNextButton}
                    onClick={onClick}
                  >
                    Submit
                  </Button>
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <div>
                <Heading
                  element={'h1'}
                >
                  Verify identity.
                </Heading>
                <IdentityForm
                  errors={errors}
                  displayExplanation
                />
              </div>
              <div>
                <AgreementContainer>
                  <Paragraph variant={'smallLight'}>
                    {hasCoBorrower
                      ? 'Both'
                      : 'I'} agree to a soft credit check.<br />(No hard credit check until you’re ready.)
                  </Paragraph>
                </AgreementContainer>
                <SubmitButton
                  aria-label={`${hasCoBorrower
                    ? 'Both'
                    : 'I'} agree to a soft credit check and submit`}
                  disabled={disableNextButton}
                  onClick={onClick}
                  type={'button'}
                  marginTop={'var(--spacing-6)'}
                >
                  Submit
                </SubmitButton>
                {denialConsentCheckbox}
              </div>
            </BasePageTemplate>
          </>
        )}
      />
    </Layout>
  );
}

const SidebarIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SidebarContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 24px;
  p {
    text-align: left;
  }

  ${responsiveStyleFromTheme({
    desktop: {
      marginBottom: 32,
    },
  })}
`;

const DesktopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const AgreementContainer = styled.div`
  text-align: center;
  margin-top: var(--spacing-12);
`;

const SeeDetailsLink = styled.a`
  color: var(--body);
  font-weight: bold;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;
