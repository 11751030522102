global.process = global.process || {
  env: {},
};

export const ENV = {
  APPLICATION_INSIGHTS_CONNECTION_STRING: process.env.APPLICATION_INSIGHTS_CONNECTION_STRING || 'fakestring',
  BACKEND_API_HOST: process.env.BACKEND_API_HOST || 'http://localhost:3000',
  BRAZE_API_KEY: process.env.BRAZE_API_KEY || 'fake_api_key',
  BRAZE_BASE_URL: process.env.BRAZE_BASE_URL || 'sdk.iad-07.braze.com',
  CALENDLY_PREFILL_URL: process.env.CALENDLY_PREFILL_URL || 'https://calendly.com/lower-dot-com/',
  DATADOG_APPLICATION_ID: process.env.DATADOG_APPLICATION_ID || 'fake',
  DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN || 'fake',
  DATADOG_ENV: process.env.DATADOG_ENV || 'local',
  DATADOG_SAMPLE_RATE: parseInt(process.env.DATADOG_SAMPLE_RATE || '0', 10),
  ENABLE_FEATURE_FLAG_OVERRIDES: process.env.ENABLE_FEATURE_FLAG_OVERRIDES || 'false',
  ENABLE_SET_FEATURE_FLAG_LISTENER: process.env.ENABLE_SET_FEATURE_FLAG_LISTENER || 'false',
  ENVIRONMENT: process.env.NODE_ENV || 'local',
  FRONTEND_SHA: process.env.FRONTEND_SHA || 'development',
  GOOGLE_ANALYTICS_ID: process.env.GOOGLE_ANALYTICS_ID || 'fakeid',
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyB77q_TBJ48EaKwlizomtwlypyJzDPUdHQ',
  HELOC_MARKETING_URL: process.env.HELOC_MARKETING_URL || 'https://www.lower.com/heloc',
  HOMEBASE_API_HOST: process.env.HOMEBASE_API_HOST || 'http://localhost:3020',
  HOME_MARKETING_URL: 'https://www.lower.com',
  INCLUDE_LOWER_TEST_PARTNER: process.env.INCLUDE_LOWER_TEST_PARTNER || 'false',
  LEGACY_API_HOST: process.env.LEGACY_API_HOST || 'https://staging-platform.lower.com',
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  PARTNER_ALLOWED_REDIRECT_DOMAINS: JSON.parse(process.env.PARTNER_ALLOWED_REDIRECT_DOMAINS || '{}') as Record<string, string[]>,
  PARTNER_DENIAL_CONSENT_DETAILS_URL: process.env.PARTNER_DENIAL_CONSENT_DETAILS_URL || 'https://www.lower.com/details/sharing-application-disposition',
  POSTHOG_API_HOST: process.env.POSTHOG_API_HOST || 'fake',
  POSTHOG_API_KEY: process.env.POSTHOG_API_KEY || 'fake',
  PURCHASE_MARKETING_URL: process.env.PURCHASE_MARKETING_URL || 'https://www.lower.com/finance',
  REFINANCE_MARKETING_URL: process.env.REFINANCE_MARKETING_URL || 'https://www.lower.com/refinance',
};
