import { STATE_NAME_TO_ABBREVIATION, useCalendlyUrl as useCalendlyUrlBase } from '@lower-financial/core-components';
import { isBusinessHours } from '@lower-financial/toolbox/src';
import { DateTime } from 'luxon';
import { ENV } from '@lightspeed/environment';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

export function useCalendlyUrl() {
  const isDuringBusinessHours = isBusinessHours(DateTime.now());
  const { mortgageApplication } = useMortgageApplication();

  return useCalendlyUrlBase(
    ENV.CALENDLY_PREFILL_URL,
    null,
    {
      email: mortgageApplication.borrowerEmail,
      firstName: mortgageApplication.borrowerFirstName,
      lastName: mortgageApplication.borrowerLastName,
      phoneNumber: mortgageApplication.borrowerPhoneNumber,
      state: STATE_NAME_TO_ABBREVIATION.get(mortgageApplication.borrowerState) ?? null,
    },
    isDuringBusinessHours,
    'lightspeed',
  );
}
