import { ReactNode } from 'react';
import styled from 'styled-components';
import { OptionalElement } from '@lower-financial/core-components/src';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { SiteFooter } from '@lightspeed/components/ui/organisms/site-footer';
import {
  PoweredByLowerFooter,
} from '@lightspeed/components/ui/organisms/powered-by-lower-footer/powered-by-lower-footer';

export const Layout = ({ showSiteFooter = false, children }:
{ showSiteFooter?: boolean, children: ReactNode | ReactNode[] }) => {
  const { partnerConfiguration: { isPartnerExperience, hasOwnBranding } } = usePartnerConfiguration();

  return (
    <>
      <LayoutContainer>
        <ContentContainer>
          {children}
        </ContentContainer>
        <OptionalElement show={isPartnerExperience && hasOwnBranding}>
          <PoweredByLowerFooter />
        </OptionalElement>
      </LayoutContainer>
      <OptionalElement show={(!isPartnerExperience || !hasOwnBranding) && showSiteFooter}>
        <SiteFooter />
      </OptionalElement>
    </>
  );
};

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
