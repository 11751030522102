import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import {
  ContractStatus,
} from '@lower-financial/lending-web-api/generated';
import { PropertyResidenceType } from '@lower-financial/mortgage-utils/src/mortgage-application';
import { FeatureFlagDefaultValues, FeatureFlags } from '@lower-financial/feature-flags';
import { PartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-helpers';

export interface RoutingContext {
  borrowerContractStatus: ContractStatus | '';
  featureFlags: FeatureFlags;
  hasCoBorrower: boolean;
  hasRates: boolean;
  isUnsupportedMarket: boolean;
  jumpstartEligibility: MortgageApplicationStore['jumpstartEligibilityDecision'];
  loanPurpose: MortgageApplicationStore['loanPurpose'];
  partnerLoanPurpose: PartnerConfiguration['loanPurpose'];
  propertyResidenceType: PropertyResidenceType;
  quoteChosen: boolean;
  showPartnerWarmUp: boolean;
}

export const DEFAULT_ROUTING_CONTEXT: RoutingContext = {
  borrowerContractStatus: '',
  featureFlags: {
    ...FeatureFlagDefaultValues,
  },
  hasCoBorrower: false,
  hasRates: false,
  isUnsupportedMarket: false,
  jumpstartEligibility: null,
  loanPurpose: null,
  partnerLoanPurpose: null,
  propertyResidenceType: '',
  quoteChosen: false,
  showPartnerWarmUp: false,
};
