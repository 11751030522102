import { getCurrencyRanges } from '@lightspeed/api/services/backend/lending/getCurrencyRanges';

export const resilientGetCurrencyRanges = async () => {
  let retries = 0;
  let apiResponse;

  while (retries < 3) {
    // eslint-disable-next-line rulesdir/no-try-catch
    try {
      apiResponse = await getCurrencyRanges();
      if (apiResponse.status === 200) {
        return await apiResponse.json();
      }
    } catch (_e) {
      // pass
    }

    retries += 1;
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`Unexpected response code when getting currency ranges: ${apiResponse?.status}`);
};
