import { useCallback } from 'react';
import { sendException, RetryableError } from '@lower-financial/toolbox';
import { useErrorBoundary } from 'react-error-boundary';

const retryableErrors: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  instance?: Function,
  message?: RegExp,
}[] = [
  {
    instance: RetryableError,
  },
  {
    message: /Failed to fetch/,
  },
  {
    message: /Loading chunk \d+ failed/,
  },
  {
    message: /NetworkError/,
  },
];

export const useErrorHandling = () => {
  const { showBoundary: vendorShowBoundary } = useErrorBoundary();
  return useCallback(async <T>({
    tryFn,
    showBoundary = true,
    onRetryableError = () => {
      // do nothing by default
    },
  }: {
    tryFn: () => T|Promise<T>,
    onRetryableError?: ((e: Error|string) => void|Promise<void>) | (() => void|Promise<void>),
    showBoundary?: boolean
  }) => {
    // eslint-disable-next-line rulesdir/no-try-catch
    try {
      return await tryFn();
    } catch (e) {
      const containsRetryableMatch = retryableErrors.some(({ instance, message }) =>
        (instance && e instanceof instance)
          || (
            message && (
              // errors can sometimes be thrown as strings
              typeof e === 'string'
                && message.test(e)
                // otherwise it is usually an Error type object with a message property
                // we have to narrow the type from unknown here
                || typeof e === 'object'
                && e !== null
                && 'message' in e
                && typeof e.message === 'string'
                && message.test(e.message)
            )
          ));

      // we only need to manually call sendException here if we don't show the error boundary
      // otherwise the error boundary will always call sendException
      if (containsRetryableMatch) {
        sendException(e);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        await onRetryableError(e as (Error | string));
      } else if (!showBoundary) {
        sendException(e);
      } else {
        vendorShowBoundary(e);
      }
    }
  }, [vendorShowBoundary]);
};
