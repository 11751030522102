import { ComponentProps } from 'react';
import styled from 'styled-components';
import { Card, PillProps } from '@lower-financial/core-components';

interface CardSelectComponentProps {
  onChange: (value: string) => void;
  value: string;
  options: CardSelectOption[];
}

interface CardSelectOption {
  label: string;
  value: string;
  tag:  {
    text: string;
    variant: PillProps['variant'];
  }
}

export const CardSelect = ({
  options,
  value,
  onChange,
}: CardSelectComponentProps) => (
  <CardsContainer>
    {
      options.map(
        (option, index) => (
          <Card
            label={option.label}
            key={index}
            active={option.value === value}
            onClick={() => onChange(option.value)}
            variant={option.tag.variant}
          >
            <Card.Pill variant={option.tag.variant}>
              {option.tag.text}
            </Card.Pill>
            <Card.Text>
              {option.label}
            </Card.Text>
          </Card>
        ),
      )
    }
  </CardsContainer>
);

export type CardSelectProps = ComponentProps<typeof CardSelect>;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: space-between;
  column-gap: 12px;
  align-items: stretch;
  overflow: visible;
`;
