import { useCallback, useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Analytics } from './analytics';

export function useAnalytics(pageName: string) {
  const postHog = usePostHog();

  useEffect(() => {
    Analytics.pageView(
      pageName,
      window.location.href,
      window.location.pathname,
    );
    postHog.capture('page_view', {
      pageName,
    });
  }, [pageName, postHog]);

  // return a function that prefixes the events triggered with <pageName_
  return useCallback((
    eventName: string,
    eventMetadata: Parameters<typeof Analytics.event>[1] = {},
  ) => {
    const fullEvent = `${pageName}_${eventName}`;
    Analytics.event(
      fullEvent,
      eventMetadata,
    );
  }, [pageName]);
}
