import { Banner, Heading, Paragraph, HelpIcon } from '@lower-financial/core-components/src';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const DidYouKnow = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <StyledBanner backgroundColor={'var(--tertiary-1-light)'}>
    <FlexContainer>
      <HelpIcon showOnlineDot={false} />
      <Heading
        size={'xxs'}
      >Did you know?
      </Heading>
    </FlexContainer>

    <Paragraph variant={'smallLightItalic'}>
      {children}
    </Paragraph>
  </StyledBanner>
);

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.675rem;
`;

const StyledBanner = styled(Banner)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const HighlightedText = styled.strong`
  font: var(--font-paragraph-1-bold-italic);
`;
