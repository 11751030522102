import { Paragraph, responsiveStyleFromTheme } from '@lower-financial/core-components/src';
import styled from 'styled-components';
import { theme } from '@lower-financial/core-components/src/styles/primary/theme';
import { useMediaQuery } from 'react-responsive';
import { TrustpilotLogo } from '@lightspeed/components/ui/icons/trustpilot-logo';

export const LandingTrustpilotBanner = () => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });

  const text = isDesktop
    ? 'Lower.com—Top Mortgage Lender'
    : 'Top Mortgage Lender';

  return (
    <TrustpilotBannerContainer>
      <TrustpilotLogo />
      <TrustpilotText>{text}</TrustpilotText>
      <TrustpilotRating>4.8/5</TrustpilotRating>
    </TrustpilotBannerContainer>
  );
};

const TrustpilotBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: var(--chalk);
  height: 3.75rem;
  margin: auto;
  width: 100%;
  ${responsiveStyleFromTheme({
    desktop: {
      backgroundColor: 'unset',
      height: 'unset',
      margin: 'unset',
    },
  })}
`;

const TrustpilotText = styled(Paragraph)`
  font: var(--font-footer-header);
  color: var(--smoke);
`;

const TrustpilotRating = styled(Paragraph)`
  font: var(--font-footer-header);
  color: var(--charcoal);
`;
