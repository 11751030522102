import { Form, FormRow, Label, Select, ValidationError } from '@lower-financial/core-components';
import { STATE_OPTIONS } from '@lower-financial/toolbox/src';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

interface PropertyStateFormProps {
  errors: Map<string, string[]>;
  isLoading: boolean;
}

export function PropertyStateForm({
  errors,
  isLoading = false,
}: PropertyStateFormProps) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();

  return (
    <Form data-testid={'property-state-form'}>
      <FormRow>
        <Label flex={['0 0 100%', 1]}>
          Property State
          <Select
            name={'state-select'}
            placeholder={'Select One'}
            options={[{
              label: 'Select One',
              value: '',
            }, ...STATE_OPTIONS]}
            value={mortgageApplication.propertyState}
            disabled={isLoading}
            onChange={(e) => updateMortgageApplication('propertyState', e.target.value)}
          />
          <ValidationError
            path={'propertyState'}
            errors={errors}
          />
        </Label>
      </FormRow>
    </Form>
  );
}
