import { CloseXIcon, LeftArrow } from '@lower-financial/icons';
import React from 'react';
import styled from 'styled-components';
import { OptionalElement } from '../optional-element';
import { IconButton } from '../icon-button';

const BackButton = ({ onClick }: { onClick?: () => void }) => (
  <IconButton
    label={'Go Back'}
    onClick={onClick}
  >
    <img
      src={LeftArrow}
      alt={'Back Arrow'}
    />
  </IconButton>
);

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <IconButton
    label={'Close'}
    onClick={onClick}
  >
    <CloseXIcon color={'var(--satin)'} />
  </IconButton>
);

export const ModalNav = ({
  onClose,
  onBack,
}: {
  onClose: () => void,
  onBack?: () => void,
}) => (
  <ModalNavContainer>
    <Left>
      <OptionalElement show={onBack !== undefined}>
        <BackButton onClick={onBack} />
      </OptionalElement>
    </Left>
    <Right>
      <CloseButton onClick={onClose} />
    </Right>
  </ModalNavContainer>
);

const ModalNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
`;

const Left = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  justify-content: flex-start;
`;

const Right = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  justify-content: flex-end;
`;
