
const Input = {
  book: {
    color: 'var(--black)',
    font: 'var(--font-paragraph-2)',
    letterSpacing: '-0.04em',
  },
};

const Scoreboard = {
  desktop: {
    color: 'var(--charcoal)',
    font: 'var(--font-scoreboard-display)',
    letterSpacing: '-0.04em',
  },
  mobile: {
    color: 'var(--charcoal)',
    font: 'var(--font-scoreboard-display-mobile)',
    letterSpacing: ' -0.01em',
  },
};

const Body = {
  large: {
    color: 'var(--body)',
    font: 'var(--font-paragraph-5)',
  },
  largeLight: {
    color: 'var(--body-light)',
    font: 'var(--font-paragraph-5)',
  },
  legal: {
    a: {
      color: 'var(--body-legal)',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    color: 'var(--body-legal)',
    font: 'var(--font-legal)',
  },
  legalBold: {
    a: {
      color: 'var(--body-legal)',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    color: 'var(--body-legal)',
    font: 'var(--font-legal-bold)',
  },
  light: {
    color: 'var(--body-light)',
    font: 'var(--font-paragraph-4)',
  },
  lightBold: {
    color: 'var(--body-light)',
    font: 'var(--font-heading-5)',
  },
  regular: {
    color: 'var(--body)',
    font: 'var(--font-paragraph-4)',
  },
  regularBold: {
    color: 'var(--body)',
    font: 'var(--font-heading-5)',
  },
  small: {
    color: 'var(--body)',
    font: 'var(--font-paragraph-1)',
  },
  smallLight: {
    color: 'var(--body-light)',
    font: 'var(--font-paragraph-1)',
  },
  smallLightBoldItalic: {
    color: 'var(--body-light)',
    font: 'var(--font-paragraph-1-bold-italic)',
  },
  smallLightItalic: {
    color: 'var(--body-light)',
    font: 'var(--font-paragraph-1-italic)',
  },
  smallMediumBold: {
    color: 'var(--body)',
    font: 'var(--font-bold-item)',
  },
  smallMediumBoldGrey: {
    color: 'var(--body-light)',
    font: 'var(--font-bold-item)',
  },
};

const Label = {
  bold: {
    color: 'var(--body)',
    font: 'var(--font-label-bold-1)',
    letterSpacing: '-0.04em',
  },
  book: {
    color: 'var(--body)',
    font: 'var(--font-label-1)',
    letterSpacing: '-0.04em',
  },
};

const Link = {
  bold: {
    color: 'var(--body)',
    font: 'var(--font-label-bold-1)',
    letterSpacing: '-0.04em',
  },
  book: {
    color: 'var(--body)',
    font: 'var(--font-label-1)',
    letterSpacing: '-0.04em',
  },
};

const Button = {
  bold: {
    color: 'var(--black)',
    font: 'var(--font-button-md)',
    letterSpacing: '-0.04em',
  },
};

const Headers = {
  lg: {
    desktop: {
      font: 'var(--font-heading-2-tablet)',
      letterSpacing: '-0.04em',
    },
    mobile: {
      font: 'var(--font-heading-2-mobile)',
      letterSpacing: '-0.04em',
    },
  },
  md: {
    mobile: {
      font: 'var(--font-heading-3)',
      letterSpacing: '-0.04em',
    },
    tablet: {
      font: 'var(--font-heading-2-mobile)',
      letterSpacing: '-0.04em',
    },
  },
  sm: {
    mobile: {
      font: 'var(--font-heading-4)',
      letterSpacing: '-0.04em',
    },
    tablet: {
      font: 'var(--font-heading-4)',
      letterSpacing: '-0.04em',
    },
  },
  xl: {
    desktop: {
      font: 'var(--font-normal-heading-1)',
      letterSpacing: '-0.04em',
    },
    mobile: {
      font: 'var(--font-normal-heading-1-mobile)',
      letterSpacing: '-0.04em',
    },
  },
  xs: {
    mobile: {
      font: 'var(--font-heading-5)',
      letterSpacing: '-0.04em',
    },
    tablet: {
      font: 'var(--font-heading-5)',
      letterSpacing: '-0.04em',
    },
  },
  xxl: {
    desktop: {
      font: 'var(--font-large-heading-1)',
      letterSpacing: '-0.04em',
    },
    mobile: {
      font: 'var(--font-large-heading-1-mobile)',
      letterSpacing: '-0.04em',
    },
  },
  xxs: {
    mobile: {
      font: 'var(--font-heading-6)',
      letterSpacing: '-0.04em',
    },
    tablet: {
      font: 'var(--font-heading-6)',
      letterSpacing: '-0.04em',
    },
  },
};

export const TypographyTokens = {
  Body,
  Button,
  Headers,
  Input,
  Label,
  Link,
  Scoreboard,
};
