import { ROUTES } from '@lightspeed/routing/routes';
import { GUARDS } from '@lightspeed/routing/guards';
import { ROUTING_EVENTS } from './routing-events';

export const NEW_FLOW = {
  [ROUTES.PURCHASE_PROPERTY_TYPE]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.CONTRACT_STATUS,
    },
  },
  [ROUTES.REFINANCE_PROPERTY_TYPE]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.PROPERTY_VALUE,
    },
  },
  [ROUTES.HELOC_PROPERTY_TYPE]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.PROPERTY_VALUE,
    },
  },
  [ROUTES.CONTRACT_STATUS]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.PURCHASE_PRICE,
    },
  },
  [ROUTES.PURCHASE_PRICE]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.PROPERTY_STATE,
    },
  },
  [ROUTES.PROPERTY_VALUE]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.PROPERTY_ADDRESS,
    },
  },
  [ROUTES.PROPERTY_ADDRESS]: {
    on: {
      [ROUTING_EVENTS.NEXT]: [
        {
          cond: GUARDS.isUnsupportedMarket,
          target: ROUTES.UNSUPPORTED_MARKET,
        },
        {
          cond: GUARDS.isPrimaryResidenceType,
          target: ROUTES.EMPLOYMENT_STATUS,
        },
        {
          target: ROUTES.BORROWER_ADDRESS,
        },
      ],
    },
  },
  [ROUTES.PROPERTY_STATE]: {
    on: {
      [ROUTING_EVENTS.NEXT]: [
        {
          cond: GUARDS.isUnsupportedMarket,
          target: ROUTES.UNSUPPORTED_MARKET,
        },
        {
          target: ROUTES.BORROWER_ADDRESS,
        },
      ],
    },
  },
  [ROUTES.ABOUT_YOU]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.CREDIT_CHECK,
    },
  },
  [ROUTES.BORROWER_ADDRESS]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.EMPLOYMENT_STATUS,
    },
  },
  [ROUTES.EMPLOYMENT_STATUS]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.YEARLY_INCOME,
    },
  },
  [ROUTES.YEARLY_INCOME]: {
    on: {
      [ROUTING_EVENTS.NEXT]: ROUTES.ABOUT_YOU,
    },
  },
  [ROUTES.CREDIT_CHECK]: {
    on: {
      [ROUTING_EVENTS.NEXT]: [
        {
          target: ROUTES.JUMPSTART_PROCESSING,
        },
      ],
    },
  },
  [ROUTES.JUMPSTART_PROCESSING]: {
    on: {
      [ROUTING_EVENTS.NEXT]: [
        {
          cond: GUARDS.continueToJumpstart,
          target: ROUTES.VERIFY_EMAIL_SENT,
        },
        {
          cond: GUARDS.jumpstartManualIntervention,
          target: ROUTES.JUMPSTART_MANUAL_INTERVENTION,
        },
        {
          target: ROUTES.SUBMITTED,
        },
      ],
    },
  },
  [ROUTES.JUMPSTART_MANUAL_INTERVENTION]: {},
  [ROUTES.SUBMITTED]: {},
  [ROUTES.VERIFY_EMAIL_SENT]: {},
};
