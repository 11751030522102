/* tslint:disable */
/* eslint-disable */
/**
 * Lower API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ContractStatus = {
    InContract: 'inContract',
    PlacedOffer: 'placedOffer',
    StillLooking: 'stillLooking'
} as const;
export type ContractStatus = typeof ContractStatus[keyof typeof ContractStatus];


export function ContractStatusFromJSON(json: any): ContractStatus {
    return ContractStatusFromJSONTyped(json, false);
}

export function ContractStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractStatus {
    return json as ContractStatus;
}

export function ContractStatusToJSON(value?: ContractStatus | null): any {
    return value as any;
}

