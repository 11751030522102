import { EmploymentStatus } from '../types/employment-status';

export const employmentStatusOptions = [
  {
    label: EmploymentStatus.W2,
    value: EmploymentStatus.W2,
  },
  {
    label: EmploymentStatus.SELF_EMPLOYED,
    value: EmploymentStatus.SELF_EMPLOYED,
  },
  {
    label: EmploymentStatus.RETIRED,
    value: EmploymentStatus.RETIRED,
  },
];

export const employmentStatuses = [
  EmploymentStatus.W2,
  EmploymentStatus.SELF_EMPLOYED,
  EmploymentStatus.RETIRED,
];

export const coborrowerEmploymentStatusOptions = [
  ...employmentStatusOptions,
  {
    label: EmploymentStatus.UNEMPLOYED,
    value: EmploymentStatus.UNEMPLOYED,
  },
];

export const coborrowerEmploymentStatuses = [
  ...employmentStatuses,
  EmploymentStatus.UNEMPLOYED,
];

export const employmentStatusLeadApiValues = ['W2', 'SelfEmployed', 'Retired'] as const;
export const coborrowerEmploymentStatusLeadApiValues = [...employmentStatusLeadApiValues, 'Unemployed'] as const;
