import React, { useState } from 'react';
import {
  Button,
  Heading,
  Label,
  LoadingOverlay,
  OptionalElement,
  useErrorHandling,
  useValidation,
} from '@lower-financial/core-components';
import { useAnalytics } from '@lower-financial/analytics';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models';
import { objectContainsFalsyPropsForKeys, slugify } from '@lower-financial/toolbox';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import { PurchaseRefinanceSidebarBlurb } from '@lightspeed/components/shared/sidebars/purchase-refinance-sidebar-blurb';
import { RateDisclaimer } from '@lightspeed/components/shared/legal-disclaimers/rate-disclaimer';
import { HelocSidebarBlurb } from '@lightspeed/components/shared/sidebars/heloc-sidebar-blurb';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';

import { useMarket } from '@lightspeed/hooks/useMarket/useMarket';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { BasicInfoForm } from './basic-info-form';
import { TodaysRates } from './todays-rates';
import { ApplicationDisclaimer } from './application-disclaimer';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerFirstName',
  'borrowerLastName',
  'borrowerEmail',
  'borrowerPhoneNumber',
  'propertyState',
];

interface BasicInfoProps {
  fireAnalyticsEvent: ReturnType<typeof useAnalytics>
}

export function BasicInfoTemplate({ fireAnalyticsEvent }: BasicInfoProps) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const handleError = useErrorHandling();
  const [loadingApplicationLeadCreation, setLoadingApplicationLeadCreation] = useState(false);
  const { upsertApplication } = useUpsertApplication();
  const { isMarketLoading, isUnsupportedMarket } = useMarket();
  const { partnerConfiguration: { partnerName } } = usePartnerConfiguration();

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: borrowerKeys,
  });

  const disableNextButton = objectContainsFalsyPropsForKeys(
    mortgageApplication,
    borrowerKeys,
  );

  const onNextClick = () => {
    (async () => {
      if (validate(mortgageApplication).success) {
        setLoadingApplicationLeadCreation(true);

        await handleError({
          onRetryableError: () => {
            setLoadingApplicationLeadCreation(false);
          },
          tryFn: async () => {
            await upsertApplication();
            setLoadingApplicationLeadCreation(false);
            if (isUnsupportedMarket(mortgageApplication.propertyState)) {
              fireAnalyticsEvent(`bad_state_${slugify(mortgageApplication.propertyState)}`);
              goToNextRoute();
            } else {
              fireAnalyticsEvent('next_button_success', {
                loanPurpose: mortgageApplication.loanPurpose,
                partnerName,
              });

              goToNextRoute();
            }
          },
        });
      }
    })();
  };

  return (
    <Layout>
      <OptionalElement show={loadingApplicationLeadCreation || isMarketLoading}>
        <LoadingOverlay />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading element={'h1'}>
                    Let’s get started.
                  </Heading>
                  <BasicInfoForm
                    errors={errors}
                  />
                  <SplitSection.LegalFooter>
                    <ApplicationDisclaimer isContinuing={false} />
                  </SplitSection.LegalFooter>
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <OptionalElement show={mortgageApplication.loanPurpose !== LoanPurpose.Heloc}>
                    <TodaysRates
                      mortgageApplication={mortgageApplication}
                    />
                    <Label
                      marginBottom={'8px'}
                    >
                      Loan Term
                    </Label>
                    <TermSelect
                      value={mortgageApplication.loanTerm}
                      onChange={(v) => {
                        updateMortgageApplication('loanTerm', v);
                      }}
                    />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.loanPurpose !== LoanPurpose.Heloc}>
                    <PurchaseRefinanceSidebarBlurb />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.loanPurpose === LoanPurpose.Heloc}>
                    <HelocSidebarBlurb />
                  </OptionalElement>
                  <Button
                    marginY={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                  <RateDisclaimer />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <Heading element={'h1'}>
                Let’s get started.
              </Heading>
              <BasicInfoForm
                errors={errors}
              />
              <Button
                marginTop={'32px'}
                marginBottom={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
              <ApplicationDisclaimer isContinuing={false} />
            </BasePageTemplate>
          </>
        )}
      />
    </Layout>
  );
}
