import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web/build/player/lottie_light';

// lottie relies on use of eval() statements in the library it wraps (bodymovin.js) which
// we do not allow at the Content Security Policy layer to avoid execution of arbitrary JS code.
// lottie_light is a wrapper around a lighter version of the library that removes the eval() statements
// (https://github.com/airbnb/lottie-web/issues/289#issuecomment-1454909624) but limits functionality
// in terms of renderer usage and audio (neither of which are issues for Lower at the moment).

type LottieSourceProps = {
  lottieJSONFile: unknown;
  style?: React.CSSProperties;
  // true/false | set how many loops (number)
  // default true
  lottieLoop?: boolean | number;
  testId?: string;
  ariaLabel?: string;
};

export const LottieSource = ({
  lottieJSONFile,
  style = {},
  lottieLoop = true,
  testId = 'lottie-animation',
  ariaLabel = 'Lottie animation',
}: LottieSourceProps) => {
  const lottieComponentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!lottieComponentRef.current) {
      return;
    }
    // https://github.com/airbnb/lottie-web/blob/cb1add8e78068ab36b6b7c3d052fd3ef6a7cf729/index.d.ts
    lottie.loadAnimation({
      animationData: lottieJSONFile,
      container: lottieComponentRef.current,
      loop: lottieLoop,
      renderer: 'svg',
    });
  }, [lottieJSONFile, lottieLoop]);

  return (
    <div
      aria-label={ariaLabel}
      data-testid={testId}
      style={style}
      ref={lottieComponentRef}
    />
  );
};

