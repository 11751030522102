import { Button, Heading, Label, OptionalElement, useErrorHandling } from '@lower-financial/core-components';
import React, { useMemo, useState } from 'react';
import { useAnalytics } from '@lower-financial/analytics';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { Header } from '@lightspeed/components/ui/molecules';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import {
  ApplicationOptionsPurchaseForm,
} from '@lightspeed/components/ui/pages/application-options/application-options-purchase-form';
import { TodaysRates } from '@lightspeed/components/ui/pages/basic-info/todays-rates';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { PurchaseRefinanceSidebarBlurb } from '@lightspeed/components/shared/sidebars/purchase-refinance-sidebar-blurb';
import { RateDisclaimer } from '@lightspeed/components/shared/legal-disclaimers/rate-disclaimer';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import {
  ApplicationOptionsNonPurchaseForm,
} from '@lightspeed/components/ui/pages/application-options/application-options-non-purchase-form';
import { HelocSidebarBlurb } from '@lightspeed/components/shared/sidebars/heloc-sidebar-blurb';

export const ApplicationOptions = () => {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const fireAnalyticsEvent = useAnalytics('application_options');
  const [loading, setLoading] = useState(false);
  const { upsertApplication } = useUpsertApplication();
  const { goToNextRoute } = useNextRoute();
  const handleError = useErrorHandling();

  const onNextClick = () => {
    if (loading) { return; }

    fireAnalyticsEvent('next_button_clicked');

    (async () => {
      setLoading(true);
      await handleError({
        onRetryableError: () => {
          setLoading(false);
        },
        tryFn: async () => {
          await upsertApplication();
          setLoading(false);
          goToNextRoute();
        },
      });
    })();
  };

  const heading = mortgageApplication.loanPurpose === LoanPurpose.Purchase
    ? 'Select all that apply'
    : 'Are you applying with someone?';

  const form = useMemo(() => {
    switch (mortgageApplication.loanPurpose) {
    case LoanPurpose.Purchase:
    case null:
      return <ApplicationOptionsPurchaseForm />;
    case LoanPurpose.Refinance:
    case LoanPurpose.Heloc:
      return <ApplicationOptionsNonPurchaseForm />;
    default:
      throw new Error('Unexpected loanPurpose on mortgageApplication');
    }
  }, [mortgageApplication.loanPurpose]);

  return (
    <Layout>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading element={'h1'}>The basics.</Heading>
                  <Heading
                    element={'h2'}
                    size={'sm'}
                    marginTop={'48px'}
                    marginBottom={'44px'}
                  >
                    {heading}
                  </Heading>
                  {form}
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <OptionalElement show={mortgageApplication.loanPurpose !== LoanPurpose.Heloc}>
                    <TodaysRates
                      mortgageApplication={mortgageApplication}
                    />
                    <Label marginBottom={'8px'}>
                      Loan Term
                    </Label>
                    <TermSelect
                      value={mortgageApplication.loanTerm}
                      onChange={(v) => {
                        updateMortgageApplication('loanTerm', v);
                      }}
                    />
                    <PurchaseRefinanceSidebarBlurb />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.loanPurpose === LoanPurpose.Heloc}>
                    <HelocSidebarBlurb />
                  </OptionalElement>
                  <Button
                    marginY={'32px'}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                  <RateDisclaimer />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <div>
                <Heading element={'h1'}>The basics.</Heading>
                <Heading
                  element={'h2'}
                  size={'sm'}
                  marginTop={'48px'}
                  marginBottom={'44px'}
                >
                  {heading}
                </Heading>
                {form}
              </div>
              <Button
                marginTop={'32px'}
                onClick={onNextClick}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </Layout>
  );
};
