import { OptionalElement, Paragraph } from '@lower-financial/core-components';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { usePartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';

export function RateDisclaimer() {
  const { mortgageApplication } = useMortgageApplication();
  const { partnerConfiguration: { isPartnerExperience } } = usePartnerConfiguration();
  const lowerContactClause = isPartnerExperience
    ? ''
    : <>&mdash;just give us a call at <b>(844) 64-LOWER</b></>;

  return (
    <OptionalElement show={mortgageApplication.loanPurpose !== LoanPurpose.Heloc}>
      <Paragraph variant={'legal'}>
        The stated interest rate may change or not be available at the time of loan commitment, lock-in, or closing.
        Your loan advisor will make these calculations for your specific property and profile{lowerContactClause}.
      </Paragraph>
    </OptionalElement>
  );
}
