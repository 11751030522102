import styled from 'styled-components';

export const SlideToggle = styled.input.attrs({
  type: 'checkbox',
})`
  all: unset;
  cursor: pointer;
  display: inline-block;
  min-width: 48px;
  height: 28px;
  border-radius: 14px;
  background-color: var(--smoke);
  transition: background-color 200ms;
  position: relative;

  &::after {
    width: 24px;
    height: 24px;
    background-color: white;
    content: "";
    display: block;
    border-radius: 50%;
    position: relative;
    top: 2px;
    left: 2px;
    transition: left 300ms cubic-bezier(.082, 1.679, .298, 1.007);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  }

  &:focus {
    box-shadow: 0 0 5px 0 var(--smoke);
  }

  &:checked {
    background-color: var(--success);

    &::after {
      left: 22px;
    }
  }
`;
