import styled from 'styled-components';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';

export const PartnerBanner = ({ color = '#5CD2AF' }) => {
  const { partnerConfiguration } = usePartnerConfiguration();
  const { displayName } = partnerConfiguration;
  return (
    <BannerContainer>
      <BannerBackground
        width={'110'}
        height={'139'}
        viewBox={'0 0 110 139'}
        fill={'none'}
        xmlns={'http://www.w3.org/2000/svg'}
      >
        <path
          d={'M0.164062 0.847656H109.155V138.848L54.6596 117.92L0.164062 138.848V0.847656Z'}
          fill={color}
        />
      </BannerBackground>
      <BannerContent>
        <BannerText>{displayName}</BannerText>
        <Divider />
        <BannerText>
          Preferred<br />Partner
        </BannerText>
      </BannerContent>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  width: 110px;
  height: 139px;
  position: relative;
`;

const Divider = styled.div`
  height: 2px;
  width: 10px;
  background: var(--ghost);
  opacity: 0.5;
`;

const BannerBackground = styled.svg`
  position: absolute;
`;

const BannerContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  align-items: center;
  padding-top: 20px;
`;

const BannerText = styled.div`
  color: var(--white);
  font-family: var(--font-bold);
  text-align: center;
  font-size: 14px;
  line-height: 18px;
`;
