export const CheckPeriodIcon = ({ color = '#FFB82F', ...props }) => (
  <svg
    width={'18'}
    height={'14'}
    viewBox={'0 0 18 14'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>check icon</title>
    <g clipPath={'url(#clip0_29_4632)'}>
      <path
        d={'M6.64911 13.441L0.5 7.54386L2.95293 4.97333L6.4475 8.3167L13.4199 0.470703L16.0912 2.83962L6.64911 13.441Z'}
        fill={color}
      />
      <path
        d={'M16.578 12.5839C17.3005 12.5839 17.8885 11.9959 17.8885 11.2902C17.8885 10.5846 17.3005 9.99658 16.578 9.99658C15.8556 9.99658 15.2676 10.5846 15.2676 11.2902C15.2676 11.9959 15.8556 12.5839 16.578 12.5839Z'}
        fill={color}
      />
    </g>
    <defs>
      <clipPath id={'clip0_29_4632'}>
        <rect
          width={'17.3889'}
          height={'12.9703'}
          fill={color}
          transform={'translate(0.5 0.470703)'}
        />
      </clipPath>
    </defs>
  </svg>
);
