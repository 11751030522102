import postHog from 'posthog-js';
import { FeatureFlags } from '@lower-financial/feature-flags';

declare global {
  interface Window {
    __FLAG_DATA__: FeatureFlags,
    __PH_DISTINCT_ID__: string,
  }
}

interface PartialPostHogConfig {
  advanced_disable_feature_flags_on_first_load: boolean;
  api_host: string;
  autocapture: boolean;
  bootstrap: {
    distinctID?: string;
    featureFlags?: Record<string, boolean | string>;
  };
  capture_pageleave: boolean;
  capture_pageview: boolean;
  capture_performance?: boolean;
  opt_out_capturing_by_default: boolean;
}

const flagData = window.__FLAG_DATA__;
const distinctId = window.__PH_DISTINCT_ID__;

export const initPostHog = (postHogApiKey: string, postHogApiHost: string, disableFeatureFlagsOnFirstLoad = true) => {
  const config: PartialPostHogConfig = {
    advanced_disable_feature_flags_on_first_load: disableFeatureFlagsOnFirstLoad,
    api_host: postHogApiHost,
    autocapture: false,
    bootstrap: {},
    capture_pageleave: false,
    capture_pageview: false,
    capture_performance: false,
    opt_out_capturing_by_default: false,
  };

  // If we're disabling feature flags on first load, the flag values need to be bootstrapped
  if (disableFeatureFlagsOnFirstLoad) {
    config.bootstrap = {
      distinctID: distinctId,
      featureFlags: flagData,
    };
  }

  postHog.init(postHogApiKey, config);

  return postHog;
};
