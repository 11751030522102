import { createContext, ReactNode, useContext, useState } from 'react';
import { TodaysRates } from '@lightspeed/hooks/useTodaysRates';

export interface RatesState {
  rates: TodaysRates | null,
  setRates: (updated: TodaysRates) => void
}

export function useRatesState(overrides: RatesState['rates'] = null) {
  const [rates, setRates] = useState<RatesState['rates']>(overrides);

  return {
    rates,
    setRates,
  };
}

const RatesContext = createContext<RatesState>({
  rates: null,
  setRates: () => { /* noop */ },
});

export function RatesContextProvider({ children, overrides = null }: {
  children?: ReactNode | ReactNode[],
  overrides: TodaysRates | null,
}) {
  return (
    <RatesContext.Provider value={useRatesState(overrides)}>
      {children}
    </RatesContext.Provider>
  );
}

export function useRatesContext() {
  return useContext(RatesContext);
}
