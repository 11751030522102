import { useMemo } from 'react';
import { getPartnerLogos } from '@lightspeed/assets/partners/partners-config';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';

export const usePartnerLogos = () => {
  const { partnerConfiguration: { partnerName } } = usePartnerConfiguration();

  const logos = useMemo(
    () => getPartnerLogos(partnerName ?? 'lower'),
    [partnerName],
  );

  return {
    logos,
  };
};
