import { useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useAnalytics } from '@lower-financial/analytics';
import { Heading, OptionalElement, responsiveStyleFromTheme } from '@lower-financial/core-components';
import { theme } from '@lower-financial/core-components/src/styles/primary/theme';
import { CardButtonGroup } from '@lower-financial/core-components/src/components/card-button-group';
import { useNavigate } from 'react-router-dom';
import { BasePageTemplate as _BasePageTemplate } from '@lightspeed/components/ui/templates';
import { Header as _Header } from '@lightspeed/components/ui/molecules/header';
import { usePartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { LandingFooter } from '@lightspeed/components/ui/pages/landing/landing-footer';
import { PersonBanner } from '@lightspeed/components/shared/banners/person-banner';
import { LandingTrustpilotBanner } from '@lightspeed/components/ui/pages/landing/landing-trustpilot-banner';
import { ROUTES } from '@lightspeed/routing/routes';
import { BuyAHomeImage } from '@lightspeed/components/ui/pages/landing/images/buy-a-home-image';
import { RefinanceImage } from '@lightspeed/components/ui/pages/landing/images/refinance-image';
import { AccessEquityImage } from '@lightspeed/components/ui/pages/landing/images/access-equity-image';

export function Landing() {
  useAnalytics('landing_home_goals');

  const navigate = useNavigate();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });

  const { clearField } = usePartnerConfiguration();

  useEffect(() => {
    clearField('loanPurpose');
  }, [clearField]);

  const landingCardButtonItems = [
    {
      icon: isDesktop
        ? <BuyAHomeImage />
        : '🏡',
      onClick: () => navigate(ROUTES.PURCHASE),
      subtitle: 'The process is simple. Get the home of your dreams without the hassle.',
      title: 'Buy a home.',
    },
    {
      icon: isDesktop
        ? <RefinanceImage />
        : '🤘',
      onClick: () => navigate(ROUTES.REFINANCE),
      subtitle: 'Lower your payments and adjust loan terms. We’ll find the right fit.',
      title: 'Refinance.',
    },
    {
      icon: isDesktop
        ? <AccessEquityImage />
        : '💸',
      onClick: () => navigate(ROUTES.HELOC),
      subtitle: 'Take cash out or get a HELOC to pay for whatever you need.',
      title: 'Access equity.',
    },
  ];

  return (
    <>
      <LandingContainer>
        <Header hideBackButton />
        <BasePageTemplate>
          <div>
            <PersonBanner text={'Let’s get started.'} />
            <StyledHeading size={'lg'}>What are your home goals?</StyledHeading>
          </div>
          <StyledCardButtonGroup items={landingCardButtonItems} />
          <OptionalElement show={isDesktop}>
            <LandingTrustpilotBanner />
          </OptionalElement>
        </BasePageTemplate>
        <OptionalElement show={!isDesktop}>
          <LandingTrustpilotBanner />
        </OptionalElement>
      </LandingContainer>
      <LandingFooter />
    </>
  );
}

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: var(--layout-min-height);
`;

const BasePageTemplate = styled(_BasePageTemplate)`
  justify-content: center;
  margin: auto;
  flex-wrap: unset;
  align-items: center;
  gap: 1.75rem;
`;

const Header = styled(_Header)`
  border-bottom: 1px solid var(--silk);
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  max-width: 17rem;
  ${responsiveStyleFromTheme({
    desktop: {
      maxWidth: 'unset',
    },
  })}
`;

const StyledCardButtonGroup = styled(CardButtonGroup)`
  max-width: 35rem;
`;
