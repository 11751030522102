import React, { ReactElement } from 'react';
import styled, { keyframes } from 'styled-components';
import CloseButton from './CloseButton.svg';
import RightCaret from './BlueRightCaret.svg';

interface Link {
  linkLabel: string;
  link: string;
}

interface MobileTrayProps {
  dismiss: () => void;
  mobileTrayConfig: {
    headerText: string,
    links: { linkLabel: string, link: string }[]
  };
  onLinkClick: (link: Link) => void;
}

export function MobileTray({
  dismiss, mobileTrayConfig, onLinkClick,
}: MobileTrayProps): ReactElement<MobileTrayProps> {
  /**
   * In general, we should not call useNavigate directly. We should
   * prefer useNextRoute, but the routing machine code was weird
   * here due to the nature of asyncronous setStates in the
   * navigate functions.  We chose to just navigate instead
   * here to keep things simple.
   */

  return (
    <BlackOverlayModal
      onClick={dismiss}
      aria-label={'Dismiss tray'}
      data-testid={'modal-bg'}
    >
      <Tray>
        <TrayContainer>
          <TrayControl>
            <CloseIcon
              data-testid={'modal-close-icon'}
              aria-label={'Close'}
              onClick={dismiss}
            >
              <img
                style={{
                  height: '11px',
                  width: '11px',
                }}
                src={CloseButton}
                alt={'icon'}
              />
            </CloseIcon>
          </TrayControl>
          <LinkHeader>
            {mobileTrayConfig.headerText}
          </LinkHeader>
          {mobileTrayConfig.links.map((link) => (
            <Links key={link.linkLabel}>
              <LinkButton
                onClick={() => onLinkClick(link)}
                aria-label={link.linkLabel}
              >{link.linkLabel}
                <CaretIcon>
                  <img
                    src={RightCaret}
                    alt={'icon'}
                    style={{
                      height: '14px',
                      width: '14px',
                    }}
                  />
                </CaretIcon>
              </LinkButton>
              <Divider />
            </Links>
          ))}
        </TrayContainer>
      </Tray>
    </BlackOverlayModal>
  );
}

const DropdownSlideUpKeyframes = keyframes`
  from {
    transform: translate3d(0,100%,0);
  }

  to {
    transform: translate3d(0,0,0);
  }
`;

const BlackOverlayModal = styled.div`
  position: fixed;
  z-index: 1000;
  background: var(--overlay);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TrayContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 40px 40px 0 0;
`;

const TrayControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 30px 28px 40px;
`;

const Tray = styled.div`
  height: 70%;
  background-color: var(--background);
  z-index: 2;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: block;
  border-radius: 40px 40px 0 0;
  animation: ${DropdownSlideUpKeyframes} 200ms cubic-bezier(0.33, 1, 0.68, 1) forwards;
`;

const CloseIcon = styled.button`
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const CaretIcon = styled.div`
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LinkButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--body);
  font: var(--font-paragraph-2-bold);
  padding: 15px 40px 15px 0;
  background: var(--background);
  border: none;
`;

const Links = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding-top: 11px;
`;

const LinkHeader = styled.div`
  font: normal normal normal 1.5rem/2.1rem var(--font-bold);
  padding-left: 40px;
`;

const Divider = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 1px;
  background-image: linear-gradient(274deg, rgba(196,198,209,0.10126057258841037) 0%, rgba(196,198,209,0.7539216370141807) 29%, rgba(196,198,209,1) 49%, rgba(196,198,209,0.748319396117822) 69%, rgba(196,198,209,0.1) 100%);
  `;

