import { datadogRum } from '@datadog/browser-rum';
import { ENV } from '@lightspeed/environment';
import { setDataDogQuotingApplicationIdContext } from '@lightspeed/utils/data-dog-context';

datadogRum.init({
  allowedTracingOrigins: [ENV.LEGACY_API_HOST, ENV.BACKEND_API_HOST],
  applicationId: ENV.DATADOG_APPLICATION_ID || '',
  clientToken: ENV.DATADOG_CLIENT_TOKEN || '',
  defaultPrivacyLevel: 'mask-user-input',
  env: ENV.DATADOG_ENV,
  sampleRate: ENV.DATADOG_SAMPLE_RATE,
  service: 'lightspeed',
  site: 'us3.datadoghq.com',
  trackInteractions: true,
  version: ENV.FRONTEND_SHA,
});

datadogRum.startSessionReplayRecording();

window.dataLayer.push({
  datadog_session_id: datadogRum.getInternalContext()?.session_id,
});

// We need to set this to undefined so that it doesn't persist across reloads
setDataDogQuotingApplicationIdContext(undefined);
