import { useCallback, useState } from 'react';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { filterInvalidPrefillFields } from '@lightspeed/utils/prefill-validation';

export type MortgageApplicationStoreKeys = keyof MortgageApplicationStore;

export const initialApplication: MortgageApplicationStore = {
  borrowerAddressAutocompleteValue: '',
  borrowerAmountInMutualFunds: '',
  borrowerAmountInSavings: '0',
  borrowerCity: '',
  borrowerContractStatus: '',
  borrowerCounty: '',
  borrowerCreditAuthDateTime: '', // DateTime ISO-8601 string
  borrowerDateOfBirth: '',
  borrowerEmail: '',
  borrowerEmployer: '',
  borrowerEmploymentStatus: '',
  borrowerFirstName: '',
  borrowerFirstTimeHomeBuyer: '',
  borrowerLastName: '',
  borrowerOtherIncomePerYear: '',
  borrowerPhoneNumber: '',
  borrowerSmsConsentedAt: new Date().toISOString(), // checkbox is checked by default
  borrowerSocialSecurityNumber: '',
  borrowerState: '',
  borrowerStreetAddress: '',
  borrowerTitle: '',
  borrowerYearlyIncomeRangeId: null,
  borrowerYearlySalary: '',
  borrowerYearsAtAddress: '',
  borrowerYearsAtCompany: '',
  borrowerZipCode: '',
  coBorrowerAddressAutocompleteValue: '',
  coBorrowerAmountInMutualFunds: '',
  coBorrowerAmountInSavings: '',
  coBorrowerCity: '',
  coBorrowerCounty: '',
  coBorrowerCreditAuthDateTime: '', // DateTime ISO-8601 string
  coBorrowerDateOfBirth: '',
  coBorrowerEmail: '',
  coBorrowerEmployer: '',
  coBorrowerEmploymentStatus: '',
  coBorrowerFirstName: '',
  coBorrowerLastName: '',
  coBorrowerOtherIncomePerYear: '',
  coBorrowerPhoneNumber: '',
  coBorrowerSocialSecurityNumber: '',
  coBorrowerState: '',
  coBorrowerStreetAddress: '',
  coBorrowerTitle: '',
  coBorrowerYearlySalary: '',
  coBorrowerYearsAtCompany: '',
  coBorrowerZipCode: '',
  consentSharePreapprovalDenialReasons: null,
  discountPoints: '',
  hasCoBorrower: false,
  hasRealEstateAgent: null,
  isBorrowerCreditFrozen: null,
  jumpstartEligibilityDecision: null,
  loanPurpose: null,
  loanTerm: 'thirty',
  preapprovalEligibilityId: '',
  propertyAutocompleteValue: '',
  propertyCashOut: '',
  propertyCity: '',
  propertyCounty: '',
  propertyDownPayment: '',
  propertyDownPaymentPartiallyGift: '',
  propertyHomeValue: '',
  propertyHomeValueRangeId: null,
  propertyMortgageBalance: '',
  propertyPurchasePrice: '',
  propertyPurchasePriceRangeId: null,
  propertyResidenceType: '',
  propertyState: '',
  propertyStreetAddress: '',
  propertyType: '',
  propertyZipCode: '',
  quoteChosen: false,
  quotingApplicationId: '',
  quotingApplicationSubmissionId: '',
};

export function useMortgageApplicationState(overrides: Partial<MortgageApplicationStore> = {}) {
  const [state, setState] = useState<MortgageApplicationStore>({
    ...initialApplication,
    ...filterInvalidPrefillFields(window.prefill),
    ...overrides,
  });
  const update = useCallback((
    keyOrState: MortgageApplicationStoreKeys | Partial<MortgageApplicationStore>,
    value?: string[] | string | boolean | null,
  ) => {
    if (typeof keyOrState === 'string') {
      const key = keyOrState;
      setState((currentState) => ({
        ...currentState,
        [key]: value,
      }));
    } else if (typeof keyOrState === 'object') {
      const updatedState = keyOrState;
      setState((currentState) => ({
        ...currentState,
        ...updatedState,
      }));
    } else {
      throw new Error(`Unsupported keyOrState type passed to updateMortgageApplication. Expected string or object, found ${typeof keyOrState}`);
    }
  }, [setState]);

  const clearCoBorrower = useCallback(() => {
    Object.keys(state).forEach((mortgageAppKey) => {
      if (mortgageAppKey.search('coBorrower') === 0) {
        update(mortgageAppKey, '');
      }
    });
  }, [state, update]);

  return {
    clearCoBorrower,
    mortgageApplication: state,
    updateMortgageApplication: update,
  };
}

export type MortgageApplicationState = (ReturnType<typeof useMortgageApplicationState>);
