import cookies, { CookieAttributes } from 'js-cookie';
import { setFeatureFlags } from '@lower-financial/feature-flags/src/posthog/set-feature-flag';
import { getCookieOptions } from '@lightspeed/utils/get-cookie-options';
import type { PostHog } from 'posthog-js';

export interface ReferralData {
  affinityPartnerName: string | null;
  friendsAndFamilyReferralProgramEmail: string | null;
  mortgageProduct: string | null;
  vendorLeadUniqueId: string | null;
  vendorMarketingId: string | null;
  utmContent: string | null;
  utmCampaign: string | null;
  utmSource: string | null;
  utmMedium: string | null;
  utmTerm: string | null;
  gbraid: string | null;
  gclid: string | null;
  lastReferrer: string | null;
  loanOfficerToken: string | null;
  searchId: string | null;
  wbraid: string | null;
}

export const defaultReferralData: ReferralData = {
  affinityPartnerName: null,
  friendsAndFamilyReferralProgramEmail: null,
  gbraid: null,
  gclid: null,
  lastReferrer: null,
  loanOfficerToken: null,
  mortgageProduct: null,
  searchId: null,
  utmCampaign: null,
  utmContent: null,
  utmMedium: null,
  utmSource: null,
  utmTerm: null,
  vendorLeadUniqueId: null,
  vendorMarketingId: null,
  wbraid: null,
};

export const AFFINITY_PARTNER_NAME_KEY = 'affinity_partner_name';
export const MORTGAGE_PRODUCT_KEY = 'mortgage_product';
export const VENDOR_LEAD_UNIQUE_ID_KEY = 'vendor_lead_unique_id';
export const VENDOR_MARKETING_ID_KEY = 'vendor_marketing_id';
export const FRIENDS_AND_FAMILY_REFERRAL_PROGRAM_KEY = 'ffrp';
export const GCLID_KEY = 'gclid';
export const GBRAID_KEY = 'gbraid';
export const WBRAID_KEY = 'wbraid';
export const UTM_SOURCE_KEY = 'utm_source';
export const UTM_CAMPAIGN_KEY = 'utm_campaign';
export const UTM_MEDIUM_KEY = 'utm_medium';
export const UTM_TERM_KEY = 'utm_term';
export const UTM_CONTENT_KEY = 'utm_content';
export const REFERRER_KEY = 'referrer';
export const LOAN_OFFICER_TOKEN_KEY = 'lo';
export const SEARCH_ID_KEY = 'search_id';

const referralCookieOptions = (): CookieAttributes => (
  {
    ...getCookieOptions(),
    sameSite: 'none',
    secure: true,
  }
);

export function removeReferralDataCookies() {
  cookies.remove(MORTGAGE_PRODUCT_KEY, referralCookieOptions());
  cookies.remove(AFFINITY_PARTNER_NAME_KEY, referralCookieOptions());
  cookies.remove(VENDOR_LEAD_UNIQUE_ID_KEY, referralCookieOptions());
  cookies.remove(VENDOR_MARKETING_ID_KEY, referralCookieOptions());
  cookies.remove(FRIENDS_AND_FAMILY_REFERRAL_PROGRAM_KEY, referralCookieOptions());
  cookies.remove(GCLID_KEY, referralCookieOptions());
  cookies.remove(GBRAID_KEY, referralCookieOptions());
  cookies.remove(WBRAID_KEY, referralCookieOptions());
  cookies.remove(UTM_SOURCE_KEY, referralCookieOptions());
  cookies.remove(UTM_CAMPAIGN_KEY, referralCookieOptions());
  cookies.remove(UTM_MEDIUM_KEY, referralCookieOptions());
  cookies.remove(UTM_TERM_KEY, referralCookieOptions());
  cookies.remove(UTM_CONTENT_KEY, referralCookieOptions());
  cookies.remove(REFERRER_KEY, referralCookieOptions());
  cookies.remove(LOAN_OFFICER_TOKEN_KEY, referralCookieOptions());
  cookies.remove(SEARCH_ID_KEY, referralCookieOptions());
}

function getCookieOrNull(key: string) {
  const val = cookies.get(key);

  return val === ''
    ? null
    : val ?? null;
}

export function getReferralDataCookieValues(): ReferralData {
  return {
    affinityPartnerName: getCookieOrNull(AFFINITY_PARTNER_NAME_KEY),
    friendsAndFamilyReferralProgramEmail: getCookieOrNull(FRIENDS_AND_FAMILY_REFERRAL_PROGRAM_KEY),
    gbraid: getCookieOrNull(GBRAID_KEY),
    gclid: getCookieOrNull(GCLID_KEY),
    lastReferrer: getCookieOrNull(REFERRER_KEY),
    loanOfficerToken: getCookieOrNull(LOAN_OFFICER_TOKEN_KEY),
    mortgageProduct: getCookieOrNull(MORTGAGE_PRODUCT_KEY),
    searchId: getCookieOrNull(SEARCH_ID_KEY),
    utmCampaign: getCookieOrNull(UTM_CAMPAIGN_KEY),
    utmContent: getCookieOrNull(UTM_CONTENT_KEY),
    utmMedium: getCookieOrNull(UTM_MEDIUM_KEY),
    utmSource: getCookieOrNull(UTM_SOURCE_KEY),
    utmTerm: getCookieOrNull(UTM_TERM_KEY),
    vendorLeadUniqueId: getCookieOrNull(VENDOR_LEAD_UNIQUE_ID_KEY),
    vendorMarketingId: getCookieOrNull(VENDOR_MARKETING_ID_KEY),
    wbraid: getCookieOrNull(WBRAID_KEY),
  };
}

export function setReferralDataCookies({
  affinityPartnerName = null,
  mortgageProduct = null,
  vendorLeadUniqueId = null,
  vendorMarketingId = null,
  friendsAndFamilyReferralProgramEmail = null,
  gclid = null,
  gbraid = null,
  wbraid = null,
  utmSource = null,
  utmCampaign = null,
  utmMedium = null,
  utmTerm = null,
  utmContent = null,
  lastReferrer = null,
  loanOfficerToken = null,
  searchId = null,
}: Partial<ReferralData>) {
  affinityPartnerName && cookies.set(AFFINITY_PARTNER_NAME_KEY, affinityPartnerName, referralCookieOptions());
  mortgageProduct && cookies.set(MORTGAGE_PRODUCT_KEY, mortgageProduct, referralCookieOptions());
  vendorLeadUniqueId && cookies.set(VENDOR_LEAD_UNIQUE_ID_KEY, vendorLeadUniqueId, referralCookieOptions());
  vendorMarketingId && cookies.set(VENDOR_MARKETING_ID_KEY, vendorMarketingId, referralCookieOptions());
  friendsAndFamilyReferralProgramEmail && cookies.set(
    FRIENDS_AND_FAMILY_REFERRAL_PROGRAM_KEY,
    friendsAndFamilyReferralProgramEmail,
    referralCookieOptions(),
  );
  gclid && cookies.set(GCLID_KEY, gclid, referralCookieOptions());
  gbraid && cookies.set(GBRAID_KEY, gbraid, referralCookieOptions());
  wbraid && cookies.set(WBRAID_KEY, wbraid, referralCookieOptions());
  utmSource && cookies.set(UTM_SOURCE_KEY, utmSource, referralCookieOptions());
  utmCampaign && cookies.set(UTM_CAMPAIGN_KEY, utmCampaign, referralCookieOptions());
  utmMedium && cookies.set(UTM_MEDIUM_KEY, utmMedium, referralCookieOptions());
  utmTerm && cookies.set(UTM_TERM_KEY, utmTerm, referralCookieOptions());
  utmContent && cookies.set(UTM_CONTENT_KEY, utmContent, referralCookieOptions());
  lastReferrer && cookies.set(REFERRER_KEY, lastReferrer, referralCookieOptions());
  loanOfficerToken && cookies.set(LOAN_OFFICER_TOKEN_KEY, loanOfficerToken, referralCookieOptions());
  searchId && cookies.set(SEARCH_ID_KEY, searchId, referralCookieOptions());
}

function getInitialReferralData() {
  const url = new URL(window.location.href);

  const mortgageProduct = url.searchParams.get(MORTGAGE_PRODUCT_KEY);
  const affinityPartnerName = url.searchParams.get(AFFINITY_PARTNER_NAME_KEY);
  const vendorLeadUniqueId = url.searchParams.get(VENDOR_LEAD_UNIQUE_ID_KEY);
  const vendorMarketingId = url.searchParams.get(VENDOR_MARKETING_ID_KEY);
  const friendsAndFamilyReferralProgramEmail = url.searchParams.get(FRIENDS_AND_FAMILY_REFERRAL_PROGRAM_KEY);
  const gclid = url.searchParams.get(GCLID_KEY);
  const gbraid = url.searchParams.get(GBRAID_KEY);
  const wbraid = url.searchParams.get(WBRAID_KEY);
  const utmSource = url.searchParams.get(UTM_SOURCE_KEY);
  const utmCampaign = url.searchParams.get(UTM_CAMPAIGN_KEY);
  const utmMedium = url.searchParams.get(UTM_MEDIUM_KEY);
  const utmTerm = url.searchParams.get(UTM_TERM_KEY);
  const utmContent = url.searchParams.get(UTM_CONTENT_KEY);
  const lastReferrer = url.searchParams.get(REFERRER_KEY);
  const loanOfficerToken = url.searchParams.get(LOAN_OFFICER_TOKEN_KEY);
  const searchId = url.searchParams.get(SEARCH_ID_KEY);

  const cookieValues = getReferralDataCookieValues();
  return {
    affinityPartnerName: affinityPartnerName ?? cookieValues.affinityPartnerName,
    friendsAndFamilyReferralProgramEmail: friendsAndFamilyReferralProgramEmail
      ?? cookieValues.friendsAndFamilyReferralProgramEmail,
    gbraid: gbraid ?? cookieValues.gbraid,
    gclid: gclid ?? cookieValues.gclid,
    lastReferrer: lastReferrer ?? cookieValues.lastReferrer,
    loanOfficerToken: loanOfficerToken ?? cookieValues.loanOfficerToken,
    mortgageProduct: mortgageProduct ?? cookieValues.mortgageProduct,
    searchId: searchId ?? cookieValues.searchId,
    utmCampaign: utmCampaign ?? cookieValues.utmCampaign,
    utmContent: utmContent ?? cookieValues.utmContent,
    utmMedium: utmMedium ?? cookieValues.utmMedium,
    utmSource: utmSource ?? cookieValues.utmSource,
    utmTerm: utmTerm ?? cookieValues.utmTerm,
    vendorLeadUniqueId: vendorLeadUniqueId ?? cookieValues.vendorLeadUniqueId,
    vendorMarketingId: vendorMarketingId ?? cookieValues.vendorMarketingId,
    wbraid: wbraid ?? cookieValues.wbraid,
  };
}

function preserveControlFlowForLoToken(referralData: ReturnType<typeof getInitialReferralData>, postHog: PostHog) {
  if (referralData.loanOfficerToken !== null) {
    setFeatureFlags(postHog, {
      lightspeedV2Purchase: 'control',
    });
  }
}
export function initializeReferralData(postHog: PostHog) {
  const referralData = getInitialReferralData();
  setReferralDataCookies(referralData);
  preserveControlFlowForLoToken(referralData, postHog);

  return referralData;
}
