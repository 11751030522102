import { useCallback } from 'react';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import {
  transformMortgageApplicationToQuotingApplication,
} from '@lightspeed/transformers/quoting-application-transformer';
import { createQuotingApplication } from '@lightspeed/api/services/backend/lending/createQuotingApplication';
import { updateQuotingApplication } from '@lightspeed/api/services/backend/lending/updateQuotingApplication';
import { setDataDogQuotingApplicationIdContext } from '@lightspeed/utils/data-dog-context';
import { useReferralData } from '@lightspeed/contexts/referral-context/referral-context';

export function useUpsertApplication() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const { referralData } = useReferralData();

  const upsertApplication = useCallback(async () => {
    const quotingApplication = transformMortgageApplicationToQuotingApplication(
      mortgageApplication,
      referralData,
    );

    let { quotingApplicationId } = mortgageApplication;
    if (mortgageApplication.quotingApplicationId) {
      const response = await updateQuotingApplication(mortgageApplication.quotingApplicationId, quotingApplication);

      if (response.status === 404) {
        throw new Error(`Couldn't find application for lead id ${mortgageApplication.quotingApplicationId}`);
      } else if (response.status === 422) {
        const data = await response.json();
        throw new Error(`Error validating application for lead id ${mortgageApplication.quotingApplicationId}: ${JSON.stringify(data.errors)}`);
      } else if (response.status === 409) {
        const data = await response.json();
        throw new Error(`Application not updatable error for lead id ${mortgageApplication.quotingApplicationId}: ${JSON.stringify(data.message)}`);
      } else if (response.status !== 204) {
        throw new Error(`Unexpected error submitting application for lead id ${mortgageApplication.quotingApplicationId}`);
      }
    } else {
      const createResponse = await createQuotingApplication(quotingApplication);
      if (createResponse.status === 201) {
        const quoteData = await createResponse.json();
        if (quoteData.quotingApplicationId) {
          updateMortgageApplication('quotingApplicationId', quoteData.quotingApplicationId);
          setDataDogQuotingApplicationIdContext(quoteData.quotingApplicationId);
          quotingApplicationId = quoteData.quotingApplicationId;
        }
      } else {
        throw new Error('Couldnt create new quoting application');
      }
    }

    return quotingApplicationId;
  }, [mortgageApplication, referralData, updateMortgageApplication]);

  return {
    upsertApplication,
  };
}
