import { STATE_TO_TIMEZONE, Timezone } from '@lower-financial/core-components';

export function getCalendlyTimezoneFromState(state: string) {
  const timezoneToCalendlyMapping = {
    [Timezone.AKST]: 'ak',
    [Timezone.CST]: 'ct',
    [Timezone.EST]: 'et',
    [Timezone.HST]: 'hi',
    [Timezone.MST]: 'mt',
    [Timezone.PST]: 'pt',
  };

  const stateTimezone = STATE_TO_TIMEZONE.get(state.toUpperCase());

  if (!stateTimezone) {
    throw new Error(`No timezone found for state: ${state}`);
  }

  return timezoneToCalendlyMapping[stateTimezone];
}
