import { ErrorResponse, Market } from '@lower-financial/lending-web-api/generated';
import { fetch, TypedResponse } from '@lower-financial/toolbox';
import { ENV } from '@lightspeed/environment';

export default function getMarket() {
  return fetch<GetMarketResponse>(
    `${ENV.BACKEND_API_HOST}/api/lending/markets`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  );
}

type NotFoundErrorResponseInterface = TypedResponse<ErrorResponse, 404>;

export type GetMarketResponse =
| NotFoundErrorResponseInterface
| TypedResponse<Market[], 200>;
