/* tslint:disable */
/* eslint-disable */
/**
 * Lower API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PropertyUsage = {
    Investment: 'investment',
    PrimaryResidence: 'primaryResidence',
    SecondHome: 'secondHome',
    Other: 'other'
} as const;
export type PropertyUsage = typeof PropertyUsage[keyof typeof PropertyUsage];


export function PropertyUsageFromJSON(json: any): PropertyUsage {
    return PropertyUsageFromJSONTyped(json, false);
}

export function PropertyUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyUsage {
    return json as PropertyUsage;
}

export function PropertyUsageToJSON(value?: PropertyUsage | null): any {
    return value as any;
}

