import styled from 'styled-components';
import { LeftArrowIcon } from '@lower-financial/icons/src';
import { IconButton } from '@lower-financial/core-components/src/components/icon-button/icon-button';
import { useNavigate } from 'react-router-dom';
import { usePartnerLogos } from '@lightspeed/hooks/usePartnerStyles/usePartnerLogos';

export const Header = ({
  beforeNavigatingBack = () => {
    // do nothing
  },
  hideBackButton = false,
  ...props
}) => {
  const { logos } = usePartnerLogos();
  const navigate = useNavigate();
  const backButtonClick = () => {
    beforeNavigatingBack();
    navigate(-1);
  };

  return (
    <Container {...props}>
      <HideableIconButton
        $display={!hideBackButton}
        label={'Back'}
        onClick={backButtonClick}
      ><LeftArrowIcon color={'var(--charcoal)'} />
      </HideableIconButton>
      <CompanyLogo>
        <img
          src={logos.logo}
          alt={'Logo'}
        />
      </CompanyLogo>
      <CompanyLogoMobile>
        <img
          src={logos.mobileLogo}
          alt={'Mobile Logo'}
        />
      </CompanyLogoMobile>

      {/* empty div to keep logo centered in flexbox parent */}
      <div />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: var(--mobile-header-height);
  padding: var(--spacing-6);
  box-sizing: border-box;

  ${(props) => props.theme.media.desktop} {
    align-items: center;
    height: var(--desktop-header-height);
  }
`;

const CompanyLogo = styled.div`
  display: none;
  ${(props) => props.theme.media.desktop} {
    display: flex;
    max-height: var(--logo-max-height-desktop);
  }

  > img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const CompanyLogoMobile = styled.div`
  display: flex;
  max-height: var(--logo-max-height-mobile);
  margin: 10px;

  ${(props) => props.theme.media.desktop} {
    display: none;
  }

  > img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const HideableIconButton = styled(IconButton)<{ $display: boolean }>`
  flex-shrink: 0;
  visibility: ${(props) => (props.$display
    ? 'visible'
    : 'hidden')};
`;
