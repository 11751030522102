export const MapPinIcon = ({ color = '#001CDB', ...props }) => (
  <svg
    width={'31'}
    height={'39'}
    viewBox={'0 0 31 39'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={'M15.5 38.1389C15.5 38.1389 31 27.8056 31 15.75C31 11.6391 29.367 7.69666 26.4602 4.78984C23.5533 1.88303 19.6109 0.25 15.5 0.25C11.3891 0.25 7.44666 1.88303 4.53984 4.78984C1.63303 7.69666 0 11.6391 0 15.75C0 27.8056 15.5 38.1389 15.5 38.1389ZM20.6654 15.7499C20.6654 18.6034 18.3522 20.9166 15.4987 20.9166C12.6452 20.9166 10.332 18.6034 10.332 15.7499C10.332 12.8964 12.6452 10.5833 15.4987 10.5833C18.3522 10.5833 20.6654 12.8964 20.6654 15.7499Z'}
      fill={color}
    />
  </svg>

);
