import { useAnalytics } from '@lower-financial/analytics';
import { SecureCallIcon } from '@lower-financial/icons/src/components/secure-call-icon';
import { SecureCallWithoutLogo } from '@lower-financial/icons/src/components/secure-call-without-logo';
import { CircleExclamationIcon } from '@lower-financial/icons/src';
import { ActionButton, TerminalScreenLayout } from '@lightspeed/components/shared/terminal-screen-layout/terminal-screen-layout';
import { useCalendlyUrl } from '@lightspeed/hooks/useCalendlyUrl/useCalendlyUrl';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

const getPageElements = (isBorrowerCreditFrozen: boolean | null) =>
  (isBorrowerCreditFrozen
    ? {
      banner: {
        heading: 'Credit Frozen',
        icon: <CircleExclamationIcon />,
        message:
      <>To move forward with any loan or lender, you’ll need to unfreeze your credit.
        <br />Here at Lower, we use a soft pull to get started, which won’t impact your score.
      </>,
      },
      icon: <SecureCallWithoutLogo />,
      showConfetti: false,
    }
    : {
      banner: undefined,
      icon: <SecureCallIcon />,
      showConfetti: true,
    });

export const Submitted = () => {
  const fireAnalyticsEvent = useAnalytics('lightspeed_submitted');
  const { mortgageApplication: { isBorrowerCreditFrozen } } = useMortgageApplication();
  const pageElements = getPageElements(isBorrowerCreditFrozen);

  const { buildCalendlyUrl } = useCalendlyUrl();

  const scheduleCallButton
    = (
      <ActionButton
        forwardedAs={'a'}
        target={'_blank'}
        href={buildCalendlyUrl().toString()}
        variant={'primary'}
        aria-label={'Schedule call'}
      >Schedule call
      </ActionButton>
    );

  return (
    <TerminalScreenLayout
      heading={<>All set! You’re on the fast track.</>}
      subtext={'Schedule a call with us at a time that works best for you-let\'s chat soon!'}
      iconComponent={pageElements.icon}
      alertBanner={pageElements.banner}
      fireAnalyticsEvent={fireAnalyticsEvent}
      callToAction={scheduleCallButton}
      animations={false}
      showConfetti={pageElements.showConfetti}
    />
  );
};
