import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { EmploymentStatus as EmploymentStatusEnum } from '@lower-financial/core-components';
import { DidYouKnow, HighlightedText } from '@lightspeed/components/shared/banners/did-you-know';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { useMortgageAnalytics } from '@lightspeed/hooks/useMortgageAnalytics/use-mortgage-analytics';
import { CardToggleLayout } from '@lightspeed/components/shared/card-toggle-screen-layout/card-toggle-layout';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { TestimonialSidebar } from '@lightspeed/components/shared/sidebars/testimonial-sidebar';

const employmentStatuses = [
  {
    icon: '🤘',
    label: 'I get a W-2 at tax time.',
    value: EmploymentStatusEnum.W2,
  },
  {
    icon: '✌️',
    label: 'I’m self-employed.',
    value: EmploymentStatusEnum.SELF_EMPLOYED,
  },
  {
    icon: '🙌',
    label: 'I’m retired.',
    value: EmploymentStatusEnum.RETIRED,
  },
  {
    icon: '🤙',
    label: 'I’m in-between things.',
    value: EmploymentStatusEnum.UNEMPLOYED,
  },
];

const EmploymentStatusDidYouKnow = () => (
  <DidYouKnow>
    The specifics about your <HighlightedText>employment</HighlightedText> status
    helps us narrow down what loan works best.
  </DidYouKnow>
);

const EmploymentStatusPurchaseTestimonial = () => (
  <Testimonial
    headingElement={'h2'}
    title={'Can’t beat help from Lower!'}
    content={'I wouldn’t have been able to buy my home without the assistance I received from Lower. Our loan advisor was extremely helpful and knowledgeable, and made the process a breeze for a first time home buyer!'}
    author={'Jillian S'}
  />
);

const EmploymentStatusHelocTestimonial = () => (
  <Testimonial
    headingElement={'h2'}
    title={'Amazing Team'}
    content={'Our HELOC loan process was simple and easy. Lower was available after hours and kept me informed with progress. Thank you for the tireless effort to help close my loan.'}
    author={'Mike'}
  />
);

export const EmploymentStatus = () => {
  const fireAnalyticsEvent = useMortgageAnalytics('employment_status');

  const { mortgageApplication } = useMortgageApplication();

  return (
    <CardToggleLayout
      title={'What kind of job do you have?'}
      subtitle={<>Working nine to five.&nbsp;🎶</>}
      mortgageApplicationField={'borrowerEmploymentStatus'}
      fireAnalyticsEvent={fireAnalyticsEvent}
      cardOptions={employmentStatuses}
      mobileBanner={<EmploymentStatusDidYouKnow />}
      desktopSidebar={(
        <TestimonialSidebar
          testimonial={mortgageApplication.loanPurpose === LoanPurpose.Purchase
            ? <EmploymentStatusPurchaseTestimonial />
            : <EmploymentStatusHelocTestimonial />
          }
          banner={<EmploymentStatusDidYouKnow />}
        />
      )}
    />
  );
};
