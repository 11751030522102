/* eslint-disable sort-keys-fix/sort-keys-fix */
import { createMachine } from 'xstate';
import { guards, GUARDS } from '@lightspeed/routing/guards';
import { ROUTES } from '@lightspeed/routing/routes';
import { NEW_FLOW } from '@lightspeed/routing/new-flow';
import { DEFAULT_ROUTING_CONTEXT, RoutingContext } from '@lightspeed/routing/routing-context';
import { RoutingEvent, ROUTING_EVENTS } from '@lightspeed/routing/routing-events';

export const RoutingMachine = createMachine<RoutingContext, RoutingEvent>(
  {
    id: 'routing',
    initial: ROUTES.PURCHASE,
    context: DEFAULT_ROUTING_CONTEXT,
    predictableActionArguments: true,
    states: {
      [ROUTES.PARTNER_LANDING]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              target: ROUTES.WARM_UP,
              cond: GUARDS.showPartnerWarmUp,
            },
            {
              target: ROUTES.PURCHASE,
              cond: GUARDS.isPartnerPurchase,
            },
            {
              target: ROUTES.REFINANCE,
              cond: GUARDS.isPartnerRefinance,
            },
            {
              target: ROUTES.HELOC,
              cond: GUARDS.isPartnerHeloc,
            },
            {
              target: ROUTES.LANDING,
            },
          ],
        },
      },
      [ROUTES.WARM_UP]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              target: ROUTES.PURCHASE,
              cond: GUARDS.isPartnerPurchase,
            },
            {
              target: ROUTES.REFINANCE,
              cond: GUARDS.isPartnerRefinance,
            },
            {
              target: ROUTES.HELOC,
              cond: GUARDS.isPartnerHeloc,
            },
            {
              target: ROUTES.LANDING,
            },
          ],
        },
      },
      [ROUTES.LANDING]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              target: ROUTES.PURCHASE,
              cond: GUARDS.isPurchase,
            },
            {
              target: ROUTES.REFINANCE,
              cond: GUARDS.isRefinance,
            },
            {
              target: ROUTES.HELOC,
            },
          ],
        },
      },
      [ROUTES.PURCHASE]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              target: ROUTES.PURCHASE_PROPERTY_TYPE,
              cond: {
                type: GUARDS.isFeatureFlagEnabled,
                featureFlagName: 'lightspeedV2Purchase',
                featureFlagValue: 'test',
              },
            },
            {
              target: ROUTES.PURCHASE_BASIC_INFO,
            },
          ],
        },
      },
      [ROUTES.PURCHASE_BASIC_INFO]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              target: ROUTES.UNSUPPORTED_MARKET,
              cond: GUARDS.isUnsupportedMarket,
            },
            {
              target: ROUTES.APPLICATION_OPTIONS,
            },
          ],
        },
      },
      [ROUTES.REFINANCE]: {
        on: {
          [ROUTING_EVENTS.NEXT]: ROUTES.REFINANCE_PROPERTY_TYPE,
        },
      },
      [ROUTES.HELOC]: {
        on: {
          [ROUTING_EVENTS.NEXT]: ROUTES.HELOC_PROPERTY_TYPE,
        },
      },
      [ROUTES.APPLICATION_OPTIONS]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              cond: GUARDS.hasCoBorrower,
              target: ROUTES.BASIC_INFO_COBORROWER,
            },
            {
              target: ROUTES.CURRENT_ADDRESS,
            },
          ],
        },
      },
      [ROUTES.BASIC_INFO_COBORROWER]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              target: ROUTES.CURRENT_ADDRESS,
            },
          ],
        },
      },
      [ROUTES.CURRENT_ADDRESS]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              target: ROUTES.PROPERTY_PURCHASE_IN_CONTRACT,
              cond: GUARDS.inContract,
            },
            {
              target: ROUTES.PROPERTY_PURCHASE_NOT_IN_CONTRACT,
            },
          ],
        },
      },
      [ROUTES.PROPERTY_PURCHASE_IN_CONTRACT]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              target: ROUTES.UNSUPPORTED_MARKET,
              cond: GUARDS.isUnsupportedMarket,
            },
            {
              target: ROUTES.ASSETS,
            },
          ],
        },
      },
      [ROUTES.PROPERTY_PURCHASE_NOT_IN_CONTRACT]: {
        on: {
          [ROUTING_EVENTS.NEXT]: ROUTES.ASSETS,
        },
      },
      [ROUTES.ASSETS]: {
        on: {
          [ROUTING_EVENTS.NEXT]: ROUTES.INCOME,
        },
      },
      [ROUTES.INCOME]: {
        on: {
          [ROUTING_EVENTS.NEXT]: ROUTES.IDENTITY,
        },
      },
      [ROUTES.IDENTITY]: {
        on: {
          [ROUTING_EVENTS.NEXT]: [
            {
              target: ROUTES.JUMPSTART_PROCESSING,
            },
          ],
        },
      },
      [ROUTES.UNSUPPORTED_MARKET]: {},
      ...NEW_FLOW,
    },
  },
  {
    guards,
  },
);
