import {
  UserAnalyticsSessionInputDto,
} from '@lower-financial/lending-web-api/generated';
import { EmptyResponse, fetch } from '@lower-financial/toolbox';
import { ENV } from '@lightspeed/environment';

export function saveUserAnalyticsSession(input: UserAnalyticsSessionInputDto) {
  return fetch<SaveUserAnalyticsSessionResponse>(
    `${ENV.BACKEND_API_HOST}/api/lending/user_analytics_sessions`,
    {
      body: JSON.stringify(input),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
}

type UserAnalyticsSessionInterface = EmptyResponse;

export type SaveUserAnalyticsSessionResponse = UserAnalyticsSessionInterface;
