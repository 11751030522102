import { useEffect } from 'react';
import { CurrencyRangeCategory, LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { useErrorHandling } from '@lower-financial/core-components';
import { DidYouKnow, HighlightedText } from '@lightspeed/components/shared/banners/did-you-know';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { useMortgageAnalytics } from '@lightspeed/hooks/useMortgageAnalytics/use-mortgage-analytics';
import { CardToggleLayout } from '@lightspeed/components/shared/card-toggle-screen-layout/card-toggle-layout';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { TestimonialSidebar } from '@lightspeed/components/shared/sidebars/testimonial-sidebar';
import { useCurrencyRanges } from '@lightspeed/contexts/currency-ranges-context/currency-ranges-context';

const YearlyIncomeDidYouKnow = () => (
  <DidYouKnow>
    An estimate of your household <HighlightedText>annual income</HighlightedText> before taxes
    helps us calculate a few key numbers on our end.
  </DidYouKnow>
);

const YearlyIncomePurchaseTestimonial = () => (
  <Testimonial
    title={'Our experience was great with Lower!'}
    content={'Our experience was great with Lower! Our loan advisor and his team worked to get our loan approved through a quick closing process responding to us and our realtor quickly and processing our paperwork flawlessly. Lower has great customer service all around and because of that we were able to purchase our dream home.'}
    author={'Nathan Downing'}
    date={'2022-10-27'}
  />
);

const YearlyIncomeHelocTestimonial = () => (
  <Testimonial
    title={'Fantastic Experience'}
    content={'We tried a lot of lenders before finding Lower. They were extremely professional, diligent, knowledgeable, and experts. Thanks again for our home equity loan and great experience.'}
    author={'Luis Rosa'}
    date={'2022-08-10'}
  />
);

export const YearlyIncome = () => {
  const fireAnalyticsEvent = useMortgageAnalytics('yearly_income');

  const { mortgageApplication } = useMortgageApplication();

  const { currencyRanges, isLoading } = useCurrencyRanges();
  const handleError = useErrorHandling();

  const rangeOptions = (currencyRanges ?? [])
    .sort((a, b) => a.minValue - b.minValue)
    .filter((range) => range.category === CurrencyRangeCategory.AnnualIncome).map((range) => ({
      label: range.label,
      value: range.id,
    }));

  useEffect(() => {
    if (rangeOptions.length === 0 && !isLoading) {
      throw new Error('No annual income currency ranges found for yearly income');
    }
  }, [handleError, isLoading, rangeOptions.length]);

  return (
    <CardToggleLayout
      title={'About how much does your household make?'}
      subtitle={<>No need to break out the paystubs yet.&nbsp;💯</>}
      mortgageApplicationField={'borrowerYearlyIncomeRangeId'}
      fireAnalyticsEvent={fireAnalyticsEvent}
      cardOptions={rangeOptions}
      mobileBanner={<YearlyIncomeDidYouKnow />}
      desktopSidebar={(
        <TestimonialSidebar
          testimonial={mortgageApplication.loanPurpose === LoanPurpose.Purchase
            ? <YearlyIncomePurchaseTestimonial />
            : <YearlyIncomeHelocTestimonial />
          }
          banner={<YearlyIncomeDidYouKnow />}
        />
      )}
    />
  );
};
