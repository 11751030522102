import { useCallback } from 'react';
import { buildCalendlyUrl as buildCalendlyUrlBase, STATE_NAME_TO_ABBREVIATION } from '@lower-financial/toolbox/src';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { ENV } from '@lightspeed/environment';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

export function useCalendlyUrl() {
  const { mortgageApplication } = useMortgageApplication();

  const buildCalendlyUrl = useCallback(() => buildCalendlyUrlBase(
    ENV.CALENDLY_PREFILL_URL,
    null,
    {
      email: mortgageApplication.borrowerEmail,
      firstName: mortgageApplication.borrowerFirstName,
      lastName: mortgageApplication.borrowerLastName,
      phoneNumber: mortgageApplication.borrowerPhoneNumber,
      state: STATE_NAME_TO_ABBREVIATION.get(mortgageApplication.borrowerState) ?? null,
    },
    'lightspeed',
    mortgageApplication.loanPurpose === null || mortgageApplication.loanPurpose === LoanPurpose.Purchase,
  ), [
    mortgageApplication.borrowerEmail,
    mortgageApplication.borrowerFirstName,
    mortgageApplication.borrowerLastName,
    mortgageApplication.borrowerPhoneNumber,
    mortgageApplication.borrowerState,
    mortgageApplication.loanPurpose,
  ]);

  return {
    buildCalendlyUrl,
  };
}
