import { ChevronRight } from '@lower-financial/icons';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { Separator } from '../separator';

export const ListItem = ({ label, onClick }: {
  label: string,
  onClick: MouseEventHandler
}) => (
  <ListItemContainer>
    <ListItemButton
      onClick={onClick}
      aria-label={label}
    >
      <StyledText>{label}</StyledText>
      <ChevronRight />
    </ListItemButton>
    <Separator horizontal />
  </ListItemContainer>
);

const ListItemContainer = styled.div`
  width: 100%;
`;

const ListItemButton = styled.button({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: 'auto',
  paddingBottom: '1.5rem',
  paddingTop: '1.5rem',
  width: '100%',
});

const StyledText = styled.span({
  font: 'var(--font-bold-item)',
});
