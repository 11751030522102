import styled from 'styled-components';
import { CheckIcon } from '@lower-financial/icons';
import { OptionalElement } from '../optional-element';

export enum Progress {
  Incomplete = 'Incomplete',
  InProgress = 'In progress',
  UpNext = 'Up next',
  Complete = 'Complete',
}

export const ProgressIcon = ({ progress }: { progress: Progress }) => (
  <ProgressIconContainer
    progress={progress}
    aria-label={progress}
  >
    <OptionalElement show={progress === Progress.Complete} >
      <CheckIcon color={'var(--white)'} />
    </OptionalElement>
  </ProgressIconContainer>
);

const ProgressIconContainer = styled.div<{ progress: Progress }>`
  border-radius: 50%;
  border: ${(props) => {
    switch (props.progress) {
    case Progress.Incomplete:
      return 'unset';
    case Progress.InProgress:
      return '7px solid var(--chalk)';
    case Progress.UpNext:
      return 'unset';
    default:
      return 'unset';
    }
  }};
  background-color: ${(props) => {
    switch (props.progress) {
    case Progress.Incomplete:
      return 'var(--chalk)';
    case Progress.InProgress:
      return 'var(--success)';
    case Progress.UpNext:
      return 'var(--silk)';
    default:
      return 'var(--chalk)';
    }
  }};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    height: 0.5rem;
  }
`;
