import { Button, ButtonProps, responsiveStyleFromTheme } from '@lower-financial/core-components';
import styled from 'styled-components';

const defaultLabel = 'Next step';

export function NextStepButton({
  isLoading,
  disabled,
  onClick,
  label = defaultLabel,
  ariaLabel = label,
}: {
  isLoading: ButtonProps['isLoading'],
  disabled: ButtonProps['disabled'],
  onClick: ButtonProps['onClick'],
  label?: string,
  ariaLabel?: ButtonProps['aria-label'],
}) {
  return (
    <StyledButton
      aria-label={ariaLabel}
      isLoading={isLoading}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  width: 100%;
  ${responsiveStyleFromTheme({
    desktop: {
      margin: 'var(--spacing-4) 0',
      width: '240px',
    },
  })}
`;
