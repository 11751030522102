import { CheckPeriodIcon, LightningIcon, HeartIcon } from '@lower-financial/icons/src';
import { useAnalytics } from '@lower-financial/analytics';
import { WarmUpTemplate } from '@lightspeed/components/ui/pages/warm-up/warm-up-template';
import { usePartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';

const helocBenefits = [
  {
    descriptionText: 'Access as much of your equity as possible—up to $500k with Lower.',
    headerText: 'Unlock Up to 95% LTV',
    icon: <LightningIcon color={'var(--primary)'} />,
  },
  {
    descriptionText: 'Seeing if you qualify is super easy and won’t impact your credit.',
    headerText: 'Zero Credit Impact',
    icon: <CheckPeriodIcon color={'var(--primary)'} />,
  },
  {
    descriptionText: 'A super low monthly payment so you can use that extra cash to get to a better place.',
    headerText: 'Pay Only interest',
    icon: <HeartIcon color={'var(--primary)'} />,
  },
];

export const HelocWarmUp = () => {
  const { partnerConfiguration: { displayName } } = usePartnerConfiguration();
  const fireAnalyticsEvent = useAnalytics('heloc_warm_up');

  return (
    <WarmUpTemplate
      explanationText={`We’re Lower. We teamed up with ${displayName ?? ''} to help you renovate, pay off high-interest debt, or keep it for the next rainy day.`}
      benefits={helocBenefits}
      reviewText={'Lower helped our family with a HELOC and was extremely friendly, knowledgeable, and responsive. Despite a rapidly changing market, they guided us every step of the way.'}
      reviewAuthor={'Dakota M.'}
      backgroundImagePath={'../../../../../static/assets/warm-up-backgrounds/heloc-warm-up.jpg'}
      fireAnalyticsEvent={fireAnalyticsEvent}
    />
  );
};
