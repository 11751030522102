import { useEffect } from 'react';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

export const useSyncCurrentAddressWithPropertyAddress = () => {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();

  useEffect(() => {
    if (mortgageApplication.propertyResidenceType === 'Primary Residence' && mortgageApplication.loanPurpose !== LoanPurpose.Purchase) {
      updateMortgageApplication(
        {
          borrowerAddressAutocompleteValue: mortgageApplication.propertyAutocompleteValue,
          borrowerCity: mortgageApplication.propertyCity,
          borrowerCounty: mortgageApplication.propertyCounty,
          borrowerState: mortgageApplication.propertyState,
          borrowerStreetAddress: mortgageApplication.propertyStreetAddress,
          borrowerZipCode: mortgageApplication.propertyZipCode,
        },
      );
    }
  }, [
    mortgageApplication.loanPurpose,
    mortgageApplication.propertyAutocompleteValue,
    mortgageApplication.propertyCity,
    mortgageApplication.propertyCounty,
    mortgageApplication.propertyResidenceType,
    mortgageApplication.propertyState,
    mortgageApplication.propertyStreetAddress,
    mortgageApplication.propertyZipCode,
    updateMortgageApplication,
  ]);
};
