import styled from 'styled-components';
import { ComponentProps, useMemo } from 'react';
import { isEqual } from 'lodash';
import { responsiveStyleFromTheme } from '../../styles/utils/theme-utils';
import { CardToggle } from '../card-toggle/card-toggle';

interface Item<TValue> {
  label: ComponentProps<typeof CardToggle>['label'];
  value: TValue;
  icon?: ComponentProps<typeof CardToggle>['icon'];
}

export function CardRadioGroup<TValue>({
  label, items, value, onChange, tabIndex = 0, disabled = false,
}: {
  label: string,
  items: Item<TValue>[],
  // we want value to be a required prop, but it should also be allowed to be set to undefined
  value: TValue | undefined,
  onChange: (val: TValue) => void,
  tabIndex?: number,
  disabled?: boolean,
}) {
  const handleChange = (newVal: TValue) => {
    onChange(newVal);
  };

  // using a memo here since isEqual can be expensive on large objects
  const isChecked = useMemo(() => items.map((i) => isEqual(i.value, value)), [items, value]);

  return (
    <Container
      role={'radiogroup'}
      aria-label={label}
    >
      {items.map((item, index) => (
        <CardToggle
          variant={'radio'}
          role={'radio'}
          label={item.label}
          checked={isChecked[index]}
          onChange={() => handleChange(item.value)}
          icon={item.icon}
          key={`card-radio-${index}`}
          tabIndex={tabIndex}
          disabled={disabled}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  
  > div {
    width: 100%;
  }
  
  ${responsiveStyleFromTheme({
    desktop: {
      '> div': {
        width: 'calc(50% - 1.25rem)',
      },
      gap: '1.5rem',
    },
  })}
`;
