import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { LoadingIcon, OptionalElement, useErrorHandling } from '@lower-financial/core-components';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { useAnalytics } from '@lower-financial/analytics/src/useAnalytics';
import { CardRadioGroup } from '@lower-financial/core-components/src/components/card-radio-group/card-radio-group';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import { PageLayout, PageLayoutProps } from '@lightspeed/components/shared/page-layout/page-layout';

const wait = async (ms = 1000) =>  new Promise((resolve) => {
  setTimeout(resolve, ms);
});

type CardOption<TField extends keyof MortgageApplicationStore> = {
  icon?: string,
  label: string,
  value:  MortgageApplicationStore[TField],
};

export function CardToggleLayout<TField extends keyof MortgageApplicationStore>({
  hideBackButton = false,
  title,
  subtitle,
  mortgageApplicationField,
  cardOptions,
  fireAnalyticsEvent,
  desktopSidebar,
  mobileBanner,
}: {
  mortgageApplicationField: TField,
  cardOptions: readonly CardOption<TField>[],
  fireAnalyticsEvent: ReturnType<typeof useAnalytics>,
} & Pick<PageLayoutProps, 'hideBackButton' | 'title' | 'subtitle' | 'desktopSidebar' | 'mobileBanner'>) {
  const { updateMortgageApplication, mortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const handleError = useErrorHandling();

  const { upsertApplication } = useUpsertApplication();
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const submissionExecutedRef = useRef(false);

  const updateField = (value: MortgageApplicationStore[TField]) => {
    updateMortgageApplication(mortgageApplicationField, value);
    setFormSubmitted(true);
  };

  useEffect(() => {
    // when upsertApplication creates a new application, it will update the mortgage application
    // context with the ID it receives from the response. This will cause upsertApplication to
    // change since it depends on mortgageApplication, which will cause this useEffect to run a second time
    if (formSubmitted && !submissionExecutedRef.current) {
      submissionExecutedRef.current = true;
      (async () => {
        setIsLoading(true);

        await handleError({
          onRetryableError: () => {
            setIsLoading(false);
            submissionExecutedRef.current = false;
            setFormSubmitted(false);
          },
          tryFn: async () => {
            // we want to show the selected option as check for some time to provide
            // feed back to the user that their choice registered
            await Promise.all([wait(500), upsertApplication()]);

            fireAnalyticsEvent('next_success');
            setIsLoading(false);
            goToNextRoute();
          },
        });
      })();
    }
  }, [formSubmitted, setIsLoading, upsertApplication, fireAnalyticsEvent, goToNextRoute, handleError]);

  const cardCheckboxGroupItems = cardOptions.map((cardOption) => ({
    checked: mortgageApplication[mortgageApplicationField] === cardOption.value,
    disabled: isLoading,
    onChange: () => updateField(cardOption.value),
    ...cardOption,
  }));

  return (
    <Layout>
      <OptionalElement show={isLoading}>
        <LoadingIconContainer>
          <LoadingIconBackground>
            <LoadingIcon
              spinnerHeight={'100%'}
            />
          </LoadingIconBackground>
        </LoadingIconContainer>
      </OptionalElement>
      <PageLayout
        hideBackButton={hideBackButton}
        title={title}
        subtitle={subtitle}
        desktopSidebar={desktopSidebar}
        mobileBanner={mobileBanner}
      >
        <CardRadioGroup
          disabled={isLoading}
          label={title?.toString() ?? 'Choose an option'}
          items={cardCheckboxGroupItems}
          onChange={updateField}
          value={mortgageApplication[mortgageApplicationField]}
        />
      </PageLayout>
    </Layout>
  );
}

const LoadingIconBackground = styled.div`
  background: var(--white);
  border-radius: var(--radius-full);
  border: 1px solid var(--chalk);
  box-shadow: 0 var(--spacing-3) var(--spacing-6) 0 rgba(0, 0, 0, 0.2);
  height: var(--spacing-10);
  width: var(--spacing-10);
  padding: var(--spacing-2);
  margin: auto;
`;

const LoadingIconContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 80px;
`;
