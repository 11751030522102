import styled from 'styled-components';
import { LoadingIcon } from '@lower-financial/core-components';

export const LoadingSpinner = ({ ...props }) => (
  <div {...props}>
    <LoadingIconBackground>
      <LoadingIcon
        spinnerHeight={'100%'}
      />
    </LoadingIconBackground>
  </div>
);

const LoadingIconBackground = styled.div`
  background: var(--white);
  border-radius: var(--radius-full);
  border: 1px solid var(--chalk);
  box-shadow: 0 var(--spacing-3) var(--spacing-6) 0 rgba(0, 0, 0, 0.2);
  height: var(--spacing-10);
  width: var(--spacing-10);
  padding: var(--spacing-2);
  margin: auto;
`;
