import { useEffect, useState } from 'react';
import { getAutocompletePredictions } from '@lower-financial/core-components';

export function useAddressAutocomplete(search: string, apiKey: string, appEnv: string, debounceMillis = 300) {
  const [results, setResults] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search === '') {
      setResults([]);
      setLoading(false);
      return;
    }
    if (search.length < 3) {
      return;
    }

    let cancelled = false;
    const timeout = setTimeout(() => {
      (async () => {
        setLoading(true);
        await getAutocompletePredictions(search, (response) => {
          setLoading(false);
          if (!cancelled) {
            setResults(response ?? []);
          }
        }, apiKey, appEnv);
      })();
    }, debounceMillis);

    return () => {
      cancelled = true;
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, debounceMillis]);

  return [loading, results] as const;
}
