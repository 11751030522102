import { useEffect } from 'react';
import { CurrencyRangeCategory } from '@lower-financial/lending-web-api/generated';
import { DidYouKnow, HighlightedText } from '@lightspeed/components/shared/banners/did-you-know';
import { useMortgageAnalytics } from '@lightspeed/hooks/useMortgageAnalytics/use-mortgage-analytics';
import { CardToggleLayout } from '@lightspeed/components/shared/card-toggle-screen-layout/card-toggle-layout';
import { TestimonialSidebar } from '@lightspeed/components/shared/sidebars/testimonial-sidebar';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { useCurrencyRanges } from '@lightspeed/contexts/currency-ranges-context/currency-ranges-context';

const PurchasePriceDidYouKnow = () => (
  <DidYouKnow>
    <HighlightedText>Home value</HighlightedText> is key helping us find the best loan
    for what you need.
  </DidYouKnow>
);

const PurchasePriceTestimonial = () => (
  <Testimonial
    title={'Helpful in guiding us through.'}
    content={'Lower was helpful in guiding us through our first time buying a home in which we knew next to nothing about what we were doing. Our loan advisor was able to get us a good rate and we closed on time. Thank you!'}
    author={'Erin Perko'}
  />
);

export const PurchasePrice = () => {
  const fireAnalyticsEvent = useMortgageAnalytics('purchase_price');
  const { currencyRanges, isLoading } = useCurrencyRanges();

  const rangeOptions = (currencyRanges ?? [])
    .sort((a, b) => a.minValue - b.minValue)
    .filter((range) => range.category === CurrencyRangeCategory.PropertyValue).map((range) => ({
      label: range.label,
      value: range.id,
    }));

  useEffect(() => {
    if (rangeOptions.length === 0 && !isLoading) {
      throw new Error('No property values currency ranges found for purchase price');
    }
  }, [isLoading, rangeOptions.length]);

  return (
    <CardToggleLayout
      title={'About how much do you want to spend?'}
      subtitle={<>A ballpark home price works for now.&nbsp;💵</>}
      mortgageApplicationField={'propertyPurchasePriceRangeId'}
      fireAnalyticsEvent={fireAnalyticsEvent}
      cardOptions={rangeOptions}
      mobileBanner={<PurchasePriceDidYouKnow />}
      desktopSidebar={(
        <TestimonialSidebar
          testimonial={<PurchasePriceTestimonial />}
          banner={<PurchasePriceDidYouKnow />}
        />
      )}
    />
  );
};
