export const CellPhoneHomeIcon = ({
  color = 'var(--primary)', withCheckmark = true, label = 'Cell phone icon', ...props
}) => (
  <svg
    viewBox={'0 0 107 107'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>{label}</title>
    <circle
      cx={'50%'}
      cy={'50%'}
      r={'50%'}
      fill={'var(--tertiary-1)'}
      fillOpacity={'0.1'}
    />
    <rect
      x={'22.5306'}
      y={'21.5066'}
      width={'61.9389'}
      height={'101.618'}
      rx={'9.91022'}
      fill={'white'}
    />
    <path
      d={'M28.312 84.3057H71.0498'}
      stroke={'var(--tertiary-1)'}
      strokeOpacity={'0.1'}
      strokeWidth={'3.71633'}
    />
    <path
      d={'M27.6924 92.3582H54.9455'}
      stroke={'var(--tertiary-1)'}
      strokeOpacity={'0.1'}
      strokeWidth={'3.71633'}
    />
    <circle
      cx={'31.8221'}
      cy={'31.5525'}
      r={'4.33572'}
      fill={'var(--tertiary-1)'}
      fillOpacity={'0.1'}
    />
    <rect
      x={'27.4863'}
      y={'41.1826'}
      width={'52.0287'}
      height={'34.6858'}
      rx={'2.47756'}
      fill={'var(--tertiary-1)'}
      fillOpacity={'0.1'}
    />
    <path
      d={'M47.3062 56.5263C47.3062 56.0873 47.5107 55.6733 47.8595 55.4066L52.6438 51.748C53.1492 51.3616 53.8509 51.3616 54.3563 51.748L59.1406 55.4066C59.4894 55.6733 59.6939 56.0873 59.6939 56.5263V63.8086C59.6939 64.1978 59.3784 64.5134 58.9891 64.5134H55.0485H51.9516H48.0109C47.6217 64.5134 47.3062 64.1978 47.3062 63.8086V56.5263Z'}
      fill={color}
    />
    {withCheckmark && (
      <circle
        cx={'80.1688'}
        cy={'27.5285'}
        r={'10.3231'}
        fill={'var(--success)'}
      />
    ) || undefined}
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={'M83.0405 24.7756L84.2981 25.9361L79.2788 31.6577L76.0396 28.5165L77.2018 27.2558L79.181 29.1751L83.0405 24.7756Z'}
      fill={'white'}
    />
  </svg>
);
