import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  type?: Required<JSX.IntrinsicElements['button']['type']>;
  onClick?: MouseEventHandler;
  label: string;
  children: ReactNode;
}

export const IconButton = ({
  type = 'button',
  onClick = () => {
    // do nothing
  },
  label,
  children,
  ...props
}: Props) => (
  <StyledButton
    aria-label={label}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </StyledButton>
);

const StyledButton = styled.button({
  '&:hover, &:focus': {
    background: 'var(--icon-button-background)',
  },

  '> svg, > img': {
    height: 'calc(0.4 * var(--icon-button-size))',
    padding: 0,
    width: 'calc(0.4 * var(--icon-button-size))',
  },

  alignItems: 'center',
  borderRadius: 36,
  display: 'flex',
  height: 'var(--icon-button-size)',
  justifyContent: 'center',
  padding: 0,
  transition: 'background 0.2s ease-in-out',
  width: 'var(--icon-button-size)',
});
