import styled from 'styled-components';
import { FontWeightProps, LayoutProps, SpaceProps } from 'styled-system';
import React, { ComponentProps } from 'react';

const ButtonGroupWrapper = styled.div`
  background-color: var(--primary);
  width: 324px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 0px 14px 36px var(--box-shadow);
  margin-top: 39px;
  margin-bottom: 38px;
  align-items: center;
  div:nth-of-type(3) {
    display: none !important;
  }
  button:nth-of-type(1) {
    border: 0px;
    background-color: var(--primary);
    border-radius: 30px 0px 0px 30px;
    height: 4em;
    width: 8em;
    cursor: pointer;
  }
  button:nth-of-type(2) {
    border: 0px;
    background-color: var(--primary);
    border-radius: 0;
    height: 4em;
    width: 8em;
    cursor: pointer;
  }
  button:nth-of-type(3) {
    border: 0px;
    background-color: var(--primary);
    border-radius: 0px 30px 30px 0px;
    height: 4em;
    width: 8em;
    cursor: pointer;
  }
`;

const ButtonLink = styled.button`
  font: var(--font-bold-item);
`;

const Divider = styled.div`
  border: 1px solid;
  border-color: var(--primary-hover);
  width: 0px;
  height: 44px;
  margin: 0px;
  padding: 0px;
`;

type ButtonLinkProps = {
  buttonLabel: string;
  link: string;
};

type ButtonGroupComponentProps = {
  buttons: ButtonLinkProps[];
  onClick: (button: ButtonLinkProps) => void;
} & FontWeightProps & LayoutProps & SpaceProps;

export function ButtonGroup({
  buttons, onClick, ...props
}: ButtonGroupComponentProps) {
  /**
   * In general, we should not call useNavigate directly. We should
   * prefer useNextRoute, but the routing machine code was weird
   * here due to the nature of asyncronous setStates in the
   * navigate functions.  We chose to just navigate instead
   * here to keep things simple.
   */
  return (
    <div>
      <ButtonGroupWrapper>
        {buttons.map((button) => (
          <React.Fragment key={button.buttonLabel}>
            <ButtonLink
              style={{
                color: 'var(--white)',
                display: 'flex !important',
              }}
              onClick={() => onClick(button)}
            >
              {button.buttonLabel}
            </ButtonLink>
            <Divider />
          </React.Fragment>
        ))}
      </ButtonGroupWrapper>
    </div>
  );
}

export type ButtonGroupProps = ComponentProps<typeof ButtonGroup>;
