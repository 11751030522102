import { useEffect } from 'react';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { MORTGAGE_APPLICATION_DEFAULTS } from '@lightspeed/utils/mortgage-application-defaults';

export function useMortgageContextDefault(key: keyof MortgageApplicationStore, shouldSet = true) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();

  useEffect(() => {
    if (!MORTGAGE_APPLICATION_DEFAULTS[key]) {
      throw new Error('Attempted to set default mortgage application value, but no default is defined.');
    }

    if (shouldSet && mortgageApplication[key] === '') {
      const defaultValue = MORTGAGE_APPLICATION_DEFAULTS[key];
      updateMortgageApplication(key, defaultValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
