import styled from 'styled-components';
import { ReactNode, ComponentProps } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.space[4]};
  min-height: calc(var(--vh) * 100);
  box-sizing: border-box;
`;

const Content = styled.section`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: ${(props) => props.theme.contentWidth};
`;

interface GenericTemplateComponentProps {
  children: ReactNode;
}

export const GenericTemplate = ({ children, ...props }: GenericTemplateComponentProps) => (
  <Wrapper {...props}>
    <Content>{children}</Content>
  </Wrapper>
);

export type GenericTemplateProps = ComponentProps<typeof GenericTemplate>;
