export enum FeatureFlagKeys {
  lightspeedV2Purchase = 'lightspeedV2Purchase',
  onlyScheduleCallButton = 'onlyScheduleCallButton',
}

export const FeatureFlagValues = {
  [FeatureFlagKeys.lightspeedV2Purchase]: ['control', 'test'],
  [FeatureFlagKeys.onlyScheduleCallButton]: ['control', 'test'],
} as const;  // `as const` to make it immutable (which helps with type inference)

export const FeatureFlagDefaultValues: FeatureFlags = {
  [FeatureFlagKeys.lightspeedV2Purchase]: 'test',
  [FeatureFlagKeys.onlyScheduleCallButton]: 'control',
} as const;

type RestrictedFeatureFlags = {
  [key in FeatureFlagKeys]: typeof FeatureFlagValues[key][number];
};

export type FeatureFlags = RestrictedFeatureFlags;
export type FeatureFlagVariant<TKey extends FeatureFlagKeys> = typeof FeatureFlagValues[TKey][number];

export const isValidFlagValue = <TKey extends FeatureFlagKeys>(flag: TKey, value: string | boolean):
  value is FeatureFlagVariant<TKey> => FeatureFlagValues[flag].some((v) => v === value);
