import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from '../loading-spinner';
import { StandardModal } from './standard-modal';

type Props = Pick<ComponentProps<typeof StandardModal>, 'heading' | 'onCloseModal' | 'isOpen' | 'fitContent'>;

export const LoadingModal = ({
  heading,
  ...standardModalProps
}: Props) => (
  <StandardModal
    {...standardModalProps}
    heading={heading}
  >
    <LoadingSpinnerContainer>
      <LoadingSpinner />
    </LoadingSpinnerContainer>
  </StandardModal>
);

const LoadingSpinnerContainer = styled.div`
  padding: 2rem;
`;
