import { OptionalElement } from '@lower-financial/core-components/src';
import styled from 'styled-components';
import { ReactNode } from 'react';

export function TestimonialSidebar({
  testimonial,
  banner,
}: {
  testimonial: ReactNode | ReactNode[],
  banner?: ReactNode | ReactNode[],
}) {
  return (
    <>
      <OptionalElement show={banner !== undefined}>
        {banner}
        <HorizontalRule />
      </OptionalElement>
      {testimonial}
    </>
  );
}

const HorizontalRule = styled.hr`
  border-top-color: var(--silk);
  margin: var(--spacing-6) 0;
`;
