import { Button, Heading, Paragraph, responsiveStyleFromTheme } from '@lower-financial/core-components/src';
import { GoldMedal, LockIcon } from '@lower-financial/icons/src';
import styled from 'styled-components';
import { useAnalytics } from '@lower-financial/analytics';
import { ComponentProps, useEffect, useState } from 'react';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { IconList } from '@lightspeed/components/ui/organisms/icon-list/icon-list';
import { PartnerBanner } from '@lightspeed/components/ui/pages/warm-up/partner-banner';
import { ImagePanel } from '@lightspeed/components/ui/pages/warm-up/image-panel';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { getPartnerLogos } from '@lightspeed/assets/partners/partners-config';

const largeDesktopBreakpoint = '1300px';

export const WarmUpTemplate = ({
  explanationText, benefits, reviewText, reviewAuthor, backgroundImagePath, fireAnalyticsEvent,
}: {
  explanationText: string,
  benefits: ComponentProps<typeof IconList>['iconItems'],
  reviewText: string,
  reviewAuthor: string,
  backgroundImagePath: string,
  fireAnalyticsEvent: ReturnType<typeof useAnalytics>,
}) => {
  const { partnerConfiguration } = usePartnerConfiguration();
  const { displayName, isPartnerExperience } = partnerConfiguration;
  const { goToNextRoute } = useNextRoute();
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    // redirecting if someone comes to the warm up screen outside of a partner flow
    // redirect is to ensure a single redirect, this is particularly an issue in tests
    if (!isPartnerExperience && !redirected) {
      setRedirected(true);
      goToNextRoute();
    }
  }, [isPartnerExperience, goToNextRoute, redirected]);

  const startFlow = () => {
    fireAnalyticsEvent('get_started_clicked');
    goToNextRoute();
  };

  return (
    <PageContainer>
      <Header>
        <HeaderSpacer />
        <div>POWERED BY LOWER.COM</div>
      </Header>
      <BodyContainer>
        <InfoContainer>
          <PartnerBannerHeader>
            <GoldMedalIcon src={GoldMedal} />
            <PartnerHeaderText>{displayName}</PartnerHeaderText>
            <Divider />
            <PartnerHeaderText>
              Preferred Partner
            </PartnerHeaderText>
          </PartnerBannerHeader>
          <InfoBodyContainer>
            <ContentContainer>
              <img
                alt={'Lower logo'}
                src={getPartnerLogos('lower').logo}
              />
              <WelcomeHeader
                element={'h1'}
                size={'lg'}
              >Hey there, nice to meet you.&nbsp;👋
              </WelcomeHeader>
              <WelcomeParagraph variant={'regular'}>
                {explanationText}
              </WelcomeParagraph>
              <IconList
                headerType={'h2'}
                iconItems={benefits}
              />
              <StyledButton
                onClick={startFlow}
              >Get started
              </StyledButton>
            </ContentContainer>
            <PartnerBannerContainer>
              <PartnerBanner color={'var(--partner-warm-up-banner-color)'} />
            </PartnerBannerContainer>
          </InfoBodyContainer>
          <Footer>
            <FooterContent>
              <LockIcon color={'var(--charcoal)'} />
              <FooterText>
                <FooterBold>
                  Your privacy, ensured.
                </FooterBold>
                <FooterLight>Industry leading data security</FooterLight>
              </FooterText>
            </FooterContent>
          </Footer>
        </InfoContainer>
        <ImagePanel
          reviewText={reviewText}
          reviewAuthor={reviewAuthor}
          backgroundImagePath={backgroundImagePath}
        />
      </BodyContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh) * 100);
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--ash);
  font: var(--font-powered-by-lower-mobile);
  padding: var(--spacing-6) 0;
  border-bottom: 1px solid var(--silk);
  text-align: center;

  ${responsiveStyleFromTheme({
    desktop: {
      font: 'var(--font-powered-by-lower-desktop)',
      padding: '24px 48px',
    },
  })}
`;

const Footer = styled.footer`
  width: 100%;
  background: var(--chalk);
`;

const FooterContent = styled.div`
  display: flex;
  gap: var(--spacing-2);
  align-items: flex-start;
  padding: var(--spacing-6) 0 var(--spacing-6) 24px;

  ${responsiveStyleFromTheme({
    desktop: {
      alignItems: 'center',
      padding: 'var(--spacing-6) 0 var(--spacing-6) 100px',
    },
  })}
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${responsiveStyleFromTheme({
    desktop: {
      flexDirection: 'row',
    },
  })}
`;

const InfoContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InfoBodyContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 40px 32px 40px 24px;

  ${responsiveStyleFromTheme({
    desktop: {
      padding: '60px 72px 52px 100px',
    },
  })}

  @media (min-width: ${largeDesktopBreakpoint}) {
    padding-right: 0;
  }
`;

const PartnerBannerContainer = styled.div` 
  display: none;

  @media (min-width: ${largeDesktopBreakpoint}) {
    display: flex;
    justify-content: center;
    width: 176px;
    height: 100%;
  }
`;

const PartnerBannerHeader = styled.header`
  width: 100%;
  padding: var(--spacing-4) 0;
  background: var(--partner-warm-up-banner-color);
  color: var(--white);
  display: flex;
  align-items: center;
  padding-left: var(--spacing-5);
  gap: 8px;

  @media (min-width: ${largeDesktopBreakpoint}) {
    display: none;
  }
`;

const PartnerHeaderText = styled.div`
  font: var(--font-label-bold-1);
`;

const GoldMedalIcon = styled.img`
  height: 24px;
  margin-right: 8px;
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background: var(--ghost);
  opacity: 0.5;
`;

const WelcomeHeader = styled(Heading)`
  padding-top: var(--spacing-4);
`;

const WelcomeParagraph = styled(Paragraph)`
  color: var(--ash);
  padding: var(--spacing-4) 0 var(--spacing-8) 0;
`;

const StyledButton = styled(Button)`
  width: 100%;
  
  ${responsiveStyleFromTheme({
    desktop: {
      width: 'auto',
    },
  })}
`;

const FooterText = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  ${responsiveStyleFromTheme({
    desktop: {
      flexDirection: 'row',
    },
  })}
`;

const FooterBold = styled.span`
  font: var(--font-label-bold-1);
  color: var(--charcoal);
  padding-right: var(--spacing-1);
  display: flex;
  align-items: center;
`;

const FooterLight = styled.span`
  font: var(--font-label-1);  
  color: var(--ash);
`;

// invisible flex item
const HeaderSpacer = styled.div`
  width: var(--icon-button-size);
  height: var(--icon-button-size);
`;
