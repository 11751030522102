import { ObjectSchema, ValidationError } from 'yup';
import { useState } from 'react';
import { ObjectShape } from 'yup/lib/object';
import { SchemaLike } from 'yup/lib/types';

export type ValidationResultSuccess = {
  success: true;
  errors?: never;
};

export type ValidationResultError = {
  success: false;
  errors: Map<string, string[]>
};

export type ValidationResult = ValidationResultSuccess | ValidationResultError;

interface ValidationOptions<TValidationContext> {
  keysToValidate?: string[];
  yupValidationContext?: TValidationContext;
}

export function useValidation<TSchema extends ObjectShape, TValidationContext extends Record<string, unknown>>(
  schema: ObjectSchema<TSchema>,
  options: ValidationOptions<TValidationContext> = {},
) {
  const [errors, setErrors] = useState<Map<string, string[]> | null>(null);

  const validate = (objectToValidate: unknown): ValidationResult => {
    // eslint-disable-next-line rulesdir/no-try-catch
    try {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const validationSchema = (options.keysToValidate
        ? schema.pick(options.keysToValidate)
        : schema) as SchemaLike;

      validationSchema.validateSync(
        objectToValidate,
        {
          abortEarly: false,
          context: options.yupValidationContext,
        },
      );

      setErrors(null);

      return {
        success: true,
      };
    } catch (e) {
      if (e instanceof ValidationError) {
        // Path should always be set
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const errorMap = new Map(e.inner.map((err) => [err.params?.path as string, err.errors]));

        setErrors(errorMap);

        return {
          errors: errorMap,
          success: false,
        };
      }

      throw e;
    }
  };

  return [validate, errors] as const;
}
