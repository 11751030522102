export const LockIcon = ({ color = '#6236FF', ...props }) => (
  <svg
    width={'25'}
    height={'25'}
    viewBox={'0 0 25 25'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <g clipPath={'url(#clip0_2_1763)'}>
      <path
        d={'M17.7782 7.94158H16.8091V6.00341C16.8091 3.32872 14.6383 1.15796 11.9637 1.15796C9.28897 1.15796 7.11821 3.32872 7.11821 6.00341V7.94158H6.14912C5.08312 7.94158 4.21094 8.81376 4.21094 9.87976V19.5707C4.21094 20.6367 5.08312 21.5088 6.14912 21.5088H17.7782C18.8442 21.5088 19.7164 20.6367 19.7164 19.5707V9.87976C19.7164 8.81376 18.8442 7.94158 17.7782 7.94158ZM11.9637 16.6634C10.8977 16.6634 10.0255 15.7912 10.0255 14.7252C10.0255 13.6592 10.8977 12.787 11.9637 12.787C13.0296 12.787 13.9018 13.6592 13.9018 14.7252C13.9018 15.7912 13.0296 16.6634 11.9637 16.6634ZM14.9678 7.94158H8.95947V6.00341C8.95947 4.34626 10.3065 2.99923 11.9637 2.99923C13.6208 2.99923 14.9678 4.34626 14.9678 6.00341V7.94158Z'}
        fill={color}
      />
    </g>
    <defs>
      <clipPath id={'clip0_2_1763'}>
        <rect
          width={'24.4211'}
          height={'24.4211'}
          fill={'white'}
          transform={'translate(0.140625 0.140381)'}
        />
      </clipPath>
    </defs>
  </svg>

);
