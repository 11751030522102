import {
  EmploymentType,
  PropertyType,
  PropertyUsage,
  QuotingApplication,
} from '@lower-financial/lending-web-api/generated';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { DateTime } from 'luxon';
import { EmploymentStatus } from '@lower-financial/core-components';
import { STATE_NAME_TO_ABBREVIATION } from '@lower-financial/toolbox/src';
import { ReferralData } from '@lightspeed/contexts/referral-context/referral-data-helpers';

export const EMPLOYMENT_STATUS_MAP: Record<string, EmploymentType> = {
  [EmploymentStatus.RETIRED]: EmploymentType.Retired,
  [EmploymentStatus.SELF_EMPLOYED]: EmploymentType.SelfEmployed,
  [EmploymentStatus.W2]: EmploymentType.W2,
  [EmploymentStatus.UNEMPLOYED]: EmploymentType.Unemployed,
};

export const PROPERTY_RESIDENCE_TYPE_MAP: Record<string, PropertyUsage> = {
  Investment: PropertyUsage.Investment,
  'Primary Residence': PropertyUsage.PrimaryResidence,
  'Secondary Residence': PropertyUsage.SecondHome,
};

export const PROPERTY_TYPE_MAP: Record<string, PropertyType> = {
  Condo: PropertyType.Condo,
  Duplex: PropertyType.Duplex,
  Fourplex: PropertyType.Fourplex,
  'Single Family': PropertyType.SingleFamily,
  Triplex: PropertyType.Triplex,
};

const buildLoanIntent = (application: MortgageApplicationStore) => {
  const loanIntent = {
    cashOutAmount: safeParseFloatValue(application.propertyCashOut),
    contractStatus: application.borrowerContractStatus === ''
      ? undefined
      : application.borrowerContractStatus,
    downPayment: safeParseFloatValue(application.propertyDownPayment),
    hasRealEstateAgent: application.hasRealEstateAgent,
    isDownPaymentPartiallyGifted: application.propertyDownPaymentPartiallyGift
      ? application.propertyDownPaymentPartiallyGift === 'true'
      : undefined,
    isFirstTimeHomeBuyer: application.borrowerFirstTimeHomeBuyer
      ? application.borrowerFirstTimeHomeBuyer === 'Yes'
      : undefined,
    mortgageBalance: safeParseFloatValue(application.propertyMortgageBalance),
    propertyValue: safeParseFloatValue(application.propertyHomeValue),
    propertyValueRangeId: application.propertyHomeValueRangeId,
    purchasePrice: safeParseFloatValue(application.propertyPurchasePrice),
    purchasePriceRangeId: application.propertyPurchasePriceRangeId,
    purpose: application.loanPurpose,
  };

  if (Object.keys(loanIntent).length > 0) {
    return loanIntent;
  }

  return undefined;
};

const buildProperty = (application: MortgageApplicationStore) => {
  const property = {
    addressCity: application.propertyState
      ? application.propertyCity.trim()
      : undefined,
    addressCounty: application.propertyState
      ? application.propertyCounty
      : undefined,
    addressLine1: application.propertyState
      ? application.propertyStreetAddress
      : undefined,
    addressLine2: application.propertyState
      ? ''
      : undefined, // we don't store line 2 but the backend needs something for it
    addressState: STATE_NAME_TO_ABBREVIATION.get(application.propertyState) || undefined,
    addressZipCode: application.propertyZipCode,
    occupancy: application.propertyResidenceType
      ? PROPERTY_RESIDENCE_TYPE_MAP[application.propertyResidenceType]
      : undefined,
    type: PROPERTY_TYPE_MAP[application.propertyType],
  };

  if (Object.keys(property).length > 0) {
    return property;
  }

  return undefined;
};

const buildBorrower = (application: MortgageApplicationStore) => {
  const borrower = {
    addressCity: application.borrowerCity || undefined,
    addressCounty: application.borrowerCounty || undefined,
    addressLine1: application.borrowerStreetAddress || undefined,
    addressLine2: application.borrowerStreetAddress
      ? ''
      : undefined, // we don't store line 2 but the backend needs something for it
    addressState: STATE_NAME_TO_ABBREVIATION.get(application.borrowerState) || undefined,
    addressZipCode: application.borrowerZipCode || undefined,
    annualIncomeRangeId: application.borrowerYearlyIncomeRangeId,
    creditPullAuthorizedAt: application.borrowerCreditAuthDateTime
      ? new Date(application.borrowerCreditAuthDateTime).toISOString()
      : undefined,
    dateOfBirth: application.borrowerDateOfBirth
      ? DateTime.fromJSDate(new Date(application.borrowerDateOfBirth)).toFormat('yyyy-MM-dd')
      : undefined,
    emailAddress: application.borrowerEmail || undefined,
    employer: application.borrowerEmployer || undefined,
    employmentType: EMPLOYMENT_STATUS_MAP[application.borrowerEmploymentStatus],
    firstName: application.borrowerFirstName || undefined,
    jobTitle: application.borrowerTitle || undefined,
    lastName: application.borrowerLastName || undefined,
    liquidAssets: safeParseFloatValue(application.borrowerAmountInSavings),
    otherIncomePerYear: application.borrowerOtherIncomePerYear !== undefined
      ? parseFloat(application.borrowerOtherIncomePerYear)
      : undefined,
    phoneNumber: application.borrowerPhoneNumber || undefined,
    ssn: application.borrowerSocialSecurityNumber || undefined,
    yearlyGrossSalary: safeParseFloatValue(application.borrowerYearlySalary),
    yearsAtAddress: safeParseIntValue(application.borrowerYearsAtAddress),
    yearsEmployed: safeParseIntValue(application.borrowerYearsAtCompany),
  };

  if (Object.keys(borrower).length > 0) {
    return borrower;
  }

  return undefined;
};

const buildCoBorrower = (application: MortgageApplicationStore) => {
  const coBorrower = {
    addressCity: application.coBorrowerCity || undefined,
    addressCounty: application.coBorrowerCounty || undefined,
    addressLine1: application.coBorrowerStreetAddress || undefined,
    addressLine2: application.coBorrowerStreetAddress
      ? ''
      : undefined, // we don't store line 2 but the backend needs something for it
    addressState: STATE_NAME_TO_ABBREVIATION.get(application.coBorrowerState) || undefined,
    addressZipCode: application.coBorrowerZipCode || undefined,
    creditPullAuthorizedAt: application.coBorrowerCreditAuthDateTime
      ? new Date(application.coBorrowerCreditAuthDateTime).toISOString()
      : undefined,
    dateOfBirth: application.coBorrowerDateOfBirth
      ? DateTime.fromJSDate(new Date(application.coBorrowerDateOfBirth)).toFormat('yyyy-MM-dd')
      : undefined,
    emailAddress: application.coBorrowerEmail || undefined,
    employer: application.coBorrowerEmployer || undefined,
    employmentType: application.coBorrowerEmploymentStatus
      ? EMPLOYMENT_STATUS_MAP[application.coBorrowerEmploymentStatus]
      : undefined,
    firstName: application.coBorrowerFirstName || undefined,
    jobTitle: application.coBorrowerTitle || undefined,
    lastName: application.coBorrowerLastName || undefined,
    liquidAssets: application.coBorrowerFirstName
      ? safeParseFloatValue(application.coBorrowerAmountInSavings)
      : undefined,
    otherIncomePerYear: safeParseFloatValue(application.coBorrowerOtherIncomePerYear),
    phoneNumber: application.coBorrowerPhoneNumber || undefined,
    ssn: application.coBorrowerSocialSecurityNumber || undefined,
    yearlyGrossSalary: safeParseFloatValue(application.coBorrowerYearlySalary),
    yearsAtAddress: undefined,
    yearsEmployed: application.coBorrowerYearsAtCompany
      ? safeParseIntValue(application.coBorrowerYearsAtCompany)
      : undefined,
  };

  if (Object.values(coBorrower).every((val) => val === undefined)) {
    return null;
  }

  if (Object.keys(coBorrower).length > 0) {
    return coBorrower;
  }

  return undefined;
};

export const transformMortgageApplicationToQuotingApplication = (
  application: MortgageApplicationStore,
  referralData: ReferralData,
) => {
  const builder: QuotingApplication = {
    attribution: referralData,
    borrower: buildBorrower(application),
    coBorrower: buildCoBorrower(application),
    consentSharePreapprovalDenialReasons: application.consentSharePreapprovalDenialReasons ?? false,
    loanIntent: buildLoanIntent(application),
    property: buildProperty(application),
  };

  return builder;
};

const safeParseIntValue = (value: string) => {
  const parsedInt = parseInt(value, 10);
  return Number.isSafeInteger(parsedInt)
    ? parsedInt
    : undefined;
};

const safeParseFloatValue = (value: string) => {
  const parsedValue = parseFloat(value);

  if (Number.isNaN(parsedValue)) {
    return;
  }

  return parsedValue;
};
