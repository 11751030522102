export const SecureCallIcon = ({ ...props }) => (
  <svg
    width={'102'}
    height={'130'}
    viewBox={'0 0 102 130'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>Secure call</title>
    <rect
      x={'16'}
      y={'51.124'}
      width={'69.8761'}
      height={'69.8761'}
      rx={'17.8407'}
      fill={'#001CDB'}
    />
    <rect
      x={'13.0265'}
      y={'48.1506'}
      width={'75.823'}
      height={'75.823'}
      rx={'20.8142'}
      stroke={'#001CDB'}
      strokeOpacity={'0.14'}
      strokeWidth={'5.9469'}
    />
    <path
      d={'M62.8875 91.3194C61.1096 91.3194 59.3896 91.0303 57.7851 90.5099C57.2792 90.3365 56.7155 90.4666 56.3253 90.8568C54.9966 92.524 52.6618 93.0893 50.9326 91.8424C49.0848 90.5101 47.389 88.8495 46.0147 86.9917C44.7176 85.2384 45.2548 82.8464 46.9155 81.4326C47.3058 81.0279 47.4214 80.4642 47.2624 79.9583C46.7276 78.3539 46.453 76.6338 46.453 74.8559C46.453 74.0754 45.8026 73.425 45.022 73.425H40.0208C39.2403 73.425 38.3008 73.7719 38.3008 74.8559C38.3008 88.284 49.4739 99.4427 62.8875 99.4427C63.9138 99.4427 64.3185 98.532 64.3185 97.7371V92.7503C64.3185 91.9698 63.668 91.3194 62.8875 91.3194Z'}
      fill={'white'}
    />
    <circle
      cx={'81.3894'}
      cy={'54.6106'}
      r={'15.6106'}
      fill={'#02CA02'}
    />
    <mask
      id={'mask0_1967_22644'}
      maskUnits={'userSpaceOnUse'}
      x={'74'}
      y={'47'}
      width={'15'}
      height={'15'}
    >
      <rect
        x={'74.2791'}
        y={'47.4999'}
        width={'14.2209'}
        height={'14.2209'}
        fill={'#D9D9D9'}
      />
    </mask>
    <g mask={'url(#mask0_1967_22644)'}>
      <path
        d={'M77.8342 60.5354C77.5083 60.5354 77.2294 60.4194 76.9973 60.1873C76.7652 59.9552 76.6492 59.6762 76.6492 59.3504V53.425C76.6492 53.0991 76.7652 52.8201 76.9973 52.588C77.2294 52.356 77.5083 52.2399 77.8342 52.2399H78.4268V51.0549C78.4268 50.2352 78.7156 49.5365 79.2934 48.9588C79.8711 48.381 80.5698 48.0922 81.3895 48.0922C82.2091 48.0922 82.9078 48.381 83.4855 48.9588C84.0633 49.5365 84.3521 50.2352 84.3521 51.0549V52.2399H84.9447C85.2706 52.2399 85.5495 52.356 85.7816 52.588C86.0137 52.8201 86.1297 53.0991 86.1297 53.425V59.3504C86.1297 59.6762 86.0137 59.9552 85.7816 60.1873C85.5495 60.4194 85.2706 60.5354 84.9447 60.5354H77.8342ZM79.6118 52.2399H83.1671V51.0549C83.1671 50.5611 82.9942 50.1414 82.6486 49.7957C82.3029 49.4501 81.8832 49.2772 81.3895 49.2772C80.8957 49.2772 80.476 49.4501 80.1303 49.7957C79.7847 50.1414 79.6118 50.5611 79.6118 51.0549V52.2399Z'}
        fill={'white'}
      />
    </g>
    <rect
      x={'24'}
      y={'4'}
      width={'56'}
      height={'27'}
      rx={'8'}
      fill={'black'}
    />
    <path
      d={'M35.008 22V13.6H36.856V20.32H41.044V22H35.008ZM44.8045 22.144C42.8125 22.144 41.3365 20.668 41.3365 18.82V18.796C41.3365 16.948 42.8245 15.448 44.8285 15.448C46.8205 15.448 48.2965 16.924 48.2965 18.772V18.796C48.2965 20.644 46.8085 22.144 44.8045 22.144ZM44.8285 20.572C45.8725 20.572 46.4965 19.768 46.4965 18.82V18.796C46.4965 17.848 45.8125 17.02 44.8045 17.02C43.7605 17.02 43.1365 17.824 43.1365 18.772V18.796C43.1365 19.744 43.8205 20.572 44.8285 20.572ZM50.2846 22.048L48.3046 15.568H50.1526L51.1846 19.468L52.3966 15.544H53.9686L55.1926 19.48L56.2486 15.568H58.0606L56.0566 22.048H54.4126L53.1766 18.1L51.9166 22.048H50.2846ZM61.4318 22.144C59.4998 22.144 58.0718 20.788 58.0718 18.82V18.796C58.0718 16.96 59.3798 15.448 61.2518 15.448C63.3998 15.448 64.3838 17.116 64.3838 18.94C64.3838 19.084 64.3718 19.252 64.3598 19.42H59.8838C60.0638 20.248 60.6398 20.68 61.4558 20.68C62.0678 20.68 62.5118 20.488 63.0158 20.02L64.0598 20.944C63.4598 21.688 62.5958 22.144 61.4318 22.144ZM59.8598 18.28H62.6078C62.4998 17.464 62.0198 16.912 61.2518 16.912C60.4958 16.912 60.0038 17.452 59.8598 18.28ZM65.2632 22V15.568H67.0872V16.864C67.4592 15.976 68.0592 15.4 69.1392 15.448V17.356H69.0432C67.8312 17.356 67.0872 18.088 67.0872 19.624V22H65.2632Z'}
      fill={'white'}
    />
    <rect
      x={'52'}
      y={'27'}
      width={'5.65686'}
      height={'5.65686'}
      transform={'rotate(45 52 27)'}
      fill={'black'}
    />
  </svg>
);
