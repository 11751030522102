import { QuotingApplication, ErrorResponse, ValidationErrorResponse } from '@lower-financial/lending-web-api/generated';
import { fetch, TypedResponse, EmptyResponse } from '@lower-financial/toolbox';
import { ENV } from '@lightspeed/environment';

/**
 * You may be asking yourself "Why?"
 * The answer is that DateOfBirth needs to be in the format of YYYY-MM-DD
 * to make the server happy. The borrower in the quoting application uses
 * a native Date object, and JSON.stringify just formats it to an ISO
 * string. We need to override that behavior on one field...
 */
function replacer(key: string, value: unknown) {
  if (key === 'dateOfBirth' && typeof value === 'string') {
    return value.substr(0, 10);
  }

  return value;
}

export function updateQuotingApplication(id: string, quotingApplication: QuotingApplication) {
  return fetch<UpdateQuotingApplicationResponse>(
    `${ENV.BACKEND_API_HOST}/api/lending/quoting_applications/${id}`,
    {
      body: JSON.stringify(quotingApplication, replacer),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    },
  );
}

type ValidationErrorResponseInterface = TypedResponse<ValidationErrorResponse, 422>;
type QuotingApplicationNotFoundInterface = TypedResponse<ErrorResponse, 404>;
type QuotingApplicationNotUpdatableInterface = TypedResponse<ErrorResponse, 409>;
type QuotingApplicationNoContentInterface = EmptyResponse<204>;

export type UpdateQuotingApplicationResponse = ValidationErrorResponseInterface
  | QuotingApplicationNotFoundInterface
  | QuotingApplicationNoContentInterface
  | QuotingApplicationNotUpdatableInterface;
