import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { ENV } from '@lightspeed/environment';

const closeButtonUrlMap = {
  [LoanPurpose.Heloc]: ENV.HELOC_MARKETING_URL,
  [LoanPurpose.Purchase]: ENV.PURCHASE_MARKETING_URL,
  [LoanPurpose.Refinance]: ENV.REFINANCE_MARKETING_URL,
  [LoanPurpose.Other]: ENV.HOME_MARKETING_URL,
} as const;

export const getMarketingUrl = (loanPurpose: LoanPurpose | null) =>
  closeButtonUrlMap[loanPurpose ?? LoanPurpose.Other];
