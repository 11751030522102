import { CurrencyRangeCategory } from '@lower-financial/lending-web-api/generated';
import { useEffect } from 'react';
import { DidYouKnow, HighlightedText } from '@lightspeed/components/shared/banners/did-you-know';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { useMortgageAnalytics } from '@lightspeed/hooks/useMortgageAnalytics/use-mortgage-analytics';
import { CardToggleLayout } from '@lightspeed/components/shared/card-toggle-screen-layout/card-toggle-layout';
import { TestimonialSidebar } from '@lightspeed/components/shared/sidebars/testimonial-sidebar';
import { useCurrencyRanges } from '@lightspeed/contexts/currency-ranges-context/currency-ranges-context';

const PropertyValueDidYouKnow = () => (
  <DidYouKnow>
    <HighlightedText>Home value</HighlightedText> is key to calculating your
    equity and helping us find the best loan for what you need.
  </DidYouKnow>
);

const PropertyValueTestimonial = () => (
  <Testimonial
    title={'Great Company to Work With'}
    content={'Lower helped our family with a HELOC and was extremely friendly, knowledgeable, and responsive. Despite a rapidly changing market, he guided us every step of the way.'}
    author={'Dakota Maksimovich'}
  />
);

export const PropertyValue = () => {
  const fireAnalyticsEvent = useMortgageAnalytics('property_value');
  const { currencyRanges, isLoading } = useCurrencyRanges();

  const rangeOptions = (currencyRanges ?? [])
    .sort((a, b) => a.minValue - b.minValue)
    .filter((range) => range.category === CurrencyRangeCategory.PropertyValue).map((range) => ({
      label: range.label,
      value: range.id,
    }));

  useEffect(() => {
    if (rangeOptions.length === 0 && !isLoading) {
      throw new Error('No property values currency ranges found for property value');
    }
  }, [isLoading, rangeOptions.length]);

  return (
    <CardToggleLayout
      title={'About how much is the home worth?'}
      subtitle={<>Just need a ballpark number for now.&nbsp;💵</>}
      mortgageApplicationField={'propertyHomeValueRangeId'}
      fireAnalyticsEvent={fireAnalyticsEvent}
      cardOptions={rangeOptions}
      mobileBanner={<PropertyValueDidYouKnow />}
      desktopSidebar={(
        <TestimonialSidebar
          testimonial={<PropertyValueTestimonial />}
          banner={<PropertyValueDidYouKnow />}
        />
      )}
    />
  );
};
