import styled from 'styled-components';
import { compose, fontWeight, FontWeightProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import React, { ComponentProps } from 'react';
import cx from 'classnames';
import { ThemedProps } from '../../styles/utils/theme-utils';

type CircleButtonComponentProps = {
  active?: boolean;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
  variant?: 'default' | 'selected';
} & FontWeightProps & LayoutProps & SpaceProps;

export const CircleButton = styled.button.attrs(({
  className,
  type = 'button',
  variant = 'default',
  ...props
}: CircleButtonComponentProps) => ({
  className: cx('Button', className, {
    '--default': variant === 'default',
    'is-disabled': props.disabled,
    'is-selected': props.selected,
  }),
  type,
  variant,
  ...props,
}))`
  /* Reset */
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  font-family: var(--font-bold);
  text-align: center;
  font-size: 1rem;
  line-height: 1rem !important;

  ${ (props: ThemedProps) => props.theme.Button.bold };
  border-radius: 50%;
  border: 1px solid;
  border-color: var(--silk);


  &.--default {
    color: var(--charcoal);
    background-color: var(--white);

    &:hover:not(:active):not(.is-disabled):not(:disabled) {
      cursor: pointer;
      transform: scale(1.05);
      border: 2px solid;
      border-color: var(--primary);
      box-shadow: ${ (props: ThemedProps) => props.theme.shadows.generic };
    }

    &:active:not(.is-disabled):not(:disabled) {
      transform: scale(.95);
      border: 2px solid;
      border-color: var(--primary);
      box-shadow: ${ (props: ThemedProps) => props.theme.shadows.generic };
    }
  }

  &.is-selected {
    transform: scale(.95);
    color: var(--primary);
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--primary);
    box-shadow: ${ (props: ThemedProps) => props.theme.shadows.generic };
  }

  &.is-disabled, &:disabled {
    cursor: not-allowed;
    color: var(--silk);
    border: 1px solid;
    border-color: var(--silk);
    box-shadow: none;
  }

  &:focus-visible {
    border: 1px solid;
    border-color: var(--primary);
  }

  ${ compose(layout, space, fontWeight) };
`;

export type CircleButtonProps = ComponentProps<typeof CircleButton>;
