import { useCallback, useMemo, useState } from 'react';
import { LoadingOverlay, OptionalElement, useValidation } from '@lower-financial/core-components';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { objectContainsFalsyPropsForKeys, slugify } from '@lower-financial/toolbox';
import { useErrorHandling } from '@lower-financial/core-components/src/hooks/useErrorHandling/useErrorHandling';
import { DidYouKnow, HighlightedText } from '@lightspeed/components/shared/banners/did-you-know';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { useMarket } from '@lightspeed/hooks/useMarket/useMarket';
import { useMortgageAnalytics } from '@lightspeed/hooks/useMortgageAnalytics/use-mortgage-analytics';
import { NextStepButton } from '@lightspeed/components/shared/buttons/next-step-button';
import { PageLayout } from '@lightspeed/components/shared/page-layout/page-layout';
import { TestimonialSidebar } from '@lightspeed/components/shared/sidebars/testimonial-sidebar';
import { PropertyAddressForm } from './property-address-form';
import { useSyncCurrentAddressWithPropertyAddress } from './use-sync-current-address-with-property-address';

const propertyKeys: MortgageApplicationStoreKeys[] = [
  'propertyStreetAddress',
  'propertyCity',
  'propertyZipCode',
  'propertyState',
];

const title = 'And where can we find this home?';
const subtitle = (
  <>
    You’ll be first on our holiday card list.&nbsp;🥰
  </>
);

export function PropertyAddress() {
  const { mortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const handleError = useErrorHandling();

  const fireAnalyticsEvent = useMortgageAnalytics('property_address');

  const { upsertApplication } = useUpsertApplication();
  const [isLoading, setIsLoading] = useState(false);
  const { isUnsupportedMarket, isMarketLoading } = useMarket();

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: propertyKeys,
  });

  const disableNextButton = isLoading || objectContainsFalsyPropsForKeys(
    mortgageApplication,
    propertyKeys,
  );

  useSyncCurrentAddressWithPropertyAddress();

  const onNextClick = useCallback(
    () => {
      (async () => {
        if (!validate(mortgageApplication).success) {
          return;
        }

        setIsLoading(true);
        await handleError({
          onRetryableError: () => {
            setIsLoading(false);
          },
          tryFn: async () => {
            await upsertApplication();

            if (isUnsupportedMarket(mortgageApplication.propertyState)) {
              fireAnalyticsEvent(`bad_state_${slugify(mortgageApplication.propertyState)}`);
            } else {
              fireAnalyticsEvent('next_button_success');
            }
            goToNextRoute();
          },
        });
      })();
    },
    [
      fireAnalyticsEvent,
      goToNextRoute,
      handleError,
      isUnsupportedMarket,
      mortgageApplication,
      upsertApplication,
      validate,
    ],
  );

  const nextStepButton = useMemo(
    () => (
      <NextStepButton
        isLoading={isLoading}
        disabled={disableNextButton}
        onClick={onNextClick}
      />
    ),
    [disableNextButton, isLoading, onNextClick],
  );

  return (
    <Layout>
      <OptionalElement show={isMarketLoading}>
        <LoadingOverlay />
      </OptionalElement>
      <PageLayout
        title={title}
        subtitle={subtitle}
        button={nextStepButton}
        mobileBanner={<PropertyAddressDidYouKnow />}
        desktopSidebar={(
          <TestimonialSidebar
            testimonial={mortgageApplication.loanPurpose === LoanPurpose.Purchase
              ? <PropertyAddressPurchaseTestimonial />
              : <PropertyAddressHelocTestimonial />
            }
            banner={<PropertyAddressDidYouKnow />}
          />
        )}
      >
        <PropertyAddressForm
          isLoading={isLoading}
          errors={errors || new Map()}
          fireAnalyticsEvent={fireAnalyticsEvent}
        />
      </PageLayout>
    </Layout>
  );
}

const PropertyAddressDidYouKnow = () => (
  <DidYouKnow>
    The <HighlightedText>property address</HighlightedText> helps us find an
    expert in your area and narrow down your options.
  </DidYouKnow>
);

const PropertyAddressPurchaseTestimonial = () => (
  <Testimonial
    title={'Quick and simple closing!'}
    content={'Our advisor worked with me to find the best rates and payment plan in a rapidly changing and competitive market. He quickly answered any questions I had and made sure I understood all of the critical details of the process.'}
    author={'Nick'}
    date={'2022-05-26'}
  />
);

const PropertyAddressHelocTestimonial = () => (
  <Testimonial
    headingElement={'h2'}
    title={'Awesome experience!'}
    content={'Lower was very responsive, attentive to detail, and a pleasure to work with throughout the process.'}
    author={'Scott Crawford'}
    date={'2022-08-11'}
  />
);
