import React from 'react';
import {
  FormRow,
  Label,
  TextInput,
  Select,
  ValidationError,
  ZipcodeInput,
} from '@lower-financial/core-components';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { STATE_OPTIONS } from '@lower-financial/toolbox/src';
import {
  MortgageApplicationState,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';

export type AddressType = 'borrower' | 'property';

interface FullPropertyAddressFormProps {
  mortgageApplication: MortgageApplicationStore;
  updateMortgageApplication: MortgageApplicationState['updateMortgageApplication'];
  errors: Map<string, string[]>;
  addressLabel: string;
  addressType: AddressType;
  displayRequiredSymbols?: boolean;
  disabled?: boolean;
}

export function FullAddressForm({
  mortgageApplication,
  updateMortgageApplication,
  errors,
  addressLabel,
  addressType,
  displayRequiredSymbols = true,
  disabled = false,
}: FullPropertyAddressFormProps) {
  const {
    city, state, streetAddress, zip,
  } = addressFieldNames(addressType);

  return (
    <>
      <FormRow>
        <Label flex={1}>
          {addressLabel}
          <TextInput
            name={streetAddress}
            value={mortgageApplication[streetAddress]}
            onChange={(e) => { updateMortgageApplication(streetAddress, e.target.value); }}
            placeholder={'123 Main St.'}
            disabled={disabled}
          />
          <ValidationError
            path={streetAddress}
            errors={errors}
          />
        </Label>
        <Label flex={1}>
          City{renderRequiredSymbol(displayRequiredSymbols)}
          <TextInput
            name={city}
            value={mortgageApplication[city]}
            onChange={(e) => { updateMortgageApplication(city, e.target.value); }}
            placeholder={'e.g. Columbus'}
            disabled={disabled}
          />
          <ValidationError
            path={city}
            errors={errors}
          />
        </Label>
      </FormRow>
      <FormRow>
        <Label flex={['0 0 100%', 1]}>
          State{renderRequiredSymbol(displayRequiredSymbols)}
          <Select
            name={state}
            options={[{
              label: 'Select One',
              value: '',
            }, ...STATE_OPTIONS]}
            value={mortgageApplication[state]}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication(state, e.target.value)}
            disabled={disabled}
          />
          <ValidationError
            path={state}
            errors={errors}
          />
        </Label>
        <ZipcodeInput
          displayRequiredSymbol={displayRequiredSymbols}
          name={zip}
          value={mortgageApplication[zip]}
          onChange={((value) => { updateMortgageApplication(zip, value); })}
          errorPath={zip}
          errors={errors}
          disabled={disabled}
        />
      </FormRow>
    </>
  );
}

const propertyFields = {
  city: 'propertyCity',
  state: 'propertyState',
  streetAddress: 'propertyStreetAddress',
  zip: 'propertyZipCode',
} as const;

const borrowerFields = {
  city: 'borrowerCity',
  state: 'borrowerState',
  streetAddress: 'borrowerStreetAddress',
  zip: 'borrowerZipCode',
} as const;

export const addressFieldNames = (addressType: AddressType) => {
  if (addressType === 'property') {
    return propertyFields;
  }

  if (addressType === 'borrower') {
    return borrowerFields;
  }

  throw new Error('Unexpected address type');
};

const renderRequiredSymbol = (displayRequiredSymbols: boolean) => (
  displayRequiredSymbols
    ? '*'
    : ''
);
