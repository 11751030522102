import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { HelocWarmUp } from '@lightspeed/components/ui/pages/warm-up/heloc-warm-up';
import { PurchaseWarmUp } from '@lightspeed/components/ui/pages/warm-up/purchase-warm-up';
import { usePartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';

export const WarmUp = () => {
  const { partnerConfiguration } = usePartnerConfiguration();

  switch (partnerConfiguration.loanPurpose) {
  case LoanPurpose.Heloc:
    return <HelocWarmUp />;
  case LoanPurpose.Purchase:
  default:
    return <PurchaseWarmUp />;
  }
};
