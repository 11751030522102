import { OptionalElement } from '@lower-financial/core-components/src/components/optional-element';
import { ReactNode } from 'react';
import styled from 'styled-components';

export function DesktopButtonLayout({
  button,
  disclaimer,
}:
{
  button: ReactNode | ReactNode[],
  disclaimer?: ReactNode | ReactNode[],
}) {
  return (
    <DesktopButtonContainer>
      {button}
      <OptionalElement show={disclaimer !== undefined}>
        <div>
          {disclaimer}
        </div>
      </OptionalElement>
    </DesktopButtonContainer>
  );
}

const DesktopButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-10);
`;
