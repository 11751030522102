import { useState } from 'react';
import { Quote } from '@lower-financial/lending-web-api/generated';

export type QuotesStore = {
  quotes: Array<Required<Quote>>,
  passedFilters: boolean,
};

export interface QuotesState {
  quotesOptions: QuotesStore,
  updateQuotes: (updatedState: Partial<QuotesStore>) => void
}

const initialLoan: QuotesStore = {
  passedFilters: false,
  quotes: [],
};

export function useQuotesState(overrides: Partial<QuotesStore>) {
  const [quotes, setLoanProducts] = useState<QuotesStore>({
    ...initialLoan,
    ...overrides,
  });

  const update = (updatedState: Partial<QuotesStore>) => {
    setLoanProducts((currentState) => ({
      ...currentState,
      ...updatedState,
    }));
  };

  return {
    quotesOptions: quotes,
    updateQuotes: update,
  };
}
