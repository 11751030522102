import React from 'react';
import { Form, FormRow, Heading, Label, PhoneInput, Select, TextInput, ValidationError } from '@lower-financial/core-components';
import { STATE_OPTIONS } from '@lower-financial/toolbox/src';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

interface BasicInfoFormProps {
  errors: Map<string, string[]>|null;
}

export function BasicInfoForm({ errors }: BasicInfoFormProps) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();

  return (
    <>
      <Form>
        <Heading
          element={'h4'}
        >
          Basic Info
        </Heading>
        <FormRow>
          <Label flex={1}>
            First Name*
            <TextInput
              name={'borrowerFirstName'}
              value={mortgageApplication.borrowerFirstName}
              onChange={(e) => { updateMortgageApplication('borrowerFirstName', e.target.value); }}
            />
            <ValidationError
              path={'borrowerFirstName'}
              errors={errors}
            />
          </Label>
          <Label flex={1}>
            Last Name*
            <TextInput
              name={'borrowerLastName'}
              value={mortgageApplication.borrowerLastName}
              onChange={(e) => { updateMortgageApplication('borrowerLastName', e.target.value); }}
            />
            <ValidationError
              path={'borrowerLastName'}
              errors={errors}
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label flex={['0 0 100%', 1]}>
            Email*
            <TextInput
              $hasError={!!errors?.get('borrowerEmail')}
              inputMode={'email'}
              name={'borrowerEmail'}
              value={mortgageApplication.borrowerEmail}
              onChange={(e) => { updateMortgageApplication('borrowerEmail', e.target.value); }}
              placeholder={'me@example.com'}
            />
            <ValidationError
              path={'borrowerEmail'}
              errors={errors}
            />
          </Label>
          <Label flex={['0 0 100%', 1]}>
            Phone*
            <PhoneInput
              name={'borrower-phone-number'}
              value={mortgageApplication.borrowerPhoneNumber}
              onChange={(v) => updateMortgageApplication('borrowerPhoneNumber', v)}
            />
            <ValidationError
              path={'borrowerPhoneNumber'}
              errors={errors}
            />
          </Label>
        </FormRow>
      </Form>
      <Form
        style={{
          paddingTop: 48,
        }}
      >
        <Heading
          element={'h4'}
        >
          Where is the property located?
        </Heading>
        <FormRow>
          <Label
            flex={[1, 1 / 2]}
          >
            State*
            <Select
              label={'State'}
              name={'property-state'}
              options={[{
                label: 'Select One',
                value: '',
              }, ...STATE_OPTIONS]}
              value={mortgageApplication.propertyState}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('propertyState', e.target.value)}
            />
            <ValidationError
              path={'propertyState'}
              errors={errors}
            />
          </Label>
        </FormRow>
      </Form>
    </>
  );
}
