import { ComponentProps } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { compose, layout, LayoutProps, shadow, ShadowProps, space, SpaceProps } from 'styled-system';
import { ToolTip, Symbol, responsiveStyleFromTheme } from '@lower-financial/core-components';

type ScoreboardComponentProps = {
  label?: string;
  tooltip?: string;
  prefix?: string;
  suffix?: string;
  value: string | number;
  className?: string;
} & LayoutProps & ShadowProps & SpaceProps;

export const Scoreboard = ({
  className,
  label,
  tooltip,
  prefix,
  suffix,
  value,
  ...props
}: ScoreboardComponentProps) => (
  <Container
    className={cx('Scoreboard', className)}
    {...props}
  >
    {
      label && (
        <Label>
          {label}
          {
            tooltip && (
              <ToolTip
                bubbleStyle={
                  {
                    textAlign: 'left',
                  }
                }
                bubble={tooltip}
              >
                <Symbol type={'Info'} />
              </ToolTip>
            )
          }
        </Label>
      )
    }
    <ScoreContainer>
      {
        prefix && (
          <Superscript>{prefix}</Superscript>
        )
      }
      <Score>{value}</Score>
      {
        suffix && (
          <Superscript>{suffix}</Superscript>
        )
      }
    </ScoreContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  ${ compose(layout, space, shadow) };
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.h4`
  ${(props) => responsiveStyleFromTheme(props.theme.Headers.sm)}
  color: var(--charcoal);
  margin-bottom: 16px;
  font-weight: bold;
  font-family: var(--font-book);
`;

const Score = styled.h2`
  all: unset;
  box-sizing: border-box;
  ${ (props) => responsiveStyleFromTheme(props.theme.Scoreboard) };
`;

const Superscript = styled.span`
  font: var(--font-superscript-mobile);
  ${(props) => props.theme.media.desktop} {
    font: var(--font-superscript);
  }
`;

export type ScoreboardProps = ComponentProps<typeof Scoreboard>;
