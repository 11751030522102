export const getFormValues = (
  form: HTMLFormElement,
  sanitizeFn: (field: HTMLInputElement) => string = (field) => field.value,
) => {
  const values: Record<string, string> = {};
  Array.from(form.elements)
    .filter((field): field is HTMLInputElement => field instanceof HTMLInputElement)
    .forEach((field) => {
      values[field.name] = sanitizeFn(field);
    });
  return values;
};
