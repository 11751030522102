import styled from 'styled-components';
import { ReactNode } from 'react';
import { Paragraph } from '../paragraph';

export const ChatBubble = (
  { children }:
  { children?: ReactNode | ReactNode[] },
) => {
  const bubbleColor = 'var(--info-banner-background)';
  return (
    <ChatBubbleContainer>
      <ChatBubbleTail
        color={bubbleColor}
        data-testid={'bubble-tail'}
      />
      <ChatBubbleTextContainer
        color={bubbleColor}
        data-testid={'bubble'}
      >
        <ChatBubbleText variant={'smallLight'}>{children}</ChatBubbleText>
      </ChatBubbleTextContainer>
    </ChatBubbleContainer>
  );
};

const ChatBubbleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 0.25rem;
`;

const ChatBubbleTail = styled.div<{ color: string }>`
  width: 0.5rem;
  height: 1rem;
  border-left: 0.5rem solid transparent;
  border-bottom: 0.5rem solid ${(props) => props.color};
  border-bottom-left-radius: 50%;
`;

const ChatBubbleTextContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: flex-start;
  padding: 0.875rem 1rem;
  background-color: ${(props) => props.color};
  border-radius: 1.25rem 1.25rem 1.25rem 0rem;
`;

const ChatBubbleText = styled(Paragraph)`
  text-align: left;
  font-style: italic;
`;
