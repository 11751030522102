import { LoanPurpose, MortgageRateDto } from '@lower-financial/lending-web-api/generated/models';
import useTodaysRates from './useTodaysRates';

const currencyFormatter = (value: string, maximumFractionDigits: number, minimumFractionDigits: number) => new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits,
  minimumFractionDigits,
  style: 'currency',
}).format(parseFloat(value));

const isRateComplete = (rate?: Partial<MortgageRateDto>): rate is Required<MortgageRateDto> =>
  !!rate
  && !!rate.apr
  && !!rate.rate
  && !!rate.lender
  && !!rate.term
  && !!rate.loanAmount
  && !!rate.loanType;

export function useRate(term: 'twenty'|'thirty'|'fifteen', loanPurpose: LoanPurpose | null) {
  if (loanPurpose === LoanPurpose.Heloc || loanPurpose === LoanPurpose.Other) {
    throw new Error(`loan purpose '${loanPurpose}' not supported in useRate`);
  }
  // todays rates fires a network request, there's a chance it's null in which case we render fallback
  const todaysRates = useTodaysRates();

  if (todaysRates && loanPurpose) {
    const todaysRate = todaysRates[term][loanPurpose];
    if (isRateComplete(todaysRate)) {
      return {
        apr: todaysRate.apr.toFixed(3),
        discountPoints: todaysRate.discountPoints?.toFixed(1),
        loanAmount: currencyFormatter(todaysRate.loanAmount?.toFixed(0), 0, 0),
        ltv: ((todaysRate.loanAmount / 400001) * 100).toFixed(0), // NOTE(quinton): Hardcoded value used to determine loans in daily rate calculation. Contact Ben Danis / Brand Team if you have questions
        monthlyPayment: currencyFormatter(todaysRate.payment.toFixed(2), 2, 2),
        rate: todaysRate.rate.toFixed(3),
      };
    }
  }

  return {
    apr: '-.---',
    discountPoints: '',
    loanAmount: '',
    ltv: '',
    monthlyPayment: '',
    rate: '-.---',
  };
}
