import { useMemo } from 'react';
import { useFeatureFlagVariantKey } from '@lower-financial/feature-flags/src/hooks/useFeatureFlagVariantKey/use-feature-flag-variant-key';
import { FeatureFlagKeys, FeatureFlags } from '@lower-financial/feature-flags/src/feature-flags';

export const useFeatureFlags = (): FeatureFlags => {
  const lightspeedV2Purchase = useFeatureFlagVariantKey(FeatureFlagKeys.lightspeedV2Purchase);
  const onlyScheduleCallButton = useFeatureFlagVariantKey(FeatureFlagKeys.onlyScheduleCallButton);
  return useMemo(() => ({
    lightspeedV2Purchase,
    onlyScheduleCallButton,
  }), [
    lightspeedV2Purchase,
    onlyScheduleCallButton,
  ]);
};
