import React from 'react';
import { CardCheckboxGroup } from '@lower-financial/core-components/src/components/card-checkbox-group/card-checkbox-group';
import { ContractStatus } from '@lower-financial/lending-web-api/generated';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { useMortgageContextDefault } from '@lightspeed/hooks/useMortgageContextDefaults/useMortgageContextDefaults';

export const ApplicationOptionsPurchaseForm = () => {
  const {
    updateMortgageApplication, mortgageApplication, clearCoBorrower,
  } = useMortgageApplication();
  const { partnerConfiguration: { realEstateAgentQuestion } } = usePartnerConfiguration();
  useMortgageContextDefault('borrowerContractStatus');
  useMortgageContextDefault('borrowerFirstTimeHomeBuyer');

  const realEstateQuestion = {
    checked: mortgageApplication.hasRealEstateAgent === false,
    icon: '👋',
    label: 'I don’t have a real estate agent',
    onChange: () => updateMortgageApplication(
      'hasRealEstateAgent',
      mortgageApplication.hasRealEstateAgent === null
        ? false
        : !mortgageApplication.hasRealEstateAgent,
    ),
  };

  const items = [
    {
      checked: mortgageApplication.hasCoBorrower,
      icon: '👯',
      label: 'I have a co-borrower',
      onChange: () => {
        // delete all coborrower fields if this was checked previously
        if (mortgageApplication.hasCoBorrower) {
          clearCoBorrower();
        }
        updateMortgageApplication('hasCoBorrower', !mortgageApplication.hasCoBorrower);
      },
    },
    {
      checked: mortgageApplication.borrowerFirstTimeHomeBuyer === 'Yes',
      icon: '👋',
      label: 'I’m a first-time home buyer',
      onChange: () => updateMortgageApplication(
        'borrowerFirstTimeHomeBuyer',
        mortgageApplication.borrowerFirstTimeHomeBuyer === 'Yes'
          ? 'No'
          : 'Yes',
      ),
    },
    {
      checked: mortgageApplication.borrowerContractStatus === ContractStatus.InContract,
      icon: '🏆',
      label: 'I’ve had my offer accepted',
      onChange: () => updateMortgageApplication(
        'borrowerContractStatus',
        mortgageApplication.borrowerContractStatus === ContractStatus.InContract
          ?  ContractStatus.StillLooking
          : ContractStatus.InContract,
      ),
    },
  ];

  if (realEstateAgentQuestion) {
    items.unshift(realEstateQuestion);
  }

  return (
    <CardCheckboxGroup
      items={items}
    />
  );
};

