import { ReactNode, ComponentProps } from 'react';
import styled from 'styled-components';
import { ChevronDownIcon } from '@lower-financial/icons';

interface AccordionComponentProps {
  active?: boolean;
  disabled?: boolean;
  className?: string;
  label?: string;
  onClick?: () => void;
  children?: ReactNode;
  icon?: ReactNode;
}

const Container = styled.div`
  width: 100%;
  border-radius: var(--spacing-3);
  border: 1px solid var(--satin);
  padding: var(--spacing-2) var(--spacing-6);
`;

const Thumbnail = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const ArrowIndicator = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    transition: transform 0.3s ease-in-out;
    position: relative;
    ${open
    ? 'transform: rotate(180deg);'
    : 'transform: rotate(0deg);'}
  `}
`;

const Panel = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
    ${open
    ? 'max-height: 100rem;'
    : ''};
  `}
`;

const PanelContent = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    padding-top: var(--spacing-4);
    padding-bottom: var(--spacing-2);
    opacity: 0;
    transform: translateY(-0.5rem);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    ${open
    ? `
      opacity: 1;
      transform: translateY(0);
    `
    : ''};
  `}
`;

const ThumbnailLabel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
`;

const IconWrapper = styled.div`
  display: inline;
  margin-right: var(--spacing-2);
  padding-top: .3rem;
`;

export const Accordion = ({
  active,
  disabled,
  children,
  label,
  className,
  onClick,
  icon,
  ...props
}: AccordionComponentProps) => (
  <Container>
    <Thumbnail onClick={onClick}>
      <ThumbnailLabel>
        {icon && (
          <IconWrapper>{icon}</IconWrapper>
        )}
        {label}
      </ThumbnailLabel>
      <ArrowIndicator open={active}>
        <ChevronDownIcon color={'var(--ash)'} />
      </ArrowIndicator>
    </Thumbnail>
    <Panel
      data-testid={'panel'}
      open={active}
    >
      <PanelContent open={active}>
        {children && children}
      </PanelContent>
    </Panel>
  </Container>
);

export type AccordionProps = ComponentProps<typeof Accordion>;
