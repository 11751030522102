import { PrefillKey } from '@lower-financial/mortgage-utils';
import { isPrefillField } from './is-prefill-fields';

export const forEachPrefillValue = (
  data: Record<string, unknown> | undefined,
  callback: (entry: [PrefillKey, string]) => void,
) => data && Object.entries(data).forEach(([propertyName, value]) => {
  if (isPrefillField(propertyName) && value && typeof value === 'string') {
    callback([propertyName, value]);
  }
});
