import { useEffect, useMemo } from 'react';
import { throttle } from 'lodash';

export const VhCalculator = () => {
  const recalculateViewport = useMemo(() => throttle(() => {
    const vh = (window.visualViewport?.height ?? window.innerHeight) * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, 500), []);

  useEffect(() => {
    window.visualViewport?.addEventListener('resize', recalculateViewport);
    return () => window.visualViewport?.removeEventListener('resize', recalculateViewport);
  }, [recalculateViewport]);
  return null;
};

