export enum NumericRangeCategory {
  EmploymentDuration = 'EmploymentDuration',
}

export interface NumericRange {
  Id: string;
  IsActive: boolean;
  Category: NumericRangeCategory;
  Label: string;
}

export interface NumericRangeDictionary {
  [key: string]: NumericRange[]
}
