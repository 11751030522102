export const ReviewStarIcon = ({ ...props }) => (
  <svg
    xmlns={'http://www.w3.org/2000/svg'}
    width={'107'}
    height={'20'}
    viewBox={'0 0 107 20'}
    fill={'none'}
    {...props}
  >
    <title>5 star review</title>
    <g clipPath={'url(#clip0_1004_25125)'}>
      <path
        d={'M20 0H0V20H20V0Z'}
        fill={'#219653'}
      />
      <path
        d={'M41.667 0H21.667V20H41.667V0Z'}
        fill={'#219653'}
      />
      <path
        d={'M63.333 0H43.333V20H63.333V0Z'}
        fill={'#219653'}
      />
      <path
        d={'M85 0H65V20H85V0Z'}
        fill={'#219653'}
      />
      <path
        d={'M106.667 0H86.667V20H106.667V0Z'}
        fill={'#219653'}
      />
      <path
        d={'M10 13.4792L13.0417 12.7083L14.3125 16.625L10 13.4792ZM17 8.41667H11.6458L10 3.375L8.35417 8.41667H3L7.33333 11.5417L5.6875 16.5833L10.0208 13.4583L12.6875 11.5417L17 8.41667Z'}
        fill={'white'}
      />
      <path
        d={'M31.666 13.4792L34.7077 12.7083L35.9785 16.625L31.666 13.4792ZM38.666 8.41667H33.3118L31.666 3.375L30.0202 8.41667H24.666L28.9993 11.5417L27.3535 16.5833L31.6868 13.4583L34.3535 11.5417L38.666 8.41667Z'}
        fill={'white'}
      />
      <path
        d={'M53.333 13.4792L56.3747 12.7083L57.6455 16.625L53.333 13.4792ZM60.333 8.41667H54.9788L53.333 3.375L51.6872 8.41667H46.333L50.6663 11.5417L49.0205 16.5833L53.3538 13.4583L56.0205 11.5417L60.333 8.41667Z'}
        fill={'white'}
      />
      <path
        d={'M75 13.4792L78.0417 12.7083L79.3125 16.625L75 13.4792ZM82 8.41667H76.6458L75 3.375L73.3542 8.41667H68L72.3333 11.5417L70.6875 16.5833L75.0208 13.4583L77.6875 11.5417L82 8.41667Z'}
        fill={'white'}
      />
      <path
        d={'M96.666 13.4792L99.7077 12.7083L100.979 16.625L96.666 13.4792ZM103.666 8.41667H98.3118L96.666 3.375L95.0202 8.41667H89.666L93.9994 11.5417L92.3535 16.5833L96.6869 13.4583L99.3535 11.5417L103.666 8.41667Z'}
        fill={'white'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_1004_25125'}>
        <rect
          width={'106.667'}
          height={'20'}
          fill={'white'}
        />
      </clipPath>
    </defs>
  </svg>
);
