import React, {  useState } from 'react';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { CardRadioGroup } from '@lower-financial/core-components/src/components/card-radio-group/card-radio-group';
import {
  AddressAutocomplete,
  AddressAutocompleteOnChange,
  AddressAutocompleteWrapper,
  doesContainUndefinedFeatures,
  Form,
  formatAddressResult,
  FormRow,
  Heading,
  Label,
  NumberInput,
  OptionalElement,
  Select,
  STATE_OPTIONS,
  TextInput,
  ValidationError,
  ZipcodeInput,
} from '@lower-financial/core-components';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { ENV } from '@lightspeed/environment';

interface AddressFormProps {
  fireAnalyticsEvent: (event: string) => void;
  errors: Map<string, string[]>;
  coBorrowerHasSameAddress: boolean;
  setCoBorrowerHasSameAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

const anyBorrowerAddressFieldsPresent = (mortgageApplication: MortgageApplicationStore) => {
  const fields: (keyof MortgageApplicationStore)[] = ['borrowerStreetAddress', 'borrowerCity', 'borrowerState', 'borrowerZipCode'];

  return fields.some((field) => !!mortgageApplication[field]);
};

const anyCoBorrowerAddressFieldsPresent = (mortgageApplication: MortgageApplicationStore) => {
  const fields: (keyof MortgageApplicationStore)[] = ['coBorrowerStreetAddress', 'coBorrowerCity', 'coBorrowerState', 'coBorrowerZipCode'];

  return fields.some((field) => !!mortgageApplication[field]);
};

export function AddressForm({
  errors,
  coBorrowerHasSameAddress,
  setCoBorrowerHasSameAddress,
  fireAnalyticsEvent,
}: AddressFormProps) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const initialShowFallback = mortgageApplication.borrowerAddressAutocompleteValue === '' && anyBorrowerAddressFieldsPresent(mortgageApplication);
  const initialShowFallbackCoBorrower = mortgageApplication.coBorrowerAddressAutocompleteValue === '' && anyCoBorrowerAddressFieldsPresent(mortgageApplication);
  const [showFallback, setShowFallback] = useState(initialShowFallback);
  const [showFallbackCoBorrower, setShowFallbackCoBorrower] = useState(initialShowFallbackCoBorrower);

  const handleAutocompleteOnChange: AddressAutocompleteOnChange = (argument) => {
    if (argument && doesContainUndefinedFeatures(argument)) {
      setShowFallback((show) => !show);
    }

    const addressResult = formatAddressResult(argument);
    updateMortgageApplication({
      borrowerAddressAutocompleteValue: addressResult.addressAutocompleteValue,
      borrowerCity: addressResult.city,
      borrowerCounty: addressResult.county,
      borrowerState: addressResult.state,
      borrowerStreetAddress: addressResult.streetAddress,
      borrowerZipCode: addressResult.zipCode,
    });
  };

  return (
    <>
      <Heading
        element={'h2'}
        size={'sm'}
        marginTop={'48px'}
      >
        What’s your current address?
      </Heading>
      <Form data-testid={'borrower-form'}>
        <AddressAutocomplete
          fireAnalyticsEvent={fireAnalyticsEvent}
          id={'autocomplete-borrower'}
          setShowFallback={setShowFallback}
          showFallback={showFallback}
          initialSearchText={mortgageApplication.borrowerAddressAutocompleteValue}
          onChange={handleAutocompleteOnChange}
          WrappingElement={AddressAutocompleteWrapper}
          placeholder={'123 Apple Creek Ln. Columbus, OH 43004'}
          apiKey={ENV.GOOGLE_MAPS_API_KEY}
          appEnv={ENV.ENVIRONMENT}
          fallbackUi={(
            <>
              <FormRow>
                <Label flex={1}>
                  Address*
                  <TextInput
                    name={'borrowerStreetAddress'}
                    value={mortgageApplication.borrowerStreetAddress}
                    onChange={(e) => { updateMortgageApplication('borrowerStreetAddress', e.target.value); }}
                    placeholder={'123 Main St.'}
                  />
                  <ValidationError
                    path={'borrowerStreetAddress'}
                    errors={errors}
                  />
                </Label>
                <Label flex={1}>
                  City*
                  <TextInput
                    name={'borrowerCity'}
                    value={mortgageApplication.borrowerCity}
                    onChange={(e) => { updateMortgageApplication('borrowerCity', e.target.value); }}
                    placeholder={'e.g. Columbus'}
                  />
                  <ValidationError
                    path={'borrowerCity'}
                    errors={errors}
                  />
                </Label>
              </FormRow>
              <FormRow>
                <Label flex={['0 0 100%', 1]}>
                  State*
                  <Select
                    name={'state-select'}
                    placeholder={'Select One'}
                    options={[{
                      label: 'Select One',
                      value: '',
                    }, ...STATE_OPTIONS]}
                    value={mortgageApplication.borrowerState}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('borrowerState', e.target.value)}
                  />
                  <ValidationError
                    path={'borrowerState'}
                    errors={errors}
                  />
                </Label>
                <ZipcodeInput
                  displayRequiredSymbol
                  name={'borrowerZipCode'}
                  value={mortgageApplication.borrowerZipCode}
                  onChange={((value) => { updateMortgageApplication('borrowerZipCode', value); })}
                  errorPath={'borrowerZipCode'}
                  errors={errors}
                />
              </FormRow>
            </>
          )}
        />
        <FormRow>
          <Label flex={1}>
            Years at Address*
            <NumberInput
              autoComplete={'off'}
              inputMode={'numeric'}
              name={'borrowerYearsAtAddress'}
              value={mortgageApplication.borrowerYearsAtAddress}
              onChange={(e) => { updateMortgageApplication('borrowerYearsAtAddress', e.target.value); }}
              placeholder={'0'}
            />
            <ValidationError
              path={'borrowerYearsAtAddress'}
              errors={errors}
            />
          </Label>
        </FormRow>
      </Form>
      <OptionalElement show={mortgageApplication.hasCoBorrower}>
        <Heading
          element={'h2'}
          size={'sm'}
          marginTop={'3rem'}
          marginBottom={'1.5rem'}
          isSensitive
        >
          Does {mortgageApplication.coBorrowerFirstName} live here too?
        </Heading>
        <CardRadioGroup
          label={'Does the co-borrower live here too?'}
          items={[
            {
              icon: '🤙',
              label: 'Yep—we’re roomies.',
              value: true,
            },
            {
              icon: '✌',
              label: 'No—they live somewhere else.',
              value: false,
            },
          ]}
          value={coBorrowerHasSameAddress}
          onChange={(val) => {
            if (val) { // clear coborrower address if they have the same address
              updateMortgageApplication('coBorrowerAddressAutocompleteValue', '');
              updateMortgageApplication('coBorrowerStreetAddress', '');
              updateMortgageApplication('coBorrowerCity', '');
              updateMortgageApplication('coBorrowerCounty', '');
              updateMortgageApplication('coBorrowerZipCode', '');
              updateMortgageApplication('coBorrowerState', '');
            }
            setCoBorrowerHasSameAddress(val);
          }}
        />
      </OptionalElement>
      <OptionalElement show={mortgageApplication.hasCoBorrower && !coBorrowerHasSameAddress}>
        <Form data-testid={'coborrower-form'}>
          <Heading
            element={'h2'}
            size={'sm'}
            marginTop={'20px'}
            isSensitive
          >
            {mortgageApplication.coBorrowerFirstName}’s Address
          </Heading>
          <AddressAutocomplete
            fireAnalyticsEvent={fireAnalyticsEvent}
            id={'autocomplete-coborrower'}
            initialSearchText={mortgageApplication.coBorrowerAddressAutocompleteValue}
            setShowFallback={setShowFallbackCoBorrower}
            showFallback={showFallbackCoBorrower}
            onChange={(argument) => {
              if (argument && doesContainUndefinedFeatures(argument)) {
                setShowFallbackCoBorrower((show) => !show);
              }

              const addressResult = formatAddressResult(argument);
              updateMortgageApplication({
                coBorrowerAddressAutocompleteValue: addressResult.addressAutocompleteValue,
                coBorrowerCity: addressResult.city,
                coBorrowerCounty: addressResult.county,
                coBorrowerState: addressResult.state,
                coBorrowerStreetAddress: addressResult.streetAddress,
                coBorrowerZipCode: addressResult.zipCode,
              });
            }}
            WrappingElement={AddressAutocompleteWrapper}
            placeholder={'123 Apple Creek Ln. Columbus, OH 43004'}
            apiKey={ENV.GOOGLE_MAPS_API_KEY}
            appEnv={ENV.ENVIRONMENT}
            fallbackUi={(
              <>
                <FormRow>
                  <Label flex={1}>
                    Address*
                    <TextInput
                      name={'coBorrowerStreetAddress'}
                      value={mortgageApplication.coBorrowerStreetAddress}
                      onChange={(e) => { updateMortgageApplication('coBorrowerStreetAddress', e.target.value); }}
                      placeholder={'123 Main St.'}
                    />
                    <ValidationError
                      path={'coBorrowerStreetAddress'}
                      errors={errors}
                    />
                  </Label>
                  <Label flex={1}>
                    City*
                    <TextInput
                      name={'coBorrowerCity'}
                      value={mortgageApplication.coBorrowerCity}
                      onChange={(e) => { updateMortgageApplication('coBorrowerCity', e.target.value); }}
                      placeholder={'e.g. Columbus'}
                    />
                    <ValidationError
                      path={'coBorrowerCity'}
                      errors={errors}
                    />
                  </Label>
                </FormRow>
                <FormRow>
                  <Label flex={['0 0 100%', 1]}>
                    State*
                    <Select
                      name={'coborrower-state-select'}
                      options={[{
                        label: 'Select One',
                        value: '',
                      }, ...STATE_OPTIONS]}
                      value={mortgageApplication.coBorrowerState}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('coBorrowerState', e.target.value)}
                    />
                    <ValidationError
                      path={'coBorrowerState'}
                      errors={errors}
                    />
                  </Label>
                  <ZipcodeInput
                    displayRequiredSymbol
                    name={'coBorrowerZipCode'}
                    value={mortgageApplication.coBorrowerZipCode}
                    onChange={((value) => { updateMortgageApplication('coBorrowerZipCode', value); })}
                    errorPath={'coBorrowerZipCode'}
                    errors={errors}
                  />
                </FormRow>
              </>
            )}
          />
        </Form>
      </OptionalElement>
    </>
  );
}
