import styled from 'styled-components';
import { responsiveStyleFromTheme } from '@lower-financial/core-components/src/styles/utils/theme-utils';

interface FormRowProps {
  className?: string;
  columnOnMobile?: boolean;
}

export const FormRow = styled.div.attrs(
  ({
    className,
    ...props
  }: FormRowProps) => ({
    className,
    ...props,
  }),
)<FormRowProps>`
  display: flex;
  flex-wrap: wrap;
  ${(props) => (props.columnOnMobile
    ? 'flex-direction: column;'
    : '')}
  gap: 1.125rem;
  > * {
    flex: 1;
  }
  ${responsiveStyleFromTheme({
    desktop: {
      flexDirection: 'row',
    },
  })}
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
  ${responsiveStyleFromTheme({
    desktop: {
      gap: '2rem',
    },
  })}
`;

export const  FormHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100% ;
`;
