import { DateTime } from 'luxon';
import { isBusinessHours } from './is-business-hours';
import { STATE_TO_TIMEZONE, Timezone } from './us-states';

export type CalendlyBorrower = {
  state: string | null,
  firstName: string | null,
  lastName: string | null,
  email: string | null,
  phoneNumber: string | null
};

export function getCalendlyTimezoneFromState(state: string) {
  const timezoneToCalendlyMapping = {
    [Timezone.AKST]: 'ak',
    [Timezone.CST]: 'ct',
    [Timezone.EST]: 'et',
    [Timezone.HST]: 'hi',
    [Timezone.MST]: 'mt',
    [Timezone.PST]: 'pt',
  };

  const stateTimezone = STATE_TO_TIMEZONE.get(state.toUpperCase());

  if (!stateTimezone) {
    throw new Error(`No timezone found for state: ${state}`);
  }

  return timezoneToCalendlyMapping[stateTimezone];
}

export const buildCalendlyUrl = (
  prefillUrl: string,
  activeLeadId: string | null,
  borrower: CalendlyBorrower,
  utmSource: string,
) => {
  const calendlyPrefillBaseUrl = `${prefillUrl}lower-com-loan-consultation-`;

  const borrowerStateToUser = !borrower.state
    ? 'CA'
    : borrower.state;

  const timeZoneSuffix = getCalendlyTimezoneFromState(borrowerStateToUser);
  const calendlyUrl = new URL(`${calendlyPrefillBaseUrl}${timeZoneSuffix}`);

  if (borrower.firstName) {
    calendlyUrl.searchParams.append('first_name', borrower.firstName);
  }
  if (borrower.lastName) {
    calendlyUrl.searchParams.append('last_name', borrower.lastName);
  }
  if (borrower.email) {
    calendlyUrl.searchParams.append('email', borrower.email);
  }
  if (borrower.phoneNumber) {
    const USCountryCode = '1';
    calendlyUrl.searchParams.append('a1', `${USCountryCode}${borrower.phoneNumber}`);
  }
  calendlyUrl.searchParams.append('utm_medium', 'web');
  calendlyUrl.searchParams.append('utm_source', utmSource);
  calendlyUrl.searchParams.append('utm_campaign', isBusinessHours(DateTime.now())
    ? 'businesshours'
    : 'afterhours');
  calendlyUrl.searchParams.append('utm_content', 'scheduleforlater');
  if (activeLeadId) {
    calendlyUrl.searchParams.append('sfid', activeLeadId);
  }
  return calendlyUrl;
};
