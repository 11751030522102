/* tslint:disable */
/* eslint-disable */
/**
 * Lower API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CurrencyRangeCategory = {
    AnnualIncome: 'annualIncome',
    PropertyValue: 'propertyValue'
} as const;
export type CurrencyRangeCategory = typeof CurrencyRangeCategory[keyof typeof CurrencyRangeCategory];


export function CurrencyRangeCategoryFromJSON(json: any): CurrencyRangeCategory {
    return CurrencyRangeCategoryFromJSONTyped(json, false);
}

export function CurrencyRangeCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyRangeCategory {
    return json as CurrencyRangeCategory;
}

export function CurrencyRangeCategoryToJSON(value?: CurrencyRangeCategory | null): any {
    return value as any;
}

