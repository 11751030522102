import { useCallback, useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Analytics } from './analytics';

export function useAnalytics(pageName: string, skipPageView = false) {
  const postHog = usePostHog();

  useEffect(() => {
    if (!skipPageView) {
      Analytics.pageView(
        pageName,
        window.location.href,
        window.location.pathname,
      );
      postHog.capture('page_view', {
        pageName,
      });
    }
  }, [pageName, postHog, skipPageView]);

  // return a function that prefixes the events triggered with <pageName_
  return useCallback((
    eventName: string,
    eventMetadata: Parameters<typeof Analytics.event>[1] = {},
  ) => {
    const fullEvent = `${pageName}_${eventName}`;
    Analytics.event(
      fullEvent,
      eventMetadata,
    );
  }, [pageName]);
}
