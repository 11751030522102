export const LeftArrowIcon = ({ color = '#1D1D23', ...props }) => (
  <svg
    width={'15'}
    height={'11'}
    viewBox={'0 0 15 11'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <line
      x1={'14'}
      y1={'5.09961'}
      x2={'2'}
      y2={'5.09961'}
      stroke={color}
      strokeWidth={'2'}
      strokeLinecap={'round'}
    />
    <path
      d={'M5.19922 1.09961L0.999219 5.29961L5.19922 9.49961'}
      stroke={color}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>

);
