import React from 'react';
import {
  coborrowerEmploymentStatusOptions,
  EmploymentStatus,
  employmentStatusOptions,
  Form,
  FormRow,
  Heading,
  Label,
  NumberInput,
  OptionalElement,
  Select,
  Symbol,
  TextInput,
  ToolTip,
  ValidationError,
} from '@lower-financial/core-components';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import {
  MortgageApplicationState,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';

interface IncomeFormProps {
  mortgageApplication: MortgageApplicationStore;
  updateMortgageApplication: MortgageApplicationState['updateMortgageApplication'];
  errors: Map<string, string[]>|null;
}

export function EmployerForm({
  mortgageApplication, updateMortgageApplication, errors,
}: IncomeFormProps) {
  const { hasCoBorrower } = mortgageApplication;
  return (
    <>
      <Form>
        <Heading element={'h4'}>Employment</Heading>
        <FormRow>
          <Label flex={['0 0 100%', 1]}>
            Employer*
            <TextInput
              name={'borrowerEmployer'}
              value={mortgageApplication.borrowerEmployer}
              onChange={(e) => updateMortgageApplication('borrowerEmployer', e.target.value)}
              placeholder={'Company ABC'}
            />
            <ValidationError
              path={'borrowerEmployer'}
              errors={errors}
            />
          </Label>
          <Label flex={['0 0 100%', 1]}>
            <span>
              Employment Status*
              <ToolTip
                bubble={'Some more specifics about your current employment situation help us see how stable your income will be going forward. '}
              >
                <Symbol type={'Info'} />
              </ToolTip>
            </span>
            <Select
              name={'borrowerEmploymentStatus'}
              placeholder={'Select One'}
              options={employmentStatusOptions}
              value={mortgageApplication.borrowerEmploymentStatus}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('borrowerEmploymentStatus', e.target.value)}
            />
            <ValidationError
              path={'borrowerEmploymentStatus'}
              errors={errors}
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label flex={['0 0 100%', 1]}>
            Years at Company*
            <NumberInput
              name={'borrowerYearsAtCompany'}
              value={mortgageApplication.borrowerYearsAtCompany}
              onChange={(e) => { updateMortgageApplication('borrowerYearsAtCompany', e.target.value); }}
              placeholder={'0'}
            />
            <ValidationError
              path={'borrowerYearsAtCompany'}
              errors={errors}
            />
          </Label>
          <Label flex={['0 0 100%', 1]}>
            Job Title*
            <TextInput
              name={'borrowerTitle'}
              value={mortgageApplication.borrowerTitle}
              onChange={(e) => updateMortgageApplication('borrowerTitle', e.target.value)}
              placeholder={'Role'}
            />
            <ValidationError
              path={'borrowerTitle'}
              errors={errors}
            />
          </Label>
        </FormRow>
      </Form>
      <OptionalElement show={hasCoBorrower}>
        <Form>
          <Heading
            element={'h4'}
            isSensitive
          >{mortgageApplication.coBorrowerFirstName}’s Employment
          </Heading>
          <FormRow>
            <Label
              flex={['0 0 100%', 4]}
              disabled={mortgageApplication.coBorrowerEmploymentStatus === EmploymentStatus.UNEMPLOYED}
            >
              Employer*
              <TextInput
                disabled={mortgageApplication.coBorrowerEmploymentStatus === EmploymentStatus.UNEMPLOYED}
                name={'coBorrowerEmployer'}
                value={mortgageApplication.coBorrowerEmployer}
                onChange={(e) => updateMortgageApplication('coBorrowerEmployer', e.target.value)}
                placeholder={'Company ABC'}
              />
              <ValidationError
                path={'coBorrowerEmployer'}
                errors={errors}
              />
            </Label>
            <Label flex={[6, 4]}>
              <span>
                Employment Status*
                <ToolTip
                  bubble={'Some more specifics about your current employment situation help us see how stable your income will be going forward. '}
                >
                  <Symbol type={'Info'} />
                </ToolTip>
              </span>
              <Select
                name={'coBorrowerEmploymentStatus'}
                placeholder={'Select One'}
                options={coborrowerEmploymentStatusOptions}
                value={mortgageApplication.coBorrowerEmploymentStatus}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('coBorrowerEmploymentStatus', e.target.value)}
              />
              <ValidationError
                path={'coBorrowerEmploymentStatus'}
                errors={errors}
              />
            </Label>
            <Label
              flex={[4, 2]}
              disabled={mortgageApplication.coBorrowerEmploymentStatus === EmploymentStatus.UNEMPLOYED}
            >
              Years at Company*
              <NumberInput
                disabled={mortgageApplication.coBorrowerEmploymentStatus === EmploymentStatus.UNEMPLOYED}
                name={'coBorrowerYearsAtCompany'}
                value={mortgageApplication.coBorrowerYearsAtCompany}
                onChange={(e) => { updateMortgageApplication('coBorrowerYearsAtCompany', e.target.value); }}
                placeholder={'0'}
              />
              <ValidationError
                path={'coBorrowerYearsAtCompany'}
                errors={errors}
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label
              flex={[6, '0 0 50%']}
              disabled={mortgageApplication.coBorrowerEmploymentStatus === EmploymentStatus.UNEMPLOYED}
            >
              Job Title*
              <TextInput
                disabled={mortgageApplication.coBorrowerEmploymentStatus === EmploymentStatus.UNEMPLOYED}
                name={'coBorrowerTitle'}
                value={mortgageApplication.coBorrowerTitle}
                onChange={(e) => updateMortgageApplication('coBorrowerTitle', e.target.value)}
                placeholder={'Role'}
              />
              <ValidationError
                path={'coBorrowerTitle'}
                errors={errors}
              />
            </Label>
          </FormRow>
        </Form>
      </OptionalElement>
    </>
  );
}
