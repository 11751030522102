import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { ContractStatus } from '@lower-financial/lending-web-api/generated';

interface MortgageApplicationDefaults extends Partial<MortgageApplicationStore> {
  borrowerAmountInMutualFunds: '0',
  borrowerContractStatus: ContractStatus,
  borrowerEmploymentStatus: 'W-2',
  borrowerFirstTimeHomeBuyer: 'No',
  borrowerOtherIncomePerYear: '0',
  coBorrowerAmountInMutualFunds: '0',
  coBorrowerAmountInSavings: '0',
  coBorrowerEmploymentStatus: 'W-2',
  coBorrowerOtherIncomePerYear: '0',
  propertyCashOut: '0',
  propertyDownPaymentPartiallyGift: 'No',
}

export const MORTGAGE_APPLICATION_DEFAULTS: MortgageApplicationDefaults = {
  borrowerAmountInMutualFunds: '0',
  borrowerContractStatus: ContractStatus.StillLooking,
  borrowerEmploymentStatus: 'W-2',
  borrowerFirstTimeHomeBuyer: 'No',
  borrowerOtherIncomePerYear: '0',
  coBorrowerAmountInMutualFunds: '0',
  coBorrowerAmountInSavings: '0',
  coBorrowerEmploymentStatus: 'W-2',
  coBorrowerOtherIncomePerYear: '0',
  propertyCashOut: '0',
  propertyDownPaymentPartiallyGift: 'No',
};
