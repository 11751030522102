import { CheckBox, Form, FormRow, Label, Link, Paragraph, PhoneInput, TextInput, ValidationError } from '@lower-financial/core-components';
import styled from 'styled-components';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

interface AboutYouFormProps {
  errors: Map<string, string[]>|null;
  disabled: boolean;
}

export function AboutYouForm({ errors, disabled }: AboutYouFormProps) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();

  return (
    <Form>
      <FormRow>
        <Label>
          First Name
          <TextInput
            name={'borrowerFirstName'}
            value={mortgageApplication.borrowerFirstName}
            onChange={(e) => {
              updateMortgageApplication('borrowerFirstName', e.target.value);
            }}
            disabled={disabled}
          />
          <ValidationError
            path={'borrowerFirstName'}
            errors={errors}
          />
        </Label>
        <Label>
          Last Name
          <TextInput
            name={'borrowerLastName'}
            value={mortgageApplication.borrowerLastName}
            onChange={(e) => {
              updateMortgageApplication('borrowerLastName', e.target.value);
            }}
            disabled={disabled}
          />
          <ValidationError
            path={'borrowerLastName'}
            errors={errors}
          />
        </Label>
      </FormRow>
      <FormRow columnOnMobile>
        <Label>
          Email
          <TextInput
            $hasError={!!errors?.get('borrowerEmail')}
            inputMode={'email'}
            name={'borrowerEmail'}
            value={mortgageApplication.borrowerEmail}
            onChange={(e) => {
              updateMortgageApplication('borrowerEmail', e.target.value);
            }}
            placeholder={'me@example.com'}
            disabled={disabled}
          />
          <ValidationError
            path={'borrowerEmail'}
            errors={errors}
          />
        </Label>
        <Label>
          Phone
          <PhoneInput
            name={'borrower-phone-number'}
            value={mortgageApplication.borrowerPhoneNumber}
            onChange={(v) => updateMortgageApplication('borrowerPhoneNumber', v)}
            disabled={disabled}
          />
          <ValidationError
            path={'borrowerPhoneNumber'}
            errors={errors}
          />
        </Label>
      </FormRow>
      <FormRow columnOnMobile>
        <div>
          <CheckBox
            checked={!!mortgageApplication.borrowerSmsConsentedAt}
            onChange={(val) => {
              const consented = val
                ? new Date().toISOString()
                : null;
              updateMortgageApplication('borrowerSmsConsentedAt', consented);
            }}
            id={'exclude-checkbox'}
            label={<CheckboxLabel variant={'small'}>Text me important loan info</CheckboxLabel>}
            ariaLabel={'Text me important loan info'}
          />

          <Paragraph variant={'smallLight'}>
            By checking this box, you agree to receive transactional text messages (e.g. loan updates) and
            automated alerts from Lower at the cell number used when signing up. You will still receive
            important loan updates via email. Msg frequency varies. Msg and data rates may apply.
            Reply STOP to cancel. Reply HELP for help.

            <InlineLink
              href={'https://www.lower.com/legal/privacy-policy'}
              title={'Lower Privacy Policy'}
            >
              Lower Privacy Policy
            </InlineLink>.

            <InlineLink
              href={'https://www.lower.com/legal/sms-terms-and-conditions'}
              title={'SMS Terms and Conditions'}
            >
              SMS Terms and Conditions
            </InlineLink>.
          </Paragraph>
        </div>
      </FormRow>
    </Form>
  );
}

const CheckboxLabel = styled(Paragraph)`
  margin-top: 0.25rem;
`;

const InlineLink = styled(Link)`
  display: inline-block;
  color: var(--charcoal);
  margin: auto 0.125rem;
`;
