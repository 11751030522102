import styled from 'styled-components';
import { ChangeEventHandler, FocusEventHandler, FunctionComponent, ComponentProps } from 'react';
import cx from 'classnames';
import { ChevronDown } from '@lower-financial/icons';

interface SelectComponentProps {
  label?: string;
  className?: string;
  disabled?: boolean;
  value: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  placeholder?: string;
  options?: Array<SelectComponentOption>;
  hasError?: boolean;
  onFocus?: FocusEventHandler<HTMLSelectElement>;
  onBlur?: () => void;
  name?: string,
}

interface SelectComponentOption {
  key?: string;
  label: string;
  value: string;
  disabled?: boolean;
  hidden?: boolean;
}

export const Select = ({
  label,
  className,
  value,
  onChange,
  placeholder = 'Select an option',
  options = [],
  disabled = false,
  hasError = false,
  onFocus,
  onBlur = () => {
    // do nothing
  },
  name,
}: SelectComponentProps) => (
  <DropDown
    aria-label={label}
    name={name}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
    onChange={onChange}
    hasError={hasError}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    {
      options && options.map(
        ({
          label: optionLabel, hidden = false, value: optionVal, disabled: optionDisabled,
        }) => (
          <option
            key={optionLabel}
            value={optionVal}
            hidden={hidden}
            disabled={optionDisabled}
          >
            {optionLabel}
          </option>
        ),
      )
    }
  </DropDown>

);

type DropDownProps = JSX.IntrinsicElements['select'] & {
  hasError?: boolean;
};

export const DropDown: FunctionComponent<DropDownProps> = styled.select.attrs<SelectProps>((
  {
    className,
    disabled,
    hasError,
    ...props
  },
) => ({
  className: cx(className, {
    'has-error': hasError,
    'is-disabled': disabled,
  }),
  disabled,
  ...props,
}))`
  appearance: none;
  cursor: pointer;
  font: var(--font-paragraph-2);
  letter-spacing: -0.04em;
  height: 50px;
  width: 100%;
  border: 1px solid;
  border-color: var(--border);
  border-radius: 12px;
  padding: 15px 35px 15px 20px;
  background: url(${ChevronDown}) 92% center no-repeat var(--white);

  ${(props) => `color: ${props.value === ''
    ? 'var(--placeholder)'
    : 'var(--input)'};`}

  &.has-error {
    border: 1px solid;
    border-color: var(--error);
  }
  &:focus-visible {
    outline: 1px solid var(--primary);
  }

  option {
    all: unset;
    font: var(--font-paragraph-2);
    display: block;
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px 20px;
    width: 100%;
    color: var(--primary);
    background-color: var(--white);

    &:focus-visible {
      outline: 1px solid var(--primary);
    }
    &:hover {
      background-color: var(--gray);
    }
  }
`;

export type SelectProps = ComponentProps<typeof Select>;
