import styled from 'styled-components';

const defaultLength = 14; // this is based on the sum of the dash array lengths plus line caps

export const VerticalConnector = ({
  color = '#E6E6EF', length = defaultLength, dashed = false, ...props
}) => (
  <ConnectorContainer
    length={length}
    {...props}
  >
    <svg
      viewBox={`0 0 2 ${length}`}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <title>{
        dashed
          ? 'Dashed connector'
          : 'Solid connector'
      }
      </title>
      <line
        x1={'1'}
        y1={'1'}
        x2={'1'}
        y2={`${-1 + length}`}
        stroke={color}
        strokeWidth={2}
        strokeLinecap={'round'}
        strokeDasharray={
          dashed
            ? '1 4 2 4'
            : ''
        }
      />
    </svg>
  </ConnectorContainer>
);

const ConnectorContainer = styled.div<{ length: number }>`
  display: flex;
  justify-content: center;
  height: calc(0.75rem * ${(props) => props.length}/${defaultLength});
`;
