import { MachineOptions } from 'xstate';
import {
  ContractStatus,
  JumpstartEligibilityStatus,
  LoanPurpose,
} from '@lower-financial/lending-web-api/generated';
import { PropertyResidenceType } from '@lower-financial/mortgage-utils/src/mortgage-application';
import { Guard } from 'xstate/lib/types';
import { FeatureFlags } from '@lower-financial/feature-flags';
import { isStringIndexable } from '@lower-financial/toolbox';
import { RoutingEvent } from '@lightspeed/routing/routing-events';
import { RoutingContext } from '@lightspeed/routing/routing-context';

export enum GUARDS {
  isHeloc = 'isHeloc',
  isPurchase = 'isPurchase',
  isRefinance = 'isRefinance',
  hasRates = 'hasRates',
  hasCoBorrower = 'hasCoBorrower',
  inContract = 'inContract',
  stillLooking = 'stillLooking',
  isUnsupportedMarket = 'isUnsupportedMarket',
  requiresRateSelection = 'requiresRateSelection',
  isFeatureFlagEnabled = 'isFeatureFlagEnabled',
  isPartnerPurchase = 'isPartnerPurchase',
  isPartnerRefinance = 'isPartnerRefinance',
  isPartnerHeloc = 'isPartnerHeloc',
  showPartnerWarmUp = 'showPartnerWarmUp',
  isPrimaryResidenceType = 'isPrimaryResidenceType',
  continueToJumpstart = 'continueToJumpstart',
  jumpstartManualIntervention = 'jumpstartManualIntervention',
}

interface FeatureFlagGuardMeta {
  featureFlagName: keyof FeatureFlags;
  featureFlagValue: string | boolean;
}

function featureFlagEnabled(
  context: RoutingContext,
  cond: Guard<RoutingContext, RoutingEvent> | FeatureFlagGuardMeta,
) {
  // this function is rather verbose because of the `any` types in the Guard interface from xstate
  if (
    'featureFlagName' in cond
    && 'featureFlagValue' in cond
    && typeof cond.featureFlagName === 'string'
    && isStringIndexable(context.featureFlags)
    && cond.featureFlagName in context.featureFlags
  ) {
    // @ts-expect-error typescript is not smart enough to know we already checked that cond.featureFlagName is in cond.featureFlags
    return context.featureFlags[cond.featureFlagName] === cond.featureFlagValue;
  }
  return false;
}

export const guards: MachineOptions<RoutingContext, RoutingEvent>['guards'] = {
  [GUARDS.isHeloc]: (context) => context.loanPurpose === LoanPurpose.Heloc,
  [GUARDS.isPurchase]: (context) => context.loanPurpose === LoanPurpose.Purchase,
  [GUARDS.isRefinance]: (context) => context.loanPurpose === LoanPurpose.Refinance,
  [GUARDS.hasCoBorrower]: (context) => context.hasCoBorrower,
  [GUARDS.hasRates]: (context) => context.hasRates,
  [GUARDS.stillLooking]: (context) => context.borrowerContractStatus === ContractStatus.StillLooking,
  [GUARDS.inContract]: (context) => context.borrowerContractStatus === ContractStatus.InContract,
  [GUARDS.isUnsupportedMarket]: (context) => context.isUnsupportedMarket,
  [GUARDS.requiresRateSelection]: (context) => context.hasRates && !context.quoteChosen,
  [GUARDS.isPartnerPurchase]: (context) => context.partnerLoanPurpose === LoanPurpose.Purchase,
  [GUARDS.isPartnerRefinance]: (context) => context.partnerLoanPurpose === LoanPurpose.Refinance,
  [GUARDS.isPartnerHeloc]: (context) => context.partnerLoanPurpose === LoanPurpose.Heloc,
  [GUARDS.showPartnerWarmUp]: (context) => context.showPartnerWarmUp,
  [GUARDS.isFeatureFlagEnabled]: (context, _, { cond }) => featureFlagEnabled(context, cond),
  [GUARDS.isPrimaryResidenceType]: (context) =>
    context.propertyResidenceType === PropertyResidenceType.PrimaryResidence,
  [GUARDS.continueToJumpstart]: (context) =>
    context.jumpstartEligibility === JumpstartEligibilityStatus.ContinueToJumpstart,
  [GUARDS.jumpstartManualIntervention]: (context) =>
    context.jumpstartEligibility === JumpstartEligibilityStatus.ManualIntervention,
};
