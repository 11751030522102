import {
  Form,
  FormRow,
  Heading,
  Label,
  CurrencyInput,
  OptionalElement,
  Symbol,
  ToolTip,
  ValidationError,
} from '@lower-financial/core-components';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { MortgageApplicationState } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';

interface IncomeFormProps {
  mortgageApplication: MortgageApplicationStore;
  updateMortgageApplication: MortgageApplicationState['updateMortgageApplication'];
  errors: Map<string, string[]>|null;
}

const MAX_AMOUNT = 25000000;

function capToMaxAmount(v: string) {
  const value = parseInt(v, 10);

  if (Number.isNaN(value)) {
    return '';
  }

  return Math.min(MAX_AMOUNT, value).toString();
}

export function IncomeForm({
  mortgageApplication, updateMortgageApplication, errors,
}: IncomeFormProps) {
  const { hasCoBorrower } = mortgageApplication;
  return (
    <>
      <Form>
        <Heading element={'h4'}>Income</Heading>
        <FormRow>
          <Label flex={['0 0 100%', 1]}>
            <span>
              Primary Annual Income (Gross)
              <ToolTip bubble={'Let us know how much money you make every year before taxes. An estimate of your income works if you don\'t have an exact number.'}>
                <Symbol type={'Info'} />
              </ToolTip>
            </span>
            <CurrencyInput
              allowDecimals={false}
              name={'borrowerYearlySalary'}
              placeholder={'$50,000'}
              value={mortgageApplication.borrowerYearlySalary}
              onChange={(v) => updateMortgageApplication('borrowerYearlySalary', capToMaxAmount(v || ''))}
            />
            <ValidationError
              path={'borrowerYearlySalary'}
              errors={errors}
            />
          </Label>
          <Label flex={['0 0 100%', 1]}>
            <span>
              Other Annual Income (Bonus, Commission, etc.)
              <ToolTip
                bubble={'This is your chance to let us know about any additional income you make via bonuses, commissions or even rentals. Only include alimony, child support, separate maintenance, or other income if you want it to be considered for your loan.'}
              >
                <Symbol type={'Info'} />
              </ToolTip>
            </span>
            <CurrencyInput
              allowDecimals={false}
              name={'borrowerOtherIncomePerYear'}
              placeholder={'$0'}
              value={mortgageApplication.borrowerOtherIncomePerYear}
              onChange={(v) => updateMortgageApplication('borrowerOtherIncomePerYear', capToMaxAmount(v || ''))}
            />
            <ValidationError
              path={'borrowerOtherIncomePerYear'}
              errors={errors}
            />
          </Label>
        </FormRow>
      </Form>
      <OptionalElement show={hasCoBorrower}>
        <Form>
          <Heading
            element={'h4'}
            isSensitive
          >{mortgageApplication.coBorrowerFirstName}’s Income
          </Heading>
          <FormRow>
            <Label flex={['0 0 100%', 1]}>
              Primary Annual Income (Gross)
              <CurrencyInput
                allowDecimals={false}
                name={'coBorrowerYearlySalary'}
                placeholder={'$50,000'}
                value={mortgageApplication.coBorrowerYearlySalary}
                onChange={(v) => updateMortgageApplication('coBorrowerYearlySalary', capToMaxAmount(v || ''))}
              />
              <ValidationError
                path={'coBorrowerYearlySalary'}
                errors={errors}
              />
            </Label>
            <Label flex={['0 0 100%', 1]}>
              Other Annual Income (Bonus, Commission, etc.)
              <CurrencyInput
                allowDecimals={false}
                name={'coBorrowerOtherIncomePerYear'}
                placeholder={'$0'}
                value={mortgageApplication.coBorrowerOtherIncomePerYear}
                onChange={(v) => updateMortgageApplication('coBorrowerOtherIncomePerYear', capToMaxAmount(v || ''))}
              />
              <ValidationError
                path={'coBorrowerOtherIncomePerYear'}
                errors={errors}
              />
            </Label>
          </FormRow>
        </Form>
      </OptionalElement>
    </>
  );
}
