import { useCallback, useMemo, useState } from 'react';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { objectContainsFalsyPropsForKeys } from '@lower-financial/toolbox';
import { useErrorHandling, useValidation } from '@lower-financial/core-components';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { useMortgageAnalytics } from '@lightspeed/hooks/useMortgageAnalytics/use-mortgage-analytics';
import { NextStepButton } from '@lightspeed/components/shared/buttons/next-step-button';
import { PageLayout } from '@lightspeed/components/shared/page-layout/page-layout';
import { BorrowerAddressForm } from './borrower-address-form';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerStreetAddress',
  'borrowerCity',
  'borrowerZipCode',
  'borrowerState',
];

const title = 'And where is home right now?';
const subtitle = (
  <>
    Welcome to my crib.&nbsp;🏰
  </>
);

const BorrowerAddressPurchaseTestimonial = () => (
  <Testimonial
    title={'Great first home buying experience.'}
    content={'This was our first home buying experience and the Lower team really made it easier than expected. Our advisors were very supportive and always available to contact when I had any questions or concerns about the whole process. Definitely recommend to anyone looking to buy a home! Great customer service and care about their clients!'}
    author={'Jakob B'}
    date={'2022-09-23'}
  />
);

const BorrowerAddressHelocTestimonial = () => (
  <Testimonial
    title={'Loan Process Made Easy'}
    content={'I was recently shopping around for a HELOC, Lower was recommended by a friend and I can see why! Lower was able to explain what my options were and take me through each step of the process. I would recommend Lower.com to anyone!'}
    author={'Andrea'}
    date={'2022-09-08'}
  />
);

export function BorrowerAddress() {
  const { mortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const handleError = useErrorHandling();

  const fireAnalyticsEvent = useMortgageAnalytics('borrower_address');

  const { upsertApplication } = useUpsertApplication();
  const [isLoading, setIsLoading] = useState(false);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: borrowerKeys,
  });

  const disableNextButton = isLoading || objectContainsFalsyPropsForKeys(
    mortgageApplication,
    borrowerKeys,
  );

  const onNextClick = useCallback(
    () => {
      (async () => {
        if (!validate(mortgageApplication).success) {
          return;
        }

        setIsLoading(true);
        await handleError({
          onRetryableError: () => {
            setIsLoading(false);
          },
          tryFn: async () => {
            await upsertApplication();
            fireAnalyticsEvent('next_button_success');
            goToNextRoute();
          },
        });
      })();
    },
    [fireAnalyticsEvent, goToNextRoute, handleError, mortgageApplication, upsertApplication, validate],
  );

  const nextStepButton = useMemo(
    () => (
      <NextStepButton
        isLoading={isLoading}
        disabled={disableNextButton}
        onClick={onNextClick}
      />
    ),
    [disableNextButton, isLoading, onNextClick],
  );

  return (
    <Layout>
      <PageLayout
        title={title}
        subtitle={subtitle}
        button={nextStepButton}
        desktopSidebar={mortgageApplication.loanPurpose === LoanPurpose.Purchase
          ? <BorrowerAddressPurchaseTestimonial />
          : <BorrowerAddressHelocTestimonial />
        }
      >
        <BorrowerAddressForm
          isLoading={isLoading}
          errors={errors || new Map()}
          fireAnalyticsEvent={fireAnalyticsEvent}
        />
      </PageLayout>
    </Layout>
  );
}
