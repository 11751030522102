import { ContractStatus } from '@lower-financial/lending-web-api/generated';
import { useAnalytics } from '@lower-financial/analytics';
import { CardToggleLayout } from '@lightspeed/components/shared/card-toggle-screen-layout/card-toggle-layout';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';

const Status = [
  {
    icon: '⭐️',
    label: 'Yep, I found the one.',
    value: ContractStatus.PlacedOffer,
  },
  {
    icon: '🥳',
    label: 'Yep, my offer was accepted.',
    value: ContractStatus.InContract,
  },
  {
    icon: '🛒',
    label: 'No, I’m still shopping.',
    value: ContractStatus.StillLooking,
  },
] as const;

const ContractStatusTestimonial = () => (
  <Testimonial
    title={'Highly recommend.'}
    content={'My loan advisor was available 24/7 for me. It didn’t matter what time of the day or night it was, when I had a question and I messaged him, he messaged right back. He went above and bevond what was expected of him. I would definitely work with Lower again in the future and plan on recommending Lower to any/all friends who are in the home buying process.'}
    author={'Jasmine Sinkhada'}
  />
);

export const PurchaseContractStatus = () => {
  const fireAnalyticsEvent = useAnalytics('contract-status');

  return (
    <CardToggleLayout
      title={'Do you have a specific home in mind?'}
      subtitle={'Here’s to finding one with great vibes. ✨'}
      mortgageApplicationField={'borrowerContractStatus'}
      cardOptions={Status}
      fireAnalyticsEvent={fireAnalyticsEvent}
      desktopSidebar={<ContractStatusTestimonial />}
    />
  );
};
