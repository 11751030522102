import { ReactNode, useEffect } from 'react';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { importStylesheets } from '@lightspeed/assets/partners/partners-config';

export const PartnerStylesheetProvider = ({ children }: { children?: ReactNode | ReactNode[] }) => {
  const { partnerConfiguration: { partnerName } } = usePartnerConfiguration();

  useEffect(() => {
    if (partnerName) {
      importStylesheets(partnerName);
    }
  }, [partnerName]);

  return <>{children}</>;
};
