import styled from 'styled-components';
import CurrencyBaseInput from 'react-currency-input-field';
import React, { ComponentProps, Ref } from 'react';
import { TransitionTokens } from '../../styles/primary/tokens/transitions';

interface CurrencyInputComponentProps {
  value: string;
  onChange: (value?: string) => void;
  placeholder: string;
  allowDecimals?: boolean;
  ref?: Ref<HTMLInputElement>;
  hasError?: boolean;
  name?: string;
  maxAmount?: number;
  disabled?: boolean;
}

export function CurrencyInput({
  value,
  onChange,
  placeholder,
  ref,
  allowDecimals = true,
  hasError = false,
  name,
  maxAmount,
  disabled = false,
}: CurrencyInputComponentProps) {
  return (
    <MaskedCurrencyInput
      name={name}
      value={value}
      onValueChange={(v) => {
        if (maxAmount && v) {
          const parsedNumber = parseFloat(v);

          if (Number.isNaN(parsedNumber) || !Number.isFinite(parsedNumber)) {
            onChange(v);
            return;
          }
          onChange(Math.min(maxAmount, parsedNumber).toString());
        } else {
          onChange(v);
        }
      }}
      placeholder={placeholder}
      ref={ref}
      prefix={'$'}
      decimalSeparator={'.'}
      groupSeparator={','}
      allowNegativeValue={false}
      allowDecimals={allowDecimals}
      decimalScale={allowDecimals
        ? 2
        : 0}
      decimalsLimit={allowDecimals
        ? 2
        : 0}
      $hasError={hasError}
      disabled={disabled}
    />
  );
}

const MaskedCurrencyInput = styled(CurrencyBaseInput)<{ $hasError: boolean }>(({ $hasError }) => ({
  '&:-ms-input-placeholder': {
    color: 'var(--body-light)',
  },
  '&::-ms-input-placeholder': {
    color: 'var(--body-light)',
  },
  '&::placeholder': {
    color: 'var(--body-light)',
    opacity: 1,
  },
  alignItems: 'center',
  background: 'var(--background)',
  border: '1px solid',
  borderColor: `${$hasError
    ? 'var(--error)'
    : 'var(--silk)'}`,
  borderRadius: 12,
  boxSizing: 'border-box',
  color: 'var(--input)',
  display: 'flex',
  flexDirection: 'row',
  font: 'var(--font-paragraph-2)',
  letterSpacing: '-0.04em',
  padding: '15px 20px',
  transition: `border ${TransitionTokens.prop}`,
  width: '100%',
}));

export type CurrencyInputProps = ComponentProps<typeof CurrencyInput>;
