import styled from 'styled-components';
import { theme } from '@lower-financial/core-components/src/styles/primary/theme';
import { responsiveStyleFromTheme } from '@lower-financial/core-components';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';

export const BarebonesError = () => (
  <Container>
    <div />{/* keep this here for spacing */}
    <PageWrapper>
      <Heading>
        Something went wrong!
      </Heading>
      <Paragraph>
        Our engineering team has been notified and will take a look.
      </Paragraph>
      <Button
        type={'button'}
        onClick={() => window.location.reload()}
      >
        Reload
      </Button>
    </PageWrapper>
    <div />{/* keep this here for spacing */}
  </Container>
);

const Heading = styled.h1`
  font: var(--font-normal-heading-1-mobile);
`;

const Paragraph = styled.p`
  font: var(--font-paragraph-2);
  margin-bottom: 3rem;
`;

// Try not to put too many styles here that are hardcoded in case the css vars change they will stay in sync
// with the core components, we can't use a core component here because that depends on the ThemeProvider
// which is going to be inside this error boundary page
const Button = styled.button`
  all: unset;
  cursor: pointer;
  font: var(--font-button-md);
  background: var(--primary);
  color: var(--white);
  border-radius: var(--button-border-radius);
  padding: var(--spacing-button-vert-padding) var(--spacing-button-horz-padding);
  box-shadow: ${theme.shadows.generic};

  &:hover {
    background: var(--primary-hover);
  }
`;

const Container = styled(BasePageTemplate)`
  padding: 0;
  margin: 0 auto;
`;

const PageWrapper = styled.div`
  width: calc(100% - var(--mobile-page-padding) * 2);
  text-align: center;
  padding: var(--mobile-page-padding);
    
  ${responsiveStyleFromTheme({
    desktop: {
      padding: 'var(--desktop-page-padding)',
      width: 'calc(100% - var(--desktop-page-padding) * 2)',
    },
  })}
`;
