import { sendException } from '@lower-financial/toolbox';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models';
import { ApplyPolyfills } from '@lightspeed/utils/polyfills';
import {
  CurrentAddressPurchase,
} from '@lightspeed/components/ui/pages/current-address-purchase/current-address-purchase';
import { YourAssets } from '@lightspeed/components/ui/pages/assets/your-assets';
import { VerifyIdentity } from '@lightspeed/components/ui/pages/verify-identity/verify-identity';
import { Income } from '@lightspeed/components/ui/pages/income/income';
import {
  PropertyPurchaseInContract,
} from '@lightspeed/components/ui/pages/property-purchase/property-purchase-in-contract';
import {
  PropertyPurchaseNotInContract,
} from '@lightspeed/components/ui/pages/property-purchase/property-purchase-not-in-contract';
import { URLWatcher } from '@lightspeed/components/app/url-watcher';
import '@lightspeed/utils/data-dog';
import { UnsupportedState } from '@lightspeed/components/ui/pages/unsupported-state/unsupported-state';
import { ROUTES } from '@lightspeed/routing/routes';
import { AppProvider } from '@lightspeed/components/app/app-provider';
import { UrlProgressIndicator } from '@lightspeed/components/ui/atoms/url-progress-indicator/url-progress-indicator';
import { PartnerLanding } from '@lightspeed/components/ui/pages/partner-landing/partner-landing';
import { importDefaultStylesheet } from '@lightspeed/assets/partners/partners-config';
import { Landing } from '@lightspeed/components/ui/pages/landing/landing';
import { WarmUp } from '@lightspeed/components/ui/pages/warm-up/warm-up';
import { PartnerQueryStringCleaner } from '@lightspeed/components/app/partner-query-string-cleaner';
import { ApplicationOptions } from '@lightspeed/components/ui/pages/application-options/application-options';
import { BasicInfoCoborrower } from '@lightspeed/components/ui/pages/basic-info/basic-info-coborrower';
import { PropertyType } from '@lightspeed/components/ui/pages/property-type/property-type';
import { LandingTransition } from '@lightspeed/components/ui/pages/landing-transition/landing-transition';
import { PropertyValue } from '@lightspeed/components/ui/pages/property-value/property-value';
import { AboutYou } from '@lightspeed/components/ui/pages/about-you/about-you';
import { EmploymentStatus } from '@lightspeed/components/ui/pages/employment-status/employment-status';
import { YearlyIncome } from '@lightspeed/components/ui/pages/yearly-income/yearly-income';
import { PropertyAddress } from '@lightspeed/components/ui/pages/property-address/property-address';
import { CreditCheck } from '@lightspeed/components/ui/pages/credit-check/credit-check';
import { BorrowerAddress } from '@lightspeed/components/ui/pages/borrower-address/borrower-address';
import { PropertyState } from '@lightspeed/components/ui/pages/property-state/property-state';
import { PurchaseBasicInfo } from '@lightspeed/components/ui/pages/basic-info/purchase-basic-info';
import { PurchasePrice } from '@lightspeed/components/ui/pages/purchase-price/purchase-price';
import {
  PurchaseContractStatus,
} from '@lightspeed/components/ui/pages/purchase-contract-status/purchase-contract-status';
import { VerifyEmailSent } from '@lightspeed/components/ui/pages/verify-email';
import { JumpstartProcessing } from '@lightspeed/components/ui/pages/processing/jumpstart-processing';
import {
  JumpstartManualIntervention,
} from '@lightspeed/components/ui/pages/jumpstart-manual-intervention/jumpstart-manual-intervention';
import { BarebonesError } from '@lightspeed/components/ui/pages/error/barebones-error';
import { Submitted } from '@lightspeed/components/ui/pages/submitted/submitted';

ApplyPolyfills();
importDefaultStylesheet();

// NOTE(quinton): the fallback component is necessary as without it we lose query params when replacing
const FallbackComponent = () => {
  const { search } = useLocation();
  return (
    <Navigate
      replace
      to={`${ROUTES.LANDING}${search}`}
    />
  );
};

export function App() {
  return (
    <ErrorBoundary
      FallbackComponent={BarebonesError}
      onError={(e) => {
        sendException(e);
      }}
    >
      <BrowserRouter>
        <AppProvider>
          <UrlProgressIndicator />
          <URLWatcher />
          <PartnerQueryStringCleaner />
          <Routes>
            <Route
              path={ROUTES.WARM_UP}
              element={<WarmUp />}
            />
            <Route
              path={ROUTES.LANDING}
              element={<Landing />}
            />
            <Route
              path={ROUTES.PARTNER_LANDING}
              element={<PartnerLanding />}
            />
            <Route
              path={ROUTES.HELOC}
              element={<LandingTransition loanPurpose={LoanPurpose.Heloc} />}
            />
            <Route
              path={ROUTES.PURCHASE}
              element={<LandingTransition loanPurpose={LoanPurpose.Purchase} />}
            />
            <Route
              path={ROUTES.REFINANCE}
              element={<LandingTransition loanPurpose={LoanPurpose.Refinance} />}
            />
            <Route
              path={ROUTES.PURCHASE_BASIC_INFO}
              element={<PurchaseBasicInfo />}
            />
            <Route
              path={ROUTES.APPLICATION_OPTIONS}
              element={<ApplicationOptions />}
            />
            <Route
              path={ROUTES.BASIC_INFO_COBORROWER}
              element={<BasicInfoCoborrower />}
            />
            <Route
              path={ROUTES.CURRENT_ADDRESS}
              element={<CurrentAddressPurchase />}
            />
            <Route
              path={ROUTES.PROPERTY_PURCHASE_IN_CONTRACT}
              element={<PropertyPurchaseInContract />}
            />
            <Route
              path={ROUTES.PROPERTY_PURCHASE_NOT_IN_CONTRACT}
              element={<PropertyPurchaseNotInContract />}
            />
            <Route
              path={ROUTES.ASSETS}
              element={<YourAssets />}
            />
            <Route
              path={ROUTES.INCOME}
              element={<Income />}
            />
            <Route
              path={ROUTES.IDENTITY}
              element={<VerifyIdentity />}
            />
            <Route
              path={ROUTES.UNSUPPORTED_MARKET}
              element={<UnsupportedState />}
            />
            <Route
              path={ROUTES.PURCHASE_PROPERTY_TYPE}
              element={<PropertyType loanPurpose={LoanPurpose.Purchase} />}
            />
            <Route
              path={ROUTES.HELOC_PROPERTY_TYPE}
              element={<PropertyType loanPurpose={LoanPurpose.Heloc} />}
            />
            <Route
              path={ROUTES.REFINANCE_PROPERTY_TYPE}
              element={<PropertyType loanPurpose={LoanPurpose.Refinance} />}
            />
            <Route
              path={ROUTES.PROPERTY_VALUE}
              element={<PropertyValue />}
            />
            <Route
              path={ROUTES.PURCHASE_PRICE}
              element={<PurchasePrice />}
            />
            <Route
              path={ROUTES.PROPERTY_ADDRESS}
              element={<PropertyAddress />}
            />
            <Route
              path={ROUTES.ABOUT_YOU}
              element={<AboutYou />}
            />
            <Route
              path={ROUTES.EMPLOYMENT_STATUS}
              element={<EmploymentStatus />}
            />
            <Route
              path={ROUTES.YEARLY_INCOME}
              element={<YearlyIncome />}
            />
            <Route
              path={ROUTES.CREDIT_CHECK}
              element={<CreditCheck />}
            />
            <Route
              path={ROUTES.BORROWER_ADDRESS}
              element={<BorrowerAddress />}
            />
            <Route
              path={ROUTES.PROPERTY_STATE}
              element={<PropertyState />}
            />
            <Route
              path={ROUTES.CONTRACT_STATUS}
              element={<PurchaseContractStatus />}
            />
            <Route
              path={ROUTES.VERIFY_EMAIL_SENT}
              element={<VerifyEmailSent />}
            />
            <Route
              path={ROUTES.JUMPSTART_PROCESSING}
              element={<JumpstartProcessing />}
            />
            <Route
              path={ROUTES.JUMPSTART_MANUAL_INTERVENTION}
              element={<JumpstartManualIntervention />}
            />
            <Route
              path={ROUTES.SUBMITTED}
              element={<Submitted />}
            />
            <Route
              path={'*'}
              element={(
                <FallbackComponent />
              )}
            />
          </Routes>
        </AppProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
