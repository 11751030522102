import { useContext } from 'react';
import { MarketsContext } from '@lightspeed/contexts/market-context/markets-context';

export function useMarket() {
  const { markets, isLoading } = useContext(MarketsContext);

  const isUnsupportedMarket = (propertyState: string) =>
    !!markets?.some((market) => market.name === propertyState && !market.isSupported);

  return {
    isMarketLoading: isLoading,
    isUnsupportedMarket,
    markets,
  };
}
