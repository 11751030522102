import { CardSelectProps } from '@lightspeed/components/ui/organisms';

export function getDownPaymentOptions(isFirstTimeHomeBuyer: boolean) {
  const options: CardSelectProps['options'] = [
    {
      label: '5%',
      tag: {
        text: '$$',
        variant: 'variant1',
      },
      value: '.05',
    },
    {
      label: '15%',
      tag: {
        text: '$$$',
        variant: 'variant4',
      },
      value: '.15',
    },
    {
      label: '20%',
      tag: {
        text: '$$$$',
        variant: 'variant3',
      },
      value: '.20',
    },
  ];

  if (isFirstTimeHomeBuyer) {
    options.unshift({
      label: '3%',
      tag: {
        text: '$',
        variant: 'variant2',
      },
      value: '.03',
    });
  }

  return options;
}
