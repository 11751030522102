import React, { ComponentProps, KeyboardEvent, ReactNode } from 'react';
import { CheckIcon } from '@lower-financial/icons';
import styled from 'styled-components';
import { OptionalElement } from '../optional-element';
import { Paragraph } from '../paragraph';

type CardToggleProps = ComponentProps<typeof CardToggle>;

export const CardToggle = ({
  label, sublabel, checked, onChange, icon, variant, tabIndex = 0, role = 'checkbox', disabled = false,
}: {
  label: string,
  sublabel?: string,
  checked: boolean,
  onChange: () => void,
  icon?: ReactNode|ReactNode[],
  variant: 'checkbox' | 'radio',
  tabIndex?: number,
  role?: 'checkbox' | 'radio' | 'button',
  disabled?: boolean,
}) => {
  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Space') {
      onChange();
    }
  };

  return (
    <CardInputContainer
      $checked={checked}
      $variant={variant}
      aria-checked={checked}
      aria-label={label}
      aria-disabled={disabled}
      role={role}
      tabIndex={tabIndex}
      onKeyUp={disabled
        ? undefined
        : handleKeyPress}
      onClick={disabled
        ? undefined
        : onChange}
    >
      <CardInputContent
        variant={variant}
        label={label}
        sublabel={sublabel}
        checked={checked}
        icon={icon}
      />
    </CardInputContainer>
  );
};

export const CardInputContent = ({
  label, sublabel, checked, icon, variant,
}: {
  label: string,
  sublabel?: string,
  checked: boolean,
  icon?: ReactNode|ReactNode[],
  variant: CardToggleProps['variant'],
}) => (
  <>
    <Checkbox
      $checked={checked}
      $variant={variant}
      data-testid={'checkbox-icon'}
    >
      <CheckIcon
        color={'var(--white)'}
        data-testid={'checkbox-icon-svg'}
      />
    </Checkbox>
    <OptionalElement show={!!icon}><div>{icon}</div></OptionalElement>
    <TextContainer>
      <div>
        <div>{label}</div>
        {sublabel !== undefined
          ? <Sublabel variant={'small'}>{sublabel}</Sublabel>
          : undefined}
      </div>
    </TextContainer>
  </>
);

export const CardInputContainer = styled.div<{ $variant: CardToggleProps['variant'], $checked: CardToggleProps['checked'] }>`
  cursor: pointer;
  border: 1px solid var(--border);
  font: var(--font-card-toggle);
  padding: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: var(--radius-xs);
  transition: all 0.2s ease-out;
  
  &:focus, &:active, &:hover {
    box-shadow: 0 24px 48px rgba(23, 23, 47, 0.06);
  }

  &[aria-disabled="true"] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`;

const Sublabel = styled(Paragraph)`
  margin-top: 0.5rem;
`;

const Checkbox = styled.div<{ $checked: CardToggleProps['checked'], $variant: CardToggleProps['variant'] }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.$checked
    ? 'none'
    : '1px solid var(--border)')};
  border-radius: ${(props) => (props.$variant === 'radio'
    ? 'var(--radius-full)'
    : 'none')};
  background-color: ${(props) => (props.$checked
    ? 'var(--success)'
    : 'transparent')};
  width: 1.5rem;
  height: 1.5rem;
  transition: background-color .2s ease-out;
  
  > svg {
    transition: opacity 0.2s ease-out;
      ${(props) => (props.$variant === 'checkbox'
    ? 'height: 11px;'
    : 'height:8px;')}
      
    ${(props) => (props.$variant === 'checkbox' && !props.$checked
    ? 'opacity: 0;'
    : '')}
  }
`;
