import { Error } from '@lower-financial/core-components';

interface ValidationErrorProps {
  path: string;
  errors: Map<string, string[]>|null;
}

export const ValidationError = ({ path, errors }: ValidationErrorProps) => {
  if (!errors) {
    return null;
  }
  const thisError = errors.get(path);
  if (!thisError) {
    return null;
  }

  const errorMessage = thisError[0];

  return (
    <Error>
      {errorMessage}
    </Error>
  );
};
