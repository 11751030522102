import React, { ComponentProps } from 'react';
import styled from 'styled-components';

interface ProgressBarComponentProps {
  progress: number;
  label?: string;
  isAnimated?: boolean;
  animationDelayMs?: number;
}

export function ProgressBar({
  progress, label = 'progress bar', isAnimated = false, animationDelayMs = 0, ...props
}: ProgressBarComponentProps) {
  return (
    <ProgressBarWrapper
      role={'progressbar'}
      aria-valuenow={progress}
      aria-label={label}
      {...props}
    >
      <ProgressIndicatorWrapper
        progress={progress}
        data-testid={'progress-indicator'}
      >
        {
          isAnimated
            ? <AnimatedProgressIndicator animationDelayMs={animationDelayMs} />
            : <ProgressIndicator />
        }
      </ProgressIndicatorWrapper>
    </ProgressBarWrapper>
  );
}

ProgressBar.AnimationDurationMs = 1000;

export type ProgressBarProps = ComponentProps<typeof ProgressBar>;

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 0.25rem;
`;

const ProgressIndicatorWrapper = styled.div<{ progress: number }>`
  width: ${(props) => (props.progress)}%;
  border-radius: inherit;
  height: 100%;
`;

const ProgressIndicator = styled.div`
  background-color: var(--primary);
  height: 100%;
  width: 100%;
  border-radius: inherit;
`;

const AnimatedProgressIndicator = styled(ProgressIndicator)<{ animationDelayMs: number }>`
  width: 0;
  animation: grow ${ProgressBar.AnimationDurationMs}ms ease-out forwards;
  animation-delay: ${(props) => (props.animationDelayMs)}ms;
  @keyframes grow {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`;
