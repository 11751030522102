import styled from 'styled-components';
import React from 'react';
import { Paragraph } from '../paragraph';
import { OptionalElement } from '../optional-element';
import { VerticalConnector } from './vertical-connector';
import { Progress, ProgressIcon } from './progress-icon';

export type Step = {
  name: string,
  stepSubheading?: string;
  progress: Progress,
};

export const VerticalStepList = ({ steps }: { steps: Step[] }) => (
  <VerticalStepListContainer>
    {steps.map((step, index) => (
      <React.Fragment  key={`step-${index + 1}`}>
        <OptionalElement show={index > 0}><StepConnector progress={step.progress} />
        </OptionalElement>
        <Step step={step} />
      </React.Fragment>
    ))}
  </VerticalStepListContainer>
);

const StepConnector = ({ progress }: { progress: Progress }) => (
  <StyledConnector
    color={
      progress === Progress.Incomplete
        ? 'var(--silk)'
        : 'var(--success)'
    }
    dashed={progress === Progress.Incomplete}
  />
);

const Step = ({ step }: { step: Step }) => (
  <StepContainer>
    <ProgressIcon progress={step.progress} />
    <div>
      <StepText
        variant={'regular'}
        progress={step.progress}
      >
        {step.name}
      </StepText>

      {step.stepSubheading && (
        <div>
          <Paragraph
            variant={'smallLight'}
          >
            {step.stepSubheading}
          </Paragraph>
        </div>
      )}
    </div>
  </StepContainer>
);

const VerticalStepListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1875rem;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledConnector = styled(VerticalConnector)`
  width: 1.5rem;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  justify-content: flex-start;
  align-items: center;
`;

const StepText = styled(Paragraph)<{ progress: Progress }>`
  font: var(--font-vertical-step-list);
  color: ${(props) => (props.progress === Progress.InProgress
    ? 'var(--charcoal)'
    : 'var(--smoke)'
  )};
`;
