import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { Scoreboard } from '@lightspeed/components/ui/molecules/scoreboard/scoreboard';
import { useRate } from '@lightspeed/hooks/useRate';

export function TodaysRates({ mortgageApplication }: { mortgageApplication: MortgageApplicationStore }) {
  const {
    rate, apr, monthlyPayment, loanAmount, discountPoints, ltv,
  } = useRate(mortgageApplication.loanTerm, mortgageApplication.loanPurpose);

  return (
    <Scoreboard
      tooltip={`Interest rate based on a ${loanAmount} loan amount with a ${mortgageApplication.loanTerm}-year fixed rate and ${apr} APR and ${monthlyPayment} monthly payment at ${ltv}% loan-to-value (LTV) and ${discountPoints} discount points.`}
      label={'Today’s Rate'}
      suffix={'%'}
      value={rate}
    />
  );
}
