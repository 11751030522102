import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models';
import styled from 'styled-components';
import { useAnalytics } from '@lower-financial/analytics';
import { Heading, Paragraph, ThemedProps } from '@lower-financial/core-components';
import { CellPhoneHomeIcon as CoreCellPhoneHomeIcon } from '@lower-financial/icons';
import { useEffect } from 'react';
import { Header } from '@lightspeed/components/ui/molecules';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

const openingDelay = 200;
const closingDelay = 100;
const slideInOutduration = 350;
const stillDuration = 3500;
const delayOffsetIncrement = 80;
const totalDuration = openingDelay + slideInOutduration * 2 + stillDuration + delayOffsetIncrement * 2 + closingDelay;

export const LandingTransition = ({ loanPurpose }: { loanPurpose: LoanPurpose }) => {
  const { goToNextRoute } = useNextRoute();
  const { updateMortgageApplication } = useMortgageApplication();
  const fireAnalyticsEvent = useAnalytics(`${loanPurpose}_landing_transition`);

  useEffect(() => {
    updateMortgageApplication('loanPurpose', loanPurpose);
  }, [updateMortgageApplication, loanPurpose]);

  useEffect(() => {
    const transitionTimeout = setTimeout(() => {
      fireAnalyticsEvent('continued');
      goToNextRoute();
    }, totalDuration);

    return () => { clearTimeout(transitionTimeout); };
  }, [fireAnalyticsEvent, goToNextRoute]);

  const heading = loanPurpose === LoanPurpose.Purchase
    ? 'Let’s start with the home.'
    : 'Let’s see what your home qualifies for.';

  return (
    <Layout>
      <Header hideBackButton />
      <BasePageTemplate>
        <BodyContent aria-live={'polite'}>
          <AnimationContainer $delayOffset={0}>
            <CellPhoneHomeIcon withCheckmark={false} />
          </AnimationContainer>
          <AnimationContainer $delayOffset={delayOffsetIncrement}>
            <StyledHeading
              element={'h1'}
              size={'lg'}
            >
              {heading}
            </StyledHeading>
          </AnimationContainer>
          <AnimationContainer $delayOffset={delayOffsetIncrement * 2}>
            <Paragraph
              variant={'light'}
              aria-label={'We only ask for essential info.'}
            >
              We only ask for essential info.
            </Paragraph>
          </AnimationContainer>
        </BodyContent>
      </BasePageTemplate>
    </Layout>
  );
};

const CellPhoneHomeIcon = styled(CoreCellPhoneHomeIcon)((props: ThemedProps) => ({
  paddingBottom: '1rem',
  width: 90,
  [props.theme.media.desktop]: {
    width: 100,
  },
}));

const StyledHeading = styled(Heading)`
  padding-bottom: 0.5rem;
`;

const BodyContent = styled.div({
  alignItems: 'center',
  flexDirection: 'column',
  gap: '1rem',
  justifyContent: 'center',
  paddingTop: ' calc(var(--vh) * 20)',
  textAlign: 'center',
});

const AnimationContainer = styled.div<{ $delayOffset: number  }>(({ $delayOffset }) => `
  animation: hide ${openingDelay + $delayOffset}ms, start-down ${slideInOutduration}ms ${openingDelay + $delayOffset}ms cubic-bezier(.03,.34,.43,.98), move-up ${slideInOutduration}ms ${openingDelay + stillDuration + slideInOutduration + $delayOffset}ms cubic-bezier(.62,.07,.85,.49), hide 10000ms ${openingDelay + stillDuration + slideInOutduration * 2 + $delayOffset}ms;
  @keyframes hide {
    from { 
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes move-up {
    from { 
      opacity: 1;
      transform: translateY(0px);
    }
    to {
      opacity: 0;
      transform: translateY(-52px);
    }
  }
  @keyframes start-down {
    from { 
      opacity: 0;
      transform: translateY(52px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`);
