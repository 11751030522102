import { ComponentProps } from 'react';
import { Button, Heading } from '@lower-financial/core-components';
import { TypographyTokens } from '@lower-financial/core-components/src/styles/primary/tokens/typography';
import styled from 'styled-components';
import { openUrl } from '@lower-financial/toolbox/src/open-url';

interface CardListCtaComponentProps {
  cards: CardCta[];
  cardsTitle: string;
}

interface CardCta {
  CtaTitle: string;
  CtaText?: string;
  CtaLink: {
    linkText: string;
    linkValue: string;
  };
}
export const CardListCta = ({ cards, cardsTitle }: CardListCtaComponentProps) => (
  <CardListContainer>
    <CardsTitle element={'h4'}>{cardsTitle}</CardsTitle>
    <CardsContainer>
      {
        cards.map(
          (card, index) => (

            <ScrollCard key={index}>
              <CtaTitle element={'h3'}>{card.CtaTitle}</CtaTitle>
              <CtaText>{card.CtaText}</CtaText>
              <Button
                aria-label={card.CtaLink.linkText}
                variant={'link'}
                onClick={() => { openUrl(card.CtaLink.linkValue); }}
              >{card.CtaLink.linkText} &gt;
              </Button>
            </ScrollCard>
          ),
        )
      }
    </CardsContainer>
  </CardListContainer>
);
const CardListContainer = styled.div({
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
});

const CardsContainer = styled.div((props) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  justifyContent: 'center',
  marginBottom: 35,
  width: '100%',
  [props.theme.media.desktop]: {
    flexDirection: 'row',
    width: '100%',
  },
}));

const ScrollCard = styled.div((props) => ({
  alignItems: 'center',
  border: '1px solid',
  borderColor: 'var(--silk)',
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: 35,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 30,
  width: 300,
  [props.theme.media.desktop]: {
    paddingBottom: 50,
    paddingTop: 50,
    width: 400,
  },
}));

const CardsTitle = styled(Heading)((props) => ({
  ...TypographyTokens.Headers.sm,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 35,
  textAlign: 'center',
  [props.theme.media.desktop]: {
    ...TypographyTokens.Headers.sm,
  },
}));

const CtaTitle = styled(Heading)((props) => ({
  ...TypographyTokens.Headers.sm,
  display: 'flex',
  paddingBottom: 5,
  textAlign: 'center',
  [props.theme.media.desktop]: {
    ...TypographyTokens.Headers.md,
  },
}));

const CtaText = styled.div({
  ...TypographyTokens.Body.light,
  display: 'flex',
  paddingBottom: 10,
  paddingTop: 10,
});

export type CardListCtaProps = ComponentProps<typeof CardListCta>;
