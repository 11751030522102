import { useEffect } from 'react';
import { isKeyof } from '@lower-financial/toolbox';
import { MortgageRateDto } from '@lower-financial/lending-web-api/generated';
import { useErrorHandling } from '@lower-financial/core-components';
import todaysRates from '@lightspeed/api/services/content/todays-rates';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { useRatesContext } from '@lightspeed/contexts/rates-context/rates-context';

interface MortgageTerm {
  [key: string]: Partial<MortgageRateDto> | undefined,
}

export interface TodaysRates {
  'fifteen': MortgageTerm,
  'twenty': MortgageTerm,
  'thirty': MortgageTerm,
}

const termMap = new Map<number, string>([
  [15, 'fifteen'],
  [20, 'twenty'],
  [30, 'thirty'],
]);

export default function useTodaysRates() {
  const { partnerConfiguration: { ratesName } } = usePartnerConfiguration();
  const { rates, setRates } = useRatesContext();
  const handleError = useErrorHandling();

  useEffect(() => {
    if (rates !== null) {
      return;
    }

    (async () => {
      await handleError({
        showBoundary: false,
        tryFn: async () => {
          const response = await todaysRates(ratesName);

          const networkRates = (await response.json());

          if (networkRates.length === 0) {
            return;
          }

          const formattedRates: TodaysRates = {
            fifteen: {
              purchase: {},
              refinance: {},
            },
            thirty: {
              purchase: {},
              refinance: {},
            },
            twenty: {
              purchase: {},
              refinance: {},
            },
          };

          networkRates.forEach((r) => {
            const termKey = termMap.get(r.term);
            if (!termKey) { return; }

            const { loanType } = r;

            if (isKeyof(termKey, formattedRates)) {
              formattedRates[termKey][loanType] = r;
            }
          });

          setRates(formattedRates);
        },
      });
    })();
  }, [rates, setRates, ratesName, handleError]);

  return rates;
}
