import { CheckPeriodIcon, LightningIcon, MoonStarIcon } from '@lower-financial/icons/src';
import { useAnalytics } from '@lower-financial/analytics';
import { WarmUpTemplate } from '@lightspeed/components/ui/pages/warm-up/warm-up-template';
import { usePartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';

const purchaseBenefits = [
  {
    descriptionText: 'Apply online. Upload docs from your phone. All whenever and wherever you are.',
    headerText: 'All Digital Experience',
    icon: <LightningIcon color={'var(--primary)'} />,
  },
  {
    descriptionText: 'Getting pre-approved won’t hurt your credit and there’s no commitment.',
    headerText: 'Zero Credit Impact',
    icon: <CheckPeriodIcon color={'var(--primary)'} />,
  },
  {
    descriptionText: 'You work after 5 o’clock, so we do, too. Contact us any time, via text, phone or email.',
    headerText: 'Available After Hours',
    icon: <MoonStarIcon color={'var(--primary)'} />,
  },
];

export const PurchaseWarmUp = () => {
  const { partnerConfiguration: { displayName } } = usePartnerConfiguration();
  const fireAnalyticsEvent = useAnalytics('purchase_warm_up');

  return (
    <WarmUpTemplate
      explanationText={`We’re Lower. We teamed up with ${displayName ?? ''} to get you an amazing mortgage experience.`}
      benefits={purchaseBenefits}
      reviewText={'We had never done a virtual closing and it was smooth as silk from the comfort of our couch at the time of our choosing! The whole process was very easy to follow and understand.'}
      reviewAuthor={'Stephanie W.'}
      backgroundImagePath={'../../../../../static/assets/warm-up-backgrounds/purchase-warm-up.jpg'}
      fireAnalyticsEvent={fireAnalyticsEvent}
    />
  );
};
