import styled from 'styled-components';
import { responsiveStyleFromTheme } from '@lower-financial/core-components/src/styles/utils/theme-utils';
import React, { ReactNode } from 'react';
import { Heading, OptionalElement } from '@lower-financial/core-components';
import { usePartnerLogos } from '@lightspeed/hooks/usePartnerStyles/usePartnerLogos';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import {
  PoweredByLowerFooter,
} from '@lightspeed/components/ui/organisms/powered-by-lower-footer/powered-by-lower-footer';

interface ProcessingTemplateProps {
  progress: number;
  prompt: string | ReactNode;
  subtext?: string | ReactNode;
}

export function ProcessingTemplate({
  progress, prompt, subtext,
}: ProcessingTemplateProps) {
  const {
    partnerConfiguration: {
      displayName, isPartnerExperience, hasOwnBranding,
    },
  } = usePartnerConfiguration();
  const { logos } = usePartnerLogos();

  return (
    <StyledBackgroundWrapper>
      <LogoContainer>
        <LogoSizeContainer>
          <LogoImg
            src={logos.loadingLogo}
            alt={`${displayName || 'Lower'} Logo`}
          />
        </LogoSizeContainer>
      </LogoContainer>
      <MainContainer>
        <ProgressText
          element={'h1'}
          size={'sm'}
        >
          {prompt}
        </ProgressText>
        <OptionalElement show={subtext !== undefined}>
          <ProgressSubtext>
            {subtext}
          </ProgressSubtext>
        </OptionalElement>
        <ProgressBar>
          <ProgressBarInner
            style={{
              width: `${progress}%`,
            }}
          />
        </ProgressBar>
      </MainContainer>
      {isPartnerExperience && hasOwnBranding && (
        <PoweredByLowerFooter />
      )}
    </StyledBackgroundWrapper>
  );
}

const LogoContainer = styled.div`
  border-bottom: 1px solid var(--silk);
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1.5rem 0;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 3rem;
`;

const LogoSizeContainer = styled.div`
  max-height: var(--logo-max-height-mobile);
  display: flex;
  justify-content: center;
  
  ${responsiveStyleFromTheme({
    desktop: {
      maxHeight: 'var(--logo-max-height-desktop)',
    },
  })}
`;

const LogoImg = styled.img`
  max-height: 100%;
  max-width: 100%;
  height: var(--logo-max-height-mobile);
  ${responsiveStyleFromTheme({
    desktop: {
      height: 'var(--logo-max-height-desktop)',
    },
  })}
`;

const ProgressBar = styled.div`
  width: 80vmin;
  height: 3px;
  background-color: var(--silk);
  margin-top: 3rem;
`;

const ProgressBarInner = styled.div`
  height: 3px;
  background-color: var(--primary);
  transition: width 80ms;
`;

const StyledBackgroundWrapper = styled.div`
  height: calc(var(--vh) * 100);
  width: 100vw;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const ProgressText = styled(Heading)`
  color: var(--charcoal);
  text-align: center;
  ${responsiveStyleFromTheme({
    desktop: {
      font: 'var(--font-heading-3)',
    },
  })}
`;

const ProgressSubtext = styled.div`
  color: var(--ash);
  font: var(--font-paragraph-1);
  text-align: center;
  margin-top: 0.5rem;
  ${responsiveStyleFromTheme({
    desktop: {
      font: 'var(--font-paragraph-3)',
    },
  })}
`;
