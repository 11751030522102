import { useCallback, useMemo, useState } from 'react';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import styled from 'styled-components';
import { responsiveStyleFromTheme, useErrorHandling, useValidation } from '@lower-financial/core-components';
import { objectContainsFalsyPropsForKeys } from '@lower-financial/toolbox';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import { AboutYouForm } from '@lightspeed/components/ui/pages/about-you/about-you-form';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { Testimonial } from '@lightspeed/components/ui/molecules/testimonial';
import { ApplicationDisclaimer } from '@lightspeed/components/ui/pages/basic-info/application-disclaimer';
import { useMortgageAnalytics } from '@lightspeed/hooks/useMortgageAnalytics/use-mortgage-analytics';
import { NextStepButton } from '@lightspeed/components/shared/buttons/next-step-button';
import { PageLayout } from '@lightspeed/components/shared/page-layout/page-layout';

const title = 'Sweet. Now just a few things about you.';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerFirstName',
  'borrowerLastName',
  'borrowerEmail',
  'borrowerPhoneNumber',
];

const AboutYouPurchaseTestimonial = () => (
  <Testimonial
    headingElement={'h2'}
    title={'Excellent customer service with a very simple process.'}
    content={'The process was very simple and easy. Lower kept me informed made several recommendations that fit my needs. I feel like they truly wanted to help me and my family get the best outcome for our needs. My advisor was available even after hours to help and answer question.'}
    author={'Joe Mozee'}
  />
);

const AboutYouHelocTestimonial = () => (
  <Testimonial
    headingElement={'h2'}
    title={'Great HELOC Experience'}
    content={'The process was easy and painless. Lower was able to give me a great rate + a larger money amount than any other lender! If I ever need a refi, etc. I will be definitely returning to them.'}
    author={'Jordan Reilly'}
  />
);

export function AboutYou() {
  const { mortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const handleError = useErrorHandling();

  const fireAnalyticsEvent = useMortgageAnalytics('about_you');

  const { upsertApplication } = useUpsertApplication();
  const [isLoading, setIsLoading] = useState(false);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: borrowerKeys,
  });

  const disableNextButton = isLoading || objectContainsFalsyPropsForKeys(
    mortgageApplication,
    borrowerKeys,
  );

  const disclaimer = (<ApplicationDisclaimer isContinuing />);

  const onNextClick = useCallback(
    () => {
      (async () => {
        if (!validate(mortgageApplication).success) {
          return;
        }

        setIsLoading(true);
        await handleError({
          onRetryableError: () => {
            setIsLoading(false);
          },
          tryFn: async () => {
            await upsertApplication();

            fireAnalyticsEvent('next_button_success');
            goToNextRoute();
          },
        });
      })();
    },
    [fireAnalyticsEvent, goToNextRoute, handleError, mortgageApplication, upsertApplication, validate],
  );

  const nextStepButton = useMemo(
    () => (
      <NextStepButton
        isLoading={isLoading}
        disabled={disableNextButton}
        onClick={onNextClick}
      />
    ),
    [disableNextButton, isLoading, onNextClick],
  );

  return (
    <Layout>
      <AboutYouPageLayout
        title={title}
        button={nextStepButton}
        disclaimer={disclaimer}
        desktopSidebar={mortgageApplication.loanPurpose === LoanPurpose.Purchase
          ? <AboutYouPurchaseTestimonial />
          : <AboutYouHelocTestimonial />}
      >
        <AboutYouForm
          errors={errors}
          disabled={isLoading}
        />
      </AboutYouPageLayout>
    </Layout>
  );
}

// Use min-height for this page specifically because we only want part of the disclaimer to be shown
// and the button to stay in the same place
const AboutYouPageLayout = styled(PageLayout)`
> div > div:first-child {
  min-height: calc(var(--layout-min-height) - var(--mobile-header-height) - var(--mobile-page-padding) - var(--mobile-page-padding) - var(--powered-by-lower-footer-height));
  ${responsiveStyleFromTheme({
    desktop: {
      minHeight: 'unset',
    },
  })}
}
`;
