export const ApplyPolyfills = () => {
  // NOTE(quinton): Chrome v84 and below does not support replaceAll, so we need to polyfill it.
  // example error: https://us3.datadoghq.com/rum/error-tracking?issueId=71b64080-ba8c-11ec-83bc-da7ad0900003&start=1650651745549&end=1650655345549&paused=false
  if (!String.prototype.replaceAll) {
    // @ts-expect-error type-error
    // eslint-disable-next-line no-extend-native
    String.prototype.replaceAll = function (str, newStr) {
      if (typeof newStr !== 'string') {
        return;
      }

      // If a regex pattern
      if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
        return this.replace(str, newStr);
      }

      // If a string
      return this.replace(new RegExp(str, 'g'), newStr);
    };
  }
};
