import React, { ComponentProps, FocusEventHandler } from 'react';
import InputMask from 'react-input-mask';
import { TextInput } from '..';

interface SSNInputComponentProps {
  mask?: string;
  hasError?: boolean;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  name?: string;
}

export function SSNInput({
  mask = '999-99-9999',
  value,
  name,
  onChange,
  hasError,
  placeholder = 'Enter Social Security Number',
  disabled = false,
  onFocus,
}: SSNInputComponentProps) {
  return (
    <InputMask
      name={name}
      mask={mask}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      onFocus={onFocus}
    >
      {(inputProps: SSNInputProps) => (
        <TextInput
          {...inputProps}
          disabled={disabled}
          $hasError={hasError}
          inputMode={'numeric'}
          autoComplete={'none'}
        />
      )}
    </InputMask>
  );
}

export default SSNInput;

export type SSNInputProps = ComponentProps<typeof SSNInput>;
