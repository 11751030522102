import { useCallback, useEffect, useRef } from 'react';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { poll } from '@lower-financial/toolbox';
import { useProgress } from '@lower-financial/core-components';
import styled from 'styled-components';
import { useMarket } from '@lightspeed/hooks/useMarket/useMarket';
import { ProcessingTemplate } from '@lightspeed/components/ui/pages/processing/processing-template';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import {
  usePartnerConfiguration,
} from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { PartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-helpers';

const NoWrap = styled.span`
  text-wrap: nowrap;
`;

const loadingText = (loanPurpose: PartnerConfiguration['loanPurpose']) => {
  switch (loanPurpose) {
  case LoanPurpose.Heloc:
    return 'Connecting you to Lower to access your equity.';
  case LoanPurpose.Purchase:
    return <>Connecting you to Lower to get <NoWrap>pre-approved.</NoWrap></>;
  default:
    return 'Connecting you to Lower.com';
  }
};

export const PartnerLanding = () => {
  const { goToNextRoute } = useNextRoute();
  const { isMarketLoading }  = useMarket();
  const { partnerConfiguration: { loanPurpose } } = usePartnerConfiguration();
  const hasLoadedInitialMarkets = useRef<boolean>(false);

  useEffect(() => {
    if (!isMarketLoading) {
      hasLoadedInitialMarkets.current = true;
    }
  }, [isMarketLoading]);

  const completeAsyncAction = useCallback(() => {
    goToNextRoute();
  }, [goToNextRoute]);

  const awaitMarketUpdate = async () => {
    await poll(
      // I believe we have to use a ref here. If you use isMarketLoading, the initial value will always be returned.
      // The tests will pass regardless, but that's likely because the polling will eventually time out.
      () => Promise.resolve(hasLoadedInitialMarkets.current),
      (res) => res,
      100,
      4900,
    );
  };

  const [progress] = useProgress(
    [],
    () => awaitMarketUpdate(),
    completeAsyncAction,
    5,
  );

  return (
    <ProcessingTemplate
      progress={progress}
      prompt={loadingText(loanPurpose)}
      subtext={'(We’ll see you back here right after.)'}
    />
  );
};
