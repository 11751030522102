export const ClosedHouse = () => (
  <svg
    width={'24'}
    height={'25'}
    viewBox={'0 0 24 25'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M24 23.6667V11.609C24 10.8808 23.7022 10.1843 23.1758 9.68117L13.8425 0.760926C12.8117 -0.224233 11.1883 -0.224232 10.1575 0.760927L0.824193 9.68117C0.297777 10.1843 0 10.8808 0 11.609V23.6667C0 24.403 0.596954 25 1.33333 25H9H15H22.6667C23.403 25 24 24.403 24 23.6667Z'}
      fill={'#1D1D23'}
      fillOpacity={'0.2'}
    />
  </svg>
);
