import { ErrorResponse, QuotingApplicationSubmissionCreated, ValidationErrorResponse } from '@lower-financial/lending-web-api/generated';
import { fetch, TypedResponse } from '@lower-financial/toolbox';
import { ENV } from '@lightspeed/environment';

export function submitQuotingApplication(quotingApplicationId: string) {
  return fetch<SubmitQuotingApplicationResponse>(
    `${ENV.BACKEND_API_HOST}/api/lending/quoting_applications/${quotingApplicationId}/submissions`,
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
}

type NotFoundErrorResponseInterface = TypedResponse<ErrorResponse, 404>;
type ConflictErrorResponseInterface = TypedResponse<ErrorResponse, 409>;
type ValidationErrorResponseInterface = TypedResponse<ValidationErrorResponse, 422>;
type SubmissionCreatedInterface = TypedResponse<QuotingApplicationSubmissionCreated, 202>;

export type SubmitQuotingApplicationResponse = NotFoundErrorResponseInterface
  | SubmissionCreatedInterface
  | ValidationErrorResponseInterface
  | ConflictErrorResponseInterface;
