import { ReactNode } from 'react';

interface OptionalElementProps {
  show: boolean;
  children: ReactNode | ReactNode[]
}

export function OptionalElement({ show, children }: OptionalElementProps) {
  if (!show) { return null; }

  return <>{children}</>;
}
