import styled from 'styled-components';
import { DropDownArrowIcon } from '@lower-financial/icons';
import { useMediaQuery } from 'react-responsive';
import { theme } from '../../styles/primary/theme';
import { Paragraph } from '../paragraph';

export const TextArrow = ({ text }: { text: string }) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });

  return isDesktop
    ? (
      <DesktopTextArrow variant={'small'}>
        {text} <RightDropDownArrowIcon color={'var(--tertiary-1)'} />
      </DesktopTextArrow>
    )
    : (
      <MobileArrow>
        <RightDropDownArrowIcon color={'var(--tertiary-1)'} />
      </MobileArrow>
    );
};

const DesktopTextArrow = styled(Paragraph)`
  font-family: Gotham-Bold;
  color: var(--tertiary-1);
  margin: 1rem;
  text-align: right;
  min-width: 3.5rem;
`;

const MobileArrow = styled.div`
  margin-left: 1rem;
`;

const RightDropDownArrowIcon = styled(DropDownArrowIcon)`
  height: 0.375rem;
  transform: rotate(-90deg);
`;
