export const StarIcon = ({ color = '#5CD2AF', ...props }) => (
  <svg
    width={'14'}
    height={'13'}
    viewBox={'0 0 14 13'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>star icon</title>
    <path
      d={'M7.29492 0L9.5579 3.88528L13.9523 4.83688L10.9565 8.18972L11.4094 12.6631L7.29492 10.85L3.18043 12.6631L3.63335 8.18972L0.637526 4.83688L5.03195 3.88528L7.29492 0Z'}
      fill={color}
    />
  </svg>
);
