import { ComponentProps } from 'react';
import styled from 'styled-components';
import { color, compose } from 'styled-system';
import { ThemedProps } from '@lower-financial/core-components/src/styles/utils/theme-utils';
import type { ColorProps } from 'styled-system';

type BaseLinkProps = {
  bold?: boolean;
} & ColorProps;

type Props = BaseLinkProps & ThemedProps;

export const Link = styled.a<BaseLinkProps>`
  display: block;
  ${ (props: Props) => props.theme.Link[props.bold
    ? 'bold'
    : 'book'] };
  color: var(--primary);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  ${ compose(color) };
`;

export type LinkProps = ComponentProps<typeof Link>;
