import { usePostHog } from 'posthog-js/react';
import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { AnalyticsEvent, GtagApiResult } from '@lower-financial/analytics/src/global';
import { FeatureFlags, useFeatureFlags } from '@lower-financial/feature-flags';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { saveUserAnalyticsSession } from '@lightspeed/api/services/backend/lending/analytics/saveUserAnalyticsSession';

export function FeatureFlagWatcher()  {
  const postHog = usePostHog();
  const featureFlags = useFeatureFlags();
  const { mortgageApplication } = useMortgageApplication();
  const [featureFlagsUpdated, setFeatureFlagsUpdated] = useState<FeatureFlags>();
  const [featureFlagsSaved, setFeatureFlagsSaved] = useState<FeatureFlags>();
  const [hasSavedSession, setHasSavedSession] = useState(false);

  const saveFeatureFlags = useCallback(async () => {
    const featureFlagStrings: Record<string, string> = {};

    Object.entries(featureFlags).forEach(([flagName, flagValue]: [string, string | boolean]) => {
      featureFlagStrings[flagName] = flagValue.toString();
    });
    const isGtagApiResult = (event: AnalyticsEvent, index: number, obj: AnalyticsEvent[]): event is GtagApiResult => 'h' in event && 'gtagApiResult' in event.h;

    const input = {
      featureFlags: featureFlagStrings,
      googleAnalyticsClientId: window.dataLayer.find<GtagApiResult>(isGtagApiResult)?.h.gtagApiResult.client_id ?? null,
      quotingApplicationId: mortgageApplication.quotingApplicationId,
      sessionId: postHog.get_distinct_id(),
    };

    await saveUserAnalyticsSession(input);
  }, [featureFlags, mortgageApplication.quotingApplicationId, postHog]);

  useEffect(
    () => {
      if (
        mortgageApplication.quotingApplicationId !== ''
        && (
          !hasSavedSession
          || !isEqual(featureFlagsSaved, featureFlagsUpdated)
        )
      ) {
        (async () => saveFeatureFlags())();
        setFeatureFlagsSaved(featureFlagsUpdated);
        setHasSavedSession(true);
      }
    },
    [
      featureFlags,
      featureFlagsSaved,
      featureFlagsUpdated,
      hasSavedSession,
      mortgageApplication.quotingApplicationId,
      saveFeatureFlags,
    ],
  );

  const handleFeatureFlagsUpdate = useCallback(() => {
    setFeatureFlagsUpdated(featureFlags);
  }, [featureFlags]);

  useEffect(() => {
    postHog.onFeatureFlags(handleFeatureFlagsUpdate);
  }, [handleFeatureFlagsUpdate, postHog]);

  return null;
}
