import { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';

export interface CircleContainerProps {
  background: string;
  circleSize?: number;
}

interface IconCircleComponentProps extends CircleContainerProps {
  icon: string | ReactNode;
  testId?: string;
  altText?: string;
}

export const IconCircle = ({
  icon,
  background,
  testId = 'icon-circle',
  circleSize = 40,
  altText,
}: IconCircleComponentProps) => {
  const iconComponent = typeof icon === 'string'
    ? (
      <IconImg
        alt={altText ?? 'Circle Icon'}
        src={icon}
      />
    )
    : icon;

  return (
    <CircleContainer
      background={background}
      data-testid={testId}
      circleSize={circleSize}
    >
      {iconComponent}
    </CircleContainer>
  );
};

export type IconCircleProps = ComponentProps<typeof IconCircle>;

export const IconImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const CircleContainer = styled.div<CircleContainerProps>(({
  background,
  circleSize = 40,
}) => ({
  alignItems: 'center',
  background,
  borderRadius: circleSize,
  display: 'flex',
  height: circleSize,
  justifyContent: 'center',
  minHeight: circleSize,
  minWidth: circleSize,
  position: 'relative',
  width: circleSize,
}));
