import React, { useState } from 'react';
import { useAnalytics } from '@lower-financial/analytics';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import {
  Button,
  Heading,
  Label,
  LoadingOverlay,
  OptionalElement,
  useErrorHandling,
  useValidation,
} from '@lower-financial/core-components';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models/LoanPurpose';
import { objectContainsFalsyPropsForKeys } from '@lower-financial/toolbox';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { TodaysRates } from '@lightspeed/components/ui/pages/basic-info/todays-rates';
import { RateDisclaimer } from '@lightspeed/components/shared/legal-disclaimers/rate-disclaimer';
import { PurchaseRefinanceSidebarBlurb } from '@lightspeed/components/shared/sidebars/purchase-refinance-sidebar-blurb';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import { checkIfBorrowersShareAddress } from '@lightspeed/components/ui/pages/current-address-purchase/address-utils';
import { HelocSidebarBlurb } from '@lightspeed/components/shared/sidebars/heloc-sidebar-blurb';
import { AddressForm } from './address-form';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerStreetAddress',
  'borrowerCity',
  'borrowerZipCode',
  'borrowerState',
  'borrowerYearsAtAddress',
];

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerStreetAddress',
  'coBorrowerCity',
  'coBorrowerZipCode',
  'coBorrowerState',
];

export function CurrentAddressPurchase() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const { hasCoBorrower } = mortgageApplication;
  const [coBorrowerHasSameAddress, setCoBorrowerHasSameAddress] = useState<boolean>(
    checkIfBorrowersShareAddress(mortgageApplication),
  );
  const { goToNextRoute } = useNextRoute();
  const handleError = useErrorHandling();
  const fireAnalyticsEvent = useAnalytics('v2/current_address_purchase');
  const { upsertApplication } = useUpsertApplication();
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoBorrower
      ? [...borrowerKeys, ...coBorrowerKeys]
      : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower,
    },
  });

  const disableNextButton = objectContainsFalsyPropsForKeys<MortgageApplicationStore>(
    mortgageApplication,
    hasCoBorrower && !coBorrowerHasSameAddress
      ? [...borrowerKeys, ...coBorrowerKeys]
      : borrowerKeys,
  );

  const setPropertyAddressToCurrentAddress = () => {
    updateMortgageApplication('propertyStreetAddress', mortgageApplication.borrowerStreetAddress);
    updateMortgageApplication('propertyCity', mortgageApplication.borrowerCity);
    updateMortgageApplication('propertyState', mortgageApplication.borrowerState);
    updateMortgageApplication('propertyCounty', mortgageApplication.borrowerCounty);
    updateMortgageApplication('propertyZipCode', mortgageApplication.borrowerZipCode);
  };

  const onNextClick = () => {
    (async () => {
      if (hasCoBorrower && coBorrowerHasSameAddress) {
        updateMortgageApplication('coBorrowerStreetAddress', mortgageApplication.borrowerStreetAddress);
        updateMortgageApplication('coBorrowerCity', mortgageApplication.borrowerCity);
        updateMortgageApplication('coBorrowerCounty', mortgageApplication.borrowerCounty);
        updateMortgageApplication('coBorrowerZipCode', mortgageApplication.borrowerZipCode);
        updateMortgageApplication('coBorrowerState', mortgageApplication.borrowerState);
      }

      const validationResult = validate(mortgageApplication);

      if (validationResult.success) {
        if (mortgageApplication.propertyStreetAddress === '' && mortgageApplication.loanPurpose !== LoanPurpose.Purchase) {
          setPropertyAddressToCurrentAddress();
        }

        setShowLoadingModal(true);
        await handleError({
          onRetryableError: () => {
            setShowLoadingModal(false);
          },
          tryFn: async () => {
            await upsertApplication();
            setShowLoadingModal(false);
            fireAnalyticsEvent('next_button_success');
            goToNextRoute();
          },
        });
      }
    })();
  };

  return (
    <Layout>
      <OptionalElement show={showLoadingModal}>
        <LoadingOverlay />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading
                    element={'h1'}
                  >
                    The basics.
                  </Heading>
                  <AddressForm
                    fireAnalyticsEvent={fireAnalyticsEvent}
                    errors={errors || new Map()}
                    coBorrowerHasSameAddress={coBorrowerHasSameAddress}
                    setCoBorrowerHasSameAddress={setCoBorrowerHasSameAddress}
                  />
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <OptionalElement show={mortgageApplication.loanPurpose !== LoanPurpose.Heloc}>
                    <TodaysRates
                      mortgageApplication={mortgageApplication}
                    />
                    <Label marginBottom={'8px'}>
                      Loan Term
                    </Label>
                    <TermSelect
                      value={mortgageApplication.loanTerm}
                      onChange={(v) => {
                        updateMortgageApplication('loanTerm', v);
                      }}
                    />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.loanPurpose !== LoanPurpose.Heloc}>
                    <PurchaseRefinanceSidebarBlurb />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.loanPurpose === LoanPurpose.Heloc}>
                    <HelocSidebarBlurb />
                  </OptionalElement>
                  <Button
                    marginY={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                  <RateDisclaimer />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <div>
                <Heading element={'h1'}>
                  The basics.
                </Heading>
                <AddressForm
                  fireAnalyticsEvent={fireAnalyticsEvent}
                  errors={errors || new Map()}
                  coBorrowerHasSameAddress={coBorrowerHasSameAddress}
                  setCoBorrowerHasSameAddress={setCoBorrowerHasSameAddress}
                />
              </div>

              <Button
                marginTop={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </Layout>
  );
}
