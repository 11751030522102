import styled from 'styled-components';
import { responsiveStyleFromTheme } from '@lower-financial/core-components';

export const PoweredByLowerFooter = () => (
  <PartnerFooterContainer>
    <div>POWERED BY LOWER.COM</div>
  </PartnerFooterContainer>
);

const PartnerFooterContainer = styled.footer`
  background: var(--chalk);
  color: var(--ash);
  font: var(--font-powered-by-lower-mobile);
  padding: 1rem 0;
  text-align: center;

  ${responsiveStyleFromTheme({
    desktop: {
      font: 'var(--font-powered-by-lower-desktop)',
    },
  })}
`;
