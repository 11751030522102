export const TrustPilotStarIcon = ({ color = '#5CD2AF', ...props }) => (
  <svg
    width={'16'}
    height={'16'}
    viewBox={'0 0 16 16'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <title>Trustpilot star icon</title>
    <path
      d={'M10 6L8 0L6 6H0L5 10L3 16L8 12L13 16L11 10L16 6H10Z'}
      fill={color}
    />
    <path
      d={'M11 10L8 12L11.5 11.5L11 10Z'}
      fill={'#434343'}
      fillOpacity={'0.65'}
    />
  </svg>
);
