export const HeartIcon = ({ color = '#6A6B76' }) => (
  <svg
    xmlns={'http://www.w3.org/2000/svg'}
    width={'18'}
    height={'16'}
    viewBox={'0 0 18 16'}
    fill={'none'}
  >
    <title>Heart Icon</title>
    <g clipPath={'url(#clip0_1774_779)'}>
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={'M16.004 2.11945C15.18 1.31338 14.0694 0.847656 12.923 0.847656C11.7766 0.847656 10.6481 1.29547 9.84203 2.11945L9.00014 2.94343L8.15825 2.11945C6.45656 0.435667 3.69802 0.435667 1.97842 2.11945C0.276725 3.80323 0.276725 6.52594 1.97842 8.20972L2.82031 9.0337L9.00014 15.1419L15.18 9.0337L16.0219 8.20972C16.8458 7.40365 17.2937 6.31099 17.2937 5.16458C17.2937 4.01818 16.8279 2.92551 16.0219 2.11945H16.004Z'}
        fill={color}
      />
    </g>
    <defs>
      <clipPath id={'clip0_1774_779'}>
        <rect
          width={'17.7778'}
          height={'15.3204'}
          fill={'white'}
          transform={'translate(0.111328 0.334564)'}
        />
      </clipPath>
    </defs>
  </svg>
);
