import cookies, { CookieAttributes } from 'js-cookie';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { sendException } from '@lower-financial/toolbox';
import { getCookieOptions } from '@lightspeed/utils/get-cookie-options';
import { ENV } from '@lightspeed/environment';

export const PARTNER_NAME_KEY = 'partner_name';
export const REAL_ESTATE_AGENT_QUESTION_KEY = 'rea_question';
export const CONSENT_REQUIRED_KEY = 'consent_required';
export const SHOW_WARM_UP_KEY = 'show_warm_up';
export const LOAN_TYPE_KEY = 'loan_type';
export const REDIRECT_URL_KEY = 'redirect_url';
export const ORIGIN_KEY = 'origin';

export const PARTNER_QUERY_PARAMS = [
  PARTNER_NAME_KEY,
  REAL_ESTATE_AGENT_QUESTION_KEY,
  CONSENT_REQUIRED_KEY,
  SHOW_WARM_UP_KEY,
  LOAN_TYPE_KEY,
  REDIRECT_URL_KEY,
];

export type PartnerConfiguration = PartnerExperienceConfig | DefaultExperienceConfig;

interface BasePartnerConfig {
  consentRequired: boolean,
  loanPurpose: null | LoanPurpose,
  ratesName: string,
  redirectUrl: string | null,
  realEstateAgentQuestion: boolean,
  showWarmUp: boolean,
  hasOwnBranding: boolean,
}
interface PartnerExperienceConfig extends BasePartnerConfig {
  isPartnerExperience: true,
  partnerName: PARTNER_NAME,
  affinityPartnerName: AFFINITY_PARTNER_NAME,
  displayName: PARTNER_DISPLAY_NAMES,
}
interface DefaultExperienceConfig extends BasePartnerConfig {
  isPartnerExperience: false,
  partnerName: null,
  affinityPartnerName: null,
  displayName: null,
}

export enum PARTNER_NAME {
  Opendoor = 'opendoor',
  Eden = 'eden',
  Gravy = 'gravy',
  Homelight = 'homelight',
  Prevu = 'prevu',
  Mashvisor = 'mashvisor',
  LowerTest = 'lower_test',
}

export enum AFFINITY_PARTNER_NAME {
  Opendoor = 'Opendoor',
  Eden = 'eden',
  Gravy = 'Gravy',
  Homelight = 'homelight',
  Prevu = 'Prevu',
  Mashvisor = 'Mashvisor',
  // This affinity partner name is not associated with anything in salesforce or the backend.
  // It is strictly for front end testing.
  LowerTest = 'LowerTest',
}

export enum PARTNER_DISPLAY_NAMES {
  Opendoor = 'Opendoor',
  Eden = 'Eden',
  Gravy = 'Gravy',
  Homelight = 'Homelight',
  Prevu = 'Prevu',
  Mashvisor = 'Mashvisor',
  LowerTest = 'Lower Test',
}

export enum PARTNER_RATES_NAMES {
  Lower = 'lower',
  Opendoor = 'opendoor',
  Eden = 'lower',
  Gravy = 'lower',
  Homelight = 'lower',
  Prevu = 'lower',
  Mashvisor = 'lower',
  LowerTest = 'lower',
}

const PARTNER_BRANDING = {
  Eden: false,
  Gravy: true,
  Homelight: false,
  LowerTest: true,
  Mashvisor: false,
  Opendoor: false,
  Prevu: false,
};

export const defaultPartnerConfiguration: DefaultExperienceConfig = {
  affinityPartnerName: null,
  consentRequired: false,
  displayName: null,
  hasOwnBranding: false,
  isPartnerExperience: false,
  loanPurpose: null,
  partnerName: null,
  ratesName: PARTNER_RATES_NAMES.Lower,
  realEstateAgentQuestion: true,
  redirectUrl: null,
  showWarmUp: false,
};

export type ClearableField = keyof Omit<BasePartnerConfig, 'ratesName' | 'frameAncestorOrigin' | 'hasOwnBranding'>;

export const isValidLoanType = (loanType: string): loanType is LoanPurpose => (
  Object.values<string>(LoanPurpose).includes(loanType)
);

const isPartnerName = (partnerName: string | null): partnerName is PARTNER_NAME => (
  partnerName !== null && Object.values(PARTNER_NAME).some((name) => name === partnerName
  && (partnerName !== PARTNER_NAME.LowerTest || ENV.INCLUDE_LOWER_TEST_PARTNER === 'true'))
);

export const initializePartnerConfiguration: () => PartnerConfiguration
  = () => {
    const {
      consentRequired, partnerName, realEstateAgentQuestion, showWarmUp, loanPurpose, redirectUrl, origin,
    } = getPartnerConfigurationQueryParameters();

    if (isPartnerName(partnerName)) {
      setPartnerConfigurationCookies({
        consentRequired,
        loanPurpose,
        partnerName,
        realEstateAgentQuestion,
        redirectUrl,
        showWarmUp,
      });
    }

    const cookieValues = getPartnerConfigurationCookies();

    const consolidatedValues = {
      consentRequired: (consentRequired ?? cookieValues.consentRequired),
      loanPurpose: (loanPurpose ?? cookieValues.loanPurpose),
      origin,
      partnerName: (partnerName ?? cookieValues.partnerName),
      realEstateAgentQuestion: (realEstateAgentQuestion ?? cookieValues.realEstateAgentQuestion),
      redirectUrl: (redirectUrl ?? cookieValues.redirectUrl),
      showWarmUp: (showWarmUp ?? cookieValues.showWarmUp),
    };

    if (!isPartnerName(consolidatedValues.partnerName)) {
      return defaultPartnerConfiguration;
    }

    const config = partnerNameConfiguration(consolidatedValues.partnerName);

    return {
      affinityPartnerName: config.affinityPartnerName,
      consentRequired: consolidatedValues.consentRequired === 'true',
      displayName: config.displayName,
      hasOwnBranding: config.hasOwnBranding,
      isPartnerExperience: true,
      loanPurpose: consolidatedValues.loanPurpose && isValidLoanType(consolidatedValues.loanPurpose)
        ? consolidatedValues.loanPurpose
        : null,
      partnerName: consolidatedValues.partnerName,
      ratesName: config.ratesName,
      realEstateAgentQuestion: consolidatedValues.realEstateAgentQuestion !== 'false',
      redirectUrl: validRedirectUrl(consolidatedValues) ?? null,
      showWarmUp: consolidatedValues.showWarmUp === 'true',
    };
  };

const getPartnerConfigurationCookies = () => ({
  consentRequired: cookies.get(CONSENT_REQUIRED_KEY) ?? null,
  loanPurpose: cookies.get(LOAN_TYPE_KEY) ?? null,
  partnerName: cookies.get(PARTNER_NAME_KEY) ?? null,
  realEstateAgentQuestion: cookies.get(REAL_ESTATE_AGENT_QUESTION_KEY) ?? null,
  redirectUrl: cookies.get(REDIRECT_URL_KEY) ?? null,
  showWarmUp: cookies.get(SHOW_WARM_UP_KEY) ?? null,
});

const partnerCookieOptions = (): CookieAttributes => (
  {
    ...getCookieOptions(),
    expires: 180,
    sameSite: 'none',
    secure: true,
  }
);

const setPartnerConfigurationCookies = ({
  consentRequired = null,
  loanPurpose = null,
  partnerName = null,
  realEstateAgentQuestion = null,
  redirectUrl = null,
  showWarmUp = null,
}: {
  consentRequired?: string | null,
  loanPurpose?: string | null,
  partnerName?: string | null,
  realEstateAgentQuestion?: string | null,
  redirectUrl?: string | null,
  showWarmUp?: string | null
}) => {
  consentRequired && cookies.set(CONSENT_REQUIRED_KEY, consentRequired, partnerCookieOptions());
  partnerName && cookies.set(PARTNER_NAME_KEY, partnerName, partnerCookieOptions());
  realEstateAgentQuestion && cookies.set(
    REAL_ESTATE_AGENT_QUESTION_KEY,
    realEstateAgentQuestion,
    partnerCookieOptions(),
  );
  redirectUrl && cookies.set(REDIRECT_URL_KEY, redirectUrl, partnerCookieOptions());
  showWarmUp && cookies.set(SHOW_WARM_UP_KEY, showWarmUp, partnerCookieOptions());
  loanPurpose && cookies.set(LOAN_TYPE_KEY, loanPurpose, partnerCookieOptions());
};

class PartnerConfigInvalid extends Error {
  constructor(partnerName: string, propertyName: string, value: string) {
    super(`Partner config for '${partnerName}' is invalid. Invalid value of '${value}' for property '${propertyName}'.`);
  }
}

const validRedirectUrl = ({
  redirectUrl = null,
  partnerName = null,
}: {
  redirectUrl: string | null,
  partnerName: string | null
}) => {
  if (partnerName && redirectUrl) {
    const url = new URL(redirectUrl);
    const domain = url.host;
    if (!ENV.PARTNER_ALLOWED_REDIRECT_DOMAINS[partnerName]?.some((d) => domain === d)) {
      const error = new PartnerConfigInvalid(partnerName, 'redirectUrl', redirectUrl);
      // eslint-disable-next-line no-console
      console.error(error);
      sendException(error);
      return null;
    }

    return redirectUrl;
  }
};

const getPartnerConfigurationQueryParameters = () => {
  const url = new URL(window.location.href);

  const consentRequired = url.searchParams.get(CONSENT_REQUIRED_KEY);
  const partnerName = url.searchParams.get(PARTNER_NAME_KEY);
  const realEstateAgentQuestion = url.searchParams.get(REAL_ESTATE_AGENT_QUESTION_KEY);
  const showWarmUp = url.searchParams.get(SHOW_WARM_UP_KEY);
  const loanPurpose = url.searchParams.get(LOAN_TYPE_KEY);
  const redirectUrl = url.searchParams.get(REDIRECT_URL_KEY);
  const origin = url.searchParams.get(ORIGIN_KEY);

  return {
    consentRequired,
    loanPurpose,
    origin,
    partnerName,
    realEstateAgentQuestion,
    redirectUrl: redirectUrl === null
      ? null
      : decodeURIComponent(redirectUrl),
    showWarmUp,
  };
};

const partnerNameConfiguration = (partnerName: PARTNER_NAME) => {
  switch (partnerName) {
  case PARTNER_NAME.Opendoor:
    return {
      affinityPartnerName: AFFINITY_PARTNER_NAME.Opendoor,
      displayName: PARTNER_DISPLAY_NAMES.Opendoor,
      hasOwnBranding: PARTNER_BRANDING.Opendoor,
      ratesName: PARTNER_RATES_NAMES.Opendoor,
    };
  case PARTNER_NAME.Gravy:
    return {
      affinityPartnerName: AFFINITY_PARTNER_NAME.Gravy,
      displayName: PARTNER_DISPLAY_NAMES.Gravy,
      hasOwnBranding: PARTNER_BRANDING.Gravy,
      ratesName: PARTNER_RATES_NAMES.Gravy,
    };
  case PARTNER_NAME.Eden:
    return {
      affinityPartnerName: AFFINITY_PARTNER_NAME.Eden,
      displayName: PARTNER_DISPLAY_NAMES.Eden,
      hasOwnBranding: PARTNER_BRANDING.Eden,
      ratesName: PARTNER_RATES_NAMES.Eden,
    };
  case PARTNER_NAME.Homelight:
    return {
      affinityPartnerName: AFFINITY_PARTNER_NAME.Homelight,
      displayName: PARTNER_DISPLAY_NAMES.Homelight,
      hasOwnBranding: PARTNER_BRANDING.Homelight,
      ratesName: PARTNER_RATES_NAMES.Homelight,
    };
  case PARTNER_NAME.Prevu:
    return {
      affinityPartnerName: AFFINITY_PARTNER_NAME.Prevu,
      displayName: PARTNER_DISPLAY_NAMES.Prevu,
      hasOwnBranding: PARTNER_BRANDING.Prevu,
      ratesName: PARTNER_RATES_NAMES.Prevu,
    };
  case PARTNER_NAME.Mashvisor:
    return {
      affinityPartnerName: AFFINITY_PARTNER_NAME.Mashvisor,
      displayName: PARTNER_DISPLAY_NAMES.Mashvisor,
      hasOwnBranding: PARTNER_BRANDING.Mashvisor,
      ratesName: PARTNER_RATES_NAMES.Mashvisor,
    };
  case PARTNER_NAME.LowerTest:
    return {
      affinityPartnerName: AFFINITY_PARTNER_NAME.LowerTest,
      displayName: PARTNER_DISPLAY_NAMES.LowerTest,
      hasOwnBranding: PARTNER_BRANDING.LowerTest,
      ratesName: PARTNER_RATES_NAMES.LowerTest,
    };
  default:
    throw new Error('partnerName is not supported');
  }
};

const fieldNameToCookieKey = {
  consentRequired: CONSENT_REQUIRED_KEY,
  loanPurpose: LOAN_TYPE_KEY,
  partnerName: PARTNER_NAME_KEY,
  realEstateAgentQuestion: REAL_ESTATE_AGENT_QUESTION_KEY,
  redirectUrl: REDIRECT_URL_KEY,
  showWarmUp: SHOW_WARM_UP_KEY,
};

export const removeCookie = (fieldName: ClearableField) => {
  const cookieKey = fieldNameToCookieKey[fieldName];

  if (cookieKey) {
    cookies.remove(cookieKey);
  }
};
