export const CheckIcon = ({
  color = '#0064FF', label = 'Check', ...props
}) => (
  <svg
    width={'14'}
    height={'12'}
    viewBox={'0 0 14 12'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>{label}</title>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={'M11.8682 0L14 1.96731L5.49116 11.6667L0 6.34164L1.97023 4.20442L5.32545 7.45813L11.8682 0Z'}
      fill={color}
    />
  </svg>
);
