import { Paragraph, responsiveStyleFromTheme } from '@lower-financial/core-components/src';
import styled from 'styled-components';

export const LandingFooter = () => (
  <LandingFooterContainer>
    <FooterParagraph variant={'legal'}>
      Copyright © {new Date().getFullYear()} Lower. 8131 Smith's Mill Rd New Albany, OH 43054.
      NMLS ID #1124061 All rights reserved. Equal Housing Lender. All loans are subject to approval,
      to include the underwriting of credit and collateral.&nbsp;
      <ParagraphLink href={'https://www.nmlsconsumeraccess.org'}>nmlsconsumeraccess.org</ParagraphLink>
    </FooterParagraph>
    <ParagraphLinkContainer>
      <ParagraphLink href={'https://www.lower.com/legal/license-information'}>License</ParagraphLink>&nbsp;|&nbsp;
      <ParagraphLink href={'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1124061'}>NMLS</ParagraphLink>&nbsp;|&nbsp;
      <ParagraphLink href={'https://www.lower.com/legal/privacy-policy'}>Privacy</ParagraphLink>&nbsp;|&nbsp;
      <ParagraphLink href={'https://www.lower.com/legal/website-terms-of-use'}>Terms</ParagraphLink>
    </ParagraphLinkContainer>
  </LandingFooterContainer>
);

const LandingFooterContainer = styled.div`
  background-color: var(--silk);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  ${responsiveStyleFromTheme({
    desktop: {
      backgroundColor: 'var(--chalk)',
      display: 'flex',
      flexDirection: 'row',
    },
  })}
`;

const FooterParagraph = styled(Paragraph)`
  text-align: left;
  width: 100%;
  max-width: 49rem;
`;

const ParagraphLinkContainer = styled.div`
  color: var(--ash);
  font: var(--font-paragraph-link);
  flex-grow: 1;
  ${responsiveStyleFromTheme({
    desktop: {
      textAlign: 'right',
      width: 'unset',
    },
  })}
`;

const ParagraphLink = styled.a`
  font-size: 0.75rem;
  color: var(--ash);
  text-decoration: none !important;
`;
