export const LightningIcon = ({ color = '#6236FF', ...props }) => (
  <svg
    width={'13'}
    height={'20'}
    viewBox={'0 0 13 20'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <title>lightning icon</title>
    <path
      d={'M6.98349 5.61786L8.6357 0.299805H3.16275L0.495117 9.88607H5.60665L3.66186 19.1109L12.8179 5.61786H6.98349Z'}
      fill={color}
    />
  </svg>
);
