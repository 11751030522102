import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { FeatureFlagKeys, useFeatureFlags, useFeatureFlagVariantKey } from '@lower-financial/feature-flags';
import { QuotesStore } from '@lightspeed/contexts/quotes-context/use-quotes-state';
import { useMarket } from '@lightspeed/hooks/useMarket/useMarket';
import { usePartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { ROUTES, RoutesPriorToMinimumInfo } from '@lightspeed/routing/routes';
import { ROUTING_EVENTS } from '@lightspeed/routing/routing-events';
import { RoutingMachine } from './routing-machine';

export function useNextRoute(quotesStore: QuotesStore = {
  passedFilters: false,
  quotes: [],
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();
  const { mortgageApplication } = useMortgageApplication();
  const { isUnsupportedMarket } = useMarket();
  const evaluateMarket = isUnsupportedMarket(mortgageApplication.propertyState);
  const { partnerConfiguration: { showWarmUp, loanPurpose } } = usePartnerConfiguration();
  const isLightspeedPurchaseTestVariant = useFeatureFlagVariantKey(FeatureFlagKeys.lightspeedV2Purchase) === 'test';

  // if a user hits a page with an obviously incomplete application context, send them back to basic info to start over.
  if (
    !mortgageApplication.loanPurpose
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    && Object.values(ROUTES).includes(location.pathname as ROUTES)
    && !RoutesPriorToMinimumInfo.has(location.pathname)
  ) {
    setTimeout(() => navigate(determineRoute(
      loanPurpose,
      isLightspeedPurchaseTestVariant,
    )), 0); // timeout allows us to bypass some router safety features
  }
  const goToRoute = (event: ROUTING_EVENTS = ROUTING_EVENTS.NEXT) => {
    const state = RoutingMachine
      .withContext({
        borrowerContractStatus: mortgageApplication.borrowerContractStatus,
        featureFlags,
        hasCoBorrower: mortgageApplication.hasCoBorrower,
        hasRates: quotesStore.quotes.length > 0,
        isUnsupportedMarket: evaluateMarket,
        jumpstartEligibility: mortgageApplication.jumpstartEligibilityDecision,
        loanPurpose: mortgageApplication.loanPurpose,
        partnerLoanPurpose: loanPurpose,
        propertyResidenceType: mortgageApplication.propertyResidenceType,
        quoteChosen: mortgageApplication.quoteChosen,
        showPartnerWarmUp: showWarmUp,
      })
      .transition(location.pathname, event);
    navigate(state.value);
  };

  const memoizedGoToRoute = useCallback(goToRoute, [
    mortgageApplication.hasCoBorrower,
    mortgageApplication.loanPurpose,
    mortgageApplication.borrowerContractStatus,
    mortgageApplication.jumpstartEligibilityDecision,
    mortgageApplication.quoteChosen,
    mortgageApplication.propertyResidenceType,
    quotesStore.quotes.length,
    evaluateMarket,
    loanPurpose,
    showWarmUp,
    location.pathname,
    navigate,
    featureFlags,
  ]);

  return {
    goToNextRoute: useCallback(() => memoizedGoToRoute(), [memoizedGoToRoute]),
    triggerRoutingEvent: memoizedGoToRoute,
  };
}

const determineRoute = (
  loanPurpose: MortgageApplicationStore['loanPurpose'],
  isLightspeedPurchaseTestVariant: boolean,
) => {
  switch (loanPurpose) {
  case LoanPurpose.Purchase:
    return isLightspeedPurchaseTestVariant
      ? ROUTES.PURCHASE_PROPERTY_TYPE
      : ROUTES.PURCHASE_BASIC_INFO;
  case LoanPurpose.Refinance:
    return ROUTES.REFINANCE_PROPERTY_TYPE;
  case LoanPurpose.Heloc:
    return ROUTES.HELOC_PROPERTY_TYPE;
  default:
    return ROUTES.LANDING;
  }
};
