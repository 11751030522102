export const PlusIcon = ({ color = '#001CDB', ...props }) => (
  <svg
    width={'12'}
    height={'12'}
    viewBox={'0 0 12 12'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <line
      x1={'0.895833'}
      y1={'6.10417'}
      x2={'11.1042'}
      y2={'6.10417'}
      stroke={color}
      strokeWidth={'1.79167'}
      strokeLinecap={'round'}
    />
    <line
      x1={'5.89583'}
      y1={'0.895833'}
      x2={'5.89583'}
      y2={'11.1042'}
      stroke={color}
      strokeWidth={'1.79167'}
      strokeLinecap={'round'}
    />
  </svg>
);
