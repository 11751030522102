import styled from 'styled-components';
import { compose, layout, LayoutProps, space, SpaceProps, typography, TypographyProps, variant } from 'styled-system';
import { ReactNode } from 'react';
import cx from 'classnames';

export type ParagraphProps = LayoutProps & SpaceProps & TypographyProps & {
  className?: string;
  children?: ReactNode;
  variant?: 'large' | 'legal' | 'legalBold' | 'regular' | 'regularBold' | 'smallMediumBoldGrey' | 'smallMediumBold' | 'small' | 'smallLight' | 'smallLightItalic' | 'smallLightBoldItalic' | 'light' | 'largeLight'| 'lightBold';
};

export const Paragraph = styled.p.attrs<ParagraphProps>(
  ({
    className,
    ...props
  }: ParagraphProps) => ({
    className: cx('Paragraph', className, `--${props.variant || 'regular'}`),
    variant: props?.variant ?? 'regular',
    ...props,
  }),
)<ParagraphProps>`
  margin: 0;
  ${compose(
    layout,
    space,
    typography,
    variant({
      scale: 'Body',
    }),
  )}
`;
