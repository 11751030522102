import styled from 'styled-components';
import { CellPhoneHomeIcon } from '@lower-financial/icons';
import { useAnalytics } from '@lower-financial/analytics';
import { TerminalScreenLayout } from '@lightspeed/components/shared/terminal-screen-layout/terminal-screen-layout';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { getMarketingUrl } from '@lightspeed/utils/get-marketing-url';

export const JumpstartManualIntervention = () => {
  const { mortgageApplication } = useMortgageApplication();

  const fireAnalyticsEvent = useAnalytics('jumpstart_manual_intervention');

  const closeButtonUrl = getMarketingUrl(mortgageApplication.loanPurpose);

  return (
    <TerminalScreenLayout
      heading={'We got your information.'}
      subtext={'Thanks for applying. We’ll reach out soon for your next steps.'}
      iconComponent={<StyledIcon withCheckmark={false} />}
      animations={false}
      fireAnalyticsEvent={fireAnalyticsEvent}
      closeButtonUrl={closeButtonUrl}
    />
  );
};

const StyledIcon = styled(CellPhoneHomeIcon)({
  height: 100,
  width: 100,
});
