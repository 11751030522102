import React, { useEffect } from 'react';
import { useAnalytics } from '@lower-financial/analytics';
import { LoanPurpose } from '@lower-financial/lending-web-api/generated/models';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { usePartnerConfiguration } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { BasicInfoTemplate } from '@lightspeed/components/ui/pages/basic-info/basic-info-template';

export function PurchaseBasicInfo() {
  const { updateMortgageApplication } = useMortgageApplication();
  const { partnerConfiguration: { loanPurpose }, clearField } = usePartnerConfiguration();
  const fireAnalyticsEvent = useAnalytics('v2/purchase');

  useEffect(() => {
    if (loanPurpose !== LoanPurpose.Purchase) {
      clearField('loanPurpose');
    }
  }, [clearField, loanPurpose]);

  useEffect(() => {
    updateMortgageApplication('loanPurpose', LoanPurpose.Purchase);
  }, [updateMortgageApplication]);

  return (
    <BasicInfoTemplate
      fireAnalyticsEvent={fireAnalyticsEvent}
    />
  );
}
