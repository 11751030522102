/* tslint:disable */
/* eslint-disable */
/**
 * Lower API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const JumpstartEligibilityStatus = {
    InProgress: 'inProgress',
    ContinueToJumpstart: 'continueToJumpstart',
    ManualIntervention: 'manualIntervention',
    NotApplicable: 'notApplicable'
} as const;
export type JumpstartEligibilityStatus = typeof JumpstartEligibilityStatus[keyof typeof JumpstartEligibilityStatus];


export function JumpstartEligibilityStatusFromJSON(json: any): JumpstartEligibilityStatus {
    return JumpstartEligibilityStatusFromJSONTyped(json, false);
}

export function JumpstartEligibilityStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): JumpstartEligibilityStatus {
    return json as JumpstartEligibilityStatus;
}

export function JumpstartEligibilityStatusToJSON(value?: JumpstartEligibilityStatus | null): any {
    return value as any;
}

