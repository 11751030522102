import refinanceImageSrc from '@lightspeed/assets/images/refinance.png';

export const RefinanceImage = ({ ...props }) => (
  <img
    src={refinanceImageSrc}
    alt={'Refinance'}
    height={'87px'}
    width={'126px'}
    {...props}
  />
);

