import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import {
  defaultReferralData,
  initializeReferralData,
  ReferralData,
  setReferralDataCookies,
} from '@lightspeed/contexts/referral-context/referral-data-helpers';

export type UpdateReferralData = (newVals: Partial<ReferralData>) => void;

const ReferralContext = createContext<{
  referralData:  ReferralData,
  updateReferralData(newVals: Partial<ReferralData>): void,
}>({
      referralData: defaultReferralData,
      updateReferralData(newVals: Partial<ReferralData>) {
        // do nothing
      },
    });

export function ReferralDataContextProvider({ children }: {
  children?: ReactNode | ReactNode[]
}) {
  const postHog = usePostHog();
  const [referralData, setReferralData] = useState(initializeReferralData(postHog));
  const updateReferralData = useCallback((newVals: Partial<ReferralData>) => {
    setReferralDataCookies(newVals);
    setReferralData((prevReferralData) => ({
      ...prevReferralData,
      ...newVals,
    }));
  }, []);

  const context = useMemo(() => (
    {
      referralData,
      updateReferralData,
    }
  ), [referralData, updateReferralData]);

  return (
    <ReferralContext.Provider
      value={context}
    >
      {children}
    </ReferralContext.Provider>
  );
}

export const useReferralData = () => useContext(ReferralContext);

