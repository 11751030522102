export enum ROUTES {
  EMPLOYMENT_STATUS = '/mortgage/lightspeed/employment-status',
  ABOUT_YOU = '/mortgage/lightspeed/about-you',
  PURCHASE_PROPERTY_TYPE = '/mortgage/lightspeed/purchase/property-type',
  HELOC_PROPERTY_TYPE = '/mortgage/lightspeed/heloc/property-type',
  REFINANCE_PROPERTY_TYPE = '/mortgage/lightspeed/refinance/property-type',
  PROPERTY_VALUE = '/mortgage/lightspeed/property-value',
  PURCHASE_PRICE = '/mortgage/lightspeed/purchase-price',
  YEARLY_INCOME = '/mortgage/lightspeed/yearly-income',
  PROPERTY_ADDRESS = '/mortgage/lightspeed/property-address',
  PROPERTY_STATE = '/mortgage/lightspeed/property-state',
  CONTRACT_STATUS = '/mortgage/lightspeed/contract-status',
  CREDIT_CHECK = '/mortgage/lightspeed/credit-check',
  BORROWER_ADDRESS = '/mortgage/lightspeed/borrower-address',
  PARTNER_LANDING = '/mortgage/lightspeed/partners',
  WARM_UP = '/mortgage/lightspeed/warm-up',
  LANDING = '/mortgage/lightspeed',
  PURCHASE = '/mortgage/lightspeed/purchase',
  REFINANCE = '/mortgage/lightspeed/refinance',
  HELOC = '/mortgage/lightspeed/heloc',
  PURCHASE_BASIC_INFO = '/mortgage/lightspeed/purchase/basic-info',
  APPLICATION_OPTIONS = '/mortgage/lightspeed/application-options',
  BASIC_INFO_COBORROWER = '/mortgage/lightspeed/coborrower-info',
  CURRENT_ADDRESS = '/mortgage/lightspeed/current-address',
  PROPERTY_PURCHASE_IN_CONTRACT = '/mortgage/lightspeed/property/purchase/in-contract',
  PROPERTY_PURCHASE_NOT_IN_CONTRACT = '/mortgage/lightspeed/property/purchase/not-in-contract',
  ASSETS = '/mortgage/lightspeed/assets',
  INCOME = '/mortgage/lightspeed/income',
  IDENTITY = '/mortgage/lightspeed/identity',
  UNSUPPORTED_MARKET = '/mortgage/lightspeed/unsupported-states',
  VERIFY_EMAIL_SENT = '/mortgage/lightspeed/verify-email-sent',
  JUMPSTART_PROCESSING = '/mortgage/lightspeed/jumpstart-processing',
  JUMPSTART_MANUAL_INTERVENTION = '/mortgage/lightspeed/jumpstart-manual-intervention',
  SUBMITTED = '/mortgage/lightspeed/submitted',
}

// These routes hide the progress bar since it has a different layout
export const TERMINAL_ROUTES = [
  ROUTES.UNSUPPORTED_MARKET,
  ROUTES.JUMPSTART_MANUAL_INTERVENTION,
  ROUTES.SUBMITTED,
];

// This controls the percentage of the progress bar at the top of the page
// For each route, enter the progress percentage that should show
export const ProgressMapping = new Map<ROUTES | string, number>([
  /* Starting Routes */
  [ROUTES.PURCHASE_BASIC_INFO, 0],

  [ROUTES.APPLICATION_OPTIONS, 10],
  [ROUTES.BASIC_INFO_COBORROWER, 12],

  /* Current Address */
  [ROUTES.CURRENT_ADDRESS, 15],

  /* Property Pages */
  [ROUTES.PROPERTY_PURCHASE_IN_CONTRACT, 30],
  [ROUTES.PROPERTY_PURCHASE_NOT_IN_CONTRACT, 30],

  /* Assets is specific to purchase */
  [ROUTES.ASSETS, 40],

  /* Income */
  [ROUTES.INCOME, 50],

  /* Identity */
  [ROUTES.IDENTITY, 60],

  /* New Flow */
  [ROUTES.PURCHASE_PROPERTY_TYPE, 10],
  [ROUTES.HELOC_PROPERTY_TYPE, 10],
  [ROUTES.REFINANCE_PROPERTY_TYPE, 10],
  [ROUTES.CONTRACT_STATUS, 20],
  [ROUTES.PROPERTY_VALUE, 30],
  [ROUTES.PURCHASE_PRICE, 30],
  [ROUTES.PROPERTY_ADDRESS, 40],
  [ROUTES.PROPERTY_STATE, 40],
  [ROUTES.BORROWER_ADDRESS, 50],
  [ROUTES.EMPLOYMENT_STATUS, 60],
  [ROUTES.YEARLY_INCOME, 70],
  [ROUTES.ABOUT_YOU, 80],
  [ROUTES.CREDIT_CHECK, 90],

  /* Terminal Routes */
  [ROUTES.UNSUPPORTED_MARKET, 100],
  [ROUTES.JUMPSTART_MANUAL_INTERVENTION, 100],
  [ROUTES.SUBMITTED, 100],
]);

export const RoutesPriorToMinimumInfo = new Set<string>([
  ROUTES.LANDING,
  ROUTES.PARTNER_LANDING,
  ROUTES.WARM_UP,
  ROUTES.PURCHASE,
  ROUTES.PURCHASE_BASIC_INFO,
  ROUTES.PURCHASE_PROPERTY_TYPE,
  ROUTES.HELOC,
  ROUTES.HELOC_PROPERTY_TYPE,
  ROUTES.REFINANCE,
  ROUTES.REFINANCE_PROPERTY_TYPE,
]);
