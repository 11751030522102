import { BaseSchema, boolean, date, number, object, ref, string } from 'yup';
import { ContractStatus, LoanPurpose } from '@lower-financial/lending-web-api/generated';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import {
  coborrowerEmploymentStatuses,
  EmploymentStatus,
  employmentStatuses,
} from '@lower-financial/core-components';
import { STATE_OPTIONS } from '@lower-financial/toolbox/src';

const today = new Date();

const refinanceOrHelocLoanPurpose = (loanPurpose: string) => [LoanPurpose.Refinance, LoanPurpose.Heloc]
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  .includes(loanPurpose as typeof LoanPurpose.Refinance | typeof LoanPurpose.Heloc);
const notEmpty = (value: unknown) => value !== '';
const allTruthy = (...args: unknown[]) => !args.some((arg) => !arg);

const MAX_INTEGER_VALUE = 1_000_000_000_000;

type KeysNotRequiringValidation =
  'loanPurpose' |
  'borrowerAddressAutocompleteValue' |
  'borrowerCreditAuthDateTime' |
  'coBorrowerAddressAutocompleteValue' |
  'coBorrowerCreditAuthDateTime' |
  'hasCoBorrower' |
  'preapprovalEligibilityId' |
  'propertyAutocompleteValue' |
  'quoteChosen' |
  'quotingApplicationId' |
  'jumpstartEligibilityDecision' |
  'isBorrowerCreditFrozen';

const mortgageApplicationShape: {
  [key in keyof Omit<MortgageApplicationStore, KeysNotRequiringValidation>]: BaseSchema
} = {
  borrowerAmountInMutualFunds: number().min(0).max(MAX_INTEGER_VALUE, 'Amount in mutual funds must be less than $1 trillion').label('Amount in mutual funds')
    .when('loanPurpose', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),
  borrowerAmountInSavings: number().min(1).max(MAX_INTEGER_VALUE, 'Savings must be less than $1 trillion').label('Amount in savings')
    .when('loanPurpose', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),
  borrowerCity: string().max(40).required().label('City'),

  borrowerContractStatus: string().oneOf(Object.values(ContractStatus), 'Contract Status is required')
    .when('loanPurpose', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  borrowerCounty: string().required().label('County'),

  borrowerDateOfBirth: date().required().typeError('ex: 03/09/1994')
    .min(new Date('01/01/1885'), 'Must be less than 115 years old')
    .max(new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()), 'Must be 18 years or older')
    .label('Date of Birth'),

  borrowerEmail: string().email().required().label('Email'),

  borrowerEmployer: string().required().label('Employer name').max(255),

  borrowerEmploymentStatus: string().oneOf(employmentStatuses, 'Employment Status is required').required(),

  borrowerFirstName: string().required().label('First name').max(40),

  borrowerFirstTimeHomeBuyer: string().oneOf(['Yes', 'No'])
    .when('loanPurpose', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  borrowerLastName: string().required().label('Last name').max(80),

  borrowerOtherIncomePerYear: number().min(0).max(MAX_INTEGER_VALUE, 'Other income must be less than $1 trillion').required().label('Other income per year')
    .when('borrowerYearlySalary', {
      is: (salary: string) => salary === '' || parseInt(salary, 10) < 1,
      then: number().min(1).required().label('Combined yearly income'),
    }),

  borrowerPhoneNumber: string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'requires valid phone number (e.g. 999-999-9999)').required().label('Phone Number'),

  borrowerSocialSecurityNumber: string().matches(/^\d{3}-?\d{2}-?\d{4}$/, 'requires valid social security number').required().label('Social Security Number'),

  borrowerState: string().oneOf(STATE_OPTIONS.map((kvp) => kvp.value), 'State is required').required().label('State'),

  borrowerStreetAddress: string().required().label('Street Address').max(100),

  borrowerTitle: string().required().label('Title'),

  borrowerYearlyIncomeRangeId: string(),

  borrowerYearlySalary: number().min(0).max(MAX_INTEGER_VALUE, 'Yearly salary must be less than $1 trillion').required().label('Annual Gross Salary')
    .when('borrowerOtherIncomePerYear', {
      is: (otherIncome: string) => otherIncome === '' || parseInt(otherIncome, 10) < 1,
      then: number().min(1).required().label('Combined yearly income'),
    }),

  borrowerYearsAtAddress: number().label('Years at Current Address').integer().min(0).max(99, 'Years at Current Address must be less than or equal to 99 years').required().typeError('Years at Current Address is required'),

  borrowerYearsAtCompany: number().min(0).max(99, 'Years at company must be less than or equal to 99 years').required().label('Years at company'),

  borrowerZipCode: string().matches(/^\d{5}$/g, 'requires valid zip code').required().label('Zip code'),

  coBorrowerAmountInMutualFunds: number().nullable().transform((curr: string, orig: string) => (orig === ''
    ? null
    : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: notEmpty,
      then: number().min(0).max(MAX_INTEGER_VALUE, 'Coborrower amount in mutual funds must be less than $1 trillion').required(),
    }),

  coBorrowerAmountInSavings: number().nullable().transform((curr: string, orig: string) => (orig === ''
    ? null
    : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: notEmpty,
      then: number().min(0).max(MAX_INTEGER_VALUE, 'Coborrower amount in savings must be less than $1 trillion').required(),
    }),

  coBorrowerCity: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().max(40).label('City').required(),
    }),

  coBorrowerCounty: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().label('County').required(),
    }),

  coBorrowerDateOfBirth: date().nullable().transform((curr: string, orig: string) => (orig === ''
    ? null
    : curr)) // transform required to handle default value of '' even when optional
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: date().required().typeError('ex: 03/09/1994')
        .min(new Date('01/01/1885'), 'Must be less than 115 years old')
        .max(new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()), 'Must be 18 years or older')
        .label('Date of Birth'),
    }),

  coBorrowerEmail: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().email().label('Email').required().notOneOf([ref('borrowerEmail')], 'Emails cannot match'),
    }),

  coBorrowerEmployer: string()
    .when(['$hasCoBorrower', 'coBorrowerEmploymentStatus'], {
      is: (hasCoBorrower: boolean, employmentStatus: string) =>
        hasCoBorrower
        && employmentStatus !== EmploymentStatus.UNEMPLOYED,
      then: string().required().label('Employer name').max(255),
    }),

  coBorrowerEmploymentStatus: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().oneOf(coborrowerEmploymentStatuses, 'Employment Status is required').required(),
    }),

  coBorrowerFirstName: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().label('First name').required().max(40),
    }),

  coBorrowerLastName: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().label('Last name').required().max(80),
    }),

  coBorrowerOtherIncomePerYear: number().nullable().transform((curr: string, orig: string) => (orig === ''
    ? null
    : curr)) // transform required to handle default value of '' even when optional
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: number().min(0).max(MAX_INTEGER_VALUE, 'Coborrower other income must be less than $1 trillion').required(),
    }),

  coBorrowerPhoneNumber: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'requires valid phone number (e.g. 999-999-9999)').label('Phone Number').required(),
    }),

  coBorrowerSocialSecurityNumber: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().matches(/^\d{3}-?\d{2}-?\d{4}$/, 'requires valid social security number').label('Social Security Number').required(),
    }),

  coBorrowerState: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().oneOf(STATE_OPTIONS.map((kvp) => kvp.value), 'State is required').required(),
    }),

  coBorrowerStreetAddress: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().label('Street Address').required().max(100),
    }),

  coBorrowerTitle: string()
    .when(['$hasCoBorrower', 'coBorrowerEmploymentStatus'], {
      is: ($hasCoBorrower: boolean, employmentStatus: string) =>
        $hasCoBorrower && employmentStatus !== EmploymentStatus.UNEMPLOYED,
      then: string().label('Title').required().max(255),
    }),

  coBorrowerYearlySalary: number().nullable().transform((curr: string, orig: string) => (orig === ''
    ? null
    : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: notEmpty,
      then: number().min(0).max(MAX_INTEGER_VALUE, 'Coborrower salary must be less than $1 trillion').required(),
    }),

  coBorrowerYearsAtCompany: number().nullable().transform((curr: string, orig: string) => (orig === ''
    ? null
    : curr)) // transform required to handle default value of '' even when optional
    .when(['$hasCoBorrower', 'coBorrowerEmploymentStatus'], {
      is: ($hasCoBorrower: boolean, employmentStatus: string) =>
        $hasCoBorrower && employmentStatus !== EmploymentStatus.UNEMPLOYED,
      then: number().min(0).max(99, 'Years at company must be less than or equal to 99 years').required(),
    }),

  coBorrowerZipCode: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().matches(/^\d{5}$/g, 'requires valid zip code').label('Zip Code').required(),
    }),

  consentSharePreapprovalDenialReasons: boolean().nullable(),

  discountPoints: string().required(),

  hasRealEstateAgent: boolean().nullable(),

  loanTerm: string().oneOf(['fifteen', 'twenty', 'thirty']).required(),

  propertyCashOut: number().label('Cash Out').min(0).max(MAX_INTEGER_VALUE, 'Cash Out must be less than $1 trillion').typeError('Cash out value is required')
    .when('loanPurpose', {
      is: refinanceOrHelocLoanPurpose,
      then: (schema) => schema.required(),
    }),

  propertyCity: string().label('City').max(40).required(),

  propertyCounty: string().label('County').required(),

  propertyDownPayment: number().label('Property Down Payment').min(1).max(MAX_INTEGER_VALUE)
    .when('loanPurpose', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  propertyDownPaymentPartiallyGift: string().oneOf(['Yes', 'No'])
    .when('loanPurpose', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  propertyHomeValue: number().label('Property Home Value').min(1).max(MAX_INTEGER_VALUE, 'Property Home Value must be less than $1 trillion').typeError('Home Value is required')
    .when('loanPurpose', {
      is: refinanceOrHelocLoanPurpose,
      then: (schema) => schema.required(),
    }),

  propertyHomeValueRangeId: string(),

  propertyMortgageBalance: number().label('Mortgage Balance').min(1).max(MAX_INTEGER_VALUE, 'Mortgage Balance must be less than $1 trillion').typeError('Outstanding Mortgage Balance is required')
    .when('loanPurpose', {
      is: refinanceOrHelocLoanPurpose,
      then: (schema) => schema.required(),
    }),

  propertyPurchasePrice: number().label('Property Purchase Price').min(1).max(MAX_INTEGER_VALUE, 'Property Purchase Price must be less than $1 trillion')
    .when('loanPurpose', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  propertyPurchasePriceRangeId: string(),

  propertyResidenceType: string().oneOf(['Primary Residence', 'Secondary Residence', 'Investment'], 'Residence Type is required').required().label('Residence Type'),

  propertyState: string()
    .oneOf(STATE_OPTIONS.map((kvp) => kvp.value), ({ value }: { value: string }) => 'State is required')
    .required('State is required')
    .label('State'),

  propertyStreetAddress: string()
    .when(['loanPurpose', 'borrowerContractStatus'], {
      is: (loanPurpose: string, borrowerContractStatus: string) =>
        (refinanceOrHelocLoanPurpose(loanPurpose) || borrowerContractStatus === ContractStatus.InContract),
      then: string().label('Street Address').required(),
    }),

  propertyType: string().oneOf(['Single Family', 'Condo', 'Duplex', 'Triplex', 'Fourplex'], 'Property Type is required').required(),

  propertyZipCode: string().matches(/^\d{5}$/g, 'requires valid zip code').label('Zip Code').required(),

  quotingApplicationSubmissionId: string(),
};

export const mortgageApplicationSchema = object().shape(mortgageApplicationShape, [['borrowerYearlySalary', 'borrowerOtherIncomePerYear']]);
