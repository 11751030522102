import { useLocation } from 'react-router-dom';
import { OptionalElement } from '@lower-financial/core-components';
import styled from 'styled-components';
import { ProgressMapping, ROUTES, TERMINAL_ROUTES } from '@lightspeed/routing/routes';

const HIDE_URLS: Array<string | ROUTES> = [...TERMINAL_ROUTES];

export const UrlProgressIndicator = () => {
  const location = useLocation();

  const progress = mapUrlProgress(location.pathname);

  // the next line fades the progress indicator out of view on the success page
  const opacity = HIDE_URLS.includes(location.pathname)
    ? 0
    : 1;

  return (
    <OptionalElement show={progress !== undefined}>
      <ProgressIndicator opacity={opacity}>
        <ProgressPercentage
          progress={progress || 0}
          data-testid={'progress-indicator-percentage'}
        />
      </ProgressIndicator>
    </OptionalElement>
  );
};

const mapUrlProgress = (pathname: string) => {
  if (ProgressMapping.has(pathname)) {
    return ProgressMapping.get(pathname);
  }
};

const ProgressIndicator = styled.div<{ opacity: number; }>`
  height: 3px;
  width: 100%;
  background: var(--primary);
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  justify-content: flex-end;
  opacity: ${(props) => props.opacity};
  transition: opacity 400ms;

  ${(props) => props.theme.media.desktop} {
    top: 100px;
  }
`;

const ProgressPercentage = styled.div<{ progress: number; }>`
  background-color: var(--chalk);
  width: min(${(props) => 100 - props.progress}%, 97%);
  height: 100%;
  transition: width .5s;
`;
