import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { PlusIcon, CloseXIcon } from '@lower-financial/icons';
import {
  formatFeatureToString,
  FormattedGoogleAddress,
  FormRow,
  getAddressByPlaceId,
  getFormattedAddressFromPlaceResult,
  Label,
  OptionalElement,
  Paragraph,
  useAddressAutocomplete,
  useModal,
  useOnClickOutside,
} from '@lower-financial/core-components';
// NOTE: TextInput is `undefined` when imported from `@lower-financial/core-components`
import { TextInput } from '@lower-financial/core-components/src/components/text-input/text-input';
import { IconButton } from '@lower-financial/core-components/src/components/icon-button/icon-button';
import { LoadingIcon } from '../loading-icon';
import PoweredByGoogle from './powered_by_google_on_white.png';

export function AddressAutocompleteWrapper({
  children, id, label = 'Address*',
}: { children: React.ReactNode; id: string; label?: string }) {
  return (
    <FormRow>
      <AutoCompleteLabelWrapper>
        <Label htmlFor={id}>
          {label}
        </Label>
        <AutoCompleteInputWrapper>
          {children}
        </AutoCompleteInputWrapper>
      </AutoCompleteLabelWrapper>
    </FormRow>
  );
}

export function doesContainUndefinedFeatures(predictions: FormattedGoogleAddress) {
  return predictions
    && predictions.streetNumber === undefined
    || predictions.streetName === undefined
    || predictions.city === undefined
    || predictions.state === undefined
    || predictions.zipcode === undefined
    || predictions.county === undefined;
}

export type AddressAutocompleteOnChange = (result: FormattedGoogleAddress|null) => void;

interface AddressAutocompleteProps {
  fallbackUi: React.ReactNode;
  id: string;
  initialSearchText: string;
  setShowFallback: React.Dispatch<React.SetStateAction<boolean>>;
  showFallback: boolean;
  onChange: AddressAutocompleteOnChange;
  placeholder: string;
  WrappingElement: React.ComponentType<{ children: React.ReactNode; id: string; }>;
  fireAnalyticsEvent: (event: string) => void;
  disabled?: boolean;
  apiKey: string;
  appEnv: string;
}

export function AddressAutocomplete({
  placeholder,
  onChange,
  fallbackUi,
  initialSearchText,
  WrappingElement,
  setShowFallback,
  showFallback,
  id,
  fireAnalyticsEvent,
  disabled = false,
  apiKey,
  appEnv,
}: AddressAutocompleteProps) {
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const [loading, results] = useAddressAutocomplete(searchText, apiKey, appEnv, 200);
  const [shouldShow, show, hide] = useModal();
  const searchWrapperRef = useRef<HTMLDivElement|null>(null);
  const searchInputRef = useRef<HTMLInputElement|null>(null);

  // this is used to detect clicks outside the wrapper element
  useOnClickOutside(searchWrapperRef, () => {
    hide();
  });

  return (
    <>
      <OptionalElement show={!showFallback}>
        <WrappingElement id={id}>
          <SearchWrapper
            ref={searchWrapperRef}
          >
            <SearchTextInputWrapper>
              <SearchInputWrapper>
                <SearchTextInput
                  autoComplete={'off'}
                  id={id}
                  placeholder={placeholder}
                  ref={searchInputRef}
                  value={searchText}
                  onChange={(e) => {
                    fireAnalyticsEvent('searched_for_address');
                    setSearchText(e.target.value);
                    onChange(null);
                  }}
                  onFocus={() => {
                    show();
                  }}
                  disabled={disabled}
                />
              </SearchInputWrapper>
              <OptionalElement show={searchText !== ''}>
                <ClearSearchButton>
                  <IconButton
                    label={'Clear address'}
                    onClick={() => {
                      setSearchText('');
                      onChange(null);
                      searchInputRef.current?.focus();
                    }}
                  >
                    <CloseXIcon color={'var(--satin)'} />
                  </IconButton>
                </ClearSearchButton>
              </OptionalElement>
            </SearchTextInputWrapper>

            <OptionalElement show={shouldShow}>
              <ResultsWrapper data-dd-privacy={'mask'}>
                <OptionalElement show={loading}>
                  <SpinnerWrapper>
                    <LoadingIcon spinnerHeight={'22px'} />
                    <Paragraph variant={'light'}>
                      Searching for your address
                    </Paragraph>
                  </SpinnerWrapper>
                </OptionalElement>
                <OptionalElement show={!loading && !!results.length}>
                  <ResultsList>
                    {results.map((r) => (
                      <PropertyButton
                        onClick={() => {
                          (async () => {
                            fireAnalyticsEvent('selected_autocomplete_result');
                            setSearchText(r.description);
                            if (r.place_id) {
                              await getAddressByPlaceId(r.place_id, (result) => {
                                if (!result) {
                                  return;
                                }
                                const address = getFormattedAddressFromPlaceResult(result);
                                setSearchText(formatFeatureToString(address) ?? '');
                                onChange(address);
                                hide();
                              }, apiKey, appEnv);
                            }
                          })();
                        }}
                        key={`${r.description}`}
                        data-dd-action-name={'Address autocomplete result'}
                      >
                        {r.description}
                      </PropertyButton>
                    ))}
                  </ResultsList>
                </OptionalElement>
                <OptionalElement show={!loading}>
                  <AddManuallyButton
                    onClick={() => {
                      fireAnalyticsEvent('manual_address_entry_clicked');
                      setShowFallback(true);
                      onChange(null);
                    }}
                    aria-label={'Add Address Manually'}
                  >
                    <PlusIcon
                      color={'var(--primary)'}
                      alt={'plus'}
                    />&nbsp;Add Address Manually
                  </AddManuallyButton>
                </OptionalElement>
                <OptionalElement show={results.length > 0 && !loading}>
                  <AttributionImageWrapper>
                    <img
                      src={PoweredByGoogle}
                      alt={'Powered by Google'}
                    />
                  </AttributionImageWrapper>
                </OptionalElement>
              </ResultsWrapper>
            </OptionalElement>
          </SearchWrapper>
        </WrappingElement>
      </OptionalElement>
      <OptionalElement show={showFallback}>
        {fallbackUi}
      </OptionalElement>
    </>
  );
}

const SearchTextInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchTextInput = styled(TextInput)`
  text-overflow: ellipsis;
  padding-right: var(--spacing-12);
`;

const ResultsList = styled.div`
  margin-top: 0.25rem;
`;

const PropertyButton = styled.button`
all: unset;
font: var(--font-paragraph-4);
color: var(--body);
padding: 0.75rem 0;
cursor: pointer;
`;

const AddManuallyButton = styled.button`
  display: flex;
  align-items: center;
  font: var(--font-paragraph-2);
  color: var(--body);
  padding: 1rem 0;
  margin-top: auto;
  > svg, > img {
    margin-right: 8px;
  }
`;

const SearchInputWrapper = styled.div({
  flex: 1,
});

const ClearSearchButton = styled.div({});

const SearchWrapper = styled.div`
position: relative;
z-index: var(--index-autocomplete-wrapper);

& ${ClearSearchButton} {
  position: absolute;
  right: 8px;
}
`;

const ResultsWrapper = styled.div`
display: flex;
flex-direction: column;
background: var(--background);
width: 100%;
position: absolute;
top: calc(100% + 12px);
z-index: 1000;
border: 1px solid var(--border);
border-radius: 12px;
padding: 0 1rem;
`;

const AutoCompleteLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const AutoCompleteInputWrapper = styled.div`
  flex: 1;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  padding: 1rem 0;
  gap: 8px;
  align-items: center;
`;

const AttributionImageWrapper = styled.div`
  margin: 0.75rem 0;
  justify-content: flex-end;
  display: flex;
`;
