import React, { useState } from 'react';
import { useAnalytics } from '@lower-financial/analytics';
import {
  Button,
  EmploymentStatus,
  Explainer,
  Heading,
  LoadingOverlay,
  OptionalElement,
  Paragraph,
  useErrorHandling,
  useValidation,
} from '@lower-financial/core-components';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { getFormValues, objectContainsFalsyPropsForKeys } from '@lower-financial/toolbox';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { Scoreboard } from '@lightspeed/components/ui/molecules/scoreboard/scoreboard';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import {
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import { useMortgageContextDefault } from '@lightspeed/hooks/useMortgageContextDefaults/useMortgageContextDefaults';
import { useUpsertApplication } from '@lightspeed/hooks/useUpsertApplication/useUpsertApplication';
import { Layout } from '@lightspeed/components/ui/organisms/layout';
import { EmployerForm } from './employer-form';
import { IncomeForm } from './income-form';

const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency',
});

function getTotalIncome(mortgageApplication: MortgageApplicationStore) {
  const income = [
    mortgageApplication.borrowerOtherIncomePerYear || '0',
    mortgageApplication.borrowerYearlySalary || '0',
    mortgageApplication.coBorrowerYearlySalary || '0',
    mortgageApplication.coBorrowerOtherIncomePerYear || '0',
  ].map<number>((stringNumber) => parseInt(stringNumber, 10))
    .reduce((acc, current) => acc + current, 0);

  return formatter.format(income).slice(1);
}

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerYearlySalary',
  'borrowerOtherIncomePerYear',
  'borrowerEmployer',
  'borrowerEmploymentStatus',
  'borrowerYearsAtCompany',
  'borrowerTitle',
];

const coBorrowerAlwaysKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerYearlySalary',
  'coBorrowerOtherIncomePerYear',
  'coBorrowerEmploymentStatus',
];

const coBorrowerEmployedKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerEmployer',
  'coBorrowerYearsAtCompany',
  'coBorrowerTitle',
];

const sanitizeNumberField = (field: HTMLInputElement) => {
  if (field.getAttribute('inputmode') === 'decimal') {
    return field.value.replaceAll(/[$.,]/g, '');
  }

  return field.value;
};

export function Income() {
  const { updateMortgageApplication, mortgageApplication } = useMortgageApplication();
  const { goToNextRoute } = useNextRoute();
  const fireAnalyticsEvent = useAnalytics('v2/income');
  const handleError = useErrorHandling();

  const { hasCoBorrower } = mortgageApplication;
  const { upsertApplication } = useUpsertApplication();
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const coBorrowerKeys = mortgageApplication.coBorrowerEmploymentStatus !== EmploymentStatus.UNEMPLOYED
    ? [...coBorrowerAlwaysKeys, ...coBorrowerEmployedKeys]
    : coBorrowerAlwaysKeys;

  useMortgageContextDefault('borrowerEmploymentStatus');
  useMortgageContextDefault('borrowerOtherIncomePerYear');
  useMortgageContextDefault('coBorrowerEmploymentStatus', hasCoBorrower);
  useMortgageContextDefault('coBorrowerOtherIncomePerYear', hasCoBorrower);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoBorrower
      ? [...borrowerKeys, ...coBorrowerKeys]
      : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower,
    },
  });

  const disableNextButton = objectContainsFalsyPropsForKeys<MortgageApplicationStore>(
    mortgageApplication,
    hasCoBorrower
      ? [...borrowerKeys, ...coBorrowerKeys]
      : borrowerKeys,
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    (async () => {
      e.preventDefault();

      const values = getFormValues(e.currentTarget, sanitizeNumberField);

      if (validate({
        ...mortgageApplication,
        ...values,
      }).success) {
        updateMortgageApplication(values);

        setShowLoadingModal(true);

        await handleError({
          onRetryableError: () => {
            setShowLoadingModal(false);
          },
          tryFn: async () => {
            await upsertApplication();
            setShowLoadingModal(false);
            fireAnalyticsEvent('next_button_success');
            goToNextRoute();
          },
        });
      }
    })();
  };

  return (
    <Layout>
      <OptionalElement show={showLoadingModal}>
        <LoadingOverlay />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <form onSubmit={handleSubmit}>
              <BasePageTemplate>
                <SplitSection>
                  <SplitSection.Content>
                    <Heading
                      element={'h1'}
                    >Let&apos;s talk income.
                    </Heading>
                    <IncomeForm
                      mortgageApplication={mortgageApplication}
                      updateMortgageApplication={updateMortgageApplication}
                      errors={errors}
                    />
                    <Explainer
                      label={'Why We Ask'}
                    >
                      <Paragraph variant={'smallLight'}>
                        It’s no secret that to buy a house, you need to make money.
                        Knowing your income helps us calculate your debt-to-income ratio,
                        which is key to qualifying you for a loan and getting you a lower rate.
                      </Paragraph>
                    </Explainer>
                    <EmployerForm
                      mortgageApplication={mortgageApplication}
                      updateMortgageApplication={updateMortgageApplication}
                      errors={errors}
                    />
                  </SplitSection.Content>
                  <SplitSection.Sidebar>
                    <Scoreboard
                      marginY={'36px'}
                      label={'Total'}
                      prefix={'$'}
                      value={getTotalIncome(mortgageApplication)}
                    />
                    <Button
                      marginTop={'48px'}
                      type={'submit'}
                      disabled={disableNextButton}
                    >
                      Next
                    </Button>
                  </SplitSection.Sidebar>
                </SplitSection>
              </BasePageTemplate>
            </form>
          </>
        )}
        mobile={(
          <>
            <Header />
            <form onSubmit={handleSubmit}>
              <BasePageTemplate>
                <Heading
                  element={'h1'}
                >
                  Let&apos;s talk income.
                </Heading>
                <IncomeForm
                  mortgageApplication={mortgageApplication}
                  updateMortgageApplication={updateMortgageApplication}
                  errors={errors}
                />
                <Explainer
                  label={'Why We Ask'}
                >
                  <Paragraph variant={'smallLight'}>
                    It’s no secret that to buy a house, you need to make money.
                    Knowing your income helps us calculate your debt-to-income ratio,
                    which is key to qualifying you for a loan and getting you a lower rate.
                  </Paragraph>
                </Explainer>
                <Scoreboard
                  marginY={'36px'}
                  label={'Total'}
                  prefix={'$'}
                  value={getTotalIncome(mortgageApplication)}
                />
                <EmployerForm
                  mortgageApplication={mortgageApplication}
                  updateMortgageApplication={updateMortgageApplication}
                  errors={errors}
                />
                <Button
                  disabled={disableNextButton}
                  type={'submit'}
                  marginY={'60px'}
                >
                  Next
                </Button>
              </BasePageTemplate>
            </form>
          </>
        )}
      />
    </Layout>
  );
}
