import { CookieAttributes } from 'js-cookie';

export const getCookieOptions = (): CookieAttributes => {
  let hostname;
  if (window.location.hostname === 'localhost') {
    hostname = 'localhost';
  } else if (window.location.hostname === '127.0.0.1') {
    hostname = '127.0.0.1';
  } else {
    const hostnameParts = window.location.hostname.split('.');
    hostname = `.${hostnameParts[hostnameParts.length - 2]}.${hostnameParts[hostnameParts.length - 1]}`;
  }

  return {
    domain: hostname,
  };
};
