
export const Info = ({ ...props }) => (
  <svg
    width={'15'}
    height={'15'}
    viewBox={'0 0 15 15'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <circle
      cx={'7.5'}
      cy={'7.5'}
      r={'7'}
      stroke={'#C4C6D1'}
    />
    <rect
      x={'6.5'}
      y={'7'}
      width={'2'}
      height={'4'}
      rx={'1'}
      fill={'#C4C6D1'}
    />
    <rect
      x={'6.5'}
      y={'4'}
      width={'2'}
      height={'2'}
      rx={'1'}
      fill={'#C4C6D1'}
    />
  </svg>
);
