import {
  Paragraph,
} from '@lower-financial/core-components';
import styled from 'styled-components';

export const ApplicationDisclaimer = ({ isContinuing }: { isContinuing: boolean }) => (
  <div>
    <Paragraph variant={'legal'}>
      By {isContinuing
        ? 'continuing'
        : 'starting'} or submitting your application, you agree to the&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/details/personal-apply-terms'}
        rel={'noreferrer'}
      >
        Terms of Use
      </a>,&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/details/personal-apply-terms#sms'}
        rel={'noreferrer'}
      >
        SMS Texting
      </a>,&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/details/personal-apply-terms#electronic-docs'}
        rel={'noreferrer'}
      >Electronic Documents
      </a>,&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/details/personal-apply-terms#realtor'}
        rel={'noreferrer'}
      >Realtor Communication
      </a>,&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/legal/privacy-policy#sharing-non-affiliates'}
        rel={'noreferrer'}
      >Non-affiliate Information Sharing Policy
      </a>,&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/details/personal-apply-terms#electronic-docs'}
        rel={'noreferrer'}
      >eConsent Policy
      </a>. Results from application do not represent a commitment to lend for the terms specified
      and are subject to credit approval.
    </Paragraph>

    <ParagraphLinkContainer>
      <ParagraphLink href={'https://www.lower.com/legal/license-information'}>License</ParagraphLink>
      &nbsp;
      |
      &nbsp;
      <ParagraphLink href={'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1124061'}>NMLS</ParagraphLink>
      &nbsp;
      |
      &nbsp;
      <ParagraphLink href={'https://www.lower.com/legal/privacy-policy'}>Privacy</ParagraphLink>
      &nbsp;
      |
      &nbsp;
      <ParagraphLink href={'https://www.lower.com/legal/website-terms-of-use'}>Terms</ParagraphLink>
    </ParagraphLinkContainer>
  </div>
);

const ParagraphLinkContainer = styled.div`
  color: var(--body);
  font: var(--font-paragraph-link);
  width: 100%;
  margin-top: 14px;
`;

const ParagraphLink = styled.a`
  color: var(--body);
`;
