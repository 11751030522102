import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import RightCaret from './RightCaret.svg';

interface SwipeButtonProps {
  children: JSX.Element | string;
  onSwipe: () => void;
  label: string;
}

export function SwipeButton({
  children, onSwipe, label,
}: SwipeButtonProps) {
  const sliderContainerRef = useRef<HTMLDivElement|null>(null);
  const [percentage, setPercentage] = useState(1);
  const [mouseIsGrabbing, setMouseIsGrabbing] = useState(false);

  useEffect(() => {
    if (sliderContainerRef.current) {
      /**
       * setting this to an arbitrarily large number, scrolls to end of scrollview
       */
      sliderContainerRef.current.scrollLeft = 100000;
    }
  }, []);

  const onScrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    setPercentage(e.currentTarget.scrollLeft / e.currentTarget.scrollWidth);
    if (e.currentTarget.scrollLeft === 0) {
      setPercentage(1);
      onSwipe();
    }
  };

  return (
    <ButtonWrapper>
      <ButtonBackground>
        <div
          style={{
            opacity: percentage * 2,
          }}
        >
          {children}
        </div>
      </ButtonBackground>
      <SliderContainer
        ref={sliderContainerRef}
        onScroll={onScrollHandler}
        data-testid={'scroll-container'}
        role={'slider'}
        aria-label={label}
        onMouseDown={() => setMouseIsGrabbing(true)}
        onMouseMove={(e: React.MouseEvent) => {
          if (mouseIsGrabbing) {
            e.currentTarget.scrollLeft -= e.movementX;
          }
        }}
        onMouseUp={() => setMouseIsGrabbing(false)}
      >
        <SliderView>
          <ButtonKnob>
            <img
              src={RightCaret}
              alt={'icon'}
            />
          </ButtonKnob>
        </SliderView>
      </SliderContainer>
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  position: relative;
  user-select: none;
`;

const SliderContainer = styled.div`
  position: absolute;
  inset: 0;
  overflow-x: scroll;
  cursor: grab;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SliderView = styled.div`
  position: absolute;
  inset: 0;
  width: calc(200% - 60px); /* 60px is equal to the width of the knob plus the inset of 4 px on each side */
  height: 100%;
`;

const ButtonKnob = styled.div`
  position: absolute;
  top: 4px;
  left: 50%;
  width: 52px;
  height: 52px;
  background: linear-gradient(180deg, #0016E5 0%, #0068FF 100%);
  border-radius: 50%;
  transform: translateX(-50%);

  & img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const ButtonBackground = styled.div`
  padding: 24px;
  background-color: var(--primary);
  border-radius: 32.25px;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 0.75rem;
  text-align: center;
  color: var(--white);
`;
