import React, { ComponentProps, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { PostHogProvider } from 'posthog-js/react';
import { Market } from '@lower-financial/lending-web-api/generated';
import { theme } from '@lower-financial/core-components/src/styles/primary/theme';
import { MortgageApplicationStore } from '@lower-financial/mortgage-utils';
import { initPostHog } from '@lower-financial/feature-flags';
import { sendException } from '@lower-financial/toolbox';
import { ErrorBoundary } from 'react-error-boundary';
import * as braze from '@braze/web-sdk';
import { MortgageContextProvider } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { QuotesStore } from '@lightspeed/contexts/quotes-context/use-quotes-state';
import { QuotesContextProvider } from '@lightspeed/contexts/quotes-context/quotes-context';
import { GlobalStyle } from '@lightspeed/styles/global-style';
import { MarketContextProvider } from '@lightspeed/contexts/market-context/markets-context';
import { PartnerConfigurationContextProvider } from '@lightspeed/contexts/partner-configuration-context/partner-configuration-context';
import { PartnerStylesheetProvider } from '@lightspeed/components/app/partner-stylesheet-provider';
import { ReferralDataContextProvider } from '@lightspeed/contexts/referral-context/referral-context';
import { ENV } from '@lightspeed/environment';
import { FeatureFlagWatcher } from '@lightspeed/components/app/feature-flag-watcher';
import { RatesContextProvider } from '@lightspeed/contexts/rates-context/rates-context';
import { TodaysRates } from '@lightspeed/hooks/useTodaysRates';
import { VhCalculator } from '@lightspeed/components/app/vh-calculator';
import { CurrencyRange } from '@lightspeed/api/services/backend/lending/getCurrencyRanges';
import { CurrencyRangesContextProvider } from '@lightspeed/contexts/currency-ranges-context/currency-ranges-context';
import { Error } from '@lightspeed/components/ui/pages/error/error';

interface AppProviderComponentProps {
  children?: React.ReactNode;
  mortgageContextOverride?: Partial<MortgageApplicationStore>,
  quotesContextOverride?: Partial<QuotesStore>,
  marketsContextOverride?: null | Market[],
  ratesContextOverride?: TodaysRates | null,
  enableErrorBoundary?: boolean,
  currencyRangesContextOverride?: CurrencyRange[] | null,
}

const postHog = initPostHog(ENV.POSTHOG_API_KEY, ENV.POSTHOG_API_HOST);

braze.initialize(ENV.BRAZE_API_KEY, {
  baseUrl: ENV.BRAZE_BASE_URL,
});
braze.openSession();

function OptionalErrorBoundary({
  children,
  enabled = true,
}: {
  children: ReactNode|ReactNode[],
  enabled?: boolean
}) {
  return (
    <ErrorBoundary
      FallbackComponent={Error}
      onError={(e) => {
        sendException(e);
        if (!enabled) {
          throw e;
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export function AppProvider(
  {
    children,
    mortgageContextOverride = {},
    quotesContextOverride = {},
    marketsContextOverride = null,
    ratesContextOverride = null,
    enableErrorBoundary = true,
    currencyRangesContextOverride = null,
  }: AppProviderComponentProps,
) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <OptionalErrorBoundary enabled={enableErrorBoundary}>
        <VhCalculator />
        <PostHogProvider
          client={postHog}
        >
          <ReferralDataContextProvider>
            <PartnerConfigurationContextProvider>
              <PartnerStylesheetProvider>
                <MarketContextProvider overrides={marketsContextOverride}>
                  <CurrencyRangesContextProvider overrides={currencyRangesContextOverride}>
                    <MortgageContextProvider overrides={mortgageContextOverride}>
                      <FeatureFlagWatcher />
                      <QuotesContextProvider overrides={quotesContextOverride}>
                        <RatesContextProvider overrides={ratesContextOverride}>
                          {children}
                        </RatesContextProvider>
                      </QuotesContextProvider>
                    </MortgageContextProvider>
                  </CurrencyRangesContextProvider>
                </MarketContextProvider>
              </PartnerStylesheetProvider>
            </PartnerConfigurationContextProvider>
          </ReferralDataContextProvider>
        </PostHogProvider>
      </OptionalErrorBoundary>
    </ThemeProvider>
  );
}

export type AppProviderProps = ComponentProps<typeof AppProvider>;
