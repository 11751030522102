import styled from 'styled-components';
import cx from 'classnames';
import { compose, layout, LayoutProps } from 'styled-system';
import { ComponentProps } from 'react';

export type SeparatorComponentProps =
  LayoutProps & { className?: string; } &
  ({ horizontal: true; vertical?: never; height?: never; } |
  { horizontal?: never; vertical: true; width?: never; });

export const Separator = styled.div.attrs(
  ({
    className,
    ...props
  }: SeparatorComponentProps) =>
    ({
      className: cx('Separator', className, {
        '--horizontal': props.horizontal,
        '--vertical': props.vertical,
      }),
    }),
)<SeparatorComponentProps> `
  &.--vertical {
    width: 1px;
    background-color: var(--background-active);
  }

  &.--horizontal {
    height: 1px;
    background-image: linear-gradient(270deg, rgba(28, 28, 30, 0) 0%, rgba(28, 28, 30, 0.1) 50%, rgba(28, 28, 30, 0) 100%);
  }

  ${ compose(layout) }
`;

export type SeparatorProps = ComponentProps<typeof Separator>;
