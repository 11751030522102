export const poll = async <T>(
  fn: () => Promise<T>,
  fnCondition: (result: T) => boolean,
  ms: number,
  timeout: number,
  onTimeout: () => void = () => {
    // do nothing
  },
) => {
  let result = await fn();
  let totalTime = 0;
  while (totalTime <= timeout && !fnCondition(result)) {
    await wait(ms);
    totalTime += ms;
    result = await fn();
  }
  if (totalTime > timeout) {
    onTimeout();
  }
  return result;
};

const wait = async (ms = 1000) =>  new Promise((resolve) => {
  setTimeout(resolve, ms);
});

