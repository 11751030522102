import styled from 'styled-components';
import {
  border,
  BorderProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { ComponentProps } from 'react';

/**
 * Acts similar to a bootstrap container.
 * Use {fluid} to make full-width.
 */
export const Section = styled.div<{
  className?: string;
  fluid?: boolean;
} &
FlexboxProps &
SpaceProps &
LayoutProps &
BorderProps &
TypographyProps>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  transition: width 200ms ease-out,
  height 200ms ease-out,
  opacity 200ms ease-out;

  width: 100%;
  margin: 0 auto;
  max-width: ${ (props) => (props.fluid
    ? '100%'
    : props.theme.contentWidth) };

  ${
  compose(
    flexbox,
    layout,
    border,
    space,
    typography,
  )
}
`;

export type SectionProps = ComponentProps<typeof Section>;
