import { useAnalytics } from '@lower-financial/analytics';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

export const useMortgageAnalytics = (pageName: string) => {
  const { mortgageApplication } = useMortgageApplication();
  const loanPurposePrefix = mortgageApplication.loanPurpose?.concat('_') ?? '';
  const fireAnalyticsEvent = useAnalytics(`${loanPurposePrefix}${pageName}`);

  return fireAnalyticsEvent;
};
