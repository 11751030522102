import { Loader } from '@googlemaps/js-api-loader';

function initGoogleMaps(apiKey: string, appEnv: string) {
  const loader = new Loader({
    apiKey,
    libraries: ['places'],
    version: 'weekly',
  });

  if (appEnv === 'test') {
    // this pattern was used in the source code unit tests here https://github.com/googlemaps/js-api-loader/blob/6fe1903ba5119f5459e65d18c27ee22f5d0abfaa/src/index.test.ts#L103
    loader.importLibrary = () => Promise.resolve();
  }

  return loader.load();
}

const map = document.createElement('div');

export async function getAddressByPlaceId(
  placeId: string,
  callback: Parameters<typeof window.google.maps.places.PlacesService.prototype.getDetails>[1],
  apiKey: string,
  appEnv: string,
) {
  const google = await initGoogleMaps(apiKey, appEnv);

  const request = {
    fields: ['address_components', 'geometry'],
    placeId,
  };

  const service = new google.maps.places.PlacesService(map);
  service.getDetails(request, callback);
}

export async function getAutocompletePredictions(
  searchText: string,
  callback: Parameters<typeof window.google.maps.places.AutocompleteService.prototype.getPlacePredictions>[1],
  apiKey: string,
  appEnv: string,
) {
  const googlePromise = initGoogleMaps(apiKey, appEnv);

  const request = {
    componentRestrictions: {
      country: 'us',
    },
    input: searchText,
    types: ['geocode'],
  };

  const google = await googlePromise;
  const placesService = new google.maps.places.AutocompleteService();

  await placesService.getPlacePredictions(request, callback);
}
